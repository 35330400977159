import React, { useState } from 'react';
import DashboardPlaylistsCard from './DashboardPlaylistsCard';
import { T } from '@transifex/react';

const DashboardPlaylists = ({ playlistsData, isloading }) => {
  return (
    <div>
      <div className='dashboardPlaylists'>
        {playlistsData.length > 0 ? (
          <DashboardPlaylistsCard playlistsData={playlistsData} />
        ) : (
          <>
            {(isloading) ? (
              <p>
                <br />
                <T _str="Loading" />
              </p>
            ) : (
              <p>
                <br />
                <T _str="No Playlists Found" />
              </p>
            )}
          </>
        )}
      </div>

    </div>
  );
};

export default DashboardPlaylists;