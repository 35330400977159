import { useState, useEffect, useContext } from 'react';
import axios from "axios";
import { MediaContext } from './LibraryContext';
import config from '../../utils/config';
import { T } from '@transifex/react';
import LoadingOverlay from "react-loading-overlay";
import { t } from "@transifex/native";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import $ from "jquery";
import FontAwesome from 'react-fontawesome';
import ReactTooltip from 'react-tooltip';
import Resizer from "react-image-file-resizer";
import { Buffer } from 'buffer';

const Fileupload = props => {
  const [loading, setLoading] = useState(false);
  const [vfid, setVfid] = useState("");
  const [imagefid, setImagefid] = useState("");
  const { dispatchMediaEvent } = useContext(MediaContext);
  const [image, setImage] = useState("");
  const [video, setVideo] = useState("");

  // Get logged in user details
  let userDetails = localStorage.getItem("userdetails");
  let loggedInUserID = '';
  if (userDetails === null) {
    loggedInUserID = 1;
  } else {
    loggedInUserID = JSON.parse(userDetails).id;
  }

  const notify = (val) => {
    toast.error(val, {
      toastId: loggedInUserID
    });
  }

  const fileUploadButton = () => {
    document.getElementById('fileButton').click();
  }

  const videoUploadButton = () => {
    document.getElementById('videoButton').click();
  }
  const resizeFile = (file) => new Promise(resolve => {
    Resizer.imageFileResizer(file, 1920, 1080, 'JPEG', 100, 0,
      uri => {
        resolve(uri);
      }, 'base64');
  });

  const onFileChange = async (e) => {
    const uFile = e.target.files;
    if (uFile.length !== 0) {
      setLoading(true);
      setImage(e.target.files[0]);
      const allowedFileTypes = ['image/jpeg', 'image/png', 'image/jpg'];
      const imageFileSize = e.target.files[0].size / 1024 / 1024;
      var frReader = new FileReader();
      frReader.fileName = e.target.files[0].name;
      frReader.readAsArrayBuffer(e.target.files[0]);
      frReader.addEventListener("loadend", () => {
        const arr = new Uint8Array(frReader.result).subarray(0, 4);
        let header = "";
        for (let i = 0; i < arr.length; i++) {
          header += arr[i].toString(16);
        }

        if (header === '47494638') {
          notify(t("The selected file %filename cannot be uploaded. File media type detected %type.").replace('%filename', e.target.files[0].name).replace('%type', 'image/gif'));
          setImage("");
          setLoading(false);
          return false;
        }
      })
      if (!allowedFileTypes.includes(e.target.files[0].type)) {
        notify(t("The selected file %filename cannot be uploaded. File type must be %extensions.").replace('%filename', e.target.files[0].name).replace('%extensions', 'png, jpg, jpeg, jfif'))
        setImage("");
        setLoading(false);
        return false;
      }
      else if (imageFileSize > props.ImageMaxAllowedSize) {
        notify(t("The selected file @filename cannot be uploaded. File size cannot exceed @maxFileSize.").replace('@filename', e.target.files[0].name).replace('@maxFileSize', props.ImageMaxAllowedSize + ' MB'))
        setImage("");
        setLoading(false);
        return false;
      } else {
        var file = e.target.files[0];
        var file_name = file.name.replace(/\s/g, "");
        var file_name_wo_extn = file_name.split('.').slice(0, -1).join('.');

        // Filename validation for special characters
        var reg_exp = /^[0-9a-zA-Z]+$/;
        var is_valid = reg_exp.test(file_name_wo_extn);
        if (!is_valid) {
          notify(t('Filename cannot include special characters. Please try again.'));
          setImage("");
          setLoading(false);
          return false;
        }

        var fReader = new FileReader();
        fReader.fileName = e.target.files[0].name;
        fReader.readAsDataURL(e.target.files[0]);
        fReader.onload = function (e) {
          var img = new Image();
          img.src = e.target.result;
          img.onload = function () {
            var height = this.height;
            var width = this.width;
            if (height < 150 || width < 470) {
              notify(t("The specified file %name could not be uploaded.").replace('%name', fReader.fileName) + ' ' + t("The image is too small; the minimum dimensions are %dimensions pixels.").replace('%dimensions', '470x150'));
              setImage("");
              setLoading(false);
              return false;
            } else {
              if (height > 1080 && width > 1920) {
                resizeFile(file).then((response) => {
                  if (response) {
                    const mimeType = response.split(";")[0];
                    var byteString = atob(response.split(',')[1]);
                    var ab = new ArrayBuffer(byteString.length);
                    var ia = new Uint8Array(ab);
                    for (var i = 0; i < byteString.length; i++) {
                      ia[i] = byteString.charCodeAt(i);
                    }
                    var fileGenerated = new File([ab], file_name, { type: mimeType });
                    //const encodedString = Buffer.from(config.auth_username + ':' + config.auth_password).toString('base64');
                    let reader = new FileReader();
                    reader.readAsArrayBuffer(fileGenerated);
                    reader.onabort = () => { console.log("file reader aborted") }
                    reader.onerror = () => { console.log('error with file reader') }
                    reader.onload = () => {
                      const arrayStr = reader.result;
                      const fileSaveUrl = config.drupal_url + '/api/node/article/field_image';
                      axios.get(config.drupal_url + '/session/token').then(result => {
                        if (200 === result.status) {
                          const csrfToken = result.data;
                          // Create a patch for JSON API changes
                          axios.post(fileSaveUrl, arrayStr, {
                            headers: {
                              "Content-Type": "application/octet-stream",
                              "Accept": "application/vnd.api+json",
                              "Content-Disposition": `file; filename="${file_name}"`,
                              withCredentials: true,
                              'X-CSRF-Token': csrfToken,
                            }
                          })
                            .then((res) => {
                              const imagefid = res.data.data.attributes.drupal_internal__fid;
                              setImagefid(imagefid);
                              dispatchMediaEvent({ 'fid': imagefid, 'vfid': vfid });
                              setLoading(false);
                            })
                            .catch(function (error) {
                              setLoading(false)
                              notify(t("Please try later.If the error persists, please contact Otis support."));
                            })
                        }
                        else {
                          setLoading(false)
                          notify(t("Please try later.If the error persists, please contact Otis support."));
                        }
                      })
                    }
                  }
                })
              } else {
                //const encodedString = Buffer.from(config.auth_username + ':' + config.auth_password).toString('base64');
                let reader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.onabort = () => { console.log("file reader aborted") }
                reader.onerror = () => { console.log('error with file reader') }
                reader.onload = () => {
                  const arrayStr = reader.result;
                  const fileSaveUrl = config.drupal_url + '/api/node/article/field_image';
                  axios.get(config.drupal_url + '/session/token').then(result => {
                    if (200 === result.status) {
                      const csrfToken = result.data;
                      // Create a patch for JSON API changes
                      axios.post(fileSaveUrl, arrayStr, {
                        headers: {
                          "Content-Type": "application/octet-stream",
                          "Accept": "application/vnd.api+json",
                          "Content-Disposition": `file; filename="${file_name}"`,
                          withCredentials: true,
                          'X-CSRF-Token': csrfToken,
                        }
                      })
                        .then((res) => {
                          const imagefid = res.data.data.attributes.drupal_internal__fid;
                          setImagefid(imagefid);
                          dispatchMediaEvent({ 'fid': imagefid, 'vfid': vfid });
                          setLoading(false);
                        })
                        .catch(function (error) {
                          setLoading(false)
                          notify(t("Please try later.If the error persists, please contact Otis support."));
                        })
                    }
                    else {
                      setLoading(false)
                      notify(t("Please try later.If the error persists, please contact Otis support."));
                    }
                  })
                }
              }
            }
          }
        }
      }
    }
  }

  const onVideoFileChange = (e) => {
    const uvFile = e.target.files;
    if (uvFile.length !== 0) {
      setLoading(true);
      setVideo(e.target.files[0]);
      const allowedFileTypes = ['video/mp4'];
      const videoFileSize = e.target.files[0].size / 1024 / 1024;
      if (!allowedFileTypes.includes(e.target.files[0].type)) {
        notify(t("The selected file %filename cannot be uploaded. File type must be %extensions.").replace('%filename', e.target.files[0].name).replace('%extensions', 'mp4'))
        setVideo("");
        setLoading(false);
        return false;
      } else if (videoFileSize > props.VideoMaxAllowedSize) {
        notify(t("The selected file @filename cannot be uploaded. File size cannot exceed @maxFileSize.").replace('@filename', e.target.files[0].name).replace('@maxFileSize', props.VideoMaxAllowedSize + ' MB'))
        setVideo("");
        setLoading(false);
        return false;
      } else {
        var file = e.target.files[0];
        var file_name = file.name.replace(/\s/g, "");
        var file_name_wo_extn = file_name.split('.').slice(0, -1).join('.');

        // Filename validation for special characters
        var reg_exp = /^[0-9a-zA-Z]+$/;
        var is_valid = reg_exp.test(file_name_wo_extn);
        if (!is_valid) {
          notify(t('Filename cannot include special characters. Please try again.'));
          setVideo("");
          setLoading(false);
          return false;
        }

        var reader = new FileReader();
        reader.fileName = e.target.files[0].name;
        reader.readAsDataURL(file);
        reader.onload = function () {
          var videoId = "videoMain";
          var $videoEl = $('<video id="' + videoId + '"></video>');
          $videoEl.attr('src', URL.createObjectURL(file));
          var videoTagRef = $videoEl[0];
          try {
            let corruptFlag = 0;
            videoTagRef.addEventListener('loadedmetadata', function () {
              corruptFlag = 1;
              // Upload video
              //const encodedString = Buffer.from(config.auth_username + ':' + config.auth_password).toString('base64');
              let reader = new FileReader();
              reader.readAsArrayBuffer(file);
              reader.onabort = () => { console.log("file reader aborted") }
              reader.onerror = () => { console.log('error with file reader') }
              reader.onload = () => {
                const arrayStr = reader.result;
                const fileSaveUrl = config.drupal_url + '/api/node/article/field_video_upload';
                axios.get(config.drupal_url + '/session/token').then(result => {
                  if (200 === result.status) {
                    const csrfToken = result.data;
                    // Create a patch for JSON API changes
                    axios.post(fileSaveUrl, arrayStr, {
                      headers: {
                        "Content-Type": "application/octet-stream",
                        "Accept": "application/vnd.api+json",
                        "Content-Disposition": `file; filename="${file_name}"`,
                        withCredentials: true,
                        'X-CSRF-Token': csrfToken,
                      }
                    })
                      .then((res) => {
                        const vfid = res.data.data.attributes.drupal_internal__fid;
                        const removeAudioURL = config.drupal_url + '/rest/api/post/remove/audio?_format=hal_json';
                        let data = {
                          'fid': vfid
                        };
                        const headers = {
                          "Content-Type": "application/hal+json",
                          withCredentials: true,
                          'X-CSRF-Token': csrfToken,
                        };
                        axios.post(removeAudioURL, data, { headers })
                          .then((response) => {
                            if (response.data.message === "success") {
                              setVfid(response.data.fid);
                              dispatchMediaEvent({ 'fid': imagefid, 'vfid': vfid });
                              setLoading(false);
                            } else {
                              setLoading(false);
                              notify(t("Please try later.If the error persists, please contact Otis support."));
                              setVideo("");
                            }
                          })
                          .catch(function (error) {
                            setLoading(false)
                            notify(t("Please try later.If the error persists, please contact Otis support."));
                            setVideo("");
                          })
                      })
                      .catch(function (error) {
                        setLoading(false)
                        notify(t("Please try later.If the error persists, please contact Otis support."));
                      })
                  }
                  else {
                    setLoading(false)
                    notify(t("Please try later.If the error persists, please contact Otis support."));
                  }
                })
              }

            });
            setTimeout(() => {
              if (corruptFlag === 0) {
                setVideo("");
                setLoading(false);
                notify(t("Please try later.If the error persists, please contact Otis support."));
                return false;
              }
            }, 500);

          }
          catch (err) {
            notify(t("Please try later.If the error persists, please contact Otis support."));
          }
          URL.revokeObjectURL(file)
        };
      }
    }
  }

  setTimeout(() => {
    $('#upload-video-instructions ul li').each(function () {
      var $this = $(this);
      var t = $this.html();
      var newString = t.replace("!size", "<strong>" + props.VideoMaxAllowedSize + " MB</strong>")
        .replace('!extensions', "<strong>mp4</strong>")
        .replace('!framerate', '<strong>' + props.MaxAllowedFPS + '</strong>')
        .replace('!bitrate', '<strong>' + props.MaxAllowedBitrate + '</strong>');
      $this.html(newString);
    });
  }, 500);

  setTimeout(() => {
    $('#upload-instructions ul li').each(function () {
      var $this = $(this);
      var t = $this.html();
      var newString = t.replace("!size", "<strong>" + props.ImageMaxAllowedSize + " MB</strong>")
        .replace('!extensions', "<strong>png jpg jpeg</strong>")
        .replace('!min', '<strong>470x150</strong>')
        .replace('!max', '<strong>1920x1080</strong>');
      $this.html(newString);
    });
  }, 500);

  return (
    <>
      <LoadingOverlay active={loading} spinner text={t('Processing')} className="file_upload_loader">
        {(props.tab === 'video') &&
          <div className="media_video_upload media_file_upload" id="media_video_upload">
            <label htmlFor="upload_video" className="upload_video element_label">
              <T _str="Upload video" /> *
            </label>
            <input
              id="videoButton"
              type="file"
              name="selectedVideoFile"
              onChange={onVideoFileChange}
              onClick={(event) => { event.target.value = null }}
              hidden
            />
            <div className="custom_file_upload">
              <button onClick={videoUploadButton} className="file_button">
                <T _str="Choose File" />
              </button>
              <label id="selectedfilename">{video ? video.name : t('No file chosen')}</label><br />
            </div>
            <div className="help-block">
              <a data-tip={'custom show'} data-event={'click focus'} data-for={'video-tp'}>
                <FontAwesome name="question-circle" size="lg" className="question-circle" />
                <T _str="More information" />
              </a>
              <div class ="note-text"><span><T _str="Note:" /></span><T _str="Videos are getting resized to fit the screen resolution." /></div>
              <ReactTooltip
                isCapture={true}
                id={'video-tp'}
                className="media_tooltip"
                effect="solid"
                globalEventOff='click'
                clickable={true}
                place="bottom"
                getContent={function () {
                  return (
                    <div id="upload-video-instructions" className="upload-instructions">
                      <h3 className="upload-instructions-title"><T _str="File requirements" /></h3>
                      <div className="arrow-cs"></div>
                      <ul>
                        <li>{t('File must be smaller than !size.')}</li>
                        <li>{t('Allowed file types: !extensions.')}</li>
                        <li>{t('Maximum frame rate: !framerate fps.')}</li>
                        <li>{t('Maximum bit rate: !bitrate mbps.')}</li>
                      </ul>
                    </div>
                  )
                }}
              />
            </div>
          </div>
        }
        <div className="media_image_upload media_file_upload" id="media_image_upload">
          <label htmlFor="upload_field" className="upload_label element_label">
            <T _str="Upload preview image" /> *
          </label>
          <input
            id="fileButton"
            type="file"
            name="selectedFile"
            onChange={onFileChange}
            onClick={(event) => { event.target.value = null }}
            hidden
          />
          <div className="custom_file_upload">
            <button onClick={fileUploadButton} className="file_button">
              <T _str="Choose File" />
            </button>
            <label id="selectedfilename">{image ? image.name : t('No file chosen')}</label>
          </div>
        </div>
        <div className="help-block">
          <a data-tip={'custom show'} data-event={'click focus'} data-for={'image-tp'}>
            <FontAwesome name="question-circle" size="lg" className="question-circle" />
            <T _str="More information" />
          </a>
          <ReactTooltip
            isCapture={true}
            id={'image-tp'}
            className="media_tooltip"
            effect="solid"
            globalEventOff='click'
            clickable={true}
            place="bottom"
            getContent={function () {
              return (
                <div id="upload-instructions" className="upload-instructions">
                  <h3 className="upload-instructions-title"><T _str="File requirements" /></h3>
                  <div className="arrow-cs"></div>
                  <ul>
                    <li>{t('File must be smaller than !size.')}</li>
                    <li>{t('Allowed file types: !extensions.')}</li>
                    <li>{t('Images must be between !min and !max pixels.')}</li>
                  </ul>
                </div>
              )
            }}
          />
        </div>
      </LoadingOverlay>
    </>
  );
}

export default Fileupload;