/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: CMSLicense.js
File Description: Implements CMSLicense functionality of CMS portal

Copyright 2021,2022 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
import React from 'react';
import Pagination from '../Components/Common/Pagination';
import { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../utils/config';
import { T } from '@transifex/react';

function CMSLicense(props) {
  const [currentPage, setCurrentPage] = useState(1);
  const [licensePerPage] = useState(10);  
  const [CMSLicense, setCMSLicense] = useState([]);
  const paginate = pageNumber => setCurrentPage(pageNumber);

  useEffect(()=> {
		const getCMSLicense = async () => {
		await axios.get(config.drupal_url + '/api/cmslicense')
      .then(response => {
			  setCMSLicense(response.data);
		  })
		  .catch(error=>console.error('API Error:'+error));
		}
		getCMSLicense();
	}
	, []);
  
  // Get current Playlists
  const indexOfLastLicense = currentPage * licensePerPage;
  const indexOfFirstLicense = indexOfLastLicense - licensePerPage;
  const currentLicense = CMSLicense.slice(indexOfFirstLicense, indexOfLastLicense);
  return (
    <>
			<div class="container">
        <div class="row">
          <div class="main-title-hold">
              <div class="left-title-hold">
				        <h1>eView<sup>&reg;</sup> - <T _str="cms licence" /></h1>
		        	</div>
            </div>
          </div>
        </div>
      <div class="container">
        <div class="row">
            <div class="column">
                <table class="table">
                    <thead className="licence-table-head">
                      <tr>
                      <th><T _str="Module Name"/></th>
                      <th><T _str="License Type"/></th>
                      <th><T _str="License URL"/></th>
                      </tr>
                    </thead>
                    <tbody className="licence-table-body">
                      {currentLicense.map((license, index) => {
                        var licenseUrlLink = license.field_license_url.includes('internal:')?(config.drupal_url+license.field_license_url.replace('internal:','')):license.field_license_url;
                        var licenseUrl = license.field_license_url.includes('internal:')?(license.field_license_url.replace('internal:','')):license.field_license_url;
                        
                        return (
                            <tr>
                              <td>{license.title}</td>
                              <td>{license.field_type_of_license}</td>
                              <td><a href={licenseUrlLink} target="blank">{licenseUrl}</a></td>
                            </tr>
                          );
                        })}
                    </tbody>
                </table>
              </div>
              </div>
           </div>
           <div class="container">
              <div class="row">
                <Pagination
                  itmesPerPage={licensePerPage}
                  totalItems={CMSLicense.length}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              </div>
            </div>
      </>
    );
}

export default CMSLicense;
