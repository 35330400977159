import React from 'react';
import { Modal } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { T } from '@transifex/react';
import { t } from "@transifex/native"
import config from '../../utils/config';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const AddGroups = (props) => {

  const [addgroup, setAddgroup] = useState([]);
  const [groupInput, setgroupInput] = useState('');
  const [screenIdsChecked, setScreenIdsChecked] = useState([]);  
  const {closePopup} = props;
  const {setRefreshKey} = props;
  const {setLoading} = props;	
  
  const INITIAL_ERROR_MSGS = {
    group_title: false,
    group_title_count:false,
    screens_selected: false
  };
  const [errorMsgs, setErrorMsgs] = useState(INITIAL_ERROR_MSGS);

  // Get logged in user details
  let userDetails = localStorage.getItem("userdetails");
  let loggedInUserID = '';
  if (userDetails === null) {
    loggedInUserID = 1;
  } else {
    loggedInUserID = JSON.parse(userDetails).id;
  }

  useEffect(() => {
    const addgroup = async () => {
      axios.get(config.drupal_url + '/api/user_assigned_devices/' + loggedInUserID, { withCredentials: true })
        .then(response => {
          setAddgroup(response.data);
          setLoading(false);
        })
    }
    addgroup();
  }, []);

  const handleCheckedscreen = (screenid, event) => {
    const screenChecked = [...screenIdsChecked];
    if (screenChecked.includes(event.target.id)) {
      var index = screenChecked.indexOf(event.target.id);
      screenChecked.splice(index, 1);
    } else {
      screenChecked.push(event.target.id);
    }
    setScreenIdsChecked(screenChecked);

  };

  const handleChange = (event) => {
    setgroupInput(event.target.value);
  }
  const onAddgroup = (e) => {
    e.preventDefault();
    setErrorMsgs({
      group_title: !groupInput || groupInput.trim() == "" ? true : false,
      screens_selected: !screenIdsChecked || screenIdsChecked == "" ? true : false,
      group_title_count: (groupInput.trim().length > 25)? true : false
    });

    if (groupInput.trim() != '' && screenIdsChecked.length > 0 && groupInput.trim().length < 25) { 
      setLoading(true);
      const groupvalue = {
        "user_id": loggedInUserID,
        "title": groupInput.trim(),
        "devices": screenIdsChecked
      }
      axios.post(config.drupal_url + '/CreateGroup?_format=json', groupvalue, {withCredentials: true})
        .then((response) => {
          setLoading(false);
          if (response.data.Sucess && response.data.Sucess == 'GroupCreated') {
            var str = t("@type %title has been created.");
            var mapObj = { "@type": t("Group"), "%title": groupInput };
            str = str.replace(/@type|%title/gi, function (matched) {
              return mapObj[matched];
            });
            toast.success(str);
            closePopup();
            setRefreshKey(oldKey => oldKey +1);
          } else if (response.data.Error && response.data.Error == 'AlreadyExists') {            
            toast.error(t('Group name already exists. Please use a different name.'));
            return false;
          }
        })
        .catch(function (error) {
          console.error('There was an error!', error);
        })
    }

  }
  return (    
    <>
    <div>
      <div className="groups-popup-container">
      <div className="popup-title">
          <h3><T _str="Group" /></h3>
          <Modal.Header closeButton></Modal.Header>
        </div>
        <div className="popup-content">

        {(errorMsgs.group_title || errorMsgs.screens_selected || errorMsgs.group_title_count)  && (
          <ul className='alert alert-danger alert-grp-popup' role="alert">
            {errorMsgs.group_title && (
            <li>
            <span style={{ color: "red" }}>
              {t('!name field is required.').replace('!name', t('Name'))}
            </span>
            </li>
           )}
          {errorMsgs.screens_selected && (
            <li>
              <span style={{ color: "red" }}>
              {t('!name field is required.').replace('!name', t('Select one or more screen for the new group'))}
            </span>
            </li>
          )}

          {errorMsgs.group_title_count && (
            <li>
              <span style={{ color: "red" }}>
              {t('Maximum @count characters are allowed.').replace('@count', 25)}
            </span>
            </li>
          )}
          
          </ul>
         
        )}
      
          <form className="popup-form" action="/" method="post" id="popup-form" accept-charset="UTF-8">
            <div className="form-item-title">
              <label className="control-label" for="edit-title-field"><T _str="Name" /><span className="form-required" title="This field is required.">*</span></label>
              <input className="form-text required"  onChange={(e)=>handleChange(e)} type="text" name="name" size="25" value={groupInput} maxlength="25" />
            </div>
            <label className="control-label" for="edit-field-select-device"><span className="form-required-text" title="This field is required."><T _str="Select one or more screen for the new group" /> *</span></label>
            <div className="pop-up-items">
              {addgroup.map((screen, index) => {
                const screenid = screen.nid_1.toString();
                return (
                  <div className="form-item checkbox group-item">
                    <label className="control-label" for="edit-field-select-device">
                      <input type="checkbox" id={screenid} checked={screenIdsChecked.includes(screenid) ? 1 : 0}
                        onChange={(event) => handleCheckedscreen(index, event)} name="field_select_device" value={screen.nid_1} className="form-checkbox" />
                      <div className="views-field views-field-title">{screen.title}</div>
                      <div className="views-field views-field-field-mpdid">
                        <span className="label-field-mpdid">CP-ID:</span>
                        <span className="field-content">{screen.field_mpdid}</span>
                      </div>
                      <div className="img"><img typeof="Image" className="img-responsive" src={screen.field_upload_themes.replace(/&amp;/g, '&')} width="100" height="56" alt="" />
                      </div>
                    </label>
                  </div>
                )
              })}
            </div>
            
          </form>
        </div>
        <div class="modal-footer">
        <div className="form-actions form-wrapper form-group" id="edit-actions">
              <button type="submit" onClick={(e) => onAddgroup(e)} id="edit-submit" name="op" value="Save" className="btn btn-save"><T _str="Save" /></button>
            </div>
          </div>
      </div>
    </div>
    </>
  );
};

export default AddGroups;