import React from "react";
import { withRouter } from "react-router";
import { Switch } from "react-router-dom";
import IdleTimer from "react-idle-timer";
import { IdleTimeOutModal } from "../../modal/IdleModal";
import PropTypes from "prop-types";
import { logoutDestory } from "../../utils/SessionManagement";

class SessionTimeout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timeout: 1000 * 60 * 10,
      showModal: false,
      userLoggedIn: false,
      isTimedOut: false,
    };

    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  _onAction(e) {
    this.setState({ isTimedOut: false });
  }

  _onActive(e) {
    this.setState({ isTimedOut: false });
  }

  _onIdle(e) {
    const isTimedOut = this.state.isTimedOut;
    if (isTimedOut) {
      this.setState({ showModal: false });
     window.location.reload();
    } else {
      this.setState({ showModal: true });
      this.idleTimer.reset();
      this.setState({ isTimedOut: true });
    }
  }

  handleClose() {
    this.setState({ showModal: false });
    window.location.reload();
  }

  handleLogout() {
    this.setState({ showModal: false });
    logoutDestory();
  }

  render() {
    return (
      <>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={this.state.timeout}
        />

        <div className="">
          <Switch></Switch>

          <IdleTimeOutModal
            showModal={this.state.showModal}
            handleClose={this.handleClose}
            handleLogout={this.handleLogout}
          />
        </div>
      </>
    );
  }
}

SessionTimeout.propTypes = {
  match: PropTypes.any.isRequired,
  history: PropTypes.func.isRequired,
};
export default withRouter(SessionTimeout);
