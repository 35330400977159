/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: AddUserMessages.js
File Description: Implements add usermessage functionality of CMS portal

Copyright 2022,2023 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
import { T, useT } from '@transifex/react';
import { t } from "@transifex/native"
import React, { useEffect, useState } from "react";
import '../../css/UserMessages.css';
import "tui-image-editor/dist/tui-image-editor.css";
import BgImage from '../../Assets/Image-BG.jpg';
import ImageEditor from "@toast-ui/react-image-editor";
import config from '../../utils/config';
import axios from 'axios'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import LoadingOverlay from "react-loading-overlay";
import Folders from '../../Components/UserMessages/Folders';
import { LibContext } from '../../Components/Library/LibraryContext';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
import $ from "jquery";
import ReactPaginate from 'react-paginate';
import { Buffer } from 'buffer';

const icona = require("tui-image-editor/dist/svg/icon-a.svg");
const iconb = require("tui-image-editor/dist/svg/icon-b.svg");
const iconc = require("tui-image-editor/dist/svg/icon-c.svg");
const icond = require("tui-image-editor/dist/svg/icon-d.svg");
const myTheme = {
  "menu.backgroundColor": "black",
  "common.backgroundColor": "#151515",
  "downloadButton.backgroundColor": "white",
  "downloadButton.borderColor": "white",
  "downloadButton.color": "black",
  "menu.normalIcon.path": icond,
  "menu.activeIcon.path": iconb,
  "menu.disabledIcon.path": icona,
  "menu.hoverIcon.path": iconc,
};


function AddUserMessages(props) {
  const docTitle = useT('Upload content to library');
  const titleError = useT('!name field is required.').replace('!name', t('Title'));
  const [loading, setLoading] = useState(false);
  const [toggleState, setToggleState] = useState(1);
  const [vocabulary, setVocabulary] = useState('');
  const [userMessageTerm, setUserMessageTerm] = useState();
  const [userLibraryTerm, setUserLibraryTerm] = useState();
  const [defaultContentTerm, setDefaultContentTerm] = useState();
  const [term, setTerm] = useState({ 'name': '', 'vid': '', 'tid': '' });
  const [mediaData, setMediaData] = useState([]);
  const history = useHistory();
  const [imageSrc, setImageSrc] = useState(BgImage);
  const initialValues = { title: "", category: "User Messages" };
  const [formValues, setFormValues] = useState(initialValues);
  const imageEditor = React.createRef();
  const [token, setToken] = useState('');
  const [imagesPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [offset, setOffset] = useState(0);

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setCurrentPage(selectedPage + 1);
    setOffset(selectedPage + 1)
  };

  const toggleTab = (index) => {
    if (index === 1) {
      setVocabulary('category');
      setTerm({ 'tid': userLibraryTerm })
    }
    if (index === 2) {
      setVocabulary('default_library');
      setTerm({ 'tid': defaultContentTerm })
    }
    if (index === 3) {
      setVocabulary('messages');
      setTerm({ 'tid': userMessageTerm })
    }
    setToggleState(index);
  }

  const dispatchLibEvent = (selectedTerm) => {
    if (selectedTerm.tid === '' || selectedTerm.tid === undefined) {
      if (toggleState === 1) {
        selectedTerm.tid = userLibraryTerm;
      }
      if (toggleState === 2) {
        selectedTerm.tid = defaultContentTerm;
      }
      if (toggleState === 3) {
        selectedTerm.tid = userMessageTerm;
      }
    }
    setTerm(selectedTerm);
  }

  let icon_language = {
    "Apply": useT("Apply"),
    "Undo": useT("Undo"),
    "Redo": useT("Redo"),
    "Reset": useT("Reset"),
    "Crop": useT("Crop"),
    "Custom": useT("Custom"),
    "Square": useT("Square"),
    "Cancel": useT("Cancel"),
    "Flip": useT("Flip"),
    "Flip X": useT("Flip X"),
    "Flip Y": useT("Flip Y"),
    "Rotate": useT("Rotate"),
    "Draw": useT("Draw"),
    "Free": useT("Free"),
    "Straight": useT("Straight"),
    "Shape": useT("Shape"),
    "Rectangle": useT("Rectangle"),
    "Triangle": useT("Triangle"),
    "Circle": useT("Circle"),
    "Fill": useT("Fill"),
    "Stroke": useT("Stroke"),
    "Color": useT("Color"),
    "Icon": useT("Icon"),
    "Arrow": useT("Arrow"),
    "Arrow-2": useT("Arrow"),
    "Arrow-3": useT("Arrow"),
    "Star-1": useT("Star"),
    "Star-2": useT("Star"),
    "Polygon": useT("Polygon"),
    "Heart": useT("Heart"),
    "Bubble": useT("Bubble"),
    "Location": useT("Location"),
    "Text": useT("Text"),
    "Bold": useT("Bold"),
    "Italic": useT("Italic"),
    "Underline": useT("Underline"),
    "Left": useT("Left"),
    "Center": useT("Center"),
    "Right": useT("Right"),
    "Text size": useT("Text size"),
    "Mask": useT("Mask"),
    "Load Mask Image": useT("Load Mask Image"),
    "Filter": useT("Filter"),
    "Tint": useT("Tint"),
    "Opacity": useT("Opacity"),
    "Range": useT("Range"),
  };

  $('input.tie-multiply, input.tie-blend').parent().parent().hide();
  $('li.tie-crop-preset-button div.preset-none, li.tie-crop-preset-button div.preset-square').hide();
  $('div.tui-image-editor-menu-icon div[data-icontype="icon-arrow"]').hide();
  $('div.tui-image-editor-menu-icon div[data-icontype="icon-arrow-3"]').hide();
  $('div.tui-image-editor-menu-icon div[data-icontype="icon-star-2"]').hide();
  $('input.tie-icon-image-file').parent().parent().parent().hide();

  // Get logged in user details
  let userDetails = localStorage.getItem("userdetails");
  let loggedInUserID = '';
  if (userDetails === null) {
    loggedInUserID = 1;
  } else {
    loggedInUserID = JSON.parse(userDetails).id;
  }

  const notify = (val) => {
    toast.error(val, {
      toastId: loggedInUserID
    });
  }

  useEffect(() => {
    document.title = docTitle + ' | CMS';
  }, [docTitle])

  useEffect(() => {
    const fetchCSRF = async () => {
      await axios.get(config.drupal_url + '/session/token').then(result => {
        if (200 === result.status) {
          const csrfToken = result.data;
          setToken(csrfToken);
        }
      })
    };
    fetchCSRF();

    // Fetch User Library Term
    const fetchULTerm = async () => {
      setLoading(true);
      await axios.get(config.drupal_url + '/api/library-category-terms', { withCredentials: true })
        .then(response => {
          response.data.forEach((key, index) => {
            if (key.name === 'User Messages') {
              setUserMessageTerm(key.tid);
            }
            if (key.name === 'User Library') {
              setTerm({ 'tid': key.tid });
              setUserLibraryTerm(key.tid);
            }
            if (key.name === 'Default Content') {
              setDefaultContentTerm(key.tid);
            }
          });
        })
        .catch(function (error) {
          notify(t("Please try later.If the error persists, please contact Otis support."));
          setLoading(false);
        })
    };
    fetchULTerm();
  }, []);

  // Fetch term data from second load
  useEffect(() => {
    if (term.tid !== "") {
      const fetchData = async () => {
        if (loading === false) {
          setLoading(true);
        }
        await axios.get(config.drupal_url + '/api/getmedia?uid=' + loggedInUserID + '&tid=' + term.tid, { withCredentials: true })
          .then(response => {
            const mediaResData = response.data;
            mediaResData.forEach((key, index) => {
              key.field_mediaimage = key.field_mediaimage.replace(/&amp;/g, '&');
              key.field_mediaimage_1 = key.field_mediaimage_1.replace(/&amp;/g, '&');
            });
            setPageCount(Math.ceil(mediaResData.length / imagesPerPage))
            setMediaData(mediaResData);
            setLoading(false);
          })
          .catch(function (error) {
            notify(t("Please try later.If the error persists, please contact Otis support."));
            setLoading(false);
          })
      };
      fetchData();
    }
  }, [term.tid])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value })
  }

  const saveMedia = () => {
    if (formValues.title !== "") {
        setLoading(true);
      // Get Image from TUI editor ref
      const imageEditorInst = imageEditor.current.imageEditorInst;
      const data = imageEditorInst.toDataURL();
      // Validate title
      axios.get(config.drupal_url + '/validate-media-title?_format=json&userid=' + loggedInUserID + '&termid=' + userMessageTerm + '&title=' + formValues.title, { withCredentials: true })
        .then((response) => {
          if (response.data.rows.length !== 0) {
            setLoading(false);
            notify(t("Title already exists in this folder."));
            return false;
          } else {
            if (data) {
              // Set up the base64 image from Editor and filename for usermessage
              const mimeType = data.split(";")[0];
              const extension = data.split(";")[0].split("/")[1];
              const img_id = Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000;
              const filename = 'usermessage_' + img_id + '.' + extension;

              // Convert base64 image into a File
              var byteString = atob(data.split(',')[1]);
              var ab = new ArrayBuffer(byteString.length);
              var ia = new Uint8Array(ab);
              for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
              }
              var fileGenerated = new File([ab], filename, { type: mimeType });
            }

            // Save file to an API
            //const encodedString = Buffer.from(config.auth_username + ':' + config.auth_password).toString('base64');
            let reader = new FileReader();
            reader.readAsArrayBuffer(fileGenerated);
            reader.onabort = () => { console.log("file reader aborted") }
            reader.onerror = () => { console.log('error with file reader') }
            reader.onload = () => {
              axios.post(config.drupal_url + '/api/node/article/field_image', reader.result, {
                headers: {
                  "Content-Type": "application/octet-stream",
                  "Accept": "application/vnd.api+json",
                  "Content-Disposition": `file; filename="${fileGenerated.name}"`,
                  withCredentials: true,
                  'X-CSRF-Token': token,
                }
              }).then((res) => {
                // Save Media node starts
                // Headers
                const headers = {
                  "Content-Type": "application/hal+json",
                  withCredentials: true,
                  'X-CSRF-Token': token,
                }
                // Data
                let data = {
                  "_links": {
                    "type": {
                      "href": config.rest_node_url + "/rest/type/node/media"
                    }
                  },
                  "title": {
                    "value": formValues.title
                  },
                  "field_tab_switcher": {
                    "value": "image"
                  },
                  "field_categories": [{
                    "target_id": userMessageTerm,
                  }],
                  "field_mediaimage": [{
                    "target_id": `${res.data.data.attributes.drupal_internal__fid}`,
                  }],
                  "uid": [{
                    "target_id": loggedInUserID,
                  }]
                };
                // POST url
                axios.post(config.drupal_url + '/node?_format=hal_json', JSON.stringify(data), { headers })
                  .then((result) => {
                    if (result.status === 201) {
                      setLoading(false);
                      history.push({
                        pathname: "/media",
                        media_title: formValues.title,
                        userMessageTerm: userMessageTerm
                      });
                    }
                  })
              });
            }
          }
        })
        .catch(function (error) {
          notify(t("Please try later.If the error persists, please contact Otis support."));
          setLoading(false);
          return false;
        })
    }
    else {
      notify(titleError);
    }
  }

  const loadImageSrc = (src) => {
    setImageSrc(src);
    imageEditor.current.imageEditorInst.loadImageFromURL(src, 'My sample image');
  }

  // Pagination settings
  const paginate = pageNumber => setCurrentPage(pageNumber);
  const indexOfLastPlaylist = currentPage * imagesPerPage;
  const indexOfFirstPlaylist = indexOfLastPlaylist - imagesPerPage;
  var mediaList = '';
  if (mediaData.length > 10) {
    mediaList = mediaData.slice(indexOfFirstPlaylist, indexOfLastPlaylist);
  } else {
    mediaList = mediaData;
  }

  return (
    <div className="loaderUserMessages">
      <LoadingOverlay active={loading} spinner text={t('Processing')}>
        <div className="addUserMessages">
          <div className="headerSection library-page page-media">
            <div className="media-header-left">
              <h1 className="pageTitle">
                <T _str="eView" /><sup>&reg;</sup> - <T _str="Library" />
              </h1>
              <div className="subtitle">
                <T _str="Use the library to manage your files and folders" />
              </div>
            </div>
            <div className="actions_div buttons_section media-header-right" id="actions_div">
              <button
                type="submit"
                value="Reset"
                className="actions_button submit_form"
                id="reset_button"
                onClick={() => window.location.reload(false)}
              ><span className="icon glyphicon glyphicon-ok"></span>
                {t('Reset')}
              </button>
              <button
                type="submit"
                value="Save"
                className="actions_button submit_form"
                id="save_button"
                onClick={saveMedia}
              ><span className="icon glyphicon glyphicon-ok"></span>
                {t('Save')}
              </button>
            </div>
          </div>
          <div className="media-messages-content">
            <div className="library-inner">
              <div className="library-left-section">
                <Tabs>
                  <TabList>
                    <Tab title={useT('User Library')} onClick={() => toggleTab(1)}>{t('User Library')}</Tab>
                    <Tab title={useT('Default Content')} onClick={() => toggleTab(2)}>{t('Default Content')}</Tab>
                    <Tab title={useT('User Messages')} onClick={() => toggleTab(3)}>{t('User Messages')}</Tab>
                  </TabList>
                  <LibContext.Provider value={{ term, dispatchLibEvent }}>
                    <TabPanel key="1">
                      <Folders vocabulary={vocabulary === '' ? 'category' : vocabulary}></Folders>
                    </TabPanel>
                    <TabPanel key="2">
                      <Folders vocabulary={vocabulary}></Folders>
                    </TabPanel>
                    <TabPanel key="3">
                      <Folders vocabulary={vocabulary}></Folders>
                    </TabPanel>
                  </LibContext.Provider>
                </Tabs>
              </div>
            </div>
          </div>
          <div className="imageSection">
            <div className="imageSectionHeader">
              <p className="imagesTitle">{t('Images')}</p>
            </div>
            <div className="imagesResult">
              {mediaList.length === 0 &&
                <div className="imageBox noContent">
                  {t("No content added yet")}
                </div>
              }
              {mediaList.map((media, index) => (
                <div className="imageBox" key={index}>
                  <div className="image">
                    <img
                      src={media.field_mediaimage}
                      width={100}
                      height={54}
                      alt="User Messages"
                      onClick={() => loadImageSrc(media.field_mediaimage_1)}
                    />
                  </div>
                  <div className="imageTitle">
                    <span>{media.title}</span>
                  </div>
                  <div className="imageDate">
                    <span>{t('Create date')}: </span>
                    <span>{media.created}</span>
                  </div>
                  <div className="imageSize">
                    <span>{t('Size')}: </span>
                    <span>{media.filesize}</span>
                  </div>
                </div>
              ))}
            </div>
            {mediaData.length > 10 &&
              <ReactPaginate
                initialPage={0}
                previousLabel={"< " + t("previous")}
                nextLabel={t("next") + " >"}
                breakLabel={"..."}
                breakClassName={"break-me disabled"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={1}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            }
          </div>
          <div className="media-messages-form">
            <form>
              <div className="title_div theme_padding_div" id="title_div">
                <label htmlFor="title_field" className="title_label element_label">{t('Title')} <span>*</span></label>
                <input
                  className="title_field element_input"
                  id="media_title_field"
                  type="text"
                  name="title"
                  maxLength={25}
                  value={formValues.title}
                  onChange={handleChange}
                />
              </div>
              {mediaList.length === 0 &&
                <ImageEditor
                  includeUI={{
                    loadImage: {
                      path: `${imageSrc}`,
                      name: "image",
                    },
                    theme: myTheme,
                    locale: icon_language,
                    menu: ["crop", "flip", "rotate", "draw", "shape", "text", "mask", "icon", "filter"],
                    initMenu: "",
                    uiSize: {
                      height: "100vh",
                      width: "40vw"
                    },
                    menuBarPosition: "bottom",
                  }}
                  selectionStyle={{
                    cornerSize: 20,
                    rotatingPointOffset: 70,
                  }}
                  usageStatistics={true}
                  ref={imageEditor}
                />
              }
              {mediaList.length !== 0 &&
                <ImageEditor
                  includeUI={{
                    loadImage: {
                      path: `${imageSrc}`,
                      name: "image",
                    },
                    theme: myTheme,
                    locale: icon_language,
                    menu: ["crop", "flip", "rotate", "draw", "shape", "text", "mask", "icon", "filter"],
                    initMenu: "",
                    uiSize: {
                      height: "100vh",
                      width: "40vw"
                    },
                    menuBarPosition: "bottom",
                  }}
                  selectionStyle={{
                    cornerSize: 20,
                    rotatingPointOffset: 70,
                  }}
                  usageStatistics={true}
                  ref={imageEditor}
                />
              }
            </form>
          </div>
        </div>
      </LoadingOverlay>
    </div>
  );
}

export default AddUserMessages;
