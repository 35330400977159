/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: SelectPlaylist.js
File Description: Scheduling the chosen playlist to device functionality

Copyright 2021 - 2024 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import config from '../utils/config';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { t } from "@transifex/native";
import { T, useT } from '@transifex/react';
import LoadingOverlay from "react-loading-overlay";
import { dateformat } from '../utils/dateFormat';

function SelectPlaylist(props) {
  const tabTitle = useT("Choose Playlist");
  var schedulePlaylistData = JSON.parse(localStorage.getItem('schedulePlaylist'));
  const [loading, setLoading] = useState(false);
  const [playlistSelected, setPlaylistSelected] = useState();
  const [playlistSelectedTitle, setPlaylistSelectedTitle] = useState();
  const [playlistsData, setPlaylistsData] = useState([]);
  const history = useHistory();
  var token = localStorage.getItem("userdetails")
  var uid = token && JSON.parse(token).id

  // Browser Tab Title
  useEffect(() => {
    document.title = tabTitle + ' | CMS';
  }, [])

  const schedulelaylist = () => {
    if (playlistSelected) {
      playlistsData.forEach((playlist, index) => {
        if (playlist.playlistId === playlistSelected) {
          schedulePlaylistData.PlaylistId = playlist.playlistId;
          schedulePlaylistData.PlaylistTitle = playlist.playlistTitle;
          schedulePlaylistData.PlaylistType = playlist.playlistType;
        }
      });
      localStorage.setItem('schedulePlaylist', JSON.stringify(schedulePlaylistData));
      let path = 'schedule';
      history.push(path);
    } else {
      toast.error(t('Please select at least one screen.'));
    }
  }

  const playlistClicked = (id, title) => {
    setPlaylistSelected(id);
    setPlaylistSelectedTitle(title);
  }

  useEffect(() => {
    const fetchPlaylists = () => {
      setLoading(true);
      axios.get(config.drupal_url + '/api/views/select-playlist/' + uid)
        .then(response => {
          const playlistResponseData = response.data;
          const playlistDataFiltered = [];
          playlistResponseData.forEach((playlist, index) => {
            let PlaylistType = '';
            if (schedulePlaylistData.ScheduleScreenType.toString() === 'eView2') {
              PlaylistType = 'Banner Layout';
            } else if (schedulePlaylistData.ScheduleScreenType.toString() === 'Fullcontent'){
              PlaylistType = 'Fullcontent';
            }else {
              PlaylistType = 'Infotainment';
            }
            if (playlist.playlistType === PlaylistType) {
              playlistDataFiltered.push(playlist);
            }
          });
          setPlaylistsData(playlistDataFiltered);
          setLoading(false);
        })
    };
    fetchPlaylists();
  }, []);
  const selected = useT("Selected");
  return (
    <div>
      <ToastContainer />
      <LoadingOverlay active={loading} spinner text={t('Processing')}>
        {/* Header Section */}

        <div className="headerSection">
          <h1 className="pageTitle">
            eView<sup>&reg;</sup> - <T _str="Choose Playlist" />
          </h1>
          <div className="head-action-button">
            <button
              className="btn btn-success form-submit"
              onClick={schedulelaylist}>
              <T _str="Next" />: <T _str="Schedule Playlist" />
            </button>
          </div>
          <div className="subtitle">
            <T _str="Choose a playlist from the list below and click Next to schedule the playlist" /><br />
            <span class="orangeColor firstWord">{playlistSelectedTitle ? playlistSelectedTitle : selected}</span><T _str="Playlist will be added to" /><span class="orangeColor">{schedulePlaylistData.screenIdsCount}</span><T _str="selected screens" />
          </div>
        </div>

        {/* Select Playlist Section */}
        <div className="selectPlaylistSection">
          <div className="choose-label"><T _str="Choose Playlist" />:</div>
          <div className="container">
            <form className="row">
              {playlistsData.map((playlist, index) => {
                var updatedOn = dateformat(playlist.updatedOn, 'selectplaylist');
                const contentimages = playlist.contentArea.split(/[$$]+/);
                var firstcontentImageURL = contentimages[0].replace(/&amp;/g, '&');
                const selectedClass = "playlist-card radio" + ((playlistSelected === playlist.playlistId) ? ' checked' : '');
                return (
                  <div className="col-sm-6">
                    <div className={selectedClass} onClick={(event) => playlistClicked(playlist.playlistId, playlist.playlistTitle)}>
                      <label>
                        <input type="radio" class="form-radio"
                          id={playlist.playlistId}
                          checked={playlistSelected === playlist.playlistId ? 1 : 0}
                        />
                        <img src={firstcontentImageURL} alt="playlist" />
                        <div className='playlistDetails'>
                          <div className="title">{playlist.playlistTitle}</div>
                          <div className="playing"><T _str="Currently playing on" /> 0 <T _str="Units" /></div>
                          <div className="updated"><T _str="Last Updated" />: {updatedOn}</div>
                        </div>
                      </label>
                    </div>
                  </div>
                );
              })}
            </form>
          </div>
        </div>
      </LoadingOverlay>
    </div >
  );
}

export default SelectPlaylist;
