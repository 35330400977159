import { useState } from 'react';
import {useEffect } from 'react'; 
import config from '../../utils/config';
import axios from "axios";
import { T } from '@transifex/react';
import { t } from "@transifex/native"
import Select from 'react-select'
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


function AddUseremail(props) {

  const [addemail, setAddemail] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [show, setShow] = useState(false);
  const [Removeshow, setRemoveshow] = useState(false);
  
  	
	var token = localStorage.getItem("userdetails")
	var countryid = token && JSON.parse(token).cid
	
	
  const customId = token && JSON.parse(token).id;
  const notify = (val) => {
    toast.error(val, {
      toastId: customId
    });
  }
  const successnotify = (val) => {
    toast.success(val, {
      toastId: customId
    });
  }

  const onAddshow = (ids)=>{
    setShow(true)

  }
  const onAdduser = (deviceid,mail)=>{

	  if(!(mail.length))
	  {
		props.buttonclicked(false);
		notify(t('Email not valid'));
		
		return false;
	  }
    setShow(true)
    const devicevalue={
      "id": deviceid,
      "email": mail
     }

    
      axios.post(config.drupal_url +`/AssignUsers` ,devicevalue,{'withCredentials': true })
         .then((reponse) => {
           successnotify(t('Successfully added user(s) to selected device(s).'));
		       setTimeout(function(){   window.location.reload(1); }, 5000);
          })
        .catch(function (error) {
          console.error('There was an error!', error);
     })
     
    }
    
    
  const onRemoveuser = (deviceid,mail)=>{
    setRemoveshow(true)
  }

  const onRemoveusermail = (deviceid,mail) =>{
	  if(!(mail.length))
	  {
    props.buttonclicked(false);
	    notify(t('Email not valid'));
		return false;
	  }
    const devicevalue={
      "id": deviceid,
      "email": mail
     }
   
      axios.post(config.drupal_url +'/RemoveUsers' ,devicevalue,{'withCredentials': true })
         .then((reponse) => {
          props.buttonclicked(false);
          successnotify(t('Successfully removed user(s) from selected device(s).'));
			    setTimeout(function(){	window.location.reload(1);	}, 5000);
			
          })
        .catch(function (error) {
          console.error('There was an error!', error);
     })
    setRemoveshow(true)
  }
  const onRemoveusers = (deviceid)=>{

    const devicevalue={
      "id": deviceid,
      "email": []
     }
      axios.post(config.drupal_url +'/RemoveUsers' ,devicevalue,{'withCredentials': true })
         .then((reponse) => {
         props.buttonclicked(false);
         successnotify(t('Successfully removed user(s) from selected device(s).'));
		     setTimeout(function(){	window.location.reload(1);	}, 5000);
          })
        .catch(function (error) {
          console.error('There was an error!', error);
     })
     return true;
  }

  useEffect(() => {
    const fetchAddemail = async () => {
      axios.get(config.drupal_url +'/Userslist/'+countryid, {withCredentials:true})
              .then(response => {
                       setAddemail(response.data)
              })
          }
  
          fetchAddemail();
  }, []);

 const handleChange = (e) => {
  setSelectedValue(Array.isArray(e) ? e.map(x => x.value) : []);
}


const options = addemail.map(user => ({
  value: user.mail,
  label: user.mail,
}));

const closeModal = (e) => {
  e.preventDefault();
	  setShow(false)
	  setRemoveshow(false)
}

return(
 <>

<div className="select-one" style={{ display: show ||Removeshow ? "none" : "block" }}>
 <div className="assign-default-message-1"><T _str="Please select an appropriate action to continue"/></div>
 <fieldset className="edit-assigngroup panel panel-default form-wrapper" id="edit-assigngroup">
    <div className="panel-body" id="edit-assigngroup-body"> 
    <button title={t("Add Users")} className="add_users btn " onClick={(e)=>onAddshow(props.checkedunitid)}  type="submit" id="edit-add-users" name="op" value="Add Users"><span className="icon glyphicon glyphicon-plus" aria-hidden="true"></span><T _str="Add Users" /></button>
    <button title={t("Remove Users")}  className="remove_users btn" onClick={(e)=>onRemoveuser(props.checkedunitid)}  type="submit" id="edit-remove-users" name="op" value="Remove Users"><span className="icon glyphicon glyphicon-trash" aria-hidden="true"></span><T _str="Remove Users" /> </button>
    <button title={t("Remove All Users")}  type="submit" id="edit-unassign" name="op" onClick={(e)=>[onRemoveusers(props.checkedunitid), props.buttonclicked(true)]} value="Remove All Users" className="btn form-submit"><span className="icon glyphicon glyphicon-trash" aria-hidden="true"></span><T _str="Remove All Users" /></button>
    </div>
 </fieldset>
 </div>
<div id="autocomplete_email" style={{ display: show  || Removeshow ? "flex" : "none" }}>

        <label class="control-label" for="edit-assignfield"><T _str="Email"/></label>
        <Select placeholder={""} options={options} isMulti isClearable size="100" onChange={handleChange} value={options.filter(obj => selectedValue.includes(obj.value))} noOptionsMessage={() => null} />
      <button style={{ display: show ? "block" : "none" }} title={t("Add Users")} class="add_users btn " onClick={(e) => [props.buttonclicked(true), onAdduser(props.checkedunitid, selectedValue)]}  type="submit" id="edit-add-users" name="op" value="Add Users"><span class="icon glyphicon glyphicon-plus" aria-hidden="true"></span><T _str="Add Users" /></button>
      <button style={{ display: Removeshow ? "block" : "none" }} title={t("Remove Users")} class="btn btn-danger form-submit icon-before remove_user_submit" onClick={(e)=>[props.buttonclicked(true), onRemoveusermail(props.checkedunitid,selectedValue)]} type="submit" id="edit-remove-users-submit"  value="Remove Users"><span class="icon glyphicon glyphicon-trash" aria-hidden="true"></span><T _str="Remove Users"/></button>
      <button title={t("Cancel")} type="submit" id="edit-unassign" onClick={closeModal} value="Cancel" class="btn form-submit"><span class="icon glyphicon glyphicon-trash"  aria-hidden="true"></span><T _str="Cancel"/></button>

</div>
 
  </>
   );
}
export default AddUseremail;