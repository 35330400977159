import React, { useEffect, useState } from "react";
import { t } from "@transifex/native";
import UserTable from "./UserTable";
import TableFooter from "./TableFooter";
import UserFilters from "./UserFilters";
import axios from "axios";
import config from "../../utils/config";

const HistoryUsers = ({
  users,
  totalUsers,
  onLoadMore,
  onSearch,
  onResetFilter,
  handleOnSubmit,
  isLoading,
  onHandleAlert,
}) => {
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [status, setStatus] = useState("All");
  const [suggestionsunitid, setSuggestionsunitid] = useState([]);
  const [suggestionscompanyname, setSuggestionscompanyname] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [historyUsers, setHistoryUsers] = useState([]);
  const [hasApprovedStatus, setHasApprovedStatus] = useState(false);
  const [hasOtherStatus, setOtherStatus] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [allSearchUsers, setAllSearchUsers] = useState([]);

  const userDetails = localStorage.getItem("userdetails");
  const countryid = userDetails && JSON.parse(userDetails).cid;

  useEffect(() => {
    setShowButtons(false);
    setSelectedUsers([]);
    setHistoryUsers([]);
    fetchHistoryUsers();
    // resetData();
  }, []);

  const fetchHistoryUsers = async () => {
    axios
      .get(config.drupal_url + `/api/users/history/${countryid}`, {
        withCredentials: true,
      })
      .then((response) => setAllSearchUsers([...response.data.rows]));
  };

  const onInputChange = async (e) => {
    const value = e.target.value;
    if (allSearchUsers.length === 0) await fetchHistoryUsers();
    if (e.target.id == "user-email") {
      setEmail(value);
      let useremailmatches = [];
      useremailmatches = allSearchUsers.filter((device) => {
        const regex = new RegExp(`${value}`, "gi");
        return device.mail.match(regex);
      });
      setSuggestionsunitid(value.length === 0 ? [] : useremailmatches);
    } else if (e.target.id == "company-name") {
      setCompany(value);
      let companynamematches = allSearchUsers.filter((device) => {
        const regex = new RegExp(`${value}`, "gi");
        return (
          device.field_companyname && device.field_companyname.match(regex)
        );
      });
      setSuggestionscompanyname(value.length === 0 ? [] : companynamematches);
    } else if (e.target.id === "user-status") {
      setStatus(e.target.value);
    }
  };

  const onSuggestHandleremailid = (unitid) => {
    setEmail(unitid);
    setSuggestionsunitid([]);
  };
  const onSuggestHandlerCompanyname = (unitid) => {
    setCompany(unitid);
    setSuggestionscompanyname([]);
  };

  const resetData = () => {
    if (users.length !== selectedUsers) {
      setIsAllSelected(false);
    }
    onResetFilter();
  };

  useEffect(() => {
    const hasApproved =
      historyUsers.length === 0
        ? false
        : historyUsers.every((element) => element.status === "Approved");

    const hasOtherSameStatus =
      historyUsers.length === 0
        ? false
        : historyUsers.every(
            (element) =>
              element.status === "Denied" || element.status === "Disabled"
          );
    setHasApprovedStatus(hasApproved);
    setOtherStatus(hasOtherSameStatus);

    const value = !hasApproved && !hasOtherSameStatus ? false : true;
    setShowButtons(value);
  }, [selectedUsers]);

  const onAllUsersSelectionChange = (e) => {
    if (e.target.checked) {
      const hasReRegisterStatus = users.every(
        (element) => element.field_disable_status === "Re-register"
      );
      if (hasReRegisterStatus) {
        onHandleAlert({
          alertType: "danger",
          msg: "There are no actions for users in Re-register state",
        });
        return;
      }
      const hasApproved = users.every(
        (element) => element.field_disable_status === "Approved"
      );
      const hasOtherSameStatus = users.every(
        (element) =>
          element.field_disable_status === "Denied" ||
          element.field_disable_status === "Disabled"
      );

      if (!hasApproved && !hasOtherSameStatus) {
        onHandleAlert({
          alertType: "danger",
          msg: "Please select same status user(s)",
        });

        return;
      }
      setHasApprovedStatus(hasApproved);
      setOtherStatus(hasOtherSameStatus);

      setSelectedUsers([]);
      setIsAllSelected(e.target.checked);
      users.map((user) => {
        selectedUsers.push(user.uid);
      });
      setSelectedUsers(selectedUsers);
    } else {
      setSelectedUsers([]);
      setIsAllSelected(e.target.checked);
    }
  };

  const handleUserStatus = (user) => {
    const hasApproved =
      user.field_disable_status === "Approved"
        ? historyUsers.every((element) => element.status === "Approved")
        : false;
    const hasOtherSameStatus =
      user.field_disable_status === "Approved"
        ? false
        : historyUsers.every(
            (element) =>
              element.status === "Denied" || element.status === "Disabled"
          );

    return !hasApproved && !hasOtherSameStatus;
  };

  const onUserSelectionChange = (e, user) => {
    if (!e.target.checked) {
      if (selectedUsers.includes(user.uid)) {
        const arr = selectedUsers.filter((uid) => user.uid != uid);
        setSelectedUsers(arr);
        const arr1 = historyUsers.filter((u) => u.uid != user.uid);
        setHistoryUsers(arr1);
        setIsAllSelected(false);
      }
    } else if (user.field_disable_status === "Re-register") {
      onHandleAlert({
        alertType: "danger",
        msg: "There are no actions for users in Re-register state",
      });
      return;
    } else if (handleUserStatus(user)) {
      onHandleAlert({
        alertType: "danger",
        msg: "Please select same status user(s)",
      });
      return;
    } else if (e.target.checked) {
      const arr = [...selectedUsers, user.uid];
      const arr1 = [
        ...historyUsers,
        { uid: user.uid, status: user.field_disable_status },
      ];
      setSelectedUsers(arr);
      setHistoryUsers(arr1);
      if (arr.length === users.length) {
        setIsAllSelected(true);
      }
    }
  };

  const clearEmail = (event) => {
    setSuggestionscompanyname([false]);
  };
  const clearCompany = (event) => {
    setSuggestionsunitid([false]);
  };

  const resetFields = () => {
    setEmail("");
    setCompany("");
    setStatus("");
  };

  return (
    <>
      <div className="views-exposed-widgets">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSearch(email, company, status);
          }}
        >
          <div className="views-exposed-widget unit-number">
            <label for="unit-number"> {t("Email")} </label>
            <div className="views-widget">
              <div className="input-group">
                <input
                  className="form-control form-text"
                  type="text"
                  id="user-email"
                  name="field_eserviceunitid"
                  onChange={onInputChange}
                  value={email}
                  size="30"
                  maxlength="128"
                  onFocus={(event) => clearEmail(event)}
                />
                <div
                  id="list-dropdown"
                  style={{
                    display: suggestionsunitid == "false" ? "none" : "block",
                  }}
                >
                  {suggestionsunitid &&
                    suggestionsunitid.map((unitidsuggestion, i) => (
                      <span
                        className="list-items"
                        key={i}
                        onClick={() =>
                          onSuggestHandleremailid(unitidsuggestion.mail)
                        }
                      >
                        {unitidsuggestion.mail}
                      </span>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="views-exposed-widget unit-number">
            <label for="unit-number"> {t("Company")} </label>
            <div className="views-widget">
              <div className="input-group">
                <input
                  className="form-control form-text"
                  type="text"
                  id="company-name"
                  name="field_companyname"
                  onChange={onInputChange}
                  value={company}
                  size="30"
                  maxlength="128"
                  onFocus={(event) => clearCompany(event)}
                />
                <div
                  id="list-dropdown"
                  style={{
                    display:
                      suggestionscompanyname == "false" ? "none" : "block",
                  }}
                >
                  {suggestionscompanyname &&
                    suggestionscompanyname.map((unitidsuggestion, i) => (
                      <span
                        className="list-items"
                        key={i}
                        onClick={() =>
                          onSuggestHandlerCompanyname(
                            unitidsuggestion.field_companyname
                          )
                        }
                      >
                        {unitidsuggestion.field_companyname}
                      </span>
                    ))}
                </div>
              </div>
            </div>
          </div>

          <div
            id="edit-field-disable-status-value-1-wrapper"
            class="views-exposed-widget views-widget-filter-field_disable_status_value_1"
          >
            <label for="edit-field-disable-status-value-1">
              {t("Status")}{" "}
            </label>
            <div class="views-widget">
              <div class="form-item form-item-field-disable-status-value-1 form-type-select form-group">
                <select
                  class="form-control form-select"
                  id="user-status"
                  name="field_disable_status_value_1"
                  value={status}
                  onChange={onInputChange}
                >
                  <option value="All" selected="selected">
                    - {t("Any")} -
                  </option>
                  <option value="0"> {t("Denied")}</option>
                  <option value="1"> {t("Disabled")}</option>
                  <option value="2">{t("Re-register")}</option>
                  <option value="3">{t("Approved")}</option>
                </select>
              </div>
            </div>
          </div>

          <div className="views-exposed-widget views-submit-button">
            <button
              type="submit"
              id="edit-submit-devices-list"
              name=""
              value="Search"
              className="btn btn-primary form-submit"
            >
              {t("Search")}
            </button>
            <button
              type="button"
              id="history"
              name="op"
              onClick={(e) => {
                e.preventDefault();
                resetFields();
                onResetFilter();
                if (users.length !== selectedUsers) {
                  setIsAllSelected(false);
                }
              }}
              value="Reset"
              className="btn btn-default form-submit"
            >
              {t("Reset")}
            </button>
          </div>
        </form>
        {/* <UserFilters
users={users}
        /> */}
      </div>
      <UserTable
        users={users}
        onAllUsersSelection={onAllUsersSelectionChange}
        onUserSelection={onUserSelectionChange}
        isAllSelected={isAllSelected}
        selectedUsers={selectedUsers}
        showStatus={true}
        noDataMsg={t("No user history")}
      />

      <div className="assignclass  view-history">
        {!showButtons || selectedUsers.length === 0 ? (
          <span className="assign-default-message user-default-message">
            {t("Please select at least one user to view actions")}
          </span>
        ) : (
          <div class="view-actions">
            {hasApprovedStatus ? (
              <button
                type="button"
                id="ResetDisable"
                class="button"
                onClick={(e) => handleOnSubmit(e, "", selectedUsers)}
              >
                {t("Disable")}
              </button>
            ) : (
              <button
                type="button"
                id="Reset"
                class="button"
                onClick={(e) => handleOnSubmit(e, "", selectedUsers)}
              >
                {t("Re-register")}
              </button>
            )}
          </div>
        )}
      </div>
      {users.length !== 0 && (
        <TableFooter
          totalItems={totalUsers}
          loadedItems={users.length}
          isLoading={isLoading}
          onLoadMore={onLoadMore}
        />
      )}
    </>
  );
};

export default HistoryUsers;
