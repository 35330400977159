import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../../utils/config";
import { t, tx } from "@transifex/native";
import { T, useT } from "@transifex/react";
import LoadingOverlay from "react-loading-overlay";

const AccountDisable = () => {
  tx.init({
    token: "1/b764923cd80f817a0b810d8688e7c8f4dc7c75eb",
  });
  var segment_str = window.location.pathname;
  var segment_array = segment_str.split("/");
  var user_language = segment_array.pop();
  const [loading, setLoading] = useState(false);

  tx.setCurrentLocale(user_language);
  return (
    <LoadingOverlay active={loading} spinner text={t('Processing')}>
      <div class="main-container container user-redirect-pages">
        <div id="main-title">
          <div class="float-left title-subline">
            <h1 class="page-header">
              eView<sup>&reg;</sup> - <T _str="Your account has been disabled." />
            </h1>
          </div>
        </div>
       <p></p>
        <p>
          {" "}
          <T _str="Please contact your local Otis representative if you have any questions." />
        </p>
        <a class="standalone-link btn btn-success"  href="/">
          <T _str="Back to Login" />{" "}
        </a>
      </div>
    </LoadingOverlay>
  );
};
export default AccountDisable;
