import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { T, useT } from '@transifex/react';
import { t } from "@transifex/native";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
function ModalPopup(props) {
    let bodyText='';
    if(props.body){
        bodyText = props.body
    }
    return (
        <Modal className='modal-with-message' show={props.show} onHide={props.onhide}>
        <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>{bodyText}</Modal.Body>
        <Modal.Footer>
        <Button variant="primary" onClick={props.onhide}>
        <T _str="Ok" />
        </Button>
        </Modal.Footer>
        </Modal>
    );
  }

  function ModalwithInput(props) {
    return (
        <Modal className='add-folder' show={props.show} onHide={props.onhide} changeHandle={props.changeHandle} nodeValue={props.nodeval} clickHandle={props.clickHandle}>
        <Modal.Header closeButton>
          <Modal.Title><T _str="Rename" /></Modal.Title>
        </Modal.Header>
        <Modal.Body><input type='text' className='full-input' value={props.nodeValue}  onChange={props.changeHandle} /></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onhide}>
          <T _str="Cancel" />
          </Button>
          <Button variant="primary" onClick={props.clickHandle}>
          <T _str="Save" />
          </Button>
        </Modal.Footer>
        </Modal>
    );
  }

  function ModalNodeDelete(props){
    return(
    <Modal show={props.show} onHide={props.onhide} clickHandle={props.clickHandle} className="library-delete-popup">
    <Modal.Body><T _str="Are you sure you want to delete the selected file(s)?" />

<div className='delete-options'>
    <Button variant="primary" onClick={props.clickHandle}>
      <T _str="Yes" />
      </Button>
      <Button variant="secondary" onClick={props.onhide}>
      <T _str="No" />
      </Button>
      </div>
    </Modal.Body>
  </Modal>);
  }


export {ModalPopup, ModalwithInput, ModalNodeDelete}