/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: LiveContentManagement.js
File Description: Implements livecontent management functionality

Copyright 2021,2022 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
import { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../utils/config';
import { Modal } from 'react-bootstrap';
import "../css/livecontent.css";
import { T, useT } from '@transifex/react';
import LoadingOverlay from "react-loading-overlay";
import { ToastContainer } from "react-toastify";
import Deletelive from './Deletelive';
import 'react-toastify/dist/ReactToastify.css';
import { t } from "@transifex/native";

function LiveContentManagement(props) {
  const tabTitle = useT("Live Content");
  const [livecontent, setLivecontent] = useState([]);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalShow, setmodalShow] = useState(false);
  const modalHandleClose = () => setmodalShow(false);
  const [modalData, setModalData] = useState([]);

  const closemodal = (event) => {
    event.preventDefault();
    setmodalShow(false);
  }

  var token = localStorage.getItem("userdetails");
  var countryid = token && JSON.parse(token).cid;
  var userid = token && JSON.parse(token).id;
  useEffect(() => {
    document.title = tabTitle + ' | CMS';
    const livedata = async () => {
      setLoading(true);
      axios.get(config.drupal_url + '/livecontentlist/' + countryid, { withCredentials: true })
        .then(response => {
          setLivecontent(response.data.rows)
          setRoles(response.data)
          setLoading(false);
        })
    }
    livedata();
  }, []);

  return (
    <>
      <LoadingOverlay active={loading} spinner text={t('Processing')}>
        <ToastContainer />
        <div className="page-live-content">
          <div className="page-heading">
            <h2>eView<sup>&reg;</sup> - <T _str="Live Content" /></h2>
          </div>
          <div className="table-responsive lcm-table">
            <table className="cols-5 table table-hover table-striped"><thead>
              <tr><th className="views-field views-field-counter"><T _str="No." /></th>
                <th className="views-field views-field-title"><T _str="Feed Title" /></th>
                <th className="views-field views-field-field-feed-type"><T _str="Feed Type" /></th>
                <th className="views-field views-field-edit-node cursor-pointer"><T _str="Edit" /></th>
                <th className="views-field views-field-delete-node cursor-pointer"><T _str="Delete" /></th>
              </tr></thead>
              <tbody>
                {livecontent.map((live, index) => {

                  if (roles.role === "country_admin") {
                    return (
                      <>
                        <tr className="odd" key={index}>
                          <td className="views-field views-field-counter">{1 + index}</td>
                          <td className="views-field views-field-title">{live.title}</td>
                          <td className="views-field views-field-field-feed-type"><T _str={live.feed_type} /></td>
                          <td className="views-field views-field-edit-node cursor-pointer"><a href={'/edit-live-content/' + live.nid}><T _str="Edit" /></a></td>
                          <td className="views-field views-field-delete-node cursor-pointer"> <a onClick={() => {
                            setModalData(live); setmodalShow(true);
                          }} ><T _str="delete" /></a></td>
                        </tr>
                      </>
                    )
                  } else {
                    const assignuser = userid.includes(live.uid)
                    return (
                      <>
                        <tr className="odd" key={index}>
                          <td className="views-field views-field-counter">{1 + index}</td>
                          <td className="views-field views-field-title">{live.title}</td>
                          <td className="views-field views-field-field-feed-type"><T _str={live.feed_type} /></td>
                          <td className="views-field views-field-edit-node cursor-pointer">{assignuser ? <a href={'/edit-live-content/' + live.nid}><T _str="Edit" /></a> : null}</td>
                          <td className="views-field views-field-delete-node cursor-pointer">{assignuser ? <a onClick={() => {
                            setModalData(live); setmodalShow(true);
                          }} ><T _str="delete" /></a> : null}</td>
                        </tr>
                      </>
                    )
                  }
                })}
                <Modal show={modalShow} onHide={modalHandleClose} className="topModal">
                  <Deletelive livenid={modalData} closemodal={closemodal} />
                </Modal>
              </tbody>
            </table>
          </div>
        </div>
      </LoadingOverlay>
    </>
  );
}

export default LiveContentManagement;
