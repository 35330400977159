/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: EditWeatherFeeds.js
File Description: Implements edit weather feed functionality of CMS portal

Copyright 2022 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
import React, { useState, useEffect } from 'react';
import { T } from '@transifex/react';
import { t } from "@transifex/native"
import "../css/livecontent.css"
import config from '../utils/config';
import $ from "jquery";
import LoadingOverlay from "react-loading-overlay";
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function EditWeatherFeeds(props) {  
  window.long = config.AzureMapFixlongitude;
  window.lati = config.AzureMapFixlatitude;
  window.subscriptionkey = config.AzureMapKey;
  
	const [languagesData, setLanguagesData] = useState([]);
  const [loading, setLoading] = useState(false);
	const history = useHistory();
  const nid = props.id;

	const [formData, setFormData] = useState({
	  title: "",
	  feed_type:"weather",
	  preview_image: "", 
	  language:"",
	  metric:"",
	  lat:"",
	  lon:"",
    node_id:""
	})
  // Fetch Counries
  const fetchLanguages = async () => {
    await axios.get(config.drupal_url + '/languagelist', { withCredentials: true })
    .then(response => {
      setLanguagesData(response.data);
    })
    .catch(function (error) { })
  };

	// Browser Tab Title
	useEffect(() => {
    
    setLoading(true);
    const getWeatherFeed = async () => {
      axios.get(config.drupal_url + '/api/livecontent_detail/' + nid+'?_format=json', { withCredentials: true })
        .then(response => {	
          let tabTitle = t("Edit @type @title");		
          var mapObj = {"@type":t("Live Content"),"@title":response.data[0].title};
          tabTitle = tabTitle.replace(/@type|@title/gi, function(matched){
            return mapObj[matched];
          });
		      document.title = tabTitle + ' | CMS';
          const feedsResData = response.data;
          setFormData({...formData, title: feedsResData[0].title,
                          language: feedsResData[0].field_language, 
                          metric: feedsResData[0].field_metric, 
                          lat:feedsResData[0].field_location_lat,
                          lon:feedsResData[0].field_location_long,
                          preview_image:feedsResData[0].field_mediaimage.replace(/&amp;/g, '&')})
          
          setLoading(false);
        })
        .catch(error => console.error('API Error:' + error));
    }
    getWeatherFeed();      
    fetchLanguages();
    setTimeout(() => {
      window.GetMap(); 
     }, 5000);
	}, [])


  const handleCancel = () => {
    history.push('/live-content-management');
  }
  // Validate form
  const handleValidation = (formData) => {
    let formValid = true;
    //let errorMsg = '';
    $("label").removeClass("error-class");
    $(".error-msg").remove();
    if (formData.title.trim() == "") {
      let errorMsg = t('!name field is required.').replace('!name',t('Title'));
      $("label.title-label").addClass("error-class");
      $("#edit-title").after('<p class="error-msg" style="color:red;">'+errorMsg+'</p>');
      formValid = false;
    }
    if ((formData.title).includes(':')) {
      let errorMsg = t('!name cannot include colon (:).').replace('!name',t('Title'));
      $("label.title-label").addClass("error-class");
      $("#edit-title").after('<p class="error-msg" style="color:red;">'+errorMsg+'</p>');
      formValid = false;
    }
  if (formData.language == "") {
      let errorMsg =  t('!name field is required.').replace('!name',t('Language'));
      $("label.language-label").addClass("error-class");
      $("#edit-field-language").after('<p class="error-msg" style="color:red;">'+errorMsg+'</p>');
      formValid = false;
    }

    formData.lat = $("#edit-field-location1-lat").val().trim();
    formData.lon = $("#edit-field-location1-lon").val().trim();

    if (formData.lat == "") {
      let errorMsg = t('!name field is required.').replace('!name',t('Latitude'));
      $("label.lat-label").addClass("error-class");
      $("#edit-field-location1-lat").after('<p class="error-msg" style="color:red;">'+errorMsg+'</p>');
      formValid = false;
    }

    if(formData.lat != "" &&  !isLatitude(formData.lat)){
      let errorMsg = 'Invalid value '+t('Latitude');
      $("label.lat-label").addClass("error-class");
      $("#edit-field-location1-lat").after('<p class="error-msg" style="color:red;">'+errorMsg+'</p>');
      formValid = false;
    }

    if (formData.lon == "") {
      let errorMsg = t('!name field is required.').replace('!name',t('Longitude'))+"\n";
      $("label.long-label").addClass("error-class");
      $("#edit-field-location1-lon").after('<p class="error-msg" style="color:red;">'+errorMsg+'</p>');
      formValid = false;
    }
    if(formData.lon != "" &&  !isLongitude(formData.lon)){
      let errorMsg = 'Invalid value '+t('Longitude');
      $("label.lat-label").addClass("error-class");
      $("#edit-field-location1-lon").after('<p class="error-msg" style="color:red;">'+errorMsg+'</p>');
      formValid = false;
    }
    
    if (formData.metric == "") {
      let errorMsg = t('!name field is required.').replace('!name',t('Metric'));
      $("label.metric-label").addClass("error-class");
      $("#edit-field-metric").after('<p class="error-msg" style="color:red;">'+errorMsg+'</p>');
      formValid = false;
    }
    return formValid;
  }
  function isLatitude(lat) {
    return isFinite(lat) && Math.abs(lat) <= 90;
  }
  
  function isLongitude(lng) {
    return isFinite(lng) && Math.abs(lng) <= 180;
  }

  const handleWeatherFeedSubmit = (e) => {
    let validForm = handleValidation(formData);
    formData.node_id = nid;
    if (validForm) {    
      setLoading(true);
      //Trim values before saving
      formData.title = formData.title.trim();
      formData.lat = formData.lat.trim();
      formData.lon = formData.lon.trim();
      // Update url
      axios.post(config.drupal_url + '/updatelivecontent?_format=json', JSON.stringify(formData), { withCredentials: true } )
      .then((result) => {
        if(result.data.success && result.data.success == 'LiveContentUpdated'){
          setLoading(false);
          var str = t("@type %title has been updated.");
          var mapObj = {"@type":t("Live Content"),"%title":formData.title};
          str = str.replace(/@type|%title/gi, function(matched){
            return mapObj[matched];
          });
          toast.success(str);
          setTimeout(() => {
            setLoading(true);
            window.location.assign('/live-content-management');
          }, 5000);
        }else if(result.data.error && result.data.error == 'AlreadyExists'){
          setLoading(false);
          toast.error('Feed with same name already exists. Please use a different name.');
          return false;
        }
      })
      .catch(function (error) {
          setLoading(false);
          toast.error(error.response);
      })
    }
  }
  return (
  <LoadingOverlay active={loading} spinner text={t('Processing')}>
  <ToastContainer />
    <div className="create-live-content">
      <div className="page-heading">
          <h2>eView<sup>&reg;</sup> -  <T _str="Edit"/> <T _str="Live Content"/></h2>
      </div>
      <div className="live-content-inner">
        <div className="form-item form-item-title">
          <label className="control-label title-label" for="edit-title">
            <T _str="Title"/> 
            <span className="form-required" >*</span>
          </label>
          <input className="form-control form-text required" 
                type="text" 
                id="edit-title" 
                name="title" 
                value={formData.title} 
                size="60" maxlength="25" 
                onChange={(e) => setFormData({...formData, title: e.target.value})} />
       </div>
       <div className="form-item form-type-select">
            <label className="control-label" for="edit-field-feed-type">
              <T _str="Feed Type"/> <span className="form-required" >*</span>
            </label>
            <select className="form-control form-select required" 
                    id="edit-field-feed-type" 
                    name="field_feed_type" 
                      onChange={(e) => setFormData({...formData, feed_type: e.target.value})}>
                <option value="weather">{t('weather')}</option>
            </select>
        </div>
        <div className="form-item form-type-managed-file">
            <label className="control-label mediaimage-label" for="edit-field-mediaimage-upload">
              <T _str="Preview Image"/> <span className="form-required" >*</span>
            </label>
            <div className="image-widget form-managed-file clearfix">
                <div className="image-widget-data">
                  <div className="input-group">
                    <img src={formData.preview_image} />
                  </div>
                </div>
            </div>
          </div>
          <div class="form-item form-item-field-language form-type-select">
            <label class="control-label language-label" for="edit-field-language">
              <T _str="Language"/> <span class="form-required" >*</span>
            </label>
            <select class="form-control form-select required" 
            id="edit-field-language" name="field_language"  
            value={formData.language} onChange={(e) =>
            setFormData({...formData, language: e.target.value})}>
            <option value="">- {t('Select a value')} -</option>
            {Object.keys(languagesData).map((keyName, i) => (
                  <option value={keyName}>{t(languagesData[keyName])}</option>
                ))}
            </select>

          </div>
          <div class="form-item form-item-field-metric form-type-select">
            <label class="control-label metric-label" for="edit-field-metric">
              <T _str="Metric"/> <span class="form-required" >*</span>
            </label>
            <select class="form-control form-select required" 
                id="edit-field-metric" name="field_metric" 
                value={formData.metric} 
                onChange={(e) => setFormData({...formData, metric: e.target.value})}>
                <option value="">- {t('Select a value')} -</option>
                <option value="true">{t('Centigrade based display')}</option>
                <option value="false">{t('Fahrenheit based display')}</option>
            </select>
          </div>
          <fieldset class="field-set">
            <p>
              <a href="#edit-field-location1-body" class="panel-title fieldset-legend" 
              data-toggle="collapse" aria-expanded="true"><T _str="Define Location" /></a>
            </p>
            <div class="help-block">
                <p><T _str="To set a location for the map, click/drag the pin or marker of the map, or enter the latitude/longitude in the field provided."/></p>
            </div>
            <div class="form-item form-item-field-location1-lat form-type-textfield">
              <label class="control-label lat-label" for="edit-field-location1-lat">
                <T _str="Latitude" /> <span class="form-required" >*</span>
              </label>
              <input data-lat-delta="0" 
                data-lat-field-id="1453" 
                class="bing-map-field-watch-change form-control form-text required" 
                type="text" id="edit-field-location1-lat" 
                name="field_location1[lat]" 
                value={formData.lat} size="60" maxlength="128"  
                onChange={(e) => setFormData({...formData, lat: e.target.value})} />
            </div>
            <div class="form-item form-item-field-location1-lon form-type-textfield">
              <label class="control-label long-label" for="edit-field-location1-lon">
                <T _str="Longitude" /> <span class="form-required" >*</span>
              </label>
              <input data-lng-delta="0" 
                     data-lng-field-id="1453" 
                     class="bing-map-field-watch-change form-control form-text required" 
                     type="text" id="edit-field-location1-lon" 
                     name="field_location1[0][lon]" value={formData.lon} 
                     size="60" maxlength="128"  
                     onChange={(e) => setFormData({...formData, lon: e.target.value})} />
            </div>
          </fieldset>
          <div className="livecontentmap">
          <T _str="Map Preview"/>
           <div id="mapDiv" className='mt-2 mb-5'></div>
          </div>
      <div className="form-actions form-wrapper mb-2" id="edit-actions">
          <button type="submit"  onClick={(e) =>handleWeatherFeedSubmit(e)} id="edit-submit" name="op" value="Save" className="btn btn-success form-submit icon-before"><span className="icon glyphicon glyphicon-ok" aria-hidden="true"></span><T _str="Save"/></button>
          <button type="submit"  onClick={(e) =>handleCancel()}  id="edit-cancel" name="op" value="Cancel" className="btn btn-default form-submit icon-before"><span className="icon glyphicon glyphicon-remove" aria-hidden="true"></span><T _str="Cancel"/></button>
      </div>
    </div>
    </div>
  </LoadingOverlay>
  );
}
export default EditWeatherFeeds;