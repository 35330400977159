import React from 'react';
import {
  Calendar as BigCalendar,
  momentLocalizer,
} from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Button from 'react-bootstrap/Button';
import { T, useT } from '@transifex/react';
const localizer = momentLocalizer(moment);


const CustomToolbar = (toolbar) => {
    const goToBack = () => {
      toolbar.date.setMonth(toolbar.date.getMonth() - 1);
      toolbar.onNavigate('prev');
    };

    const goToNext = () => {
      toolbar.date.setMonth(toolbar.date.getMonth() + 1);
      toolbar.onNavigate('next');
    };

    const goToCurrent = () => {
      toolbar.onView('month');
    };
	
    return (
<div calss="view-header">
      <ul class="pagination pull-right">
	    { toolbar.view !== 'day' && (
          <>
        <li class="prev"><Button variant="primary" onClick={goToBack}><i class="fa fa-chevron-left"></i> <T _str="Prev" /></Button></li>
        <li class="prev"><Button variant="primary" onClick={goToNext}><T _str="Next" /> <i class="fa fa-chevron-right"></i></Button></li>
         </>)}
        { toolbar.view === 'day' && (
          <li class="prev"><Button variant="primary" onClick={goToCurrent}><T _str="Back" /></Button></li>)}

        </ul>
        <h1 class="pagination">{useT(moment(toolbar.date).format('MMMM'))}  {moment(toolbar.date).format('YYYY')}</h1>
      </div>
    );
  };
  export default CustomToolbar;