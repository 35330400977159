/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: EditThemes.js
File Description: Implements edit theme functionality of CMS portal

Copyright 2022,2023 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
import { useState, useEffect } from "react";
import { T, useT } from "@transifex/react";
import "../../css/Themes.css";
import LoadingOverlay from "react-loading-overlay";
import config from "../../utils/config";
import axios from "axios";
import FontAwesome from "react-fontawesome";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { t } from "@transifex/native";
import $, { inArray } from "jquery";
import AccessDenied from "../../Components/Common/AccessDenied";
import { Buffer } from 'buffer';

function EditTheme(props) {
  // Variables and States
  const titleEmpty = useT("!name field is required.").replace(
    "!name",
    t("Title")
  );
  const nid = props.id;
  const titleExists = useT(
    "Theme with same name already exists. Please use a different name."
  );
  const [loading, setLoading] = useState(false);
  const [existingTitle, setExistingTitle] = useState("");
  const [formData, setFormData] = useState({
    title: "",
    themeFile: "",
    users: "",
    theme_public: "",
  });
  const history = useHistory();

  let userDetails = localStorage.getItem("userdetails");
  let cid = "";
  let loggedInUserID = "";
  let countryId = "";
  let countryName = "";
  let userrole = "building_manager";
  if (userDetails !== null) {
    loggedInUserID = JSON.parse(userDetails).id;
    cid = JSON.parse(userDetails).cid;
    countryId = JSON.parse(userDetails).cid;
    countryName = JSON.parse(userDetails).country;
    var userdetails = JSON.parse(localStorage.getItem("userdetails"));
    userdetails.roles.forEach((role) => {
      if (role === "country_admin") {
        userrole = "country_admin";
      }
      if (role === "super_admin") {
        userrole = "super_admin";
      }
    });
  } else {
    loggedInUserID = 1;
    countryId = 10;
    countryName = "United States";
  }

  const notify = (val) => {
    toast.error(val, {
      toastId: loggedInUserID,
    });
  };
  const regExpEscape = (literal_string) => {
    return literal_string.replace(/[-[\]{}()*+!<=:?.\/\\^$|#\s,]/g, '\\$&');
  }

  // Browser Tab Title
  useEffect(() => {
    const fetchTheme = async () => {
      setLoading(true);
      await axios
        .get(config.drupal_url + "/api/themes/edit?nid=" + nid, {
          withCredentials: true,
        })
        .then((response) => {
          const themesResData = response.data.rows;
          setFormData({
            ...formData,
            title: themesResData[0].title,
            themeFile: themesResData[0].theme.replace(/&amp;/g, "&"),
            users: themesResData[0].field_assign_user,
            theme_public: themesResData[0].field_make_this_theme_public,
          });
          document.title =
            t("Edit @type @title")
              .replace("@type", t("Themes"))
              .replace("@title", themesResData[0].title) + " | CMS";
          setExistingTitle(themesResData[0].title);
          axios
            .get(config.drupal_url + "/Userslist/" + countryId, {
              withCredentials: true,
            })
            .then((userResponse) => {
              let userArr = themesResData[0].field_assign_user.split(", ");
              var userOptions = "";
              $.each(userResponse.data, function (key, value) {
                if ($.inArray(value.uid, userArr) > -1) {
                  userOptions =
                    '<option value="' +
                    value.uid +
                    '" selected>' +
                    value.mail +
                    "</option>";
                } else {
                  userOptions =
                    '<option value="' +
                    value.uid +
                    '">' +
                    value.mail +
                    "</option>";
                }
                $("#edit-field-assign-user-und").append(userOptions);
              });
            })
            .then((userRespone) => {
              const assignwidget = $(
                "#improvedselect-edit-field-assign-user-und"
              ).length;
              if (assignwidget === 0) {
                $("#edit-field-assign-user-und")
                  .parent()
                  .append(
                    '<div class="improvedselect" sid="' +
                      $("#edit-field-assign-user-und").attr("id") +
                      '" id="improvedselect-' +
                      $("#edit-field-assign-user-und").attr("id") +
                      '"><div class="improvedselect-text-wrapper"><input type="text" class="improvedselect_filter" sid="' +
                      $("#edit-field-assign-user-und").attr("id") +
                      '" prev="" /></div><ul class="improvedselect_sel"></ul><ul class="improvedselect_all"></ul><div class="improvedselect_control"><span class="add" sid="' +
                      $("#edit-field-assign-user-und").attr("id") +
                      '">&gt;</span><span class="del" sid="' +
                      $("#edit-field-assign-user-und").attr("id") +
                      '">&lt;</span><span class="add_all" sid="' +
                      $("#edit-field-assign-user-und").attr("id") +
                      '">&raquo;</span><span class="del_all" sid="' +
                      $("#edit-field-assign-user-und").attr("id") +
                      '">&laquo;</span></div><div class="clear" /></div>'
                  );
                var improvedselect_id = $("#edit-field-assign-user-und").attr(
                  "id"
                );
                $("#edit-field-assign-user-und")
                  .find("option")
                  .each(function () {
                    if ($(this).attr("selected")) {
                      $(
                        "#improvedselect-" +
                          improvedselect_id +
                          " .improvedselect_sel"
                      ).append(
                        '<li so="' +
                          $(this).attr("value") +
                          '">' +
                          $(this).text() +
                          "</li>"
                      );
                    } else {
                      $(
                        "#improvedselect-" +
                          improvedselect_id +
                          " .improvedselect_all"
                      ).append(
                        '<li so="' +
                          $(this).attr("value") +
                          '">' +
                          $(this).text() +
                          "</li>"
                      );
                    }
                  });
                $("#improvedselect-" + improvedselect_id + " li").click(
                  function () {
                    $(this).toggleClass("selected");
                  }
                );
                setLoading(false);
                $("#edit-field-assign-user-und").hide();
                $(".improvedselect_filter").keyup(function () {
                  var text = $(this).val();
                  if (text.length && text !== $(this).attr("prev")) {
                    $(this).attr("prev", text);
                    var patt = new RegExp(`${regExpEscape(text)}`, "i");
                    $(
                      "#improvedselect-" +
                        $(this).attr("sid") +
                        " .improvedselect_all li"
                    ).each(function () {
                      var str = $(this).text();
                      if (str.match(patt)) {
                        $(this).show();
                      } else {
                        $(this).hide();
                      }
                    });
                  } else {
                    $(this).attr("prev", "");
                    $(
                      "#improvedselect-" +
                        $(this).attr("sid") +
                        " .improvedselect_all li"
                    ).each(function () {
                      $(this).show();
                    });
                  }
                });

                // Add selected items.
                $(".improvedselect .add").click(function () {
                  var sid = $(this).attr("sid");
                  $(
                    "#improvedselect-" + sid + " .improvedselect_all .selected"
                  ).each(function () {
                    $(this).removeClass("selected");
                    $(this).show();
                    $("#improvedselect-" + sid + " .improvedselect_sel").append(
                      $(this)
                    );
                  });
                  improvedselectUpdate(sid);
                });

                // Remove selected items.
                $(".improvedselect .del").click(function () {
                  var sid = $(this).attr("sid");
                  $(
                    "#improvedselect-" + sid + " .improvedselect_sel .selected"
                  ).each(function () {
                    $(this).removeClass("selected");
                    $("#improvedselect-" + sid + " .improvedselect_all").append(
                      $(this)
                    );
                  });
                  improvedselectUpdate(sid);
                });

                // Remove all filtered items.
                $(".improvedselect .add_all").click(function () {
                  var sid = $(this).attr("sid");
                  $("#improvedselect-" + sid + " .improvedselect_all li").each(
                    function () {
                      if ($(this).css("display") !== "none") {
                        $(this).removeClass("selected");
                        $(
                          "#improvedselect-" + sid + " .improvedselect_sel"
                        ).append($(this));
                      }
                    }
                  );
                  improvedselectUpdate(sid);
                });

                // Remove all items.
                $(".improvedselect .del_all").click(function () {
                  var sid = $(this).attr("sid");
                  $("#improvedselect-" + sid + " input").val("");
                  $("#improvedselect-" + sid + " input").attr("prev", "");
                  $("#improvedselect-" + sid + " .improvedselect_sel li").each(
                    function () {
                      $(this).removeClass("selected");
                      $(
                        "#improvedselect-" + sid + " .improvedselect_all"
                      ).append($(this));
                    }
                  );
                  $("#improvedselect-" + sid + " .improvedselect_all li").each(
                    function () {
                      $(this).show();
                    }
                  );
                  improvedselectUpdate(sid);
                });
              }
            });
        })
        .catch(function (error) {
          notify(
            t(
              "Please try later.If the error persists, please contact Otis support."
            )
          );
          console.log(error);
          setLoading(false);
        });
    };
    fetchTheme();
  }, []);

  function improvedselectUpdate(sid) {
    //$('#'+ sid +' option:selected').attr("selected", "");
    $("#" + sid + " option:selected").attr("selected", false);
    $("#improvedselect-" + sid + " .improvedselect_sel li").each(function () {
      $("#" + sid + ' [value="' + $(this).attr("so") + '"]').attr(
        "selected",
        "selected"
      );
    });
    $("#" + sid)
      .find("option")
      .each(function () {
        if ($(this).attr("selected")) {
          console.log(
            $(
              "#improvedselect-" +
                sid +
                ' .improvedselect_sel [so="' +
                $(this).attr("value") +
                '"]'
            )
          );
          $("#improvedselect-" + sid + " .improvedselect_sel").append(
            $(
              "#improvedselect-" +
                sid +
                ' .improvedselect_sel [so="' +
                $(this).attr("value") +
                '"]'
            )
          );
        } else {
          $("#improvedselect-" + sid + " .improvedselect_all").append(
            $(
              "#improvedselect-" +
                sid +
                ' .improvedselect_all [so="' +
                $(this).attr("value") +
                '"]'
            )
          );
        }
      });
  }

  // Handle Theme Edit Submit.
  const handleThemeSubmit = (e) => {
    e.preventDefault();
    if (formData.title === "") {
      notify(titleEmpty);
      formData.title = "";
      $("label.title_label").addClass("error-class");
    }
    if (userrole === "country_admin" && formData.theme_public === "Off") {
      let selectedOptions = $("#edit-field-assign-user-und").val();
      formData.users = selectedOptions;
      if (formData.users.length === 0) {
        let errorMsg = t("!name field is required.").replace(
          "!name",
          t("Assign user")
        );
        $("label.assign-users-label").addClass("error-class");
        $("#improvedselect-edit-field-assign-user-und").after(
          '<p class="error-msg" style="color:red;">' + errorMsg + "</p>"
        );
        return false;
      }
    } else if (userrole === "building_manager") {
      formData.users = [loggedInUserID];
    }
    if (formData.title) {
      setLoading(true);
      const titleValidate = "?cid=" + cid + "&title=" + formData.title;
      axios
        .get(config.drupal_url + "/api/themes" + titleValidate, {
          withCredentials: true,
        })
        .then((response) => {
          if (
            response.data.rows.length === 0 ||
            existingTitle === formData.title
          ) {
            axios.get(config.drupal_url + "/session/token").then((result) => {
              if (200 === result.status) {
                const csrfToken = result.data;
                //let login = config.auth_username;
                //let password = config.auth_password;
                /*const encodedString = Buffer.from(
                  `${login}:${password}`
                ).toString("base64");*/
                //Headers
                const headers = {
                  "Content-Type": "application/hal+json",
                  withCredentials: true,
                  "X-CSRF-Token": csrfToken,
                };
                let term_ids = [];
                for (const uid of formData.users) {
                  term_ids.push({ target_id: uid });
                }
                // Data
                let data = {
                  _links: {
                    type: {
                      href: config.rest_node_url + "/rest/type/node/add_themes",
                    },
                  },
                  title: {
                    value: formData.title.trim(),
                  },
                  field_assign_user: term_ids,
                  uid: [
                    {
                      target_id: loggedInUserID,
                    },
                  ],
                };
                // URL
                const saveThemeUrl =
                  config.drupal_url + "/node/" + nid + "?_format=hal_json";
                // PATCH call for saving
                axios
                  .patch(saveThemeUrl, JSON.stringify(data), { headers })
                  .then((result) => {
                    if (result.status === 200) {
                      history.push({
                        pathname: "/theme-list",
                        theme_u_title: formData.title.trim(),
                      });
                    }
                    setLoading(false);
                  })
                  .catch(function (error) {
                    notify(
                      t(
                        "Please try later.If the error persists, please contact Otis support."
                      )
                    );
                    setLoading(false);
                  });
              }
            });
          } else {
            setLoading(false);
            notify(titleExists);
          }
        }
      );
    }
  };

  return (
    <div className="edit-theme-wrapper">
      <LoadingOverlay active={loading} spinner text={t("Processing")}>
        <ToastContainer />
        <div className="headerSection">
          <h1 className="pageTitle">
            eView<sup>&reg;</sup> -{" "}
            {t("Edit @type @title")
              .replace("@type", t("Themes"))
              .replace("@title", formData.title)}
          </h1>
        </div>
        <div className="add-theme-form edit-form">
          <form className="themes_form" onSubmit={handleThemeSubmit}>
            <div className="title_div theme_padding_div" id="title_div">
              <label
                htmlFor="title_field"
                className="title_label element_label"
              >
                <T _str="Title" /> <span>*</span>
              </label>
              <input
                className="title_field element_input"
                id="title_field"
                type="text"
                name="title"
                value={formData.title}
                maxLength={25}
                onChange={(e) =>
                  setFormData({ ...formData, title: e.target.value })
                }
              />
            </div>
            <div className="upload_div theme_padding_div" id="upload_div">
              <label
                htmlFor="upload_field"
                className="upload_label element_label"
              >
                <T _str="Upload themes" /> <span>*</span>
              </label>
            </div>
            <img src={formData.themeFile} alt="Themes" />
            {userrole === "country_admin" && formData.theme_public === "Off" && (
              <div className="form-item form-item-field-assign-user-und form-type-select form-group mb-2">
                <label
                  className="control-label assign-users-label mb-2"
                  htmlFor="edit-field-assign-user-und"
                >
                  <T _str="Assign user" />
                  <span
                    className="form-required"
                    title="This field is required."
                  >
                    *
                  </span>
                </label>
                <select
                  multiple="multiple"
                  name="field_assign_user"
                  className="form-control form-select required improvedselect-processed"
                  id="edit-field-assign-user-und"
                ></select>
              </div>
            )}
            <div className="actions_div" id="actions_div">
              <button
                type="submit"
                value="Save"
                className="actions_button submit_form"
              >
                <FontAwesome name="check" size="lg" />
                <T _str="Save" />
              </button>
            </div>
          </form>
        </div>
      </LoadingOverlay>
    </div>
  );
}

export default EditTheme;
