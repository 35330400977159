/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: Groups.js
File Description: Lists the groups available in CMS portal

Copyright 2021 - 2023 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
import React, { Component } from "react";
import { Carousel } from 'react-responsive-carousel';
import { FaPlusCircle } from 'react-icons/fa';
import { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { T, useT } from '@transifex/react';
import { t } from "@transifex/native";
import { ToastContainer } from "react-toastify";
import axios from 'axios';
import config from '../utils/config';
import LoadingOverlay from "react-loading-overlay";
import AddGroups from '../Components/Groups/AddGroups';
import ManageGroups from '../Components/Groups/ManageGroups';
import DeleteGroups from '../Components/Groups/DeleteGroups';
import Pagination from '../Components/Common/Pagination';
import '../Components/Groups/Groups.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import 'react-toastify/dist/ReactToastify.css';
import { dateformat } from "../utils/dateFormat";

function Groups() {
	const tabTitle = useT("Groups");
	useEffect(() => {
		document.title = tabTitle + ' | CMS';
	}, [tabTitle]);

	const [modalShow, setmodalShow] = useState(false);
	const [mgShow, setmgShow] = useState(false);
	const [groupsData, setGroupsData] = useState([]);
	const [manageInput, setManageInput] = useState([]);
	const [groupTitle, setGroupTitle] = useState([]);
	const [deleteShow, setdeleteShow] = useState(false);
	const [loading, setLoading] = useState(false);
	const [refreshKey, setRefreshKey] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [groupsPerPage] = useState(5);  
	const paginate = pageNumber => setCurrentPage(pageNumber);

	const modalHandleClose = () => setmodalShow(false);
	const modalHandleShow = (e) => {
		setLoading(true);
		setmodalShow(true);
	}

	const closeParent = (event) => {
		event.preventDefault();
		setdeleteShow(true)
		setmgShow(false);
	}
	
	const closePopup = () => {
		setmodalShow(false);
		setdeleteShow(false);
		setmgShow(false);
	}

	const handleCancel = (event) => {
		event.preventDefault();
		setdeleteShow(false);
	}

	const onManage = (manageid, groupTitle) => {
		setLoading(true);
		setManageInput(manageid);
		setGroupTitle(groupTitle);
		setmgShow(true)
	}

	// Get logged in user details
	let userDetails = localStorage.getItem("userdetails");
	let loggedInUserID = '';
	if (userDetails === null) {
		loggedInUserID = 1;
	} else {
		loggedInUserID = JSON.parse(userDetails).id;
	}

	useEffect(() => {
        setLoading(true);
		const getGroupData = async () => {
			axios.get(config.drupal_url + '/api/groupdeviceslist/' + loggedInUserID)
				.then(response => {
					const screenDataFiltered = [];
					response.data.forEach((groups, index) => {
						if (groups.view.length !== 0) {
							screenDataFiltered.push(groups);
						}

					});
					setGroupsData(screenDataFiltered);					
					setLoading(false);
				})
				.catch(error => console.error('API Error:' + error));
		}
		getGroupData();
	}, [refreshKey]);

	const indexOfLastGroup = currentPage * groupsPerPage;
	const indexOfFirstGroup = indexOfLastGroup - groupsPerPage;
	const currentGroup = groupsData.slice(indexOfFirstGroup, indexOfLastGroup);
	return (
		<LoadingOverlay active={loading} spinner text={t('Processing')}>
		<ToastContainer />
			<div className="groups-heading-container">
				<h2>eView<sup>&reg;</sup> - <T _str="Manage Groups" /></h2>
			</div>
			<button onClick={(e)=>modalHandleShow(e)} className="add-new-btn-lg">
				<FaPlusCircle /><T _str="Create Custom Group" />
			</button>
			<Modal show={modalShow} onHide={modalHandleClose} className="topModal groups-popup">
				<AddGroups  setLoading={setLoading} closePopup={closePopup} screendata={groupsData} setRefreshKey={setRefreshKey} />
			</Modal>
			<div className='group-list-main group-slider-cs'>
				<Carousel statusFormatter = {
					function(current, total) {					
						return current+' '+t('of')+' '+total;
					}}>
					{currentGroup.map((group, index) => {
						var updatedOn = dateformat(group.changed, 'groups');
						return (
							<div>
								<div className="group-header">
									<h3 className="group-name">{group.title}</h3>
									<span className='date-updated-screens'> (<T _str="Updated on" /> {updatedOn})</span>
									{group.field_group_type === 'standard_group' && (
										<div className='group-tag'><span><T _str="Standard Group" /></span></div>
									)}
									{group.field_group_type === 'custom_group' && (
										<div className='group-tag'>
											<button onClick={(e) => onManage(group.nid_1, group.title)} id="create-group" className="group-screens">
												<T _str="Manage Group" />
											</button>
										</div>
									)}
								</div>
								<div className="main-group-wrapper">
									{group.view.map((screen, index) => {
										const themeData = screen.device_theme_blob;
										const themearray = themeData.split("\"");
										const themeURL = themearray[0].replace(/&amp;/g, '&');
										return (
											<div className='group-wrapper-here'>
												<ul className="group-list">
													<li key={index}>
														<article className='group-list-number'>
															<div className='group-list-title'>{screen.screen_title}</div>
															<div className='group-list-info'>
																<div className='group-list-info-label'>CP-ID: </div>
																<div className='group-list-info-field-items'>{screen.cpid}</div>
																{screen.deck_type === config.deck_upper && (
                                                                 <>
																	<div className='group-list-info-label'>Deck type: </div>
																	<div className='group-list-info-field-items'>{t('Upper Deck')}</div>
																</>)}
																{screen.deck_type === config.deck_lower && (
                                                                 <>
																	<div className='group-list-info-label'>Deck type: </div>
																	<div className='group-list-info-field-items'>{t('Lower Deck')}</div>
																</>)}
															</div>
															<div className='group-list-img'>
																<img typeof='foaf:Image' className='img-responsive' src={themeURL} alt="" />
															</div>
														</article>
													</li>
												</ul>
											</div>
										)
									})}
								</div>
							</div>
						)
					})}
				</Carousel>
				<Modal show={mgShow} onHide={() => setmgShow(false)} className="topModal">
					<ManageGroups setLoading={setLoading} closePopup={closePopup} setRefreshKey={setRefreshKey} closeParent={closeParent} groupdata={manageInput} />
				</Modal>
				<Modal show={deleteShow} onHide={() =>setdeleteShow(false)} className="topModal" >
					<DeleteGroups handleCancel={handleCancel} groupdata={manageInput} groupTitle={groupTitle} setRefreshKey={setRefreshKey} />
			    </Modal>
			</div>
			<div class="col-12 d-flex">
				<div class="col-6 ms-auto pagination-right">
					<Pagination
					itmesPerPage={groupsPerPage}
					totalItems={groupsData.length}
					paginate={paginate}
					currentPage={currentPage}
					/>
				</div>
			</div>
		</LoadingOverlay>
	);
}

export default Groups;