import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './Menu.module.css';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from './../Assets/otis-logo.jpg';
import MCYlogo from './../Assets/MCYlogo_white.svg';
import menuClose from './../Assets/menu-close-button.jpg';
import menuOpen from './../Assets/menu-open.jpg';
import logout from './../Assets/logouticon.svg';
import config from '../utils/config';
import { T } from '@transifex/react';
import { t } from "@transifex/native";
import {Route} from "react-router";
import axios from "axios";

function Menu() {

  const [isActive, setIsActive] = useState(false);
  const [isMcy, setMcy] = useState(null);
  const [logoutModal, setLogoutModal] = useState('false');
  const logoutModalClose = () => setLogoutModal('false');
  const logoutModalShow = () => setLogoutModal('true');
  let countryName = '';
useEffect(() => {
  let rand = (new Date()).getTime();
  axios
    .get(config.drupal_url + "/checkuser/status?time="+rand, { withCredentials: true })
    .then((response) => {
      if (response.data.user_details.id) {
        console.log(response.data.user_details);
    
          if (response.data.user_details.mercury_logo == 1) {
              sessionStorage.setItem('logo_type', 'mcy');
              setMcy(true);
              
          } else {
               sessionStorage.setItem('logo_type', 'otis');
               setMcy(false);
          }
            }
    })
    .catch((error) => {
      //Set default language
      console.log("check login error", error);
     
    });
}, [isMcy])
  var userrole = 'building_manager';

  if (JSON.parse(localStorage.getItem("userdetails")) === null) {
    var chk_storage_value = "";
    countryName = 'United States';
  } else {
    var token = localStorage.getItem("userdetails")
    //var standalone_base_url = token && JSON.parse(token).standalone_base_url;
    var chk_storage_value = JSON.parse(localStorage.getItem('userdetails'));
    var userdetails = JSON.parse(localStorage.getItem('userdetails'));
    countryName = JSON.parse(token).country;
    userdetails.roles.forEach(role => {
      if (role === "country_admin") {
        userrole = "country_admin";
      }
      if (role === "super_admin") {
        userrole = "super_admin";
      }
    });
  }


  function logoutDestory() {
    var userdetails = JSON.parse(localStorage.getItem('userdetails'));
      if (userdetails.mercury_logo == 1) {
        var logouturl = config.drupal_url + "/standalone?status=logout&logo=mcy";
      } else {
        var logouturl = config.drupal_url + "/standalone?status=logout";
      }
    window.location = encodeURI(logouturl);
    localStorage.clear();
  }

  return (
    <section>
      <div id="sidebar" className={isActive ? "active" : ''}>

        {/* Menu header */}
        <div id={styles["logo"]}>
        {isMcy == true && (
        <>
          <img className={styles.logo} alt="" src={MCYlogo} />  
        </>
        )}
        {isMcy == false && (
        <>
        <img className={styles.logo} alt="" src={logo} />  
        </>
        )}        
        
        </div>
        <h3 className={styles.menutitle} title={t("Content Configurator")}>{t("Content Configurator")}</h3>
        <div id={styles["dismiss"]} className={isActive ? "show" : ''} onClick={() => setIsActive(!isActive)}>
          <img alt="" src={menuClose} />
        </div>
        <button type="button" id={styles["sidebarCollapse"]} onClick={() => setIsActive('true')} className="btn btn-info">
          <img alt="" className="menu-open" src={menuOpen} />
        </button>

        {/* Menu Content */}
        <div className={styles.menuitems}>
          {chk_storage_value != '' && userdetails.user_status !=="2" && (
            <ul className="menu-sh-item">
              <li className='eviewtm'>eView <sup>&reg;</sup></li>
              <li key='1' className={isActive ? "show" : ''} onClick={() => setIsActive(!isActive)}><NavLink exact activeClassName="active" to="/"><T _str="Dashboard" /></NavLink></li>
              <li key='2' className={isActive ? "show" : ''} onClick={() => setIsActive(!isActive)}><NavLink activeClassName="active" to="/groups"><T _str="Groups" /></NavLink></li>
              <li key='3' className={isActive ? "show" : ''} onClick={() => setIsActive(!isActive)}><NavLink activeClassName="active" to="/playlist"><T _str="Playlists" /></NavLink></li>
              <li key='4' className={isActive ? "show" : ''} onClick={() => setIsActive(!isActive)}><NavLink activeClassName="active" to="/media"><T _str="Library" /></NavLink></li>
              <li key='5' className={isActive ? "show" : ''} onClick={() => setIsActive(!isActive)}><NavLink activeClassName="active" to="/calendar"><T _str="Calendar" /></NavLink></li>
              <li key='6' className={isActive ? "show" : ''} onClick={() => setIsActive(!isActive)}><NavLink activeClassName="active" to="/profile"><T _str="Profile" /></NavLink></li>
              {userrole === 'country_admin' && (
                <div className={styles.rolemenu}>
                  <NavDropdown title={t("Unit/ User Management")} id="nav-dropdown" className={styles.mainMenuInner}>
                    <NavLink className={styles.rolemenuItems} onClick={() => setIsActive(!isActive)} activeClassName="active" to="/manage-users"><T _str="Manage Users" /></NavLink>
					          {countryName === 'Japan' && (<NavLink className={styles.rolemenuItems} onClick={() => setIsActive(!isActive)} activeClassName="active" to="/add-mercury-user"><T _str="Manage Mercury Users" /></NavLink>)}
                    <NavLink className={styles.rolemenuItems} onClick={() => setIsActive(!isActive)} activeClassName="active" to="/add-live-content"><T _str="Add Live Content" /></NavLink>
                    <NavLink className={styles.rolemenuItems} onClick={() => setIsActive(!isActive)} activeClassName="active" to="/add-themes"><T _str="Add Themes" /></NavLink>
                    <NavLink className={styles.rolemenuItems} onClick={() => setIsActive(!isActive)} activeClassName="active" to="/live-content-management"><T _str="Live Content Management" /></NavLink>
                    <NavLink className={styles.rolemenuItems} onClick={() => setIsActive(!isActive)} activeClassName="active" to="/theme-list"><T _str="Theme List" /></NavLink>
                    <NavLink className={styles.rolemenuItems} onClick={() => setIsActive(!isActive)} activeClassName="active" to="/deviceslist"><T _str="Devices" /></NavLink>
                    <NavLink className={styles.rolemenuItems} onClick={() => setIsActive(!isActive)} activeClassName="active" to="/units-report"><T _str="Units Report" /></NavLink>
                  </NavDropdown>
                </div>
              )}

              {userrole === 'building_manager' && (
                <div className={styles.rolemenu}>
                  <NavDropdown title={t("Manage Content")} id="nav-dropdown">
                    <NavLink className={styles.rolemenuItems} onClick={() => setIsActive(!isActive)} activeClassName="active" to="/add-live-content"><T _str="Add Live Content" /></NavLink>
                    <NavLink className={styles.rolemenuItems} onClick={() => setIsActive(!isActive)} activeClassName="active" to="/live-content-management"><T _str="Live Content Management" /></NavLink>
                    <NavLink className={styles.rolemenuItems} onClick={() => setIsActive(!isActive)} activeClassName="active" to="/add-themes"><T _str="Add Themes" /></NavLink>
                    <NavLink className={styles.rolemenuItems} onClick={() => setIsActive(!isActive)} activeClassName="active" to="/theme-list"><T _str="Theme List" /></NavLink>
                  </NavDropdown>
                </div>
              )}
              {userrole === 'super_admin' && (
                <div className={styles.rolemenu}>
                  <NavDropdown title={t("Manage Weather Feeds")} id="nav-dropdown">
                    <NavLink className={styles.rolemenuItems} onClick={() => setIsActive(!isActive)} activeClassName="active" to="/add-weather-feeds"><T _str="Add Weather Feeds" /></NavLink>
                    <NavLink className={styles.rolemenuItems} onClick={() => setIsActive(!isActive)} activeClassName="active" to="/live-content-management"><T _str="Manage Weather Feeds" /></NavLink>
                    <NavLink className={styles.rolemenuItems} onClick={() => setIsActive(!isActive)} activeClassName="active" to="/add-device"><T _str="Add Device" /></NavLink>
                    <NavLink className={styles.rolemenuItems} onClick={() => setIsActive(!isActive)} activeClassName="active" to="/deviceslist"><T _str="Devices" /></NavLink>
                  </NavDropdown>
                </div>
              )}
            </ul>
          )}
        </div>
        {chk_storage_value != '' && (
          <div className={styles.logout}>
            <img alt="Logout" src={logout} onClick={logoutModalShow} />
          </div>
        )}
      </div>
      {/* Menu footer */}

      <div className={isActive ? "overlay" : ''} onClick={() => setIsActive(!isActive)}></div>

      {
        logoutModal === 'true' && (
          <div className={styles.logoutModal}>
            <div className={styles.logoutModalInner}>
              <p><T _str="Are you sure you want to log out?" /></p>
              <button className='btn btn--alt' onClick={logoutModalClose}><T _str="CANCEL" /></button>
              <button className='btn btn--alt' onClick={logoutDestory}><T _str="LOG OUT" /></button>

            </div>
            <div className={styles.logout}>
            </div>
          </div>
        )
      }
      {logoutModal === 'true' && (<div className={styles.backdrop}></div>)}

    </section >
  );
}

export default Menu;