/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: Devicelistunassigned.js
File Description: Lists the un aassigned devcies of CMS portal

Copyright 2021,2022 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
import 'react-tabs/style/react-tabs.css';
import { useState, useEffect } from 'react';
import config from '../utils/config';
import Addusermailun from '../Components/Devices/Addusermailun';
import "../css/device-list.css";
import axios from "axios";
import { T } from '@transifex/react';
import { t } from "@transifex/native";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import LoadingOverlay from "react-loading-overlay";

function Deviceslistunassigned() {

  const perPage = 5;
  const [totalItemsun, settotalItemsun] = useState([]);
  const [totalPagesun, setTotalPagesun] = useState(1);
  const [pageun, setPageun] = useState(0);
  const [searchpageun, setSearchPageun] = useState(0);
  const [searchloadun, setsearchloadun] = useState(0);
  const [loading, setLoading] = useState(false);
  const [unitid, setUnitid] = useState([]);
  const [cpid, setCpid] = useState([]);
  const [street, setStreet] = useState([]);
  const [city, setCity] = useState([]);
  const [devicedataun, setDevicedataun] = useState([]);
  const [devicedataunall, setDevicedataunall] = useState([]);
  const [suggestionsunitid, setSuggestionsunitid] = useState([]);
  const [suggestionscpid, setSuggestionscpid] = useState([]);
  const [suggestionsstreetadrress, setSuggestionsstreetadrress] = useState([]);
  const [suggestionscity, setSuggestionscity] = useState([]);
  const [unitIdsChecked, setUnitIdsChecked] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [sort, SetSort] = useState('changed');
  const [order, SetOrder] = useState('DESC');


  var token = localStorage.getItem("userdetails")
  var countryid = token && JSON.parse(token).cid

  useEffect(() => {

    setLoading(true);

    const fetchDevices = async () => {
      if (searchpageun === 0) {
        if(searchloadun === 0){
          axios.get(config.drupal_url + `/api/deviceslist?field_assign_user_target_id_op=empty&field_mpdid_value=${cpid}&field_country_nid_target_id=${countryid}&sort_by=${sort}&sort_order=${order}&items_per_page=${perPage}&page=${pageun}`)
            .then((reponse) => {
              setDevicedataun([...devicedataun, ...reponse.data.rows]);
              setTotalPagesun(reponse.data.pager.total_pages);
              settotalItemsun(reponse.data.pager.total_items);
              setLoading(false);
            })
            .catch(function (error) {
              console.error('There was an error!', error);
            })
        }else{
          axios.get(config.drupal_url + `/api/deviceslist?field_assign_user_target_id_op=empty&field_eserviceunitid_value=${unitid}&field_mpdid_value=${cpid}&field_unit_city_value=${city}&field_unit_streetaddress_value=${street}&field_country_nid_target_id=${countryid}&sort_by=${sort}&sort_order=${order}&items_per_page=${perPage}&page=${pageun}`)
          .then((reponse) => {
            setDevicedataun([...devicedataun, ...reponse.data.rows]);
            setTotalPagesun(reponse.data.pager.total_pages);
            settotalItemsun(reponse.data.pager.total_items);
            setLoading(false);
          })
          .catch(function (error) {
            console.error('There was an error!', error);
          })
        }
      }
    }
    fetchDevices();

  }, [pageun]);

  useEffect(() => {
    const fetchunassDeviceseall= async () => {
      axios.get(config.drupal_url + `/api/deviceslist?field_assign_user_target_id_op=empty&&field_eserviceunitid_value=${unitid}&field_mpdid_value=${cpid}&field_unit_city_value=${city}&field_unit_streetaddress_value=${street}&field_country_nid_target_id=${countryid}&sort_by=${sort}&sort_order=${order}`, { withCredentials: true })
        .then((reponse) => {
          setDevicedataunall([...devicedataunall, ...reponse.data.rows]);
          setLoading(false);
        })
    }
    fetchunassDeviceseall();
  }, []);
  const onChangeHandlerunitid = (unitid) => {
    setUnitid(unitid);
    let eserviceunitidmatches = []

    if (unitid) {
      const regex = new RegExp(`${unitid}`, 'i');
      eserviceunitidmatches = devicedataunall.filter(devicedataunall => regex.test(devicedataunall.field_eserviceunitid));
    }
    const unique = [...new Set(eserviceunitidmatches.map(item => item.field_eserviceunitid))];
    setSuggestionsunitid(unique)

  }
  const onChangeHandlercpid = (cpid) => {
    setCpid(cpid);
    let cpidmatches = []
    if (cpid) {
      const regex = new RegExp(`${cpid}`, 'i');
      cpidmatches = devicedataunall.filter(devicedataunall => regex.test(devicedataunall.field_mpdid));
    }
    const unique = [...new Set(cpidmatches.map(item => item.field_mpdid))];
    setSuggestionscpid(unique)
  }
  const onChangeHandlerstreet = (street) => {
    setStreet(street);
    let streetaddressmatches = []
    if (street) {
      const regex = new RegExp(`${street}`, 'i');
      streetaddressmatches = devicedataunall.filter(devicedataunall => regex.test(devicedataunall.field_unit_streetaddress));
    }
    const unique = [...new Set(streetaddressmatches.map(item => item.field_unit_streetaddress))];
    setSuggestionsstreetadrress(unique)
  }

  const onChangeHandlercity = (city) => {
    setCity(city)
    let citymatches = []
    if (city) {
      const regex = new RegExp(`${city}`, 'i');
      citymatches = devicedataunall.filter(devicedataunall => regex.test(devicedataunall.field_unit_city));
    }
    const unique = [...new Set(citymatches.map(item => item.field_unit_city))];
    setSuggestionscity(unique);
  }

  const handleSortChange = (sortvalue) => {
    SetSort(sortvalue)

  }
  const handleOrderChange = (ordervalue) => {
    SetOrder(ordervalue)

  }
  const search = (e) => {
    e.preventDefault();
    const devicefilter = async () => {
      setLoading(true);
      setSearchPageun(searchpageun + 1);
      setsearchloadun(searchloadun + 1)
      setPageun(0);
      axios.get(config.drupal_url + `/api/deviceslist?field_assign_user_target_id_op=empty&field_eserviceunitid_value=${unitid}&field_mpdid_value=${cpid}&field_unit_city_value=${city}&field_unit_streetaddress_value=${street}&field_country_nid_target_id=${countryid}&sort_by=${sort}&sort_order=${order}&items_per_page=${perPage}&page=0`)
        .then((reponse) => {
          setDevicedataun(reponse.data.rows);
          setTotalPagesun(reponse.data.pager.total_pages);
          settotalItemsun(reponse.data.pager.total_items);
          setLoading(false);
        })
        .catch(function (error) {
          console.error('There was an error!', error);
        })
    }
    devicefilter();

  }
  const refreshPage = async () => {
    // Reset all the state values and list unassigned units
    setLoading(true);
    const perPage = 5;
    settotalItemsun([]);
    setTotalPagesun(1)
    setPageun(0)
    setSearchPageun(0)
    setsearchloadun(0)
    setUnitid([])
    setCpid([])
    setStreet([])
    setCity([])
    setDevicedataun([])
    setDevicedataunall([])
    setSuggestionsunitid([])
    setSuggestionscpid([])
    setSuggestionsstreetadrress([])
    setSuggestionscity([])
    setUnitIdsChecked([])
    setAllChecked(false)
    SetSort('changed');
    SetOrder('DESC');

    const fetchunassDeviceseall= async () => {
      axios.get(config.drupal_url + `/api/deviceslist?field_assign_user_target_id_op=empty&&field_eserviceunitid_value=${unitid}&field_mpdid_value=${cpid}&field_unit_city_value=${city}&field_unit_streetaddress_value=${street}&field_country_nid_target_id=${countryid}&sort_by=${sort}&sort_order=${order}`, { withCredentials: true })
        .then((reponse) => {
          setDevicedataunall([...devicedataunall, ...reponse.data.rows]);
          setLoading(false);
        })
    }
    fetchunassDeviceseall();


    //window.location.reload(false)
  }
  const handleCheckedunitid = (unitid, event) => {
    const unitChecked = [...unitIdsChecked];
    if (unitChecked.includes(event.target.id)) {
      var index = unitChecked.indexOf(event.target.id);
      unitChecked.splice(index, 1);
    } else {
      unitChecked.push(event.target.id);
    }
    setUnitIdsChecked(unitChecked);


  }

  const handleAllCheck = e => {
    if (allChecked) {
      setAllChecked(false);
      return setUnitIdsChecked([]);
    }
    setAllChecked(true);
    return setUnitIdsChecked(devicedataun.map(row => row.nid));
  };

  const onSuggestHandlerunitid = (unitid) => {
    setUnitid(unitid)
    setSuggestionsunitid([false])
  }
  const onSuggestHandlercpid = (cpid) => {
    setCpid(cpid)
    setSuggestionscpid([false])
  }
  const onSuggestHandlerstreet = (street) => {
    setStreet(street)
    setSuggestionsstreetadrress([false])
  }

  const onSuggestHandlercity = (city) => {
    setCity(city)
    setSuggestionscity([false]);
  }
  const buttonclicked = (val) => {
    setLoading(val);
  }
  const clearUnitid = (event) => {
    setSuggestionsstreetadrress([false])
    setSuggestionscity([false])
    setSuggestionscpid([false])
  }
  const clearCpid = (event) => {
    setSuggestionsstreetadrress([false])
    setSuggestionscity([false])
    setSuggestionsunitid([false])
  }
  const clearStreetadress = (event) => {
    setSuggestionsunitid([false])
    setSuggestionscity([false])
    setSuggestionscpid([false])
  }

  const clearCity = (event) => {
    setSuggestionsunitid([false])
    setSuggestionsstreetadrress([false])
    setSuggestionscpid([false])
  }

  return (
    <>
      <LoadingOverlay active={loading} spinner text={t('Processing')}>
        <div className="devices-unassigned-tab">
          <ToastContainer />
          <div className="views-exposed-widgets">
            <form onSubmit={search} className="devices-unassigned-form">
              <div className="views-exposed-widget unit-number">
                <label for="unit-number"><T _str="Unit Number" /></label>
                <div className="views-widget">
                  <div className="input-group">
                    <input className="form-control form-text" type="text" id="unit-number" onFocus={(event)=>clearUnitid(event)} name="field_eserviceunitid" onChange={(e) => onChangeHandlerunitid(e.target.value)} value={unitid} size="30" maxlength="128" autocomplete="OFF" aria-autocomplete="list" />
                    <div id="list-dropdown" style={{ display: suggestionsunitid == "false" ? "none" : "block" }}>
                      {(suggestionsunitid.length == 0 && unitid != "") ?
                        <span className="list-items">
                          {t("No results found. Please try again.")}
                        </span> : suggestionsunitid.map((unitidsuggestion, i) =>
                          <span className="list-items" key={i} onClick={() => onSuggestHandlerunitid(unitidsuggestion)} >{unitidsuggestion}</span>
                        )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="views-exposed-widget cpid">
                <label for="cpid"><T _str="CP-ID" /></label>
                <div className="views-widget">
                  <div className="input-group">
                    <input className="form-control form-text" type="text" id="cpid" onFocus={(event)=>clearCpid(event)} name="field_mpdid" onChange={(e) => onChangeHandlercpid(e.target.value)} value={cpid} size="30" maxlength="128" autocomplete="OFF" aria-autocomplete="list" />
                    <div id="list-dropdown" style={{ display: suggestionscpid == "false" ? "none" : "block" }}>
                      {(suggestionscpid.length == 0 && cpid != "") ?
                        <span className="list-items">
                          {t("No results found. Please try again.")}
                        </span> : suggestionscpid.map((cpidsuggestion, i) =>
                          <span className="list-items" key={i} onClick={() => onSuggestHandlercpid(cpidsuggestion)} >{cpidsuggestion}</span>
                        )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="views-exposed-widget StreetAddress">
                <label for="street-address"><T _str="Street Address" /></label>
                <div className="views-widget">
                  <div className="input-group">
                    <input className="form-control form-text" type="text" id="street-address" onFocus={(event)=>clearStreetadress(event)} name="field_unit_streetaddress" onChange={(e) => onChangeHandlerstreet(e.target.value)} value={street} size="30" maxlength="128" autocomplete="OFF" aria-autocomplete="list" />
                    <div id="list-dropdown" style={{ display: suggestionsstreetadrress == "false" ? "none" : "block" }}>
                      {(suggestionsstreetadrress.length == 0 && street != "") ?
                        <span className="list-items">
                          {t("No results found. Please try again.")}
                        </span> : suggestionsstreetadrress.map((streetsuggestion, i) =>
                          <span className="list-items" key={i} onClick={() => onSuggestHandlerstreet(streetsuggestion)} >{streetsuggestion}</span>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="views-exposed-widget city">
                <label for="city"><T _str="City" /></label>
                <div className="views-widget">
                  <div className="input-group">
                    <input className="form-control form-text" type="text" id="city" name="field_unit_city"  onFocus={(event)=>clearCity(event)} onChange={(e) => onChangeHandlercity(e.target.value)} value={city} size="30" maxlength="128" autocomplete="OFF" aria-autocomplete="list" />
                    <div id="list-dropdown" style={{ display: suggestionscity == "false" ? "none" : "block" }}>
                      {(suggestionscity.length == 0 && city != "") ?
                        <span className="list-items">
                          {t("No results found. Please try again.")}
                        </span> : suggestionscity.map((citysuggestion, i) =>
                          <span className="list-items" key={i} onClick={() => onSuggestHandlercity(citysuggestion)} >{citysuggestion}</span>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="views-exposed-widget sort-by">
                <label for="sort-by"><T _str="Sort by" /></label>
                <div className="views-widget">
                  <div className="input-group">
                    <select value={sort} onChange={(val) => handleSortChange(val.target.value)} className="form-control form-select" id="edit-sort-by" name="sort_by"><option value="changed" selected="selected">{t("Updated date")}</option><option value="field_eserviceunitid_value">{t("Unit Number")}</option></select>
                  </div>
                </div>
              </div>
              <div className="views-exposed-widget sort-order">
                <label for="sort-by"><T _str="Sort Order" /></label>
                <div className="views-widget">
                  <div className="input-group">
                    <select onChange={(val) => handleOrderChange(val.target.value)} className="form-control form-select" id="edit-sort-order" name="sort_order"><option value="ASC">{t("Asc")}</option><option value="DESC" selected="selected">{t("Desc")}</option></select>
                  </div>
                </div>
              </div>

              <div className="views-exposed-widget views-submit-button">
                <button type="submit" id="edit-submit-devices-list" name="" value="Search" className="btn btn-primary form-submit"><T _str="Search" /></button>
                <button type="submit" id="edit-reset" name="op" value="Reset" onClick={refreshPage} className="btn btn-default form-submit"><T _str="Reset" /></button>
              </div>
            </form>
          </div>
          {(loading === false && devicedataun.length === 0) && (
                  <div class="device-not-found">  <T _str="No Devices Found" /></div>
               )}
       {(devicedataun.length > 0) && (
          <div className="tbody-div">
            <table className="views-table cols-7 table table-hover table-striped">
              <thead><tr>
                <th className="views-field views-row-selector" scope="col"><div className="form-item form-type-checkbox checkbox"> <label className="control-label"><input className="views-row-selector-table-select-all form-checkbox" type="checkbox" value="1" checked={allChecked}
                  onChange={handleAllCheck} /></label></div></th>
                <th className="views-field field-eserviceunitid" scope="col"><T _str="Unit Number" /></th>
                <th className="views-field field-mpdids" scope="col"><T _str="CP-ID" /></th>
                <th className="views-field field-unit-streetaddress" scope="col"><T _str="Street Address" /></th>
                <th className="views-field field-unit-city" scope="col"><T _str="City" /></th>
                <th className="views-field edit-node" scope="col"><T _str="Actions" /></th>
              </tr></thead>
              <tbody>
                {devicedataun.map((deviceun, index) => {
                  return (
                    <tr className="views-row" key={index}>
                      <td className="views-field views-row-selector"><div className="form-item form-item-views-row-selector-0 form-type-checkbox checkbox">
                        <label className="control-label" for="edit-views-row-selector-0">
                          <input className="views-row-selector-select form-checkbox" type="checkbox" id={deviceun.nid} checked={unitIdsChecked.includes(deviceun.nid) ? 1 : 0}
                            onChange={(event) => handleCheckedunitid(index, event)} name="views_row_selector[0]" value={deviceun.nid} /></label></div></td>
                      <td className="views-field field-eserviceunitid">{deviceun.field_eserviceunitid}</td>
                      <td className="views-field ">{deviceun.field_mpdid}</td>
                      <td className="views-field field-unit-streetaddress">{deviceun.field_unit_streetaddress}</td>
                      <td className="views-field field-unit-city">{deviceun.field_unit_city}</td>
                      <td className="views-field edit-node"> <a href={'/deviceconfiguration/' + deviceun.nid}><T _str="View" /></a></td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
            )}
          <div className="assignclass"><div class="assignclass-child">
            <span className="assign-default-message" style={{ display: unitIdsChecked.length ? 'none' : 'block' }}><T _str="Please select at least one device to view actions" /></span>
            {unitIdsChecked.length && <Addusermailun checkedunitid={unitIdsChecked} buttonclicked={buttonclicked} />}
          </div></div>
          <div className="device-list-footer">

            {(perPage * (pageun + 1)) < totalItemsun && <button className="btn-load-more" onClick={() => { setPageun(pageun + 1); setSearchPageun(0) }}>{loading ? '' : <T _str='Load more' />}</button>}
            {totalItemsun !== 0 && <span className="list-count"> {t('Displaying @start - @end of @total').replace('@start', 1).replace('@end', (perPage * (pageun + 1)) < totalItemsun ? perPage * (pageun + 1) : totalItemsun).replace('@total', totalItemsun)}</span>}</div>
        </div>
      </LoadingOverlay>
    </>
  )
}

export default Deviceslistunassigned;