/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: EditDevice.js
File Description: Implements edit device functionality of CMS portal

Copyright 2022,2023 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
import device from "../css/device.module.css";
import { useState, useEffect } from 'react';
import axios from "axios";
import { T } from '@transifex/react';
import config from '../utils/config';
import { useHistory } from "react-router-dom";
import { t } from "@transifex/native"
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import LoadingOverlay from "react-loading-overlay";
import $ from 'jquery';
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";

function EditDevice(props) {
  let history = useHistory()
  const [editdevice, setEditdevice] = useState([]);
  const [currentValue, setCurrentValue] = useState([]);
  const [themes, setThemes] = useState([]);
  const [slidervalue, setSliderValue] = useState(50);
  const [themevalue, setThemeValue] = useState([]);
  const [oldthemevalue, setOldThemeValue] = useState([]);
  const [loading, setLoading] = useState(false);


  const nid = props.id;
  var devicelistData = JSON.parse(
    localStorage.getItem("devicelist")
  );
  if (devicelistData) {
   devicelistData.editdevice = 1;
	   localStorage.setItem(
      "devicelist",
      JSON.stringify(devicelistData)
    );
	}
  useEffect(() => {
    const devicedata = async () => {
      setLoading(true);
      axios.get(config.drupal_url + '/DeviceConfiguration/' + nid, { withCredentials: true })
        .then(response => {
          setLoading(false);
          setEditdevice(response.data.device)
          setThemes(response.data.theme_list)
          setThemeValue(response.data.device.theme)
          setOldThemeValue(response.data.device.old_theme_id)
          setSliderValue(response.data.device.background_musicvolume)
        })
    }
    devicedata();
  }, []);


  const handleChange = (e) => {

    const value = e.target.value;
    const target = e.target;

    switch (e.target.id) {
      case "field_live_feed_refresh_rate":
        setEditdevice((prevData) => ({ ...prevData, feed_refreshrate: value }));
        break;
      case "edit-field-background-music-on-und":
        setEditdevice((prevData) => ({ ...prevData, background_musicon: value }));
        break;
      case "deviceTheme":

        setEditdevice((prevData) => ({ ...prevData, theme: themevalue }));
        break;
      case "edit-field-infotainment-message":
	    const lines = value.split("\n");
        for (var i = 0; i < lines.length; i++) {
          if (lines[i].length <= 15) continue;
          var j = 0;
          var space = 15;
          while (j++ <= 15) {
            if (lines[i].charAt(j) === " ") space = j;
          }
          lines[i + 1] = lines[i].substring(space + 1) + (lines[i + 1] || "");
          lines[i] = lines[i].substring(0, space);
        }
        if (lines.length > 6) {
      
          return;
        }
        if (value.length > 75) {
        
          return;
        }
        setEditdevice((prevData) => ({ ...prevData, infotainment_message: lines.slice(0, 10).join("\n") }));
        break;

      default:
        break;
    }
  };

  const handleSubmit = (e) => {

    e.preventDefault();
    const device_data = {
      "nid": nid,
      "feed_refreshrate": editdevice.feed_refreshrate,
      "background_musicon": editdevice.background_musicon,
      "background_musicvolume": slidervalue,
      "theme_nid": editdevice.theme,
      "infotainment_message": editdevice.infotainment_message,
      "old_theme_nid": oldthemevalue
    };

    setLoading(true);
    axios
      .post(config.drupal_url + "/saveconfiguration", device_data, {
        withCredentials: true,
      })
      .then((res) => {
        setLoading(false);
        history.push(`/deviceslist`)
        if (res.data.message == true) {
          toast.success(t("@type %title has been updated.").replace('%title', res.data.title).replace('@type', t('Device')));
        } else if (res.data.message == 'offline') {
          toast.info(res.data.title + t('is offline. Device configuration will be updated once the device is online.'));
        } else {
          toast.error(t('Error updating device') + res.data.title + t('Please try later. If the error persists, please contact Otis support.'));
        }
      })
      .catch((err) => {
        history.push(`/deviceslist`)
        setLoading(false);
      });
  }

  if ($('[id^=edit-field-infotainment-message]').length) {
    const targettextarea = document.querySelector('#edit-field-infotainment-message');

    targettextarea.addEventListener('paste', (event) => {

      var self = $('[id^=edit-field-infotainment-message]');
      var charLim = 75;
      var charlimit = 15;
      setTimeout(function (e) {
        var new_stuff = $(self).val();
        $(self).val(new_stuff);
        if (event.target.value.length >= 75) {
          var msg = 'Maximum 75 characters are allowed.';
          var slicedtxt = 75 - event.target.value.length;
          if (slicedtxt != 0) {
            var remove = event.target.value.slice(0, slicedtxt);

            $(self).val(remove);
          }
        }
        var lines = $(self).val().split('\n');

        for (var i = 0; i < lines.length; i++) {
          if (lines[i].length <= charlimit) continue;
          var j = 0;
          var space = charlimit;
          while (j++ <= charlimit) {
            if (lines[i].charAt(j) === ' ') space = j;
          }
          lines[i + 1] = lines[i].substring(space + 1) + (lines[i + 1] || "");
          lines[i] = lines[i].substring(0, space);
        }
        $(self).val(lines.slice(0, 6).join('\n'));
      }, 0);

    });


    targettextarea.addEventListener('keypress', (event) => {
      var charLength = 75;
      var charlimit = 15;
      var infoval = event.target.value;
      if (event.target.value.length >= charLength) {
        event.preventDefault();

      }

      var text_remaining = charLength - infoval.length - 1;
      if (text_remaining < 0) {
        text_remaining = 0;

      }

      $('[class^=infotext]').css("display", "block")
      $('[id^=infotextareacount').html(text_remaining);
      var lines = infoval.split('\n');

      for (var i = 0; i < lines.length; i++) {
        if (lines[i].length < charlimit) continue;
        var j = 0;
        var space = charlimit;
        while (j++ <= charlimit) {
          if (lines[i].charAt(j) === ' ') space = j;
        }
        lines[i + 1] = lines[i].substring(space + 1) + (lines[i + 1] || "");
        lines[i] = lines[i].substring(0, space);
      }
      $('[id^=edit-field-infotainment-message]').val(lines.slice(0, 6).join('\n'));
    });

  }
  $('.devicenodetheme').click(function () {
    $(this).find('input').prop("checked", true);
    setOldThemeValue(themevalue);
    setThemeValue($(this).find('input').val());
    $('.devicenodetheme').removeClass('device_selected__1b2oj');
    $(this).addClass("device_selected__1b2oj");

  });

  const redirect = () => {
    history.push(`/deviceslist`)
  }

  return (
    <>
      <LoadingOverlay active={loading} spinner text={t('Processing')}>
        <div className={device["Device-Configuration-page"]}>
          <form
            enctype="#"
            method="post"
            id="edit-device-form"
            accept-charset="UTF-8"
            onSubmit={handleSubmit}
          >
            <div className={device["groups-heading-container Devicelist-heading"]}>
              <h2>eView<sup>&reg;</sup> -  <T _str="Device Configuration" /></h2>
              <div className={device["main-action-buttons"]}>
                <button
                  id={device.btnSave}
                  type="submit"
                  value="Save"
                  className="btn"
                >
                  <T _str="Save" />
                </button>
                <button
                  id={device["edit-reset"]}
                  type="submit"
                  value="Save"
                  className="btn cancel btn-cancel ms-1"
                  onClick={redirect}
                >
                  <T _str="Cancel" />
                </button>
              </div>
            </div>
            {[editdevice].map((devices) => {
              let currentValue = devices.feed_refreshrate
              let currentBGValue = devices.background_musicon
              return (
                <>
                  <div className={device["Device-Configuration-inner"]}>
                    <div className={device["form-item"]}>
                      <label className={device["control-label"]} for="edit-title-field"><T _str="Elevator Name" /></label>
                      <input className={device["text-full form-control form-text"]} disabled="disabled" type="text" name="title_fieldsadsad[und][0][value]" value={devices.title} size="60" maxlength="255" />
                    </div>
                    <div className={device["form-item"]}>
                      <label className={device["control-label"]} for="edit-title-field"><T _str="CP-ID" /></label>
                      <input className={device["text-full form-control form-text"]} disabled="disabled" type="text" name="title_field[und][0][value]" value={devices.cp_id} size="60" maxlength="255" />
                    </div>
                    <div class={device["form-item"]}>
                      <label className={device["control-label"]} for="edit-title-field"><T _str="Building Name (same as in G3MS)" /></label>
                      <input className={device["text-full form-control form-text"]} disabled="disabled" type="text" name="title_field[und][0][value]" value={devices.building_name} size="60" maxlength="255" />
                    </div>
                    <div className={device["form-item"]}>
                      <label className={device["control-label"]} for="edit-title-field"><T _str="Unit Number (same as in G3MS)" /></label>
                      <input className={device["text-full form-control form-text"]} disabled="disabled" type="text" name="title_field[und][0][value]" value={devices.unitid} size="60" maxlength="255" />
                    </div>
                    <div className={device["form-item"]}>
                      <label className="control-label" for="edit-title-field"><T _str="Contract ID" /></label>
                      <input className="text-full form-control form-text" disabled="disabled" type="text" name="title_field[und][0][value]" value={devices.contractid} size="60" maxlength="255" />
                    </div>
                    <div class={device["form-item"]}>
                      <label className={device["control-label"]} for="edit-title-field"><T _str="Building ID" /></label>
                      <input className={device["text-full form-control form-text"]} disabled="disabled" type="text" name="title_field[und][0][value]" value={devices.building_id} size="60" maxlength="255" />
                    </div>
                    <div className={device["form-item"]}>
                      <label className={device["control-label"]} for="edit-title-field"><T _str="Street Address" /></label>
                      <input className={device["text-full form-control form-text"]} disabled="disabled" type="text" name="title_field[und][0][value]" value={devices.unit_streetaddress} size="60" maxlength="255" />
                    </div>
                    <div className={device["form-item"]}>
                      <label className={device["control-label"]} for="edit-title-field"><T _str="City" /></label>
                      <input className={device["text-full form-control form-text"]} disabled="disabled" type="text" name="title_field[und][0][value]" value={devices.unit_city} size="60" maxlength="255" />
                    </div>
                    <div className={device["form-item"]}>
                      <label className={device["control-label"]} for="edit-title-field"><T _str="State" /></label>
                      <input className={device["text-full form-control form-text"]} disabled="disabled" type="text" name="title_field[und][0][value]" value={devices.unit_state} size="60" maxlength="255" />
                    </div>
                    <div className={device["form-item"]}>
                      <label className={device["control-label"]} for="edit-title-field"><T _str="Country" /></label>
                      <input className={device["text-full form-control form-text"]} disabled="disabled" type="text" name="title_field[und][0][value]" value={devices.unit_country} size="60" maxlength="255" />
                    </div>
                    <div className={device["form-item"]}>
                      <label className={device["control-label"]} for="edit-title-field"><T _str="Postal Code" /></label>
                      <input className={device["text-full form-control form-text"]} disabled="disabled" type="text" name="title_field[und][0][value]" value={devices.unit_postal_code} size="60" maxlength="255" />
                    </div>
                    <div className={device["form-item"]}>
                      <label className="control-label text-white" for="edit-title-field"><T _str="Live Feed Refresh Rate" /></label>
                      <select name="field_live_feed_refresh_rate[und]" id="field_live_feed_refresh_rate" onChange={handleChange} value={currentValue} ><option value="1440" selected="selected">{t('Once per day')}</option><option value="720">{t('Twice per day')}</option><option value="480">{t('Three times per day')}</option><option value="360">{t('Four times per day')}</option><option value="240">{t('Six times per day')}</option></select>
                    </div>
                    {devices.cpibdevicetype == 'eView2-device' &&
                      <>
                        <div className={device["form-item"]}>
                          <label className="control-label text-white" for="edit-title-field"><T _str="Background Music" /></label>
                          <select class="form-control form-select" onChange={handleChange} id="edit-field-background-music-on-und" name="field_background_music_on[und]" value={currentBGValue}><option value="true">{t("ON")}</option><option value="false" selected="selected">{t("OFF")}</option></select>
                        </div>

                        <div className={device["form-item"]}>
                          <label className={device["control-label text-white"]} for="customRange1"><T _str="Background Music Volume in Percent" />
                          </label>
                          <Slider
                            min={0}
                            max={100}
                            id="customRange1"
                            value={slidervalue}
                            step={1}
                            onChange={(e) => {
                              setSliderValue(e);
                            }}
                            orientation="horizontal"
                          />

                        </div>
                      </>
                    }
                    {devices.cpibdevicetype == 'infotainment-device' &&
                      <div className={`${device["form-item"]} ${device["message"]}`}>
                        <label className={device["control-label text-white"]} for="edit-title-field"><T _str="Infotainment Message" /></label>
                        <textarea onChange={handleChange} class={device["form-textarea"]} id="edit-field-infotainment-message" name="field_infotainment_message[und][0][value]" cols="60" rows="3" value={devices.infotainment_message} />
                        <div id={device.charcunt} className="infotext"><span id="infotextareacount" className={device.cunt_num}>52</span><T _str="characters remaining" /></div>
                        <p className={device.limit_note}><T _str="Note: A maximum of 75 characters or 6 lines are allowed." /></p>
                      </div>
                    }

                    <div className={`${device["form-item"]} ${device["select-theme"]}`}>
                      <label className={device["control-label text-white"]} for="edit-title-field"><T _str="Select Theme" /> *</label>
                      {themes.map((theme, index) => (

                        <>
                          <div className={`${device['theme-image']} ${themevalue === theme.nid ? device['selected'] : ''} devicenodetheme`} id="deviceTheme" onClick={handleChange} >
                            <input
                              type="radio"
                              value={theme.nid}
                              className="deviceTheme"
                              name="deviceTheme"
                              checked={themevalue === theme.nid}
                            />
                            <img src={theme.path} width={100} height={56} id="deviceTheme" alt="Theme Image" />
                          </div>
                        </>

                      ))}
                    </div>

                  </div>
                </>
              )
            })}
          </form>
        </div>
      </LoadingOverlay>
    </>
  );
}

export default EditDevice;