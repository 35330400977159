/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: Login.js
File Description: Implements login functionality

Copyright 2023,2024 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
import { useState,useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";
import config from '../utils/config';
import axios from "axios";
import { T, useT } from '@transifex/react';
import { t,tx } from "@transifex/native";
import "../css/login.css";
import logo from './../Assets/logo.svg';
import MCYlogo from './../Assets/MCYlogo_green.svg';
import { Buffer } from 'buffer';


const Login = () => {
   const [isMcy, setMcy] = useState(null);
	tx.init({
		token: "1/b764923cd80f817a0b810d8688e7c8f4dc7c75eb",
	  });
	  const history = useHistory();
	  const user_language = navigator.language || navigator.userLanguage;
	  const language_code = user_language.split("-");
	  const user_lang = language_code[0];
	  const queryParams = new URLSearchParams(window.location.search);
      let logo_type = queryParams.get('logo');	  
	  tx.setCurrentLocale(user_lang);

	const [username, setusername] = useState("");
	const [password, setpassword] = useState("");
	const [loading, setLoading] = useState(false);
	const [emailvalidation, setemailvalidation] = useState(null);
	const [passvalidation, setPassvalidation] = useState(null);
	const [invalid, setInvalid] = useState(null);
    const usermail = useT("*Email");
	
    useEffect(() => {
	const mcylogo = sessionStorage.getItem('logo_type');
	let previousPageUrl= document.referrer;
    if (logo_type == "mcy" || mcylogo == "mcy" || logo_type == "MCY") {
        sessionStorage.setItem('logo_type', 'mcy');
		 setMcy(true);
    } 
    if (previousPageUrl === "" && logo_type != "mcy" && logo_type != "MCY" ) {
	    sessionStorage.setItem('logo_type', 'otis');
		setMcy(null);
	}
    if (mcylogo == 'mcy' && previousPageUrl !== "") {
	    if (logo_type != "mcy" && logo_type != "MCY") { 
            history.push("/?logo=mcy");
		}
    } 
	   createcookie();
	 }, [isMcy]);
	 
	function isValidEmail(userEmail) {
		const regex = /^[a-zA-Z0-9!#$%&'+^_`{}~-]+(?:\.[a-zA-Z0-9!#$%&'+^_`{}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/i;
		return regex.test(userEmail);
    
    }
	
	function createcookie() {
		
		  var cookieAlert = document.querySelector(".cookiealert");
		  var acceptCookies = document.querySelector(".acceptcookies");
	
		  if (!cookieAlert) {
			return;
		  }
		  //cookieAlert.offsetHeight; // Force browser to trigger reflow (https://stackoverflow.com/a/39451131)

		  // Show the alert if we cant find the "acceptCookies" cookie
		  if (!getCookie("acceptCookies")) {
			cookieAlert.classList.add("show");
			var element = document.getElementById("divoverlay");
			element.classList.remove("hidden");
		  } else {
			var element = document.getElementById("divoverlay");
			element.classList.add("hidden");
		  }

		  // When clicking on the agree button, create a 1 year
		  // cookie to remember user's choice and close the banner
		  acceptCookies.addEventListener("click", function () {
			setCookie("acceptCookies", true, 365);
			cookieAlert.classList.remove("show");
			var element = document.getElementById("divoverlay");
			element.classList.add("hidden");
		  });

		  // Cookie functions from w3schools
		  function setCookie(cname, cvalue, exdays) {

		    console.log('setcookie' + cname + cvalue +exdays)
			var d = new Date();
			d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
			var expires = "expires=" + d.toUTCString();
			document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";

		  }

		  function getCookie(cname) {
			var name = cname + "=";
			var decodedCookie = decodeURIComponent(document.cookie);
			var ca = decodedCookie.split(';');
			for (var i = 0; i < ca.length; i++) {
			  var c = ca[i];
			  while (c.charAt(0) === ' ') {
				c = c.substring(1);
			  }
			  if (c.indexOf(name) === 0) {
				return c.substring(name.length, c.length);
			  }
			}
			return "";
		  }

		  if (!(/Chrome/.test(navigator.userAgent) && /Google/.test(navigator.vendor))) {
			document.getElementById('deviceDetect').style.display = 'block'
		  }

    }

	
    const handleSubmit = (e) => {
		e.preventDefault()
	    if (!username){
		   setemailvalidation(t("Please enter your email"));
		}
		else if (!isValidEmail(username)) { 
		   setemailvalidation(t("Please enter a valid email address."));
		    
	    }else if (!password){
		   setPassvalidation(t("Please enter your password"));
		   setemailvalidation(null);
		
		}else{
			 setLoading(true);
			   axios.get(config.drupal_url + '/session/token').then(result => {
				if (200 === result.status) {
					const csrfToken = result.data;
					const loginUrl = config.drupal_url + "/user/authentication?_format=json";
					const headers = {
					"Content-Type": "application/json",
					'X-CSRF-Token': csrfToken
					}
					const data = {
					   "mail":username,
					   "pass":password
					  };

					let encodedString = Buffer.from(JSON.stringify(data)).toString('base64');
					const salt1 = "H3FilhsRp25VQNOi==";
					const salt2 = "Aj8758XJHRvbrUeJ==";
					let encryptedString = { "data": salt1 + encodedString + salt2 };

				   axios.post(loginUrl, encryptedString, {headers}).then((response) => {
						if(response.status === 200) {
							setLoading(false); 	
						    if(response.data.user_status === null){
								 window.location.href = '/reset';
							 }
							 else if(response.data.user_status === "pending"){
								window.location.href = '/account/pending';
							 }
							 else if(response.data.user_status === "denied"){
								window.location.href = '/account/denied';
							 }
							 else if(response.data.user_status === "disabled"){
								window.location.href = '/account/disable';
							 }
						    else if(response.data.user_details){    
								localStorage.setItem(
								  "userdetails",
								  JSON.stringify(response.data.user_details)
								);
								localStorage.setItem(
								  "userlang",
								  response.data.user_details.language
								);
								localStorage.setItem('session_time', new Date());
								tx.setCurrentLocale(response.data.user_details.language);
								if(response.data.user_details.user_status !=="2"){
										window.location.href = '/';
								}else{
									    window.location.href = '/profile';
								}
								
							 }
							 else if(response.data.logged_limit){				
								window.location.href = response.data.logged_limit_url;
							 }else{
							    setInvalid(t("Invalid username or password"));
							 }
							 
						}
					
					}).catch((error) => {
						if( error.response ){
							setLoading(false); 
							setInvalid(t("Invalid username or password"));
						}
					});
				}
			})
		}
    };
	
return (
   <LoadingOverlay active={loading} spinner text={t("Processing")}>
      <ToastContainer />
	<div id="login">
		<section class="login">
			  <div id="logo">
			   {isMcy === true && (
        <>
          <img src={MCYlogo} />  
        </>
        )}
        {isMcy === false || isMcy === null && (
        <>
        <img src={logo} />  
        </>
        )}  
			</div>
			  <div class="top-heading"><T _str="eView® Content Configurator" /></div>
			  <h1><T _str="Sign In" /></h1>
			<div id="deviceDetect">
			  <p><T _str="Please Note: Google Chrome is the preferred browser" /></p>
			</div>
			<form onSubmit={handleSubmit}>
			<div class="error pageLevel" aria-hidden="false">
              <p role="alert">{invalid}</p>
            </div>
				<div class="entry">
				  <div class="entry-item">
					<label for="logonIdentifier">{t("*Email")}</label>
					<div class="error itemLevel" aria-hidden="false">
                    <p role="alert">{emailvalidation}</p>
                    </div>
					<input
					type="text"
					name="Username" placeholder={usermail}
					value={username}
					onChange={(e) => setusername(e.target.value)}
					/>
				  </div>
				  <div class="entry-item">
					<div class="password-label">
					  <label for="password">{t("*Password")}</label>
					  <a id="forgotPassword" tabindex="2" href="/reset">{t("Reset Password?")}</a>
					</div>
					<div class="error itemLevel" aria-hidden="false">
                     <p role="alert">{passvalidation}</p>
                    </div>
					<input
					type="password"
					name="Password" placeholder={t("*Password")}
					onChange={(e) => setpassword(e.target.value)}
					/>
				  </div>
				  <div class="buttons">
					<button id="next" tabindex="1">{t("Sign in")}</button>
				  </div>
				</div>
				<div class="create">
				  <p>
					{t("to the eView® Content Configurator")}<a id="createAccount" tabindex="1" href="register">{t("Request Access")}</a>
				  </p>
				</div>
			</form>
			<h3 class="bottom-heading">{t("Sign Up")}</h3>
			<hr class="abc" />	
        </section>
		</div>
		<div>
			<div class="overlaylogin" id="divoverlay"></div>
			<div class="alert text-center cookiealert" role="alert">
			  <div class="divpara">
				<p><T _str="To give you the best possible browsing experience,this site may use cookies. By continuing to use this site you consent to this use. For more information on our cookie policy visit our " />
                  <a href="http://preview.otis.com/en/us/global-legal/" target="_blank"><T _str="Privacy Policy"/></a></p>
			  </div>
			  <div class="divbtn">
				<button type="button" class="btn btn-primary btn-sm acceptcookies" aria-label="Close">
				  <T _str="Accept" />
				</button>
			  </div>
			</div>
        </div>


		</LoadingOverlay>
    )
};

export default Login;