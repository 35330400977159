import { T, useT } from '@transifex/react';
import { Helmet } from "react-helmet";
import '../../css/AccessDenied.css'

function AccessDenied(props) {

  const tabTitle = useT(props.title);

  return (
    <>
      <Helmet>
        <title>{tabTitle} | CMS</title>
      </Helmet>
      <div className="page-access-denied">
        <h2>403</h2>
        <h4><T _str="Access Denied" /></h4>
      </div>
    </>
  )
}

export default AccessDenied;