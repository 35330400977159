import React, { useState } from "react";
import style from "./PlaylistsPreview.module.css";
import eviewImage from "./../../Assets/playlist-preview-eview.png";
import defaultBgImage from "./../../Assets/default-theme-bg.jpg";
import infotainmentImage from "./../../Assets/playlist-preview-infotainment.png";
import naaleftPI from "./../../Assets/naa-left-pi.png";
import noSmoking from "./../../Assets/no-smoking.png";
import upGreenArrow from "./../../Assets/pi-left-up-arrow-green.png";
import upWhiteArrow from "./../../Assets/pi-left-up-white-arrow.png";
import AutoImage from "./../../Assets/previewImages/AutoImage.png"
import otisLogo from "./../../Assets/otis-logo-left-pain.png";
import config from "../../../src/utils/config";
import { FaInfoCircle } from "react-icons/fa";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { T } from "@transifex/react";
import Parser from "html-react-parser";
import "../../css/Playlist.css";
import { t } from "@transifex/native";
import Marquee from "react-easy-marquee";
import $ from "jquery";

const PlaylistsPreview = ({
  playlistsData,
  othersPlaylistsData,
  fullPreview,
  id,
  screenId,
  infomsg,
  source,
  devicetheme,
  countryDefaultPlaylist,
  bannerbgcolor,
  countryname,
  bannertextcolor
}) => {
  if (infomsg !== "") {
    infomsg = Parser(infomsg);
  }
  let allplaylistsData = [];
  if (othersPlaylistsData.length == 0) {
    allplaylistsData = playlistsData;
  } else {
    allplaylistsData = [...playlistsData, ...othersPlaylistsData];
  }
  if (typeof countryDefaultPlaylist !== 'undefined' && countryDefaultPlaylist.length == 1) {
    allplaylistsData = [...allplaylistsData, ...countryDefaultPlaylist];
  }


  const [contintervalz, setContIntervalz] = useState(1);
  const [banintervalz, setBanIntervalz] = useState(1);

  const getInterval = (inputUrl) => {
    const inputUrlPiece = inputUrl.split("##");
    var interval = inputUrlPiece[1];
    if (!inputUrlPiece[1] || inputUrlPiece[1] === 0) {
      interval = 5;
    }
    return inputUrlPiece[0] + "##" + interval;
  };
  const bannerImages = [];
  const contentImages = [];
  var bannerTextVal = [];
  var layout = "";
  var bannerTextSize = 0;
  var scrollspeed = 0;
  var fullbannertext = "off";
  var firstcontentInterval = 3;
  var firstbannerInterval = 3;
  var containerStyle = "";
  var bgColor = "";
  var textColor = "";
  var dvPrevCss = {};
  if (source === "dashboard_screen") {
    containerStyle = {
      backgroundImage: `url(${devicetheme})`
    };
  } else if (source === "dc_preview") {
    if (devicetheme !== 'NA') {
      containerStyle = {
        backgroundImage: `url(${devicetheme})`
      };
    } else {
      containerStyle = {
        backgroundImage: `url(${defaultBgImage})`
      };  
    }
  }
  else {
    containerStyle = {
      backgroundImage: `url(${defaultBgImage})`
    };
  }
  if (source === "create_playlist" || source === "edit_playlist") {
    if (allplaylistsData.contentimage !== "") {
      const contentBlobImages = allplaylistsData.contentimage.split(/[$$]+/);
      var content = contentBlobImages[1].split(/[||]+/);
      var contUrlFormArray = [];
      content.forEach((contentImage, index) => {
        var updatedUrl = getInterval(contentImage);
        if (index === 0) {
          var updatedUrlPieces = updatedUrl.split("##");
          firstcontentInterval = parseInt(updatedUrlPieces[1], 10) * 1000;
        }
        contUrlFormArray.push(updatedUrl);
      });
      contentImages[0] = contUrlFormArray;
    } else {
      contentImages[0] = [];
    }

    if (allplaylistsData.bannerimage !== "") {
      bannerImages[0] = allplaylistsData.bannerimage.split(/[||]+/);
      var banUrlFormArray = [];
      bannerImages[0].forEach((bannerImage, index) => {
        var updatedUrl = getInterval(bannerImage);
        if (index === 0) {
          var updatedUrlPieces = updatedUrl.split("##");
          firstbannerInterval = parseInt(updatedUrlPieces[1], 10) * 1000;
        }
        banUrlFormArray.push(updatedUrl);
      });
      bannerImages[0] = banUrlFormArray;
    } else {
      bannerImages[0] = [];
    }
    bannerTextSize = allplaylistsData.fontSize;
    scrollspeed = allplaylistsData.scrollspeed;
    fullbannertext = allplaylistsData.fullbanntext;
    
    bannerTextVal[0] = allplaylistsData.bannerTxt;
	
    if(bannerImages[0].length === 0){
     
		bannerTextVal[0] = bannerTextVal[0];
	
    }else{
	   bannerTextVal[0] = bannerTextVal[0].toString().replaceAll("\n", "<br />");
    }
	
    layout = allplaylistsData.layoutname;
    id = 0;
  } else if (source === 'dc_preview') {
    bannerTextVal[0] = t("Banner Text");
    bannerTextSize = '14';
    contentImages[0] = {AutoImage};
    bannerImages[0] = {AutoImage};
    id = 0;
    bgColor = bannerbgcolor;
    textColor = bannertextcolor;
    dvPrevCss = {
      fontSize: "14px",
      color: textColor,
      backgroundColor: bgColor,
    };
  } else {
    allplaylistsData.forEach((playlist, index) => {
      if (playlist.nid === id) {
        if (playlist.contentArea !== "") {
          const contentBlobImages = playlist.contentArea.split(/[$$]+/);
          contentImages[playlist.nid] = contentBlobImages[1].split(/[||]+/);
          var contUrlFormArray = [];
          contentImages[playlist.nid].forEach((contentImage, index) => {
            var updatedUrl = getInterval(contentImage);
            if (index === 0) {
              var updatedUrlPieces = updatedUrl.split("##");
              firstcontentInterval = parseInt(updatedUrlPieces[1], 10) * 1000;
            }
            contUrlFormArray.push(updatedUrl);
          });
          contentImages[playlist.nid] = contUrlFormArray;
        } else {
          contentImages[playlist.nid] = [];
        }

        if (playlist.bannerArea !== "") {
          bannerImages[playlist.nid] = playlist.bannerArea.split(/[||]+/);
          var banUrlFormArray = [];
          bannerImages[playlist.nid].forEach((bannerImage, index) => {
            var updatedUrl = getInterval(bannerImage);
            if (index === 0) {
              var updatedUrlPieces = updatedUrl.split("##");
              firstbannerInterval = parseInt(updatedUrlPieces[1], 10) * 1000;
            }
            banUrlFormArray.push(updatedUrl);
          });
          bannerImages[playlist.nid] = banUrlFormArray;
        } else {
          bannerImages[playlist.nid] = [];
          
		  
        }
        bannerTextVal = playlist.bannerText.split(/[:]+/);
		
        if(bannerImages[id].length === 0){
		
		   bannerTextVal[0] = bannerTextVal[0].toString().replaceAll("<br />\n", " "); 
           bannerTextVal[0] = bannerTextVal[0].toString().replaceAll("  ", '<br />');
		   
        }else{
           bannerTextVal[0] = bannerTextVal[0];
        }
        layout = playlist.layout;
        bannerTextSize = bannerTextVal[1];
        scrollspeed = bannerTextVal[2];
        fullbannertext = bannerTextVal[3];
      }
    });
  }
  if (scrollspeed == "normal"){
    scrollspeed = 170;
  }
  else if(scrollspeed == "medium"){
    scrollspeed = 90;
  }
  else if(scrollspeed == "high"){
    scrollspeed = 60;
  }else if(scrollspeed == "off"){
    scrollspeed = 0;
  }

  scrollspeed = scrollspeed*bannerTextVal[0].length;
 
  if(bannerImages[id].length === 0 && fullbannertext == "off"){
	  
    if(bannerTextVal[0].length <= 99){
      scrollspeed = 0;
    }
  }

  if (source === 'dashboard_screen') {
    bgColor = bannerbgcolor;
    textColor = bannertextcolor;
  }

  var bannerTxtclass = "previewText";
  var bannerImgclass = "previewBanner";
  var contentImgclass = "previewContent";
  let fontsizes = ["22", "26", "30"];
 
  //var country_name = userdetails && JSON.parse(userdetails).country;
if ((bannerTextVal[0] && bannerImages[id].length === 0)  && countryname =="Japan") {
	 if(fontsizes.includes(bannerTextSize)){
       bannerTxtclass += " onlyBannerText japan-text";
	 }else{
	     bannerTxtclass += " onlyBannerText"; 
	 }
  }else if (bannerTextVal[0] && bannerImages[id].length === 0 ) {
     bannerTxtclass += " onlyBannerText";
  }
  else if (!bannerTextVal[0] && bannerImages[id].length > 0) {
    bannerImgclass += " onlyBannerImg";
  }
  if (bannerImages[id].length === 0 && !bannerTextVal[0]) {
    contentImgclass += " onlyContentImg";
  }

  if(layout == "Fullcontent") {
    contentImgclass += " full-preview-content";
    bannerTxtclass += " full-preview-content";
    bannerImgclass += " full-preview-content";
  }

  if (config.drupal_url.includes("-naa")) {
    bannerTxtclass += " NunitoFont";
  }

  const contentCarouselChange = (index, item) => {
    setContIntervalz(item.props["data-interval"]);
  };
  const bannerCarouselChange = (index, item) => {
    setBanIntervalz(item.props["data-interval"]);
  };

  return (
    <>
    <div className={`previewContainer${fullPreview.layout === 'full_content' ? ' full-content' : ''}`} style={containerStyle}>
	   { layout !== "Fullcontent" ? (
	     <>
        <div className={style.previewTypeImage}>
		
          {screenId !== "NA" ? (
            <>
              {fullPreview.layout === "content" && (
                <div className={style.previeweview2} >
                  <div className={style.smoking}>
                    <img alt="" src={noSmoking} />
                  </div>
                  <div className={style.uparrow}>
                    <img alt="" src={upGreenArrow} />
                  </div>
                  <div className={style.pinumber}>33</div>
                  <div className={style.nextstop}>
                    <span className={style.nextstoptxt}>Next Stop</span>
                    <span className={style.nextstopnumber}>40</span>
                  </div>
                  <div className={style.datetime}>
                    <div className={style.date}>{fullPreview.time}</div>
                    <div className={style.time}>{fullPreview.date}</div>
                  </div>
                </div>
              )}
              {fullPreview.layout === "emea_content" && (
                <div className={style.previewinfotainment}>
                  <div className={style.datetime}>
                    <div className={style.date}>{fullPreview.time}</div>
                    <div className={style.time}>{fullPreview.date}</div>
                  </div>
                  <div className={style.infomsg}>
                    {infomsg ? infomsg : t("Moving you safely and reliably")}
                  </div>
                  <div className={style.smoking}>
                    <img alt="" src={noSmoking} />
                  </div>
                  <div className={style.logo}>
                    <img alt="" src={otisLogo} />
                  </div>
                </div>
              )}
              {fullPreview.layout === "naa_content" && (
                <div className={style.previewnaa}>
                  <div className={style.uparrow}>
                    <img alt="" src={upWhiteArrow} />
                  </div>
                  <div className={style.pinumber}>33</div>
                  <div className={style.datetime}>
                    <div className={style.date}>{fullPreview.time}</div>
                    <div className={style.time}>{fullPreview.date}</div>
                  </div>
                </div>
              )}
              {fullPreview.layout === "" && (
                <div className={style.pierrormsg}>
                  <p className={style.leftpierrormsg}>
                    <T _str="Error retrieving preview content. Please try again." />
                  </p>
                </div>
              )}
            </>
          ) : (
            <>
              {config.drupal_url.includes("-naa") ? (
                <img
                  alt="eview type"
                  src={
                    layout === "Infotainment" ? infotainmentImage : naaleftPI
                  }
                />
              ) : (
		
                <img
                  alt="eview type"
                  src={
                    layout === "Infotainment" ? infotainmentImage : eviewImage
                  }
                />
              )}
            </>
          )}
        </div>
		 </>
		 ):null}
        {source !== 'dc_preview' && (
          <div className={contentImgclass}>
            <Carousel
              onChange={contentCarouselChange}
              infiniteLoop
              useKeyboardArrows
              autoPlay
              interval={
                contintervalz !== 1 ? contintervalz : firstcontentInterval
              }
            >
              {contentImages[id].map((content) => {
                const contentPieces = content.split(/[##]+/);
                const contentFiltered = contentPieces[0].replace(/&amp;/g, "&");
                const contslideInterval = parseInt(contentPieces[1], 10) * 1000;
                return (
                  <div data-interval={contslideInterval}>
                    <img
                      className="d-block w-100"
                      src={contentFiltered}
                      alt="Otis"
                    />
                  </div>
                );
              })}
            </Carousel>
          </div>
        )}
        {(source === 'dc_preview' &&
          <div className={contentImgclass}>
            <img
              className="d-block w-100"
              src={AutoImage}
              alt="Otis"
            />
          </div>
        )}
        
        {(bannerTextVal[0] &&
          (() => { 
            if (source === 'dashboard_screen') {
              return (
                <div
                  style={{
                    fontSize: bannerTextSize + "px",
                    color: textColor,
                    backgroundColor: bgColor,
                  }}
                  className={bannerTxtclass}
                >
                  {scrollspeed == "0" && bannerTextSize == 30 ?
                    <div className="inner-banner-text fontpreview">
                      {Parser(bannerTextVal[0])}
                    </div> 
                    :
                    <div className="inner-banner-text">
                      <Marquee duration={scrollspeed} height="130px" axis="Y" reverse="true">
                        {Parser(bannerTextVal[0])}
                      </Marquee>
                    </div>
                  }
                </div>
              )
            } else if (source === 'dc_preview') {
                   if(fullPreview.layout !== "full_content"){
                    <div
                      style={dvPrevCss}
                      className={bannerTxtclass}
                    >
                      <div className="inner-banner-text" id="dc-preview-banner-text">
                        {Parser(bannerTextVal[0])}
                      </div>
                    </div>
                      
                  }else{
                      <div></div>
                  }
         
            }else {
              return (
                <div
                  style={{
                    fontSize: bannerTextSize + "px"
                  }}
                  className={bannerTxtclass}
                >
                  {scrollspeed == "0" && bannerTextSize == 30 ?
                    <div className="inner-banner-text fontpreview">
                      {Parser(bannerTextVal[0])}
                    </div> 
                    :
                    <div className="inner-banner-text">
                      <Marquee duration={scrollspeed} height="130px" axis="Y" reverse="true">
                        {Parser(bannerTextVal[0])}
                      </Marquee>
                    </div>
                  }
                </div>
              )
            }
          })()
        )}
        
        {source !== 'dc_preview' && bannerImages[id].length > 0 && (
          <div className={bannerImgclass}>
            <Carousel
              onChange={bannerCarouselChange}
              infiniteLoop
              useKeyboardArrows
              autoPlay
              interval={banintervalz !== 1 ? banintervalz : firstbannerInterval}
            >
              {bannerImages[id].map((banner) => {
                const bannerPieces = banner.split(/[##]+/);
                const bannerFiltered = bannerPieces[0].replace(/&amp;/g, "&");
                const banslideInterval = parseInt(bannerPieces[1], 10) * 1000;
                return (
                  <div data-interval={banslideInterval}>
                    <img
                      className="d-block w-100"
                      src={bannerFiltered}
                      alt="Otis"
                    />
                  </div>
                );
              })}
            </Carousel>
          </div>
        )}
        {source === 'dc_preview' && fullPreview.layout !== "full_content" ?
          <div className={bannerImgclass}>
            <img
              className="d-block w-100"
              src={AutoImage}
              alt="Otis"
            />
          </div>
        :null}
      </div>
      
      <div className={style.previewSupport}>
        <FaInfoCircle />
        <T _str="The preview represents the placement and content order of the playlist only and may appear differently on the eView display." />
      </div>
    </>
  );
};

export default PlaylistsPreview;