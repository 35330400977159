/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: ScreenCalendar.js
File Description: schedule from calendar functionality

Copyright 2021 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
function ScreenCalendar(props) {
  return (
    <div>
      Screen Calendar page
    </div>
  );
}

export default ScreenCalendar;
