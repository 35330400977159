/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: EviewLicense.js
File Description: Displays eView license in CMS portal

Copyright 2021,2022 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../utils/config';
import { T } from '@transifex/react';

function EviewLicense(props) {
const [EviewLicense, setEviewLicense] = useState([]);
let licenseType = React.createRef();

  useEffect(()=> {
		const getEviewLicense = async () => {
		await axios.get(config.drupal_url + '/api/list_eview_license')
        .then(response => {
            setEviewLicense(response.data);
		  })
		  .catch(error=>console.error('API Error:'+error));
		}
		getEviewLicense();
	}
	, []);

	const searchLicense = async (event) => {
		event.preventDefault();
		await axios.get(config.drupal_url + '/api/list_eview_license?licensetype='+licenseType.current.value)
		.then(response => {
			setEviewLicense(response.data);
		})
		.catch(error=>console.error('API Error:'+error));
    }

	 const resetForm = async (event) => {
		licenseType.current.value = '';
		searchLicense(event);
	 }
	 	
    return (
    	<>
		<div class="container">
			<div class="row">
				<div class="main-title-hold">
					<div class="column">
						<h1>eView<sup>&reg;</sup> - 37010</h1>
						<form onSubmit={e => { e.preventDefault(); }}>
							<div class="d-flex_ flex justify-content-center v-align license-page">
								<p><T _str="License type" /></p>
								<div class="btn-group ">
									<div class="form-group license-page">
										<input type="text" class="form-control" name="s" id="search-input" ref={licenseType} /> <i class="entypo-search"></i>
									</div>
								</div>
								<a href="/" class="btn btn-success" onClick={searchLicense}><T _str="Search" /></a>
								<a href="/" class="btn btn-default" onClick={(event) => resetForm(event)}><T _str="Reset" /></a>
							</div>
						</form>
					</div>
					<div class="right-title-hold offer-position">
						<a target="blank" class="btn btn-success" href="/offer-letter/GPLOffer-Basic.doc"><T _str="Offer Letter" /></a>
					</div>
				</div>
			</div>
		</div>
		<div class="container">
			<div class="row">
				<div class="column">
					<div class="licence-mainblock">
					<div class="licence-mainblock scroll">
				{[EviewLicense].map((license) => {					  
					return(
						<>
						{Object.entries(license).map((licenseDtls) =>{
							return(
								<div class="card">
										<div class="card-header">
										<T _str="package name" />: {licenseDtls[0]}
										</div>
										<div class="card-body">
										<h5 class="card-title"><T _str="license type" />: {licenseDtls[1].licensetype}</h5>
										<p class="card-text"><T _str="package version" />: {licenseDtls[1].packageversion}</p>
										{licenseDtls[1].fileDetails.map((filePaths)=>{
											return(
												<>
												<a href={filePaths.filepath} class="btn btn-default" target="blank">{filePaths.filename}</a>
												</>
											)
										})}
										</div>
								</div>
								)
							})}
							</>
						)
					})
				}
				</div>
			</div>
		</div>
		</div>
		</div>
      	</>
    );
}

export default EviewLicense;
