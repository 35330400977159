/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: EditPlaylist.js
File Description: Implements edit playlist functionality of CMS portal

Copyright 2021 - 2024 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import '../../css/CreatePlaylists.css';
import { T, useT } from '@transifex/react';
import { t } from '@transifex/native';
import leftdisplay from '../../Assets/img-left-display.jpg';
import leftdisplay1 from '../../Assets/screen_cpi.png';
import { useCookies } from "react-cookie";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import $ from "jquery";
import { Sortable } from 'sortablejs';
import "../../Components/Playlist/EditPlaylist/EditPlaylistscript";
import axios from 'axios';
import config from '../../utils/config';
import { useHistory } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import { FaTimesCircle } from 'react-icons/fa';
import PlaylistsPreview from '../../Components/Common/PlaylistsPreview';
import UserLibrary from '../../Components/Library/UserLibrary';
import { LibContext, MediaContext } from '../../Components/Library/LibraryContext';
import Button from 'react-bootstrap/Button';
import Fileupload from '../../Components/Library/Fileupload';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import LoadingOverlay from "react-loading-overlay";
import queryString from 'query-string';
import { FaInfoCircle } from "react-icons/fa";
import { Buffer } from 'buffer';

function modal_open_content() {
  $("#TermsDisplay_2").html("");
  $("#content_hidden").val("");
  $("#order-content").html("");
  $("#TermsModal_2").addClass("in");
  $("#TermsModal_2").show();
  $("#TermsModal_2").css({ 'opacity': '1', 'margin-top': '166px', 'padding': '20px', 'color': 'black' });
  if ($("[id^=edit-field-content-images-]").val() && $("[id^=edit-field-content-images-]").val().length > 0) {
    var y_1;
    var x_1 = $("[id^=edit-field-content-images-]").val().split(";");
    x_1.forEach(function (entry) {
      y_1 = entry.split(":");
      var getfiletype = getfiletypeclass(y_1[0], 'FloorInfo_Dragimage', 'order_' + y_1[2]);
      if (getfiletype === true) {
        var vclass = "id='bvideo'";
      } else {
        var vclass = "id='notbvideo'";
      }
      if (y_1[3] == null) {
        y_1[3] = 5;
      }
      $("#TermsDisplay_2").append("<tbody><tr style='cursor: move;' class='drag-able' " + vclass + "><td class='file-name-draggable imagesrc_1' style='width:60%; text-align:left;'>" + y_1[0].replace(/,/g, ':') + "</td><td style='width:20%; text-align:left;'><input type='number' class='durtime' min='1' max='30' value = '" + y_1[3] + "'></td><td class='imagefid_1' style='display:none'>" + y_1[1] + "</td><td class='imageoid_1' style='display:none'>" + y_1[2] + "</td><td class='imageurl' style='display:none'>" + y_1[4] + "</td><td><div class='lib-del dimmed-div'><span class='delete-icon' > <a href='#!' class='delbutton content' style='color:red'>" + t("Delete") + "</a></span></div></td></tr></tbody>");
      $("table#TermsDisplay_2 tr#bvideo").find('input.durtime').val(0);
      $("table#TermsDisplay_2 tr#bvideo").find('input.durtime').attr("disabled", true);

    });
    $(".delbutton.content").on("click", function () {
      var x = $(this).closest('tr').find('td.imageoid_1').text();
      var fid = $(this).closest('tr').find('td.imagefid_1').text();
      var del_id = $("#content_hidden").val().trim().length > 0 ? $("#content_hidden").val() + ",order_" + x : "order_" + x;
      $("#content_hidden").val(del_id);
      $(this).closest('tr').remove();
    });

    var el = document.getElementById('TermsDisplay_2');
    Sortable.create(el);
  }
}

function modal_open() {
  $("#TermsDisplay_1").html("");
  $("#banner_hidden").val("");
  $("#order-banner").html("");
  $("#TermsModal_1").addClass("in");
  $("#TermsModal_1").show();
  $("#TermsModal_1").css({ 'opacity': '1', 'margin-top': '166px', 'padding': '20px', 'color': 'black' });

  if ($("[id^=edit-field-list-of-banners-]").val() && $("[id^=edit-field-list-of-banners-]").val().length > 0) {
    var y;
    var x = $("[id^=edit-field-list-of-banners-]").val().split(";");
    x.forEach(function (entry) {
      y = entry.split(":");
      var getfiletype = getfiletypeclass(y[0], 'FloorInfo_Dragimage2', 'order_' + y[2]);
      if (getfiletype === true) {
        var vclass = "id='video'";
      } else {
        var vclass = "id='notvideo'";
      }
      if (y[3] == null) {
        y[3] = 5;
      }
      $("#TermsDisplay_1").append("<tbody><tr style='cursor: move;' class='drag-able' " + vclass + "><td class='file-name-draggable imagesrc' style='width:60%; text-align:left;'>" + y[0].replace(/,/g, ':') + "</td><td style='width:20%; text-align:left;'><input type='number' class='bannerdurtime' min='1' max='30' value = '" + y[3] + "'></td><td class='imagefid' style='display:none'>" + y[1] + "</td><td class='imageoid' style='display:none'>" + y[2] + "</td><td class='bannerimageurl' style='display:none'>" + y[4] + "</td><td><div class='lib-del dimmed-div'><span class='delete-icon'> <a href='#!' class='delbutton banner' style='color:red'>" + t("Delete") + "</a></span></div></td></tr></tbody>");
      $("table#TermsDisplay_1 tr#video").find('input.bannerdurtime').val(0);
      $("table#TermsDisplay_1 tr#video").find('input.bannerdurtime').attr("disabled", true);
    });
    $(".delbutton.banner").on('click', function () {
      var x = $(this).closest('tr').find('td.imageoid').text();
      var fid = $(this).closest('tr').find('td.imagefid').text();
      var del_id = $("#banner_hidden").val().trim().length > 0 ? $("#banner_hidden").val() + ",order_" + x : "order_" + x;
      $("#banner_hidden").val(del_id);
      $(this).closest('tr').remove();
    });
    var el = document.getElementById('TermsDisplay_1');
    Sortable.create(el);
  }
}


function getfiletypeclass(filename, checkid, itemorderid) {
  checkid = "#" + checkid;
  itemorderid = "img." + itemorderid;
  if ($(checkid).find(itemorderid).hasClass('news')) {
    return false;
  }
  var videoClass = $(checkid).find(itemorderid).hasClass('video');
  return videoClass;
}

function EditPlaylist() {
  const tabTitle = useT("Edit Playlist");
  useEffect(() => {
    document.title = tabTitle + ' | CMS';
  }, [tabTitle]);

  var userdetails = localStorage.getItem('userdetails');
  var country_name = userdetails && JSON.parse(userdetails).country;
  //const encodedString = Buffer.from(config.auth_username + ':' + config.auth_password).toString('base64');
  //const basicAuth = 'Basic ' + encodedString;

  const history = useHistory();
  const [flag, setFlag] = useState(false);
  const [data, setData] = useState([]);
  const [livecontentdata, setlivecontentData] = useState([]);
  const [usercontentdata, setusercontentData] = useState([]);
  const [usermessagesdata, setusermessagesData] = useState([]);
  const [adminmessagesdata, setadminmessagesData] = useState([]);
  const [playlistdata, setPlaylistData] = useState([]);
  const [cookies, setCookie] = useCookies(["playlistName"]);
  const [istab, setIstab] = useState("user-message")
  const [ismute, setMutable] = useState(false)
  const [isloading, setLoading] = useState(true);
  const [isprocessing, setProcessing] = useState(false);
  const [cookies1, setCookie1] = useCookies(["azure_blob"]);
  const [defaulttid, setDefaultTid] = useState('');
  const [values, setValues] = useState({
    layoutname: '', layout: '', name: '', bannerimage: '', contentimage: '', bannertext: ''
  });
  const set = name => {
    return ({ target: { value } }) => {
      setValues(oldValues => ({ ...oldValues, [name]: value }));
    }
  };
  const [playlistpreviewdata, setPlaylistpreviewdata] = useState();
  const [bannertxtData, setBannertxtData] = useState({ 'fontSize': '', 'fontspeed': '', 'fullbannertext': '' });
  const [bannertxtDisabled, setBannertxtDisabled] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const previewModalClose = () => setShowPreview(false);
  const [toggleState, setToggleState] = useState(1);
  const [uploadToggleState, setUploadToggleState] = useState(1);
  const [vocabulary, setVocabulary] = useState('');
  const [libtabs, setLibTabs] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const setDefaultId = (tname) => {
    let defaultid = libtabs.filter(function (arr) {
      return arr.name === tname
    });
    if (defaultid.length > 0) {
      setDefaultTid(defaultid[0].tid)
      setTerm({ 'name': t(tname), 'tid': defaultid[0].tid });
    }
  }
  const toggleTab = (index) => {
    if (index === 1) {
      setVocabulary('category');
      setDefaultId('User Library');
    }
    if (index === 2) {
      setVocabulary('default_library');
      setDefaultId('Default Content');
    }
    setToggleState(index)
    setUploadMediaContent("");
  }

  const toggleUploadTab = (index) => {
    if (index === 1) {
      setVocabulary('category');
    }
    if (index === 2) {
      setVocabulary('default_library');
    }
    setUploadToggleState(index)
    setUploadMediaContent("");
  }

  const [term, setTerm] = useState({ 'tid': '', 'name': '', 'vid': 'category' });
  const dispatchLibEvent = (selectedTerm) => {
    setTerm(selectedTerm);
  }

  const handleMoveSaveClick = () => {

    if (term.name === "" || term.name === undefined) {
      term.name = t("User Library");
    }
    $("#search-input").attr("placeholder", "/" + term.name);
    let cid = JSON.parse(localStorage.getItem("userdetails")).cid;
    setusercontentData("");
    getusercontentData(term.tid, cid)
    setmodalShow(false);

  }
  const [modalShow, setmodalShow] = useState(false);
  const modalHandleClose = () => setmodalShow(false);
  const modalHandleShow = () => setmodalShow(true);

  const [showupload, setShowupload] = useState(false);
  const [showbannertextpopup, setBannertextpopup] = useState(false);
  const handleShowUploadPopClose = () => setShowupload(false);
  const handleShowBannertextPopClose = () => setBannertextpopup(false);
  const [mediatitle, setMediaTitle] = useState("");


  const [uploadMediaContent, setUploadMediaContent] = useState("");
  const showUploadPop = () => setShowupload(true);
  const showBannertextPop = () => setBannertextpopup(true);

  const customId = userdetails && JSON.parse(userdetails).id;


  const bannersize = bannertxtData && bannertxtData.fontSize ? bannertxtData.fontSize : $("input#selected-font-size").val();
  const bannerspeed = bannertxtData && bannertxtData.fontspeed ? bannertxtData.fontspeed : $("input#selected-banner-text-speed").val();
  const fullbannertext = bannertxtData && bannertxtData.fullbannertext ? bannertxtData.fullbannertext : $("input#selected-full-banner-text").val();

  if (bannertxtData.fontSize !== "" && bannersize !== undefined) {
    const bannersize = bannertxtData.fontSize;
    $("#bta-fontsize").val(bannersize);
  }
  if (bannertxtData.fontspeed !== "" && bannerspeed !== undefined) {
    const bannerspeed = bannertxtData.fontspeed;
    $("#banner_text_speed").val(bannerspeed);
  }
  if (bannertxtData.fullbannertext !== "" && fullbannertext !== undefined) {
    const fullbannertext = bannertxtData.fullbannertext;
    $("#full_banner_text").val(fullbannertext);
  }

  const notify = (val) => {
    toast.error(val, {
      toastId: customId
    });
  }

  const [validations, setValidations] = useState({ "VideoMaxAllowedSize": "", "ImageMaxAllowedSize": "", "MaxAllowedFPS": "", "MaxAllowedBitrate": "" });
  useEffect(() => {
    // Fetch Validations
    const fetchValidations = async () => {
      setLoading(true);
      await axios.get(config.drupal_url + '/rest/api/get/validations?_format=hal_json', { withCredentials: true })
        .then(response => {
          setValidations({ "VideoMaxAllowedSize": response.data.VideoMaxAllowedSize, "ImageMaxAllowedSize": response.data.ImageMaxAllowedSize, "MaxAllowedFPS": response.data.MaxAllowedFPS, "MaxAllowedBitrate": response.data.MaxAllowedBitrate });
          setLoading(false);
        })
        .catch(function (error) {
          notify(t("Please try later.If the error persists, please contact Otis support."));
          setLoading(false);
        })
    };
    fetchValidations();
  }, [])

  if (config.drupal_url.includes("-naa")) {
    $('.banner-textarea').addClass('NunitoFont');
  }

	 
   $(".Fullcontent .screen-thumb-hold").click(function () { 
        $('.Fullcontent input[name="field_select_layout[und]"]').prop("checked", true); 
        $(".Fullcontent .screen-thumb-hold").removeClass("screen-thumb-hold thumb-disable").addClass('screen-thumb-hold thumb-active');
        
        $(".Infotainment .screen-thumb-hold").removeClass("screen-thumb-hold thumb-active").addClass('screen-thumb-hold thumb-disable');
        $(".eView2 .screen-thumb-hold").removeClass("screen-thumb-hold thumb-active").addClass('screen-thumb-hold thumb-disable');
        
        $(".left-screen-hold").attr("style", "display:none");
        $(".text-image-hold").attr("style", "display:none");
        $(".right-screen-hold").addClass('full-container');
     }); 
	 
	 
	 $(".eView2 .screen-thumb-hold").click(function () { 

      $(".eView2 .screen-thumb-hold").removeClass("screen-thumb-hold thumb-disable").addClass('screen-thumb-hold thumb-active');
      $(".Fullcontent .screen-thumb-hold").removeClass("screen-thumb-hold thumb-active").addClass('screen-thumb-hold thumb-disable');
      $(".text-image-hold").attr("style", "display:show");
      $(".left-screen-hold").attr("style", "display:show");
      $(".right-screen-hold").removeClass('full-container');
     }); 
			
			
	 $(".Infotainment .screen-thumb-hold").click(function () { 

      $(".Fullcontent .screen-thumb-hold").removeClass("screen-thumb-hold thumb-active").addClass('screen-thumb-hold thumb-disable');
      $(".text-image-hold").attr("style", "display:show");
      $(".left-screen-hold").attr("style", "display:show");
      $(".right-screen-hold").removeClass('full-container');
     });  
		
  // CD-20686: Disabling Decimal numbers in time duration for playlist images.
  $(document).on("keypress", "div.DisplayTermsModal table#TermsDisplay_2 input.durtime", function (e) {
    if (e.keyCode == 46) {
      e.preventDefault();
    }
  });
  $(document).on("keypress", "div.DisplayTermsModal table#TermsDisplay_1 input.bannerdurtime", function (e) {
    if (e.keyCode == 46) {
      e.preventDefault();
    }
  });
  $(document).on("paste", "div.DisplayTermsModal table#TermsDisplay_2 input.durtime", function (e) {
    e.preventDefault();
  });
  $(document).on("paste", "div.DisplayTermsModal table#TermsDisplay_1 input.bannerdurtime", function (e) {
    e.preventDefault();
  });
  // CD-20686: Disabling Decimal numbers in time duration for playlist images. -- ends
  $('.banner-textarea').keypress(function(event) {
    if (event.which == 13) {
        var input = $(this);
        var inputVal = input.val();
		    var el = $(this).get(0);
        var pos = 0;
        if('selectionStart' in el) {
            pos = el.selectionStart;
			      el.focus();
        } else if('selection' in document) {
            el.focus();
            var Sel = document.selection.createRange();
            var SelLength = document.selection.createRange().text.length;
            Sel.moveStart('character', -el.value.length);
            pos = Sel.text.length - SelLength;
			
        }

       setTimeout(function() {
        input.val(inputVal.substr(0,pos) + " \n" + inputVal.substr(pos, inputVal.length));
		    setCaretToPos($(".banner-textarea")[0], pos+2);

        }, 1);
 
    }
  });
  
  function setSelectionRange(input, selectionStart, selectionEnd) {
	  if (input.setSelectionRange) {
      input.focus();
      input.setSelectionRange(selectionStart, selectionEnd);

	  } else if (input.createTextRange) {
      var range = input.createTextRange();
      range.collapse(true);
      range.moveEnd('character', selectionEnd);
      range.moveStart('character', selectionStart);
      range.select();

	  }

}

function setCaretToPos(input, pos) {
  setSelectionRange(input, pos, pos);

}

  function playlistPreview() {
    var bannerimage = '';
    var fontSize = $("#selected-font-size").val();
    var scroll_speed = $("#selected-banner-text-speed").val();
    var contentInteravl = [];
    var bannerInteravl = [];
    var contentArea = $("#edit-field-content-images-und-0-value").val().split(";");
    var bannerArea = $("#edit-field-list-of-banners-und-0-value").val().split(";");
    contentArea.forEach(function (entry) {
      var contentAreaPiece = entry.split(":");
      contentInteravl.push(contentAreaPiece[3]);
    });
    bannerArea.forEach(function (entry) {
      var bannerAreaPiece = entry.split(":");
      bannerInteravl.push(bannerAreaPiece[3]);
    });

    // Sort the banner images in porper order if not
    var BannerImagesArray = [];
    $('#FloorInfo_Dragimage2 img').each(function (index, CurrentImage) {
      var OrderClass = "";
      if (CurrentImage.attributes['classname']) {
        OrderClass = CurrentImage.attributes['classname']['nodeValue'].split(" ");
      } else {
        OrderClass = CurrentImage.attributes['class']['nodeValue'].split(" ");
      }
      OrderClass.forEach(function (orderCls) {
        if (orderCls.includes("order")) {
          var orderValue = parseInt(orderCls.replace("order_", ''));
          BannerImagesArray[orderValue] = CurrentImage.attributes['data-url']['nodeValue'];
        }
      });
    });
    BannerImagesArray.forEach(function (imageUrl, index) {
      const key = parseInt(index) - 1;
      //bannerimage += imageUrl + "##" + bannerInteravl[key] + "||";
      var full_bann_text = $("#selected-full-banner-text").val();
		  if(full_bann_text === "off") {
			 bannerimage += imageUrl + "##" + bannerInteravl[key] + "||";
			}else{
			 bannerimage='';
			}
    });

    // Sort the content images in porper order if not
    var contentimage = '$$';
    var ContentImagesArray = [];
    $('#FloorInfo_Dragimage img').each(function (index, CurrentImage) {
      var OrderClass = "";
      if (CurrentImage.attributes['classname']) {
        OrderClass = CurrentImage.attributes['classname']['nodeValue'].split(" ");
      } else {
        OrderClass = CurrentImage.attributes['class']['nodeValue'].split(" ");
      }
      OrderClass.forEach(function (orderCls) {
        if (orderCls.includes("order")) {
          var orderValue = parseInt(orderCls.replace("order_", ''));
          ContentImagesArray[orderValue] = CurrentImage.attributes['data-url']['nodeValue'];
        }
      });
    });
    ContentImagesArray.forEach(function (imageUrl, index) {
      const key = parseInt(index) - 1;
      contentimage += imageUrl + "##" + contentInteravl[key] + "||";
    });

    //var breaks = /\n/g;
    //var bannerTxt = $(".banner-textarea").val().replace(breaks, " ");
    var breaks = / \n/g;
    var bannerTxt = $(".banner-textarea").val().replace(breaks, "<br />");
    var layoutname = $('.screen-thumb-hold .thumb-active a').text();
    var full_bann_text = $("#selected-full-banner-text").val();

    const PlaylistData = {
      contentimage: contentimage.slice(0, -2),
      bannerimage: bannerimage.slice(0, -2),
      bannerTxt: bannerTxt,
      fontSize: fontSize,
      scrollspeed: scroll_speed,
      fullbanntext:full_bann_text,
      layoutname: layoutname
    };
    setPlaylistpreviewdata(PlaylistData);
    setShowPreview(true);
  }

  function fontspeedcharlimit() {
    var country_name = userdetails && JSON.parse(userdetails).country;
    var bta_font = $("#bta-fontsize").val();
    var full_bann_text = $("#selected-full-banner-text").val();
    if (full_bann_text === "off") {
      if (country_name == "Japan" || country_name == "Korea") {
        var charLim = $("#" + bta_font + "-char-limit-apac").val();
      } else {
        var charLim = $("#" + bta_font + "-char-limit").val();
      }
      if (country_name === "Japan" || country_name === "Korea") {
        var perLineLength = 10;
      } else {
        var perLineLength = 15;
      }
    } else {
      if (country_name == "Japan" || country_name == "Korea") {
        var charLim = 42;
      } else {
        var charLim = 99;
      }
      if (country_name === "Japan" || country_name === "Korea") {
        var perLineLength = 14;
      } else {
        var perLineLength = 33;
      }
    }

    var patt1 = /\n/g;
    var str_banner = $('.banner-textarea').val();
    var lines_count = str_banner.split(/\n/g).length;
    str_banner = str_banner.replace(patt1, '');

    if ($('.banner-textarea').val() !== '' && $("input#selected-banner-text-speed").val() == 'off') {
      if (str_banner.length >= parseInt(charLim)) {
        var char_remain = Math.floor(charLim / perLineLength);
        var bttext = $('.banner-textarea').val().substring(0, parseInt(charLim) + parseInt(char_remain));
        var lines = $('.banner-textarea').val().split('\n');
        $('.banner-textarea').val(lines.slice(0, 3).join('\n'));

      } else {
        var bttext = $('.banner-textarea').val();
      }
      var bt_text = bttext + ':' + bta_font;
      $("#edit-field-banner-text-und-0-value").val(bt_text);
      $("input#selected-banner-text-speed").val('off');
      $("#field-banner-text-add-more-wrapper .form-textarea").val(bt_text);
    }

  }

  //Banner text area font size.

  function fontsizelimit() {
    var country_name = userdetails && JSON.parse(userdetails).country;
    var bta_font = $("#bta-fontsize").val();
    var full_bann_text = $("#selected-full-banner-text").val();
    if (full_bann_text === "off") {
      if (country_name == "Japan" || country_name == "Korea") {
        var charLim = $("#" + bta_font + "-char-limit-apac").val();
      } else {
        var charLim = $("#" + bta_font + "-char-limit").val();
      }
      if (country_name === "Japan" || country_name === "Korea") {
        var perLineLength = 10;
      } else {
        var perLineLength = 15;
      }
    } else {
      if (country_name == "Japan" || country_name == "Korea") {
        var charLim = 42;
      } else {
        var charLim = 99;
      }
      if (country_name === "Japan" || country_name === "Korea") {
        var perLineLength = 14;
      } else {
        var perLineLength = 33;
      }
    }

    $("input#selected-font-size").val(bta_font);
    $('.banner-textarea').css({ 'font-size': bta_font + 'px' });


    var patt1 = /\n/g;
    var str_banner = $('.banner-textarea').val();
    var lines_count = str_banner.split(/\n/g).length;
    str_banner = str_banner.replace(patt1, '');
    if ($('.banner-textarea').val() !== '' && $("input#selected-banner-text-speed").val() == 'off') {
      if (str_banner.length >= parseInt(charLim)) {
        var char_remain = Math.floor(charLim / perLineLength);
        var bttext = $('.banner-textarea').val().substring(0, parseInt(charLim) + parseInt(char_remain));
        $('.banner-textarea').val(bttext.trim());
      } else {
        var bttext = $('.banner-textarea').val();
      }
      var bt_text = bttext + ':' + bta_font;
      $("#edit-field-banner-text-und-0-value").val(bt_text);
      $("#field-banner-text-add-more-wrapper .form-textarea").val(bt_text);
    }
  }

  const saveFormData = async (values) => {
    let id = userdetails && JSON.parse(userdetails).id;
    let user_url = config.drupal_url + '/api/user/user?filter[drupal_internal__uid]=' + id
    await axios.get(user_url, { 'withCredentials': true }).then(res => {
		
		var layoutname = $('.screen-thumb-hold .thumb-active a').text();
      const body = {
        "data": {
          "type": "node--playlist",
          "id": values.id,
          "attributes": {
            "title": values.name,
            "field_content_images": values.contentimage,
			      "field_list_of_banners":layoutname === "Fullcontent"?"":values.bannerimage,
            "field_banner_text": layoutname === "Fullcontent"?"":values.bannertext,
			
          },
          "relationships": {
            "field_select_layout": {
              "data": { "type": "node--qml_upload", "id": values.layout }
            },

            "uid": {
              "data": {
                "type": "user--user",
                "id": res.data["data"][0]['id']
              }
            }

          }
        }
      }
	  
	  console.log(body);

      let url = config.drupal_url + '/api/node/playlist/' + values.id
      axios.get(config.drupal_url + '/session/token').then(result => {
        if (200 === result.status) {
          const csrfToken = result.data;
          const response = axios.patch(url, body, {
            headers: {
              'Content-Type': 'application/vnd.api+json',
              withCredentials: true,
              'X-CSRF-Token': csrfToken,
            }
          }).then(response => {
            var buttonClickedId = localStorage.getItem('createplaylistsubmit');
            var playlistNid = response.data.data.attributes.drupal_internal__nid;

            let params = queryString.parse(window.location.search);
            if (params.default && params.default === "allow") {
              let roles = userdetails && JSON.parse(userdetails).roles;
              let cid = userdetails && JSON.parse(userdetails).cid;
              let user_id = userdetails && JSON.parse(userdetails).id;
              const post_data = {
                "nid": playlistNid,
                "roles": roles,
                "country_id": cid,
                "uid": user_id
              }
              setLoading(true);

              axios.post(config.drupal_url + '/rest/api/post/set-as-default', post_data, {
                headers: {
                  'Content-Type': 'application/json',
                  withCredentials: true,
                  'X-CSRF-Token': csrfToken,
                }
              }).then(res1 => {
                if (res1.data === "disallow") {
                  toast.error(t("Banner text is not allowed for the country default playlist. Please edit the playlist and try again."));
                  setLoading(false);
                  return false;
                } else if (res1.data === "error") {
                  toast.error(t("Please try later.If the error persists, please contact Otis support."));
                  setLoading(false);
                  return false;
                }
                let url = config.drupal_url + '/api/node/playlist/'
                const body = {
                  "data": {
                    "type": "node--playlist",
                    "id": values.id,
                    "attributes": {
                      "field_set_as_default": 1
                    }
                  }
                }


                const res = axios.patch(url + values.id, body, {
                  headers: {
                    'Content-Type': 'application/vnd.api+json',
                    withCredentials: true,
                    'X-CSRF-Token': csrfToken,
                  }
                }).then(res1 => {
                  if (buttonClickedId === 'btnSave_set') {
                    const schedulePlaylistData = {
                      PlaylistId: playlistNid,
                      PlaylistTitle: values.name,
                      PlaylistType: values.layoutname,
                      screenIds: []
                    }
                    localStorage.removeItem('schedulePlaylist');
                    localStorage.setItem('schedulePlaylist', JSON.stringify(schedulePlaylistData));
                    history.push('/add-screens');
                  } else {
                    history.push({
                      pathname: "/playlist"
                    });
                  }
                  var str = t("@type %title has been updated.");
                  var mapObj = { "@type": t("Playlist"), "%title": values.name };
                  str = str.replace(/@type|%title/gi, function (matched) {
                    return mapObj[matched];
                  });
                  toast.success(str);
                });

              });

            } else {

              if (buttonClickedId === 'btnSave_set') {
                const schedulePlaylistData = {
                  PlaylistId: playlistNid,
                  PlaylistTitle: values.name,
                  PlaylistType: values.layoutname,
                  screenIds: []
                }
                localStorage.removeItem('schedulePlaylist');
                localStorage.setItem('schedulePlaylist', JSON.stringify(schedulePlaylistData));
                history.push('/add-screens');
              } else {
                history.push({
                  pathname: "/playlist"
                });
              }
              var str = t("@type %title has been updated.");
              var mapObj = { "@type": t("Playlist"), "%title": values.name };
              str = str.replace(/@type|%title/gi, function (matched) {
                return mapObj[matched];
              });
              toast.success(str);
            }


          })
        }
      })
    });
  }

  const SaveClick = async (button) => {
    localStorage.setItem('createplaylistsubmit', button);
  };

  const onSubmit = async (event) => {
    setLoading(true);
    event.preventDefault(); // Prevent default submission
    if ($("#default-playlist .banner-textarea").length > 0) {
      if ($("#default-playlist .banner-textarea").val() !== "") {
        toast.error(t("Banner text is not allowed for the country default playlist. Please edit the playlist and try again."));
        setLoading(false);
        return false;
      }
    }
    values.layoutname = $('.screen-thumb-hold .thumb-active a').text();
    values.id = $("#playlist_id").val();
    values.layout = $('input[name="field_select_layout[und]"]:checked').val();
    values.name = $("#PlayList_ProgamName").val();
    values.bannertext = $(".banner-textarea").val() + ":" + $("input#selected-font-size").val() + ":" + $("input#selected-banner-text-speed").val() + ":" + $("input#selected-full-banner-text").val();
    if ($("input#selected-full-banner-text").val() === "on") {
      values.bannerimage = "";
    } else {
      values.bannerimage = $("#edit-field-list-of-banners-und-0-value").val();
    }
    values.contentimage = $("#edit-field-content-images-und-0-value").val();


    // Validate main content area.
    if ($("#FloorInfo_Dragimage img").length === 0 || values.contentimage.length === 0) {
      notify(t('Main content area cannot be empty. Please add content.'));
      setLoading(false);
      return false;
    }


    // Validate playlist name.

    var reg_exp = /^[A-Za-z0-9 ]+$/;
    var name = values.name;
    var is_valid = reg_exp.test(name);
    if (name === "" || name === undefined) {
      notify(t('Empty playlist name not allowed. Please enter a name.'));
      setLoading(false);
      return false;
    } else if (!is_valid) {
      notify(t('Playlist Name cannot include special characters. Please try again.'));
      setLoading(false);
      return false;
    } else {
      let id = userdetails && JSON.parse(userdetails).id;
      const res1 = axios.get(config.drupal_url + "/api/validate-name?name=" + name + "&uid=" + id, { 'withCredentials': true }).then(res1 => {
        if (res1.data.data === 1 || res1.data.data === 0) {
          setValues(values)
          try {
            saveFormData(values);
            setValues({
              id: '', name: '', bannerimage: '', contentimage: '', bannertext: '', layout: ''
            });
          } catch (e) {
            notify(t("Please try later.If the error persists, please contact Otis support."));
            setLoading(false);
          }
          return true;
        } else {
          notify(t('A playlist with the same name already exists. Please use a different name.'));
          setLoading(false);
          return false;
        }
      })
        .catch(err => {
          notify(t("Please try later.If the error persists, please contact Otis support."));
          setLoading(false);
          return false;

        });
    }

  }

  const displayMessages = (e) => {

    e.preventDefault();

    setIstab(e.target.id)
  }

  const Mutable = () => {
    setMutable(true)
  }

  const getPlaylistData = async () => {
    let params = queryString.parse(window.location.search);
    let url = config.drupal_url + '/api/node/playlist?filter[nid]=' + params.id
    const res = await axios.get(url, { 'withCredentials': true }).then(res => {

      const playlistdata1 = res.data["data"]["0"];
      const playlistdata = {
        "id": playlistdata1.id,
        "title": playlistdata1.attributes['title'] && playlistdata1.attributes['title'].replace(/(<([^>]+)>)/gi, ""),
        "text": playlistdata1.attributes['field_banner_text'] && playlistdata1.attributes['field_banner_text'].split(':')[0].replace(/<(?!br\s*\/?)[^>]+>/g, ''),

        "font": playlistdata1.attributes['field_banner_text'] && (playlistdata1.attributes['field_banner_text'].split(':')[1]) ? playlistdata1.attributes['field_banner_text'].split(':')[1].replace(/(<([^>]+)>)/gi, "") : "14",

        "banner_text_speed": playlistdata1.attributes['field_banner_text'] && (playlistdata1.attributes['field_banner_text'].split(':')[2]) ? playlistdata1.attributes['field_banner_text'].split(':')[2].replace(/(<([^>]+)>)/gi, "") : "off",
        "full_banner_text": playlistdata1.attributes['field_banner_text'] && (playlistdata1.attributes['field_banner_text'].split(':')[3]) ? playlistdata1.attributes['field_banner_text'].split(':')[3].replace(/(<([^>]+)>)/gi, "") : "off",

        "content_images": playlistdata1.attributes['field_content_images'] && playlistdata1.attributes['field_content_images'].replace(/(<([^>]+)>)/gi, ""),

        "banner_images": playlistdata1.attributes['field_list_of_banners'] && playlistdata1.attributes['field_list_of_banners'].replace(/(<([^>]+)>)/gi, ""),

        "layout": playlistdata1.relationships['field_select_layout']['data']['id'],
        "set_as_default": playlistdata1.attributes['field_set_as_default'],
        "banner_allow": params.banner_allow
      }
      setPlaylistData(playlistdata);
      setBannertxtDisabled(playlistdata.banner_text_speed === 'off' ? true : false);
      var count = 0;
      var count_banner = 0;

      setTimeout(() => {

        //Empty check for content area.
        if ($("#edit-field-content-images-und-0-value").val() !== undefined) {
          if ($("#edit-field-content-images-und-0-value").val() !== "") {
            var count = $("#edit-field-content-images-und-0-value").val().split(";").length;
            if (count > 0) {
              $("#image-count").text(count);
            }
          }
        }
        if ($("#edit-field-list-of-banners-und-0-value").val() !== undefined) {

          if ($("#edit-field-list-of-banners-und-0-value").val() !== "") {
            var count_banner = $("#edit-field-list-of-banners-und-0-value").val().split(";").length;
            if (count_banner > 0) {
              $("#image-count-banner").text(count_banner);
            }
          }
        }

        if ($("#edit-field-content-images-und-0-value").val() !== undefined) {

          if ($("#edit-field-content-images-und-0-value").val().length > 0) {
            var MainArray = [];
            var mainc_exiting_j = $("#edit-field-content-images-und-0-value").val().split(";");
            mainc_exiting_j.forEach(function (entry) {
              var mainc_exiting_i = entry.split(":");
              if (mainc_exiting_i.length !== 5) {
                if (mainc_exiting_i.length !== 4) {
                  mainc_exiting_i[3] = 5;
                }
                MainArray.push({ imagename: mainc_exiting_i[0], fid: mainc_exiting_i[1], duration: mainc_exiting_i[3] });
              }
            });
            $('#FloorInfo_Dragimage').empty();
            var mainc_j = $("#edit-field-content-images-und-0-value").val().split(";");
            mainc_j.forEach(function (entry) {
              var mainc_i = entry.split(":");
              var type = getimagetype(mainc_i[0]);
              if (type === "undefined") {
                var datatypename = "data-feedurl=" + mainc_i[0];
              } else if (type === 'mp4') {
                var datatypename = "data-vfname=" + mainc_i[0];
                var videoclass = "video";
              } else {
                var datatypename = "";
                var videoclass = "";
              }
              if (mainc_i[3] == null) {
                mainc_i[3] = 5;
              }
              var base_url = JSON.parse($("#azure_blob").val()).blob_url + JSON.parse($("#azure_blob").val()).blob_container;
              $("#FloorInfo_Dragimage").append('<img ' + datatypename + ' class="image-grab pep ' + videoclass + ' element' + mainc_i[2] + ' order_' + mainc_i[2] + '" data-fid="' + mainc_i[1] + '"data-url="' + base_url + "/" + mainc_i[4] + JSON.parse($("#azure_blob").val()).token + '" data-filename="' + mainc_i[0] + '" typeof="foaf:Image" src="' + base_url + '/' + mainc_i[4] + JSON.parse($("#azure_blob").val()).token + '" style="" draggable="false">');
            });
          }
        }

        var banner__image_length = $(".field-name-field-banner-image .image-widget-data img").length;
        if ($("[id^=edit-field-list-of-banners-]").val() !== undefined) {

          if ($("[id^=edit-field-list-of-banners-]").val().length > 0) {
            var BannerArray = [];
            var banner_exiting_j = $("[id^=edit-field-list-of-banners-]").val().split(";");
            banner_exiting_j.forEach(function (entry) {
              var banner_exiting_i = entry.split(":");
              if (banner_exiting_i.length !== 5) {
                if (banner_exiting_i.length !== 4) {
                  banner_exiting_i[3] = 5;
                }
                BannerArray.push({ imagename: banner_exiting_i[0], fid: banner_exiting_i[1], duration: banner_exiting_i[3] });
              }
            });
            $('#FloorInfo_Dragimage2').empty();
            var B_j = $("[id^=edit-field-list-of-banners-]").val().split(";");
            B_j.forEach(function (entry) {
              var B_i = entry.split(":");
              var type = getimagetype(B_i[0]);
              if (type === "undefined") {
                var datatypename = "data-feedurl=" + B_i[0];
              } else if (type === 'mp4') {
                var datatypename = "data-vfname=" + B_i[0];
                var videoclass = "video";
              } else {
                var datatypename = "";
                var videoclass = "";
              }
              var base_url = JSON.parse($("#azure_blob").val()).blob_url + JSON.parse($("#azure_blob").val()).blob_container;
              $("#FloorInfo_Dragimage2").append('<img ' + datatypename + ' className="image-grab pep ' + videoclass + ' element' + B_i[2] + ' order_' + B_i[2] + '" data-fid="' + B_i[1] + '" data-url="' + base_url + "/" + B_i[4] + JSON.parse($("#azure_blob").val()).token + '" data-filename="' + B_i[0] + '" typeof="foaf:Image" src="' + base_url + "/" + B_i[4] + JSON.parse($("#azure_blob").val()).token + '" style="" draggable="false">');
            });
          }
        }

        //Getting lenght of images playlist
        var country_name = userdetails && JSON.parse(userdetails).country;
        //Getting lent of user message playlist
        var user_lib_leng = $("#views-form-media-library-block-6 ul li .img-content-block").length;
        var live_content_leng = $(".view-live-content .img-content-block").length;
        var byId = function (id) {
          return document.getElementById(id);
        }

        var checkBoxClasses = [];
        //Limiting Banner text to 80 characterSet

        function CharLimit(input, maxChar) {
          var len = $(input).val().length;
          if (len > maxChar) {
            $(input).val($(input).val().substring(0, maxChar));
          }
        }

        var lineLength = config.bannertext_linescount;
        var scrolllineLength = 3;
        var bta_font = $("#bta-fontsize").val();
        var full_bann_text = $("#selected-full-banner-text").val();
        if (full_bann_text === "off") {
          if (country_name == "Japan" || country_name == "Korea") {
            var charLim = parseInt($("#" + bta_font + "-char-limit-apac").val());
          } else {
            var charLim = parseInt($("#" + bta_font + "-char-limit").val());
          }
          if (country_name === "Japan" || country_name === "Korea") {
            var perLineLength = 10;
          } else {
            var perLineLength = 15;
          }
        } else {
          if (country_name == "Japan" || country_name == "Korea") {
            var charLim = 42;
          } else {
            var charLim = 99;
          }
          if (country_name === "Japan" || country_name === "Korea") {
            var perLineLength = 14;
          } else {
            var perLineLength = 33;
          }
        }

        $('.banner-textarea').on("paste", function (e) {
          var self = this;
          var fontSize = $("#selected-font-size").val();
          var full_bann_text = $("#selected-full-banner-text").val();
          if (full_bann_text === "off") {
            if (country_name == "Japan" || country_name == "Korea") {
              var charLim = $("#" + fontSize + "-char-limit-apac").val();
            } else {
              var charLim = $("#" + fontSize + "-char-limit").val();
            }
            if (country_name === "Japan" || country_name === "Korea") {
              var perLineLength = 10;
            } else {
              var perLineLength = 15;
            }
          } else {
            if (country_name == "Japan" || country_name == "Korea") {
              var charLim = 42;
            } else {
              var charLim = 99;
            }
            if (country_name === "Japan" || country_name === "Korea") {
              var perLineLength = 14;
            } else {
              var perLineLength = 33;
            }
          }
          var charlimit = perLineLength;
          setTimeout(function () {
            var new_stuff = $(self).val();
            new_stuff = new_stuff.replace(/:/g, "");
            $(self).val(new_stuff);
            var patt1 = /\n/g;
            var str = $(self).val();
            str = str.replace(patt1, '');
            if (str.length > charLim) {
              var msg = t("Maximum @count characters are allowed.").replace("@count", charLim);
              // notify(msg);
              var slicedtxt = charLim - str.length;
              var remove = self.value.slice(0, slicedtxt);
              // $(self).val(remove);
            }
            var lines = $(self).val().split('\n');
            for (var i = 0; i < lines.length; i++) {
              if (lines[i].length <= charlimit) continue;
              var j = 0;
              var space = charlimit;
              while (j++ <= charlimit) {
                if (lines[i].charAt(j) === ' ') space = j;
              }
              lines[i + 1] = lines[i].substring(space).trim() + (lines[i + 1] || "");
              lines[i] = lines[i].substring(0, space);
            }
            var banner_speed = $("#selected-banner-text-speed").val();

            if (lines.length > 3 && banner_speed == 'off') {
              const bannersize = $("#selected-font-size").val();
			        const fullbannertext = $("input#selected-full-banner-text").val();
              setBannertxtData({ 'fontSize': bannersize, 'fontspeed': 'normal', 'fullbannertext': fullbannertext });
              showBannertextPop()
              setBannertxtDisabled(false);
              setIsVisible(true);
              $(self).val(lines.slice(0, scrolllineLength).join('\n'));
            }
            else {
              setIsVisible(true);
              // $(self).val(lines.join('\n'));
              $(self).val(lines.slice(0, lineLength).join('\n'));
            }

            if (lines.length === lineLength) {
              var msg = t('Maximum @count lines are allowed.').replace('@count', lineLength);
              notify(msg);
              return false;
            }

          }, 0);

        });
        //This event trigger on keypress of banner text on add playlist

        $(".banner-textarea").on("keypress", function (e) {
          if (e.key === ":") { // disallow colon
            return false;
          }
          var self = this;
          var fontSize = $("#selected-font-size").val();
          var full_bann_text = $("#selected-full-banner-text").val();
          if (full_bann_text === "off") {
            if (country_name == "Japan" || country_name == "Korea") {
            var charLim =  parseInt($("#" + fontSize + "-char-limit-apac").val());
            } else {
            var charLim =  parseInt($("#" + fontSize + "-char-limit").val());
            }
            if (country_name === "Japan" || country_name === "Korea") {
              var perLineLength = 10;
            } else {
              var perLineLength = 15;
            }
          } else {
            if (country_name == "Japan" || country_name == "Korea") {
              var charLim = 42;
            } else {
              var charLim = 99;
            }
            if (country_name === "Japan" || country_name === "Korea") {
              var perLineLength = 14;
            } else {
              var perLineLength = 33;
            }
          }

          var charlimit = perLineLength;
          setTimeout(function () {
            var new_stuff = $(self).val();
            $(self).val(new_stuff);
            var patt1 = /\n/g;
            var str = $(self).val();
            str = str.replace(patt1, '');
            str = str.replace(/:/g, "");
            if (str.length > charLim) {
              var msg = t("Maximum @count characters are allowed.").replace("@count", charLim);
              //   notify(msg);
              var slicedtxt = charLim - str.length;
              var remove = self.value.slice(0, slicedtxt);
              //    $(self).val(remove);
            }
            var lines = $(self).val().split('\n');
            for (var i = 0; i < lines.length; i++) {
              if (lines[i].length <= charlimit) continue;
              var j = 0;
              var space = charlimit;
              while (j++ <= charlimit) {
                if (i < lineLength - 1) {
                  if (lines[i].charAt(j) === ' ') space = j;
                }
              }
              lines[i + 1] = lines[i].substring(space).trim() + (lines[i + 1] || "");
              lines[i] = lines[i].substring(0, space);
            }
            var banner_speed = $("#selected-banner-text-speed").val();

            if (lines.length > 3 && banner_speed == 'off') {
              const bannersize = $("#selected-font-size").val();
			        const fullbannertext = $("input#selected-full-banner-text").val();
              setBannertxtData({ 'fontSize': bannersize, 'fontspeed': 'normal', 'fullbannertext': fullbannertext });
              showBannertextPop();
              setBannertxtDisabled(false);
              setIsVisible(true);
              $(self).val(lines.slice(0, scrolllineLength).join('\n'));
              //$(self).val(lines.join('\n'));
            }
            else {
              setIsVisible(true);
              //$(self).val(lines.join('\n'));
              $(self).val(lines.slice(0, lineLength).join('\n'));
            }
            if (lines.length > lineLength) {
              var msg = t('Maximum @count lines are allowed.').replace('@count', lineLength);
              notify(msg);
              return false;
            }
          }, 0);

        });
        $(".banner-textarea").on("keyup", function (e) {
          if (e.key === ":") { // disallow colon
            return false;
          }
          var fontSize = $("#selected-font-size").val();
          var full_bann_text = $("#selected-full-banner-text").val();
          if (full_bann_text === "off") {
            if (country_name == "Japan" || country_name == "Korea") {
              var charLim =  parseInt($("#" + fontSize + "-char-limit-apac").val());
            } else {
              var charLim =  parseInt($("#" + fontSize + "-char-limit").val());
            }
            if (country_name === "Japan" || country_name === "Korea") {
              var perLineLength = 10;
            } else {
              var perLineLength = 15;
            }
          } else {
            if (country_name == "Japan" || country_name == "Korea") {
              var charLim = 42;
            } else {
              var charLim = 99;
            }
            if (country_name === "Japan" || country_name === "Korea") {
              var perLineLength = 14;
            } else {
              var perLineLength = 33;
            }
          }
          var charlimit = perLineLength;
          var lines = $(".banner-textarea").val().split('\n');

          for (var i = 0; i < lines.length; i++) {
            if (lines[i].length <= charlimit) continue;
            var j = 0;
            var space = charlimit;
            while (j++ <= charlimit) {
              if (i < lineLength - 1) {
                if (lines[i].charAt(j) === ' ') space = j;
              }
            }
            lines[i + 1] = lines[i].substring(space + 1) + (lines[i + 1] || "");
            lines[i] = lines[i].substring(0, space);
          }
          var banner_speed = $("#selected-banner-text-speed").val();

          if (lines.length > 3 && banner_speed == 'off') {
            showBannertextPop();
            setBannertxtDisabled(false);
            $(".banner-textarea").val(lines.slice(0, scrolllineLength).join('\n'));
          }
          else if (lines.length <= 3) {
            if (e.key === "Backspace" || e.key === "Delete") {
              setBannertxtDisabled(true);
			        const fullbannertext = $("input#selected-full-banner-text").val();
              const bannersize = $("#selected-font-size").val();
              setBannertxtData({ 'fontSize': bannersize, 'fontspeed': 'off', 'fullbannertext': fullbannertext });
              $("#selected-banner-text-speed").val('off');
              $("#banner_text_speed").val('off');
            }
          }
          else {
            setIsVisible(false);
            $(".banner-textarea").val(lines.slice(0, lineLength).join('\n'));
          }

          if (lines.length > lineLength) {
            var msg = t('Maximum @count lines are allowed.').replace('@count', lineLength);
            notify(msg);
            return false;
          }
        });

        $(".banner-textarea").on("keydown", function (e) {
          if (e.key === ":") { // disallow colon
            return false;
          }
          var fontSize = $("#selected-font-size").val();
          var full_bann_text = $("#selected-full-banner-text").val();
          if (full_bann_text === "off") {
            if (country_name == "Japan" || country_name == "Korea") {
              var charLim =  parseInt($("#" + fontSize + "-char-limit-apac").val());
            } else {
              var charLim =  parseInt($("#" + fontSize + "-char-limit").val());
            }
            if (country_name === "Japan" || country_name === "Korea") {
              var perLineLength = 10;
            } else {
              var perLineLength = 15;
            }
          } else {
            if (country_name == "Japan" || country_name == "Korea") {
              var charLim = 42;
            } else {
              var charLim = 99;
            }
            if (country_name === "Japan" || country_name === "Korea") {
              var perLineLength = 14;
            } else {
              var perLineLength = 33;
            }
          }

          var charlimit = perLineLength;


          if (e.key === 8 || e.key === 46 || e.key === "Backspace" || e.key === "Delete") {

            var lines = $(".banner-textarea").val().split('\n');
            for (var i = 0; i < lines.length; i++) {
              if (lines[i].length <= charlimit) continue;
              var j = 0;
              var space = charlimit;
              while (j++ <= charlimit) {
                if (i < lineLength - 1) {
                  if (lines[i].charAt(j) === ' ') space = j;
                }
              }
              lines[i + 1] = lines[i].substring(space).trim() + (lines[i + 1] || "");
              lines[i] = lines[i].substring(0, space);
            }

            var banner_speed = $("#selected-banner-text-speed").val();

            if (lines.length > 3 && banner_speed == 'off') {
              showBannertextPop();
              setBannertxtDisabled(false);
              $(".banner-textarea").val(lines.slice(0, scrolllineLength).join('\n'));
            }
            else if (lines.length <= 3) {
              const bannersize = $("#selected-font-size").val();
			        const fullbannertext = $("input#selected-full-banner-text").val();
              setBannertxtData({ 'fontSize': bannersize, 'fontspeed': 'off', 'fullbannertext': fullbannertext });
              setBannertxtDisabled(true);
              $("#selected-banner-text-speed").val('off');
            }
            else {
              $(".banner-textarea").val(lines.slice(0, lineLength).join('\n'));
            }

          }
          var lines = $(".banner-textarea").val().split('\n');
          if (lines.length === lineLength) {
            if (e.key === 13) {
              e.preventDefault();
              var msg = t('Maximum @count lines are allowed.', { '@count': lineLength });
              notify(msg);
              return false;
            }
          }
        });


        function getimagetype(file) {
          var extension = file.substr((file.lastIndexOf('.') + 1));
          switch (extension) {
            case 'jpg':
            case 'png':
            case 'PNG':
            case 'gif':
            case 'jfif':
              return extension;
              break;
            case 'mp4':
              return extension;
              break;
            default:
              return 'undefined';
          }
        }
      }, 0);

      setFlag(true);
      setTimeout(function () { setLoading(false); }, 2000);

    })
      .catch(err => {

        setPlaylistData("");
      });
  }


  const getData = async () => {

    let url = config.drupal_url + '/api/node/qml_upload'
    const res = await axios.get(url, { 'withCredentials': true }).then(res => {

      const data = res.data["data"];

      setData(data);

    })
      .catch(err => {

        setData("");
      });
  }

  const getlivecontentData = async () => {

    let url = config.drupal_url + '/api/live-content-block'
    const res = await axios.get(url, { 'withCredentials': true }).then(res => {

      const livecontentdata = res.data;

      setlivecontentData(livecontentdata);
      dragndrop();

    })
      .catch(err => {

      });
  }

  const getusercontentData = async (tid, cid) => {

    setLoading(true);
    let userrole = "bm";
    JSON.parse(localStorage.getItem("userdetails")).roles.forEach(role => {
      if (role === "country_admin") {
        userrole = "country_admin";
      }
    });
    let url = "";
    if (userrole === "bm" && vocabulary === 'default_library') {
      url = config.drupal_url + '/api/country/playlist-usercontent-block?cid=' + cid + '&tid_raw=' + tid;
    } else {
      url = config.drupal_url + '/api/playlist-usercontent-block?tid=' + tid;
    }

    const res = await axios.get(url, { 'withCredentials': true }).then(res => {

      const usercontentdata = res.data;

      setusercontentData(usercontentdata);
      dragndrop();
      setTimeout(function () { setLoading(false); }, 1500);
    })
      .catch(err => {

        setlivecontentData("");
        setLoading(false);
      });
  }

  const getusermessagesData = async () => {

    let url = config.drupal_url + '/api/playlist-usermessages-block'
    const res = await axios.get(url, { 'withCredentials': true }).then(res => {

      const usermessagesdata = res.data;

      setusermessagesData(usermessagesdata);
      dragndrop();

    })
      .catch(err => {

        setusermessagesData("");
      });
  }

  const getadminmessagesData = async () => {

    let url = config.drupal_url + '/api/playlist-adminmessages-block'
    const res = await axios.get(url, { 'withCredentials': true }).then(res => {

      const adminmessagesdata = res.data;

      setadminmessagesData(adminmessagesdata);

      dragndrop();

    })
      .catch(err => {

        setadminmessagesData("");
      });
  }

  const [validTitle, setValidTitle] = useState(false);
  const uploadTitleChange = (e) => {
    const title = e.target.value;
    setMediaTitle(title);
    const userid = JSON.parse(localStorage.getItem("userdetails")).id;
    const tmid = (term.tid === '') ? defaulttid : term.tid
    let vTitleUrl = '/api/media/title?filter[uid]=' + userid + '&filter[tid]=' + tmid + '&filter[title]=' + title;
    axios.get(config.drupal_url + vTitleUrl, { withCredentials: true })
      .then((response) => {
        if (response.data.data.length !== 0) {
          setValidTitle(false);
        } else {
          setValidTitle(true);
        }
      })
  }

  const dispatchMediaEvent = (selectedMediaContent) => {
    setUploadMediaContent(selectedMediaContent);
  }

  const dragndrop = (e) => {
    if (document.getElementsByClassName("img-content-block")) {
      var live_content_length = document.getElementsByClassName("img-content-block").length;
      for (var i = 0; i < live_content_length; i++) {

        Sortable.create(document.getElementsByClassName("img-content-block")[i], {
          group: {
            name: "comps",
            put: false,
            pull: "clone",
          },
          draggable: ".pep", // Specifies which items inside the element should be draggable
          sort: false,
        });
      }
      $('.img-content-block').find('img').each(function () {
        if ($(this).hasClass('video')) {
          if (!$(this).hasClass('news')) {
            $(this).parent().addClass('playicon');
          }
        }
      });
    }

  }
  //get library tab ids
  const fetchLibraryTabs = async () => {
    try {
      let response = await fetch(config.drupal_url + '/api/library-category-terms?_format=json');
      let json = await response.json();
      return { success: true, data: json };
    } catch (error) {
      return { success: false };
    }
  }
  useEffect(() => {

    // Show the first tab and hide the rest
    $('#tabs-nav li:first-child').addClass('active');
    $('.tab-content').hide();
    $('.tab-content:first').show();

    // Click function
    $('#tabs-nav li').on("click", function () {
      $('#tabs-nav li').removeClass('active');
      $(this).addClass('active');
      $('.tab-content').hide();

      var activeTab = $(this).find('a').attr('href');
      $(activeTab).fadeIn();
      return false;
    });

    (async () => {

      let res = await fetchLibraryTabs();
      if (res.success) {
        let list = res.data;
        let defaultid = list.filter(function (arr) {
          return arr.name === 'User Library'
        });
        setDefaultTid(defaultid[0].tid)
        setTerm({ 'tid': defaultid[0].tid });
        setLibTabs(list);
      }
    })();
    // Tab title
    document.title = tabTitle + ' | CMS';
    setLoading(true)
    axios.get(config.drupal_url + '/rest/api/get/sastoken', { 'withCredentials': true })
      .then(res1 => {

        if (res1.data) {

          setCookie1("azure_blob", res1.data);
          var blob_url = res1.data.blob_url;
          var blob_container = res1.data.blob_container;
          var token = res1.data.token;
        }
      })
      .catch(err => {
        notify(t("Please try later.If the error persists, please contact Otis support."));
        return false;

      });

    getPlaylistData()
    getlivecontentData()
    getusercontentData('', '')
    getusermessagesData()
    getadminmessagesData()
    getData()
    dragndrop()

  }, [])

  if (livecontentdata && usercontentdata && adminmessagesdata && usermessagesdata) {
    dragndrop();
  }

  let eview2LayoutClass = "";
  let infotainmentLayoutClass = "";
  let fullcontentLayoutClass = "";
  
  data.map((pd, i) => {

    if (playlistdata.layout === pd['id']) {

      if (pd.attributes['field_cpibdevicetype'] === "eView2-device") {
        eview2LayoutClass = 'screen-thumb-hold thumb-active';
        infotainmentLayoutClass = 'screen-thumb-hold thumb-disable';
        fullcontentLayoutClass = 'screen-thumb-hold thumb-disable';
      }else if (pd.attributes['field_cpibdevicetype'] === "full-content") {	 
        
        eview2LayoutClass = 'screen-thumb-hold thumb-disable';
        infotainmentLayoutClass = 'screen-thumb-hold thumb-disable';
        fullcontentLayoutClass = 'screen-thumb-hold thumb-active';
	   }else {
        infotainmentLayoutClass = 'screen-thumb-hold thumb-active';
        eview2LayoutClass = 'screen-thumb-hold thumb-disable';
		   fullcontentLayoutClass = 'screen-thumb-hold thumb-disable';

      }

    }
    $('.screen-thumb-hold.thumb-active').click();




  });

  const divStyle = {
    fontSize: playlistdata.font + "px"
  };

  const handleFileUpload = () => {
    const tmid = (term.tid === '') ? defaulttid : term.tid;
    const userid = localStorage.getItem("userdetails") && JSON.parse(localStorage.getItem("userdetails")).id;
    if (mediatitle === '') {
      notify(t('!name field is required.').replace('!name', t('Title')));
      return null;
    }
    if (uploadMediaContent.fid === undefined || uploadMediaContent.fid === '') {
      notify(t('!name field is required.').replace('!name', t('Upload File')));
      return null;
    }
    if (!validTitle) {
      notify(t("Title already exists in this folder."));
      return null;
    }
    var tabSwitcher = "image";
    var data = {
      "_links": {
        "type": {
          "href": config.rest_node_url + '/rest/type/node/media'
        }
      },
      "title": {
        "value": mediatitle
      },
      "field_tab_switcher": {
        value: tabSwitcher
      },
      "field_mediaimage": [{
        "target_id": `${uploadMediaContent.fid}`,
      }],
      "uid": [{ "target_id": userid }],
      "field_categories": [{ "target_id": tmid }]
    };
    if (uploadToggleState === 2) {
      tabSwitcher = 'video';
      if (uploadMediaContent.vfid === undefined || uploadMediaContent.vfid === "") {
        notify(t('!name field is required.').replace('!name', t('Upload video')));
        return false;
      }
      if (uploadMediaContent.fid === undefined || uploadMediaContent.fid === "") {
        notify(t('!name field is required.').replace('!name', t('Upload File')));
        return null;
      }
      data = {
        "_links": {
          "type": {
            "href": config.rest_node_url + '/rest/type/node/media'
          }
        },
        "title": {
          "value": mediatitle
        },
        "field_tab_switcher": {
          value: tabSwitcher
        },
        "field_mediaimage": [{
          "target_id": `${uploadMediaContent.fid}`,
        }],
        "field_upload_video": [{
          "target_id": `${uploadMediaContent.vfid}`,
        }],
        "uid": [{ "target_id": userid }],
        "field_categories": [{ "target_id": tmid }]
      };
    }
    setLoading(true)
    const saveMediaUrl = config.drupal_url + '/node?_format=hal_json';
    axios.get(config.drupal_url + '/session/token').then(result => {
      if (200 === result.status) {
        const csrfToken = result.data;
        const headers = {
          "Content-Type": "application/hal+json",
          withCredentials: true,
          'X-CSRF-Token': csrfToken,
        }
        axios.post(saveMediaUrl, JSON.stringify(data), { headers })
          .then((res) => {
            toast.success(t("@type %title has been created.").replace('@type', t('Upload content to library')).replace('%title', mediatitle));
            setTimeout(function () { window.location.reload(false); }, 5000);
          })
      }
    });
    handleShowUploadPopClose()
  }

  const handleBannertextSave = () => {
    $("input#selected-banner-text-speed").val($('#banner_text_speed').val());
    $("input#selected-font-size").val($('#bta-fontsize').val());
    $("input#selected-full-banner-text").val($('#full_banner_text').val());
    fontspeedcharlimit();
    fontsizelimit();
    setIsVisible(false);
    setBannertxtData({ 'fontSize': $("#bta-fontsize").val(), 'fontspeed': $('#banner_text_speed').val(), 'fullbannertext': $('#full_banner_text').val() });
    handleShowBannertextPopClose();
    $(".banner-textarea").trigger("keypress");
  }
  const HandleUploadClick = (e) => {
    showUploadPop()
  }
  const HandleBannersettingsClick = (e) => {
    showBannertextPop()
  }
  const preveiwTip = t("Click to see preview");
  const modalduration = t("(1-30 secs)");
  return (
    <>
      <div className="dimOverlayForBlock" style={{ "display": "none" }}>
        <div data-testid="overlay" className="_loading_overlay_overlay css-df17o1">
          <div className="_loading_overlay_content css-42igfv">
            <div className="_loading_overlay_spinner css-50etie">
              <svg viewBox="25 25 50 50"><circle cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10"></circle></svg>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      <LoadingOverlay active={isloading} spinner text={t('Processing')}>
        <div className="main-container default-present create-playlist" id={playlistdata.set_as_default === 1 && playlistdata.banner_allow !== "allow" &&
          "default-playlist"}>
          <Modal show={modalShow} className="topModal">
            <div className="playlistModal" id="playlistModal">
              <Modal.Body>

                <div>

                  <Tabs selectedIndex={toggleState - 1}>
                    <TabList>
                      <Tab onClick={() => toggleTab(1)}><div id="user_library"><T _str="User Library" /></div></Tab>
                      <Tab onClick={() => toggleTab(2)}><div id="default_content"><T _str="Default Content" /></div></Tab>
                    </TabList>
                    <div className="user-library-tab">
                      <LibContext.Provider value={{ term, dispatchLibEvent }}>
                        <TabPanel key="1">
                          <UserLibrary vocabulary={vocabulary === '' ? 'category' : vocabulary}></UserLibrary>
                        </TabPanel>
                        <TabPanel key="2">
                          <UserLibrary vocabulary={vocabulary}></UserLibrary>
                        </TabPanel>

                      </LibContext.Provider>
                    </div>


                  </Tabs>
                </div>

              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handleMoveSaveClick}>
                  <T _str="OK" />
                </Button>
                <Button variant="secondary" onClick={modalHandleClose}>
                  <T _str="CANCEL" />
                </Button>

              </Modal.Footer>
            </div>

          </Modal>


          <form onSubmit={onSubmit}>

            <div className="col p-0">
              <div className="row">
                <div className="main-title-hold">
                  <div className="left-title-hold">
                    <h1>eView<sup>&reg;</sup> - {t("Edit Playlist")}</h1>
                    <div className="d-flex_ flex justify-content-center">
                      <div className="btn-group ">
                        <div className="form-group">
                          {(ismute)
                            ?
                            <>
                              <input id="PlayList_ProgamName" type="text" className="form-control" maxLength="25" placeholder={t("Assign a name to your playlist")} defaultValue={playlistdata.title} />
                            </>
                            :
                            <> <input id="PlayList_ProgamName" type="text" className="form-control disabled" maxLength="25" placeholder={t("Assign a name to your playlist")} disabled="disabled" value={playlistdata.title} />
                            </>
                          }

                          <i className="entypo-search"></i>
                        </div>
                      </div>
                      <a href="#!" className="btn btn-success" onClick={Mutable}><i className="fa fa-pencil"></i></a>
                    </div>
                  </div>
                  {flag === true && (
                    <div className="right-title-hold">
                      <button id="btnSave_set" type="submit" className="btn btn-success" onClick={(event) => [SaveClick(event.target.id)]}>{<T _str="Save and Set" />}</button>

                      <button id="btnSave" type="submit" className="btn btn-success" onClick={(event) => [SaveClick(event.target.id)]}>{<T _str="Save" />}</button>

                    </div>
                  )}

                </div>
              </div>
            </div>






            <div className="col">
              <div className="row">
                <div className="column onethird create-playlist-mainblock">





                  <div className="tab">

                    <ul id="tabs-nav">
                      <li key="nav1" className="live-content"><a href="#tab1"><T _str="Live Content" /></a></li>
                      <li key="nav2" className="user-content"><a href="#tab2"><T _str="User Content" /></a></li>
                      <li key="nav3" className="user-messages"><a href="#tab3"><T _str="User Messages" /></a></li>
                      <li key="nav4" className="admin-messages"><a href="#tab4"><T _str="Admin's Message" /></a></li>
                    </ul>


                    <div id="tabs-content">
                      <div id="tab1" className="tab-content">
                        <div className="scroll">
                          <div className="row">

                            <div id="img-content-block">

                              {livecontentdata && livecontentdata.map((pd, index) =>

                                <div className='img-content-block'>

                                  <img alt="" className={(pd.field_feed_type === "news" || pd.field_country_weather === "Country Weather") ? "video image-grab pep demo news " : "image-grab pep demo " + pd.sticky} data-fid={pd.fid} data-url={pd.field_mediaimage_1.replace(/&amp;/g, '&')} data-filename={pd.filename} data-feedurl={pd.title} typeof="foaf:Image" src={pd.field_mediaimage_1.replace(/&amp;/g, '&')} data-feedtype={pd.field_feed_type} />
                                  <div className="card-body">
                                    <p className="card-text">{pd.title_1}</p>
                                  </div>


                                </div>
                              )}
                            </div>


                          </div>
                        </div>

                      </div>
                      <div id="tab2" className="tab-content" style={{ "display": "none" }}>
                        <div className="scroll">
                          <div className="row">
                            <div className="playlist-search">
                              <div className="btn-group ">

                                <input type="text" className="form-control" name="s" id="search-input" placeholder={"/" + t("User Library")} onClick={modalHandleShow} /> <i className="entypo-search"></i>
                              </div>
                              <a href="#!" className="btn btn-success" onClick={HandleUploadClick}><i className="fa fa-upload"></i></a>
                            </div>
                            <div id="img-content-block1">

                              {usercontentdata && usercontentdata.map((pd, index) =>

                                <div className='img-content-block' key={index}>
                                  <img key={index} className={"image-grab pep " + pd.field_tab_switcher + " demo"} data-fid={pd.fid} data-url={pd.field_mediaimage_1.replace(/&amp;/g, '&')} data-filename={pd.filename} typeof="foaf:Image" src={pd.field_mediaimage_1.replace(/&amp;/g, '&')} tid={pd.tid} data-vfname={pd.filename_1} data-tab={pd.field_tab_switcher} alt="" />
                                  <div className="card-body" key={index}>
                                    <p className="card-text" key={index}>{pd.title}</p>
                                  </div>


                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="tab3" className="tab-content" style={{ "display": "none" }}>
                        <div className="scroll">
                          <div className="row">

                            <div id="img-content-block2">


                              {usermessagesdata && usermessagesdata.map((pd, index) =>
                                <div className='img-content-block'>

                                  <img className={"image-grab pep " + pd.field_tab_switcher + " demo"} data-fid={pd.fid} data-url={pd.field_mediaimage_1.replace(/&amp;/g, '&')} data-filename={pd.filename} typeof="foaf:Image" src={pd.field_mediaimage_1.replace(/&amp;/g, '&')} tid={pd.tid} data-vfname={pd.filename_1} data-tab={pd.field_tab_switcher} alt="" />
                                  <div className="card-body">
                                    <p className="card-text">{pd.title}</p>
                                  </div>

                                </div>
                              )}






                            </div>




                          </div></div>
                      </div>
                      <div id="tab4" className="tab-content" style={{ "display": "none" }}>
                        <div className="scroll">
                          <div className="row">

                            <div id="img-content-block3">
                              {adminmessagesdata && adminmessagesdata.map((pd, index) =>

                                <div className='img-content-block'>
                                  <img className={"image-grab pep " + pd.field_tab_switcher + " demo"} data-fid={pd.fid} data-url={pd.field_mediaimage_1.replace(/&amp;/g, '&')} data-filename={pd.filename} typeof="foaf:Image" src={pd.field_mediaimage_1.replace(/&amp;/g, '&')} tid={pd.tid} data-vfname={pd.filename_1} data-tab={pd.field_tab_switcher} alt="" />

                                  <div className="card-body">
                                    <p className="card-text">{pd.title}</p>
                                  </div>


                                </div>
                              )}


                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>

                </div>

                <div className="column twothird">
                  <div className="screen-preview">
                    {flag === true && (
                      <a href="#!" title={preveiwTip} onClick={playlistPreview}><i className="fa fa-eye"></i>{t("Preview")}</a>
                    )}
                  </div>
                  <div className="screen-container">
                    <div className="left-screen-hold infotainment-img hide">
                      <img src={leftdisplay} alt="" title="" /></div>
                    <div className="left-screen-hold eView-img">
                      <div id="img-left">
                        <img src={leftdisplay1} alt="" title="" height="374" /></div>
                    </div>

                    <div className="right-screen-hold">
                      <div className="screen-droppable-hold">
                        <div className="drop droppable playlist-layout" id="FloorInfo_Dragimage">

                        </div>
                        <div><a href="#!" className="droppable-settings" onClick={modal_open_content}><i className="fa fa-cog"></i></a></div>
                        <div className="image-count" id="image-count">0</div>
                        <div className="drag-here" id="Playlist_ZoneDragHint1"><font style={{ "verticalAlign": "inherit" }}><font style={{ "verticalAlign": "inherit" }}>{t("Drag one or more contents from the panel on the left to here")}</font></font></div>

                      </div>
                      <div className="text-image-hold">
                        <div className="banner-text-hold" id={(fullbannertext === "on") ? "full-banner" : "#"}>
                          <div className="drop_down">
                            <div className="dropdown-menu">
                            </div>
                            <div className="char_limits" id="char_limits">
                              <input type="hidden" name="14-char-limit" className="14-char-limit" id="14-char-limit" value="60" />
                              <input type="hidden" name="18-char-limit" className="18-char-limit" id="18-char-limit" value="55" />
                              <input type="hidden" name="22-char-limit" className="22-char-limit" id="22-char-limit" value="40" />
                              <input type="hidden" name="26-char-limit" className="26-char-limit" id="26-char-limit" value="33" />
                              <input type="hidden" name="30-char-limit" className="30-char-limit" id="30-char-limit" value="25" />
                              <input type="hidden" name="14-char-limit-apac" className="14-char-limit-apac" id="14-char-limit-apac" value="40" />
                              <input type="hidden" name="18-char-limit-apac" className="18-char-limit-apac" id="18-char-limit-apac" value="30" />
                              <input type="hidden" name="22-char-limit-apac" className="22-char-limit-apac" id="22-char-limit-apac" value="30" />
                              <input type="hidden" name="26-char-limit-apac" className="26-char-limit-apac" id="26-char-limit-apac" value="20" />
                              <input type="hidden" name="30-char-limit-apac" className="30-char-limit-apac" id="30-char-limit-apac" value="15" />

                            </div>
                            {playlistdata.font !== undefined &&
                              <input type="text" name="selected-font-size" className="selected-font-size" id="selected-font-size" style={{ display: "none" }} defaultValue={playlistdata.font} />
                            }
                            {playlistdata.banner_text_speed !== undefined &&
                              <input type='text' name="selected-banner-text-speed" className="selected-banner-text-speed" id="selected-banner-text-speed" style={{ display: "none" }} defaultValue={playlistdata.banner_text_speed} />
                            }
                            {playlistdata.full_banner_text !== undefined &&
                              <input type='text' name="selected-full-banner-text" className="selected-full-banner-text" id="selected-full-banner-text" style={{ display: "none" }} defaultValue={playlistdata.full_banner_text} />
                            }
                            {playlistdata.content_images !== undefined &&
                              <div className="form-item form-item-field-content-images-und-0-value form-type-textarea form-group field-name-field-list-of-content">
                                <div className="form-textarea-wrapper resizable textarea-processed resizable-textarea">
                                  <textarea className="text-full form-control form-textarea" id="edit-field-content-images-und-0-value" name="field_content_images[und][0][value]" cols="60" rows="5" style={{ display: "none" }} defaultValue={playlistdata.content_images} />
                                  <div className="grippie"></div>
                                </div>
                              </div>
                            }
                            {playlistdata.banner_images !== undefined &&
                              <div className="form-item form-item-field-banner-images-und-0-value form-type-textarea form-group field-name-field-list-of-banners">
                                <div className="form-textarea-wrapper resizable textarea-processed resizable-textarea">
                                  <textarea className="text-full form-control form-textarea" id="edit-field-list-of-banners-und-0-value" name="field_banner_images[und][0][value]" cols="60" rows="5" style={{ display: "none" }} defaultValue={playlistdata.banner_images} />
                                </div>
                              </div>
                            }
                          </div>
                          <div className="banner-text-wheel"><a href="#!" className="image-count-settings" onClick={HandleBannersettingsClick}><i className="fa fa-cog"></i></a></div>
                          <div className="banner-textarea1">

                            <p>{t("Banner Text")}</p>


                            {playlistdata.font !== undefined && playlistdata.text !== undefined &&
                              <textarea type="textarea" cols="60" rows="5" id="testeditor1" className="banner-textarea" name="mytest" placeholder={t("Type your custom text message here")} defaultValue={playlistdata.text} style={divStyle} />
                            }

                          </div>
                        </div>
                      
                          <div className="image-count-hold" id={(fullbannertext === "on") ? "full-banner-hide" : "#"}>
                            <div className="bottom-drop droppable playlist-layout" id="FloorInfo_Dragimage2">

                            </div>

                            <div><a href="#!" className="image-count-settings" onClick={modal_open}><i className="fa fa-cog"></i></a></div>
                            <div className="image-count-banner" id="image-count-banner">0</div>
                            <div className="drag-here02" id="Playlist_ZoneDragHint2"><font style={{ "verticalAlign": "inherit" }}><font style={{ "verticalAlign": "inherit" }}>{t("Drag one or more contents from the panel on the left to here")}</font></font></div>

                          </div>
                        
                      </div>
                    </div>
                  </div>
                  <div className="screen-thumb-hold">

                    <div className="eView2 layout-active">
                      <div className={eview2LayoutClass}>
                        <a href="#!">eView2</a>
                      </div>
                    </div>
                    <div className="Infotainment">
                      <div className={infotainmentLayoutClass}>
                        <a href="#!">Infotainment</a>
                      </div>
                    </div>
					<div className="Fullcontent">
                      <div className={fullcontentLayoutClass}>
                        <a href="#!">Fullcontent</a>
                      </div>
                    </div>

                    <div style={{ display: "none" }}>
                      {data.map((pd, index) => <div key={index}>
                        {(pd.attributes["field_cpibdevicetype"] === "eView2-device" &&
                          <div className="eView2 layout-active hide">
                            {playlistdata.layout === pd['id'] &&
                              <>
                                <input type="radio" id="edit-field-select-layout-und-61731" name="field_select_layout[und]" value={pd['id']} className="form-radio" />
                              </>
                            }
                            {playlistdata.layout !== pd['id'] &&
                              <input type="radio" id="edit-field-select-layout-und-61731" name="field_select_layout[und]" value={pd['id']} className="form-radio" />
                            }
                            <img typeof="Image" className="img-responsive" src="https://wa-cms-ui-dev-linux1.azurewebsites.net/azure/remote/cpcms/styles/thumbnail/cpcms/14102/Layout1.png?itok=cKsN7vhJ" width="100" height="56" alt="" />
                            <span className="views-field-title">eView2</span>

                          </div>
                        )}

                        <p>&nbsp;</p>
                        {(pd.attributes["field_cpibdevicetype"] === "infotainment-device" &&

                          <div className="Infotainment hide">
                            {playlistdata.layout === pd['id'] &&
                              <>
                                <input type="radio" id="edit-field-select-layout-und-61698" name="field_select_layout[und]" value={pd['id']} className="form-radio" />
                              </>
                            }
                            {playlistdata.layout !== pd['id'] &&
                              <input type="radio" id="edit-field-select-layout-und-61698" name="field_select_layout[und]" value={pd['id']} className="form-radio" />
                            }
                            <img typeof="Image" className="img-responsive" src="https://wa-cms-ui-dev-linux1.azurewebsites.net/azure/remote/cpcms/styles/thumbnail/cpcms/14046/Layout1.png?itok=QjUUrS9i" width="100" height="56" alt="" />
                            <span className="views-field-title">Infotainment</span>

                          </div>

                        )}
						 <p>&nbsp;</p>
					{(pd.attributes["field_cpibdevicetype"] === "full-content" &&

                          <div className="Fullcontent hide">
                            {playlistdata.layout === pd['id'] &&
                              <>
                                <input type="radio" id="edit-field-select-layout-und-61698" name="field_select_layout[und]" value={pd['id']} className="form-radio" />
                              </>
                            }
                            {playlistdata.layout !== pd['id'] &&
                              <input type="radio" id="edit-field-select-layout-und-61698" name="field_select_layout[und]" value={pd['id']} className="form-radio" />
                            }
                            <img typeof="Image" className="img-responsive" src="https://wa-cms-ui-dev-linux1.azurewebsites.net/azure/remote/cpcms/styles/thumbnail/cpcms/14046/Layout1.png?itok=QjUUrS9i" width="100" height="56" alt="" />
                            <span className="views-field-title">Fullcontent</span>

                          </div>

                        )}
                      </div>

                      )}
                    </div>

                  </div>

                  <div className="screen-preview-content">{t("The content for the banner area will not be shown for screens without a banner area")}</div>
                </div>

              </div>

            </div>

            <div id="TermsModal_1" className="modal fade" role="dialog">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="DisplayTermsModal">

                      <div className="trm">

                        <table border="0" cellSpacing="20" cellPadding="5">
                          <tbody>
                            <tr className="file-drag-title">
                              <td >{<T _str="Content" />}</td>
                              <td >{<T _str="Duration (Secs)" />}<span className='duration-info' title={modalduration}>&nbsp;</span></td>
                            </tr>
                          </tbody>
                        </table>
                        <input type="hidden" id="banner_hidden" />
                        <input type="hidden" id="banner_hidden_fid" />
                        <table border="0" id="TermsDisplay_1" width="100%"></table>

                      </div>
                      <div className="float-right">
                        <button type="button" id="FolderOK_1" className="button">{<T _str="SAVE" />}</button>&nbsp;&nbsp;
                        <button type="button" id="TermsCancel_1" className="button" >{<T _str="CANCEL" />}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="TermsModal_2" className="modal fade" role="dialog">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="DisplayTermsModal">

                      <div className="trm">

                        <table border="0" cellSpacing="20" cellPadding="5">
                          <tbody>
                            <tr className="file-drag-title">
                              <td >{<T _str="Content" />}</td>
                              <td >{<T _str="Duration (Secs)" />}<span className='duration-info' title={modalduration}>&nbsp;</span></td>
                            </tr>
                          </tbody>
                        </table>
                        <input type="hidden" id="content_hidden" />
                        <input type="hidden" id="content_hidden_fid" />
                        <table border="0" id="TermsDisplay_2" width="100%"></table>

                      </div>
                      <div className="float-right">
                        <button type="button" id="FolderOK_2" className="button">{<T _str="SAVE" />}</button>&nbsp;&nbsp;
                        <button type="button" id="TermsCancel_2" className="button" >{<T _str="CANCEL" />}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <input type='hidden' id="playlist_id" value={playlistdata.id} />
            <input type='hidden' id="azure_blob" value={JSON.stringify(cookies1.azure_blob)} />


          </form>
        </div>

        <Modal show={showPreview} onHide={previewModalClose} className="pl-preview">
          <Modal.Body>
            <div className="playlistPreview modalClose" onClick={previewModalClose} ><FaTimesCircle /></div>
            <PlaylistsPreview playlistsData={playlistpreviewdata} othersPlaylistsData={[]} fullPreview='NA' id='NA' screenId='NA' infomsg='NA' source="edit_playlist" countryname={country_name} />
          </Modal.Body>
        </Modal>

        <Modal show={showupload} onHide={handleShowUploadPopClose} className='library-upload'>
          <Modal.Header closeButton>
            <Modal.Title><T _str="Upload content to library" /></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <T _str="Title" />*<input type='text' className='full-input' maxLength={25} onChange={uploadTitleChange} /><br /><br />
            {(vocabulary !== 'messages') ?
              <Tabs>
                <div className="media_tabs">
                  <TabList>
                    <Tab onClick={() => toggleUploadTab(1)}>{t('Image').toUpperCase()}</Tab>
                    <Tab onClick={() => toggleUploadTab(2)}>{t('Video').toUpperCase()}</Tab>
                  </TabList>
                </div>
                <MediaContext.Provider value={{ uploadMediaContent, dispatchMediaEvent }}>
                  <TabPanel key="1" >
                    <Fileupload tab={'image'} vocabulary={vocabulary}
                      ImageMaxAllowedSize={validations.ImageMaxAllowedSize !== '' ? validations.ImageMaxAllowedSize : 10}
                    ></Fileupload>
                  </TabPanel>
                  <TabPanel key="2" >
                    <Fileupload tab={'video'}
                      VideoMaxAllowedSize={validations.VideoMaxAllowedSize !== '' ? validations.VideoMaxAllowedSize : 100}
                      ImageMaxAllowedSize={validations.ImageMaxAllowedSize !== '' ? validations.ImageMaxAllowedSize : 10}
                      MaxAllowedFPS={validations.MaxAllowedFPS !== '' ? validations.MaxAllowedFPS : 24}
                      MaxAllowedBitrate={validations.MaxAllowedBitrate !== '' ? validations.MaxAllowedBitrate : 2.5}
                    ></Fileupload>
                  </TabPanel>
                </MediaContext.Provider>
              </Tabs>
              :
              <Tabs>
                <MediaContext.Provider value={{ uploadMediaContent, dispatchMediaEvent }}>
                  <TabPanel key="1" >
                    <Fileupload tab={'image'} upload={'image'}></Fileupload>
                  </TabPanel>
                </MediaContext.Provider>
              </Tabs>
            }
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" onClick={handleFileUpload} color="primary">
              <T _str="Save" />
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showbannertextpopup} onHide={handleShowBannertextPopClose} className='library-upload'>
          <Modal.Header closeButton>
            <Modal.Title><T _str="Banner Text settings" /></Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div id="scrollbannertxt" style={{ display: isVisible ? 'block' : 'none' }} >
              <FaInfoCircle />
              <ul>
                <li>{t("As the text you entered crossed 3 lines, scrolling is enabled.")}</li>
                <li>{t("You can adjust the scroll speed.")}</li>
              </ul>
            </div>
            <div className="scroll-box-inner">
              <div class="scroll-box-inner-blok">
                <label>{t("Font size")}:</label>
                {playlistdata.font !== undefined &&
                  <select name="bta-fontsize" className="bta-fontsize" id="bta-fontsize" defaultValue={bannersize}>
                    <option value="14">14</option><option value="18">18</option>
                    <option value="22">22</option>
                    <option value="26">26</option>
                    <option value="30">30</option>
                  </select>
                }
              </div>
              <div class="scroll-box-inner-blok">
                <label>{t("Scroll speed")}:</label>
                {playlistdata.font !== undefined &&
                  <select name="banner_text_speed" className="banner_text_speed" id="banner_text_speed" defaultValue={bannerspeed} disabled={bannertxtDisabled}>
                    <option value="off">{t("Off")}</option>
                    <option value="normal">{t("Normal")}</option>
                    <option value="medium">{t("Medium")}</option>
                    <option value="high">{t("High")}</option>
                  </select>
                }
              </div>
              <div class="scroll-box-inner-blok">
                <label>{t("Autofit Banner Text to Window")}:</label>
                {playlistdata.full_banner_text !== undefined &&               
                <select name="full_banner_text" className="full_banner_text" id="full_banner_text" defaultValue={fullbannertext}>
                  <option value="off">{t("Off")}</option>
                  <option value="on">{t("On")}</option>
                </select>
             }
              </div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleBannertextSave}>
              <T _str="Save" />
            </Button>
            <Button onClick={handleShowBannertextPopClose}>
              <T _str="CANCEL" />
            </Button>
          </Modal.Footer>
        </Modal>




      </LoadingOverlay>
    </>
  );
}

export default EditPlaylist;