import Menu from './Menu';
import Footer from './Footer';
import FloatHeader from './FloatHeader';
import { t } from "@transifex/native";

function NotFoundPage(props) {
  return (
    <div class="page-not-found">
<h2>404</h2>
<h4>{t("Page not found")}</h4>
          </div>
  );
}

export default NotFoundPage;
