import $ from "jquery";
import { Sortable } from 'sortablejs';
import { T } from '@transifex/react';
import { t } from '@transifex/native';
import { toast } from "react-toastify";


$(function () {
  var userdetails = localStorage.getItem('userdetails');

  const customId = userdetails && JSON.parse(userdetails).id;

  const notify = (val) => {
    toast.error(val, {
      toastId: customId
    });
  }

  setTimeout(() => {

    sortable_click();


  }, 1000);

  function validate_images_count() {
    //Validation to restrict more than 10 images.
    var floor_image_1 = $("[id^=edit-field-content-images-]").val().split(";");
    var floor_image_2 = $("[id^=edit-field-list-of-banners-]").val().split(";");
    var tot_floor_images = floor_image_1.length + floor_image_2.length;
    var error = t("Content count should not exceed 10.");
    if (floor_image_2 === '' || floor_image_1 === '') {
      if (tot_floor_images > 10) {
        notify(error);
        return false;
      } else {
        return true;
      }
    } else {
      if (tot_floor_images >= 10) {
        //notify(error);
        return false;
      } else {
        return true;
      }
    }
  }

  function sortable_click() {


    if (document.getElementById("FloorInfo_Dragimage")) {
      Sortable.create(document.getElementById("FloorInfo_Dragimage"), {
        group: {
          name: "page",
          put: function (to, from) {
            // Restrict total count of images to 10.
            return validate_images_count();
          }
        },
        onAdd: function (evt) {
          count = $("#image-count").text();
          if (count == null || count === '' || isNaN(count)) {
            count = 0;
          }
          img_count = parseInt(count);
          var temp = img_count;
          img_count = img_count + 1;

          var floor_image_1 = [];
          var floor_image_2 = [];
          if ($("[id^=edit-field-content-images-]").val().indexOf(";") !== -1) {
            floor_image_1 = $("[id^=edit-field-content-images-]").val().split(";");
          }
          if ($("[id^=edit-field-list-of-banners-]").val().indexOf(";") !== -1) {

            floor_image_2 = $("[id^=edit-field-list-of-banners-]").val().split(";");
          }

          var tot_floor_images = floor_image_1.length + floor_image_2.length;
          if (floor_image_2 === '' || floor_image_1 === '') {
            if (tot_floor_images > 10) {

              $('#FloorInfo_Dragimage').children().last().remove();
              notify(t("Content count should not exceed 10."));
              return false;
            }
          } else {
            if (tot_floor_images >= 10) {

              $('#FloorInfo_Dragimage').children().last().remove();
              notify(t("Content count should not exceed 10."));
              return false;
            }
          }
          $("#image-count").text(img_count);
          var el = evt.item;
          $(el).addClass("element" + img_count);
          $(el).addClass("order_" + img_count);
          var bigUrl = evt.item.currentSrc;
          $(".screenContainer-screenBuilder .top .drop .order_" + img_count).attr('src', bigUrl);
          if ($(el).hasClass("banner_limit")) {
            $(".screenContainer-screenBuilder .top .drop .order_" + img_count).attr('src', el.dataset.url)
          }
          else {
            $(".screenContainer-screenBuilder .top .drop .order_" + img_count).attr('src', bigUrl);
          }
          var fid = evt.item.dataset.fid;
          if (typeof evt.item.dataset.feedurl !== typeof undefined && evt.item.dataset.feedurl !== false) {
            var filename = evt.item.dataset.feedurl;
          }
          else if (typeof evt.item.dataset.vfname !== typeof undefined && evt.item.dataset.vfname !== false && evt.item.dataset.vfname != '') {
            var filename = evt.item.dataset.vfname;
          } else {
            var filename = evt.item.dataset.filename;
          }
          var url = evt.item.dataset.url;
          var base_url = JSON.parse($("#azure_blob").val()).blob_url + JSON.parse($("#azure_blob").val()).blob_container + "/";
          var urlstring = url.includes(base_url);
          if (urlstring === true) {
            var url = url.replace(base_url, '');
            var url = url.replace(/\?.*/, '');
          } else {
            var url = evt.item.dataset.url;
          }
          var durtime = 5;
          var existingval = $(".field-name-field-list-of-content .form-textarea-wrapper textarea").val();
          if (existingval)
            $(".field-name-field-list-of-content .form-textarea-wrapper textarea").val(existingval + ";" + filename + ":" + fid + ":" + img_count + ":" + durtime + ":" + url);
          else
            $(".field-name-field-list-of-content .form-textarea-wrapper textarea").val(filename + ":" + fid + ":" + img_count + ":" + durtime + ":" + url);
        },
      });
    }
    if (document.getElementById("FloorInfo_Dragimage2")) {

      Sortable.create(document.getElementById("FloorInfo_Dragimage2"), {

        group: {
          name: "page",
          put: function (to, from) {
            // Restrict total count of images to 10.
            return validate_images_count();
          }
        },
        onAdd: function (evt) {
          count_banner = $("#image-count-banner").text();

          if (count_banner == null || count_banner === '' || isNaN(count_banner)) {
            count_banner = 0;
          }
          count_banner = parseInt(count_banner);
          var temp = count_banner;
          count_banner = count_banner + 1;
          evt.preventDefault();
          var floor_image_1 = [];
          var floor_image_2 = [];
          if ($("[id^=edit-field-content-images-]").val().indexOf(";") !== -1) {
            floor_image_1 = $("[id^=edit-field-content-images-]").val().split(";");
          }
          if ($("[id^=edit-field-list-of-banners-]").val().indexOf(";") !== -1) {

            floor_image_2 = $("[id^=edit-field-list-of-banners-]").val().split(";");
          }
          var tot_floor_images = floor_image_1.length + floor_image_2.length;
          if (floor_image_1 == '' || floor_image_2 == '') {
            if (tot_floor_images > 10) {

              $('#FloorInfo_Dragimage2').children().last().remove();
              notify(t("Content count should not exceed 10."));
              return false;
            }
          } else {
            if (tot_floor_images >= 10) {

              $('#FloorInfo_Dragimage2').children().last().remove();
              notify(t("Content count should not exceed 10."));
              return false;
            }
          }
          var el = evt.item;
          if ($(el).hasClass("banner_limit")) {
            el.parentNode.removeChild(el);
            return false;
          }
          if ($(el).hasClass("video")) {
            el.parentNode.removeChild(el);
            return false;
          }
          $("#image-count-banner").text(count_banner);
          $(el).addClass("element" + count_banner);
          $(el).addClass("order_" + count_banner);
          var bigUrl = evt.item.currentSrc;
          $(".screenContainer-screenBuilder .bottom .bottom-drop .order_" + count_banner).attr('src', bigUrl);
          var fid = evt.item.dataset.fid;
          if (typeof evt.item.dataset.feedurl !== typeof undefined && evt.item.dataset.feedurl !== false) {
            var filename = evt.item.dataset.feedurl;
          } else if (typeof evt.item.dataset.vfname !== typeof undefined && evt.item.dataset.vfname !== false && evt.item.dataset.vfname != '') {
            var filename = evt.item.dataset.vfname;
          } else {
            var filename = evt.item.dataset.filename;
          }
          var url = evt.item.dataset.url;
          var base_url = JSON.parse($("#azure_blob").val()).blob_url + JSON.parse($("#azure_blob").val()).blob_container + "/";
          var urlstring = url.includes(base_url);
          if (urlstring == true) {
            var url = url.replace(base_url, '');
            var url = url.replace(/\?.*/, '');
          } else {
            var url = evt.item.dataset.url;
          }
          var durtime = 5;
          var existingval = $(".field-name-field-list-of-banners .form-textarea-wrapper textarea").val();
          if (existingval)
            $(".field-name-field-list-of-banners .form-textarea-wrapper textarea").val(existingval + ";" + filename + ":" + fid + ":" + count_banner + ":" + durtime + ":" + url);
          else
            $(".field-name-field-list-of-banners .form-textarea-wrapper textarea").val(filename + ":" + fid + ":" + count_banner + ":" + durtime + ":" + url);

        },
      });
    }

  }
  $(".Infotainment").on("click", function () {
    $("div.top").addClass("Main");
    $("div.top").removeClass("L3Main");

    $("div.bottom").addClass("Banner");
    $("div.bottom").removeClass("L3Banner");

    $(".Banner").show();
    $(".bottom-left").show();
    $(".Main").show();

    $(".screenBuilder").toggleClass("screenBuilder infotainment-bg");
    $(".eView2 .screen-thumb-hold").removeClass("thumb-active");
    $(".eView2 .screen-thumb-hold").addClass("thumb-disable");
    $(".Infotainment .screen-thumb-hold").removeClass("thumb-disable");
    $(".Infotainment .screen-thumb-hold").addClass("thumb-active");
    $(".eView-img").addClass("hide");
    $(".infotainment-img").removeClass("hide");
    $(".Infotainment input").prop("checked", true);
  });


  $(".eView2").on("click", function () {
    // $(".playlist-layout").hide();
    $("div.top").addClass("Main");
    $("div.top").removeClass("L3Main");

    $("div.bottom").addClass("Banner");
    $("div.bottom").removeClass("L3Banner");
    $(".infotainment-bg").toggleClass("infotainment-bg screenBuilder");

    $(".Banner").show();
    $(".bottom-left").show();
    $(".Main").show();
    $(".eView-img").removeClass("hide");
    $(".infotainment-img").addClass("hide");
    $(".Infotainment .screen-thumb-hold").removeClass("thumb-active");
    $(".Infotainment .screen-thumb-hold").addClass("thumb-disable");
    $(".eView2 .screen-thumb-hold").removeClass("thumb-disable");
    $(".eView2 .screen-thumb-hold").addClass("thumb-active");
    $(".eView2 input").prop("checked", true);
  });

  // Playlist Image Durations modal window save button click function
  $("#FolderOK_1").on("click", function () {
    var stop = 0;
    var data = $("[id^=edit-field-list-of-banners-]").val();
    $("[id^=edit-field-list-of-banners-]").val("");
    var del_ids = $("#banner_hidden").val().split(",");
    del_ids.forEach(function (entry) {
      if (entry !== "" && entry !== null) {
        $(".Banner ." + entry).remove();
        if (parseInt($("#image-count-banner").text()) !== 1) {
          $("#image-count-banner").text(parseInt($("#image-count-banner").text()) - 1);
        } else {
          $("#image-count-banner").empty();
        }
      }

      //If all images removed from banner and on click on save this condition will trigger
      if ($("#image-count-banner").text().trim() === "0" || isNaN($("#image-count-banner").text()) || $("#image-count-banner").text().trim() === "") {
        $('#FloorInfo_Dragimage2 img').remove();

      }
      if (!$("#image-count-banner").text().trim()) {

        $('#FloorInfo_Dragimage2 img').remove();
      }
    });


    $(".imagesrc").each(function (index, element) {
      var bandurtime = $(".bannerdurtime").eq(index).val();
      var vid = $(".bannerdurtime").eq(index).parents('tr.drag-able').attr('id');
      if (vid === 'notvideo' && (bandurtime < 1 || bandurtime > 30)) {
        stop = 1;
        notify(t("Enter a duration between 1-30 seconds"));
      }
      if (stop === 0) {
        var x = $(element).html();
        x = x.replace(/:/g, ",");
        x = x.replace(/;/g, "!");
        var num = index + 1;
        var y = $("[id^=edit-field-list-of-banners-]").val() + x + ":" + $(".imagefid").eq(index).text() + ":" + num + ":" + bandurtime + ":" + $(".bannerimageurl").eq(index).text() + ";";
        if ($(".imagesrc").length === index + 1)
          y = y.slice(0, -1);
        $("[id^=edit-field-list-of-banners-]").val(y);
      } else {
        $("[id^=edit-field-list-of-banners-]").val(data);
      }

    });
    if (stop === 0) {
      var floor_image_1 = $("[id^=edit-field-content-images-]").val().split(";");
      var floor_image_2 = $("[id^=edit-field-list-of-banners-]").val().split(";");
      if (floor_image_1 === '') {
        floor_image_1.length = 0;
      }
      if (floor_image_2 === '') {
        floor_image_2.length = 0
      }
      var tot_floor_images = floor_image_1.length + floor_image_2.length;
      if (floor_image_1 === '' || floor_image_2 === '') {
        if (tot_floor_images < 10) {
          $(".page-layout").removeAttr("ondragstart", "return false;");
        }
      } else {
        if (tot_floor_images <= 10) {
          $(".page-layout").removeAttr("ondragstart", "return false;");
        }
      }
      if ($("[id^=edit-field-list-of-banners-]").val().length > 0) {
        $("#FloorInfo_Dragimage2").hide();
        $(".dimOverlayForBlock").show();
        setTimeout(function () {
          $(".dimOverlayForBlock").hide();
          $("#FloorInfo_Dragimage2").show();
        }, 3000);
        $('#FloorInfo_Dragimage2').empty();
        var B_j = $("[id^=edit-field-list-of-banners-]").val().split(";");
        B_j.forEach(function (entry) {
          var B_i = entry.split(":");
          var type = getimagetype(B_i[0]);
          if (type === "undefined") {
            var datatypename = "data-feedurl=" + B_i[0];
          } else if (type === 'mp4') {
            var datatypename = "data-vfname=" + B_i[0];
            var videoclass = "video";
          } else {
            var datatypename = "";
          }
          var base_url = JSON.parse($("#azure_blob").val()).blob_url + JSON.parse($("#azure_blob").val()).blob_container + "/";
          $("#FloorInfo_Dragimage2").append('<img ' + datatypename + ' class="image-grab pep ' + videoclass + ' element' + B_i[2] + ' order_' + B_i[2] + '" data-fid="' + B_i[1] + '" data-url="' + base_url + B_i[4] + JSON.parse($("#azure_blob").val()).token + '" data-filename="' + B_i[0] + '" typeof="foaf:Image" src="' + base_url + B_i[4] + JSON.parse($("#azure_blob").val()).token + '" style="" draggable="false">');
        });
      }
      $("#TermsModal_1").removeAttr("style");
    }
  });



  $("#FolderOK_2").on("click", function (evt) {
    var stop = 0;
    var data = $("[id^=edit-field-content-images-]").val();
    $("[id^=edit-field-content-images-]").val("");
    var del_ids = $("#content_hidden").val().split(",");
    del_ids.forEach(function (entry) {
      if (entry !== "" && entry !== null) {
        $(".Main ." + entry).remove();
        if (parseInt($("#image-count").text) !== 1) {
          $("#image-count").text(parseInt($("#image-count").text()) - 1);
        } else {
          $("#image-count").empty();
        }
      }
      //If all images removed from content area and on click on save this condition will trigger
      if ($("#image-count").text().trim() === "0" || isNaN($("#image-count").text()) || $("#image-count").text().trim() === '') {
        $('#FloorInfo_Dragimage img').remove();
      }
    });
    $(".imagesrc_1").each(function (index, element, e) {

      var durtime = $(".durtime").eq(index).val();
      var vid = $(".durtime").eq(index).parents('tr.drag-able').attr('id');
      if (vid === 'notbvideo' && (durtime < 1 || durtime > 30)) {
        stop = 1;
        notify(t("Enter a duration between 1-30 seconds"));
      }
      if (stop === 0) {
        var x_1 = $(element).html();
        x_1 = x_1.replace(/:/g, ",");
        x_1 = x_1.replace(/;/g, "!");


        var num = index + 1;
        var y_1 = $("[id^=edit-field-content-images-]").val() + x_1 + ":" + $(".imagefid_1").eq(index).text() + ":" + num + ":" + durtime + ":" + $(".imageurl").eq(index).text() + ";";
        if ($(".imagesrc_1").length === index + 1)
          y_1 = y_1.slice(0, -1);
        $("[id^=edit-field-content-images-]").val(y_1);
      } else {
        $("[id^=edit-field-content-images-]").val(data);
      }
    });

    if (stop === 0) {
      var floor_image_1 = $("[id^=edit-field-content-images-]").val().split(";");
      var floor_image_2 = $("[id^=edit-field-list-of-banners-]").val().split(";");
      if (floor_image_1 === '') {
        floor_image_1.length = 0;
      }
      if (floor_image_2 === '') {
        floor_image_2.length = 0
      }
      var tot_floor_images = floor_image_1.length + floor_image_2.length;
      if (floor_image_1 === '' || floor_image_2 === '') {
        if (tot_floor_images < 10) {
          $(".page-layout").removeAttr("ondragstart", "return false;");
        }
      } else {
        if (tot_floor_images <= 10) {
          $(".page-layout").removeAttr("ondragstart", "return false;");
        }
      }
      if ($("[id^=edit-field-content-images-]").val().length > 0) {
        $("#FloorInfo_Dragimage").hide();
        $(".dimOverlayForBlock").show();
        setTimeout(function () {
          $(".dimOverlayForBlock").hide();
          $("#FloorInfo_Dragimage").show();
        }, 3000);
        $('#FloorInfo_Dragimage').empty();
        var j = $("[id^=edit-field-content-images-]").val().split(";");
        j.forEach(function (entry) {
          var i = entry.split(":");
          var type = getimagetype(i[0]);
          if (type === "undefined") {
            var datatypename = "data-feedurl=" + i[0];
          } else if (type === 'mp4') {
            var datatypename = "data-vfname=" + i[0];
            var videoclass = "video";
          } else {
            var datatypename = "";
          }
          var base_url = JSON.parse($("#azure_blob").val()).blob_url + JSON.parse($("#azure_blob").val()).blob_container + "/";
          $("#FloorInfo_Dragimage").append('<img ' + datatypename + ' class="image-grab pep ' + videoclass + ' element' + i[2] + ' order_' + i[2] + '" data-fid="' + i[1] + '" data-url="' + base_url + i[4] + JSON.parse($("#azure_blob").val()).token + '" data-filename="' + i[0] + '" typeof="foaf:Image" src="' + base_url + i[4] + JSON.parse($("#azure_blob").val()).token + '" style="" draggable="false">');
        });
      }
    }
    if (stop === 0) {
      $("#TermsModal_2").removeAttr("style");
    }
  });


  $("#TermsCancel_1").on("click", function () {

    $("#TermsModal_1").removeAttr("style");
  });
  $("#TermsCancel_2").on("click", function () {

    $("#TermsModal_2").removeAttr("style");
  });

  function getimagetype(file) {
    var extension = file.substr((file.lastIndexOf('.') + 1));
    switch (extension) {
      case 'jpg':
      case 'png':
      case 'PNG':
      case 'gif':
      case 'jfif':
        return extension;
        break;
      case 'mp4':
        return extension;
        break;
      default:
        return 'undefined';
    }
  }

 
  //Getting lenght of images playlist

  //Getting lent of user message playlist
  var user_lib_leng = $("#views-form-media-library-block-6 ul li .img-content-block").length;
  var live_content_leng = $(".view-live-content .img-content-block").length;
  var byId = function (id) {
    return document.getElementById(id);
  }
  var img_count;
  if ($("body").hasClass("page-node-edit") === true) {
    var count = 0;
    //Empty check for content area.
    if ($("[id^=edit-field-content-images-]").val() !== "") {
      var count = $("[id^=edit-field-content-images-]").val().split(";").length;
      if (count > 0) {
        $("#image-count").text(count);
      }
    }
    var count_banner = 0;
    if ($("[id^=edit-field-list-of-banners-]").val() !== "") {
      var count_banner = $("[id^=edit-field-list-of-banners-]").val().split(";").length;
      if (count_banner > 0) {
        $("#image-count-banner").text(count_banner);
      }
    }

  }
  var checkBoxClasses = [];
 



  if ($("body").hasClass("page-node-edit") !== true) {
    if ($('#FloorInfo_Dragimage img').length === 0) {
      $('[id^=edit-field-content-images-]').val("");
    }
    if ($('#FloorInfo_Dragimage2 img').length == 0) {
      $('[id^=edit-field-list-of-banners-]').val("");
    }
  }


  var banner_text = $("#field-banner-text-add-more-wrapper .form-textarea").val();
  $(".banner-textarea").val(banner_text);



});
