import React from 'react';
import { Modal } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { T } from '@transifex/react';
import { t } from "@transifex/native";
import { ToastContainer, toast } from "react-toastify";
import axios from 'axios';
import config from '../../utils/config';
import 'react-toastify/dist/ReactToastify.css';
import LoadingOverlay from "react-loading-overlay";

const ManageGroups = (props) => {
	const [managegroups, setManagegroups] = useState([]);
	const [groupInput, setgroupInput] = useState('');
	const [screenmanageChecked, setScreenmanageChecked] = useState([]);
	const [popupHdng, setPopupHdng] = useState('');
	const {closePopup} = props;
	const {setRefreshKey} = props;
	const {setLoading} = props;	

	const INITIAL_ERROR_MSGS = {
		group_title: false,
		group_title_count:false,
		screens_selected: false
	};
	const [errorMsgs, setErrorMsgs] = useState(INITIAL_ERROR_MSGS);

	// Get logged in user details
	let userDetails = localStorage.getItem("userdetails");
	let loggedInUserID = '';
	if (userDetails === null) {
		loggedInUserID = 1;
	} else {
		loggedInUserID = JSON.parse(userDetails).id;
	}

	useEffect(() => {
		const getManagegroup = async () => {
			axios.get(config.drupal_url + '/api/group_detail?user_id=' + loggedInUserID + '&group_id=' + props.groupdata, {withCredentials: true})
				.then(response => {		
					setLoading(false);		
					setgroupInput(response.data[0].title);
					setManagegroups(response.data);
					let checkedgroup = [...screenmanageChecked]; 
					{response.data.map((group, index) => {
						{group?.group_devices.map((group_device,index) => {
							checkedgroup.push(group_device.device_id);
						})}

					})}
					setScreenmanageChecked(checkedgroup);	
					let str = t("Edit @type @title");
					var mapObj = { "@type": t("Group"), "@title": response.data[0].title};
					str = str.replace(/@type|@title/gi, function (matched) {
						return mapObj[matched];
					});	
					setPopupHdng(str);
				})
				.catch(error => console.error('API Error:' + error));
		}
		getManagegroup();
	}, []);

	const handleChange = (event) => {
		setgroupInput(event.target.value);
	}
	
	const handleCheckedscreen = (screenid, event) => {
		const screenChecked = [...screenmanageChecked];
		if (screenChecked.includes(event.target.id)) {
			var index = screenChecked.indexOf(event.target.id);
			screenChecked.splice(index, 1);
		} else {
			screenChecked.push(event.target.id);
		}
		setScreenmanageChecked(screenChecked);

	};

	const UpdateGroup = async (event) => {
		event.preventDefault();
		setErrorMsgs({
			group_title: !groupInput || groupInput.trim() == "" ? true : false,
			screens_selected: !screenmanageChecked || screenmanageChecked.length == 0 ? true : false,
			group_title_count: (groupInput.trim().length > 25)? true : false
		});
		if (groupInput.trim() != '' && screenmanageChecked.length > 0 && groupInput.trim().length < 25) { 
    		setLoading(true);
			const groupvalue = {
				"user_id": loggedInUserID,
				"title": groupInput.trim(),
				"node_id": props.groupdata,
				"devices": screenmanageChecked
			}
			axios.post(config.drupal_url + '/UpdateGroup?_format=json', groupvalue, {withCredentials: true})
				.then((response) => {											    
					setLoading(false);
					if (response.data.Sucess && response.data.Sucess == 'GroupUpdated') {
						var str = t("@type %title has been updated.");
						var mapObj = { "@type": t("Group"), "%title": groupInput };
						str = str.replace(/@type|%title/gi, function (matched) {
							return mapObj[matched];
						});						
						toast.success(str);
						closePopup();
            			setRefreshKey(oldKey => oldKey +1);
					} else if (response.data.Error && response.data.Error == 'AlreadyExists') {         
						alert(t('Group name already exists. Please use a different name.'));
						return false;
					}
				})
				.catch(function (error) {
					console.error('There was an error!', error);
				})
		}
	}

	return (
		<>
			<div className="groups-popup-container">
				{managegroups.map((group, index) => {
					return (
						<>
						 <div className="popup-title">
							<h3>{popupHdng}</h3>
								<Modal.Header closeButton></Modal.Header>
							</div>
							<div className="popup-content">
							{(errorMsgs.group_title || errorMsgs.screens_selected || errorMsgs.group_title_count)  && (
								<ul className='alert alert-danger alert-grp-popup' role="alert">
									{errorMsgs.group_title && (
									<li>
									<span style={{ color: "red" }}>
									{t('!name field is required.').replace('!name', t('Name'))}
									</span>
									</li>
								)}
								{errorMsgs.screens_selected && (
									<li>
									<span style={{ color: "red" }}>
									{t('!name field is required.').replace('!name', t('Select one or more screen for the new group'))}
									</span>
									</li>
								)}

								{errorMsgs.group_title_count && (
									<li>
									<span style={{ color: "red" }}>
									{t('Maximum @count characters are allowed.').replace('@count', 25)}
									</span>
									</li>
								)}
								</ul>
							)}
								<form className="popup-form" action="/" method="post" id="popup-form" accept-charset="UTF-8">
									<div className="form-item-title">
										<label className="control-label" for="edit-title-field"><T _str="Name" /> <span className="form-required">*</span></label>
										<input className="form-text required" onChange={handleChange} type="text" name="name" defaultValue={group.title} size="25" maxlength="25" />
									</div>
									<label className="control-label" for="edit-field-select-device">
										<span className="form-required-text"><T _str="Select one or more screen for the new group" /> *</span>
									</label>
									<div className="pop-up-items">
										{group.group_devices?.map((group_device, index) => {
											return (
												<>
													<div className="form-item checkbox group-item">
														<label className="control-label" for="edit-field-select-device">
															<input type="checkbox" onChange={(event) => handleCheckedscreen(index, event)} checked={screenmanageChecked.includes(group_device.device_id) ? 1 : 0} name="field_select_device" value={group_device.device_id} id={group_device.device_id} className="form-checkbox" />
															<div className="views-field views-field-title">{group_device.device_title}</div>
															<div className="views-field views-field-field-mpdid">
																<span className="label-field-mpdid">CP-ID:</span>
																<span className="field-content">{group_device.cp_id}</span>
															</div>
															<div className="img">
																<img typeof="Image" className="img-responsive" src={group_device.url} width="100" height="56" alt="" />
															</div>
														</label>
													</div>
												</>
											)
										})}
										{group.assigned_devices?.map((assigned_device, index) => {
											return (
												<>
													<div className="form-item checkbox group-item">
														<label className="control-label" for="edit-field-select-device">
															<input type="checkbox" onChange={(event) => handleCheckedscreen(index, event)} checked={screenmanageChecked.includes(assigned_device.device_id) ? 1 : 0} name="field_select_device" value={assigned_device.device_id} id ={assigned_device.device_id} className="form-checkbox" /> 
															<div className="views-field views-field-title">{assigned_device.device_title}</div>
															<div className="views-field views-field-field-mpdid">
																<span className="label-field-mpdid">CP-ID:</span>
																<span className="field-content">{assigned_device.cp_id}</span>
															</div>
															<div className="img">
																<img typeof="Image" className="img-responsive" src={assigned_device.url} width="100" height="56" alt="" />
															</div>
														</label>
													</div>
												</>
											)

										})}
									</div>            
								</form>
							</div>
							<div class="modal-footer">
								<div className="form-actions form-wrapper form-group" id="edit-actions">
									<button type="submit" onClick={(event) => UpdateGroup(event)} id="edit-submit" name="op" value="Save" className="btn btn-save"><T _str="Save" /></button>
									<button type="submit" onClick={(event) => [props.closeParent(event)]} id="edit-delete" name="op" value="Remove" className="btn btn-remove"><T _str="Delete"/></button>
								</div>
							</div>
						</>
					)
				})}
			</div>
		</>
	);
};

export default ManageGroups;