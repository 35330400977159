import React, { useCallback, useEffect, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import axios from "axios";
import config from "../../utils/config";


const DevicesCount = ({ uid }) => {
  const [Devicecount, setDevicecount] = useState([]);

  const getDeviceCount = useCallback(() => {
    axios
      .get(config.drupal_url + `/api/getdevicecount?uid=${uid}`, {
        withCredentials: true,
      })
      .then((response) => {
        const devicecount = response.data.device_count;
        setDevicecount(devicecount);
      })
      .catch((e) => {});
  });

  useEffect(() => {
    getDeviceCount();
  }, [uid]);

  return (
    <>
    <td className="views-field field_device_count">

       {Devicecount}
    </td>

    </>
  );
};
export default DevicesCount;
