/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: Register.js
File Description: Implements reset user functionality of CMS portal

Copyright 2023 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../utils/config";
import "../../css/reset.css";
import { Modal } from 'react-bootstrap';
import LoadingOverlay from "react-loading-overlay";
import { t, tx } from "@transifex/native";
import { T, useT } from '@transifex/react';
import { useParams, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import $ from "jquery";
import { Buffer } from 'buffer';

const Reset = () => {
  tx.init({
		token: "1/b764923cd80f817a0b810d8688e7c8f4dc7c75eb",
	});

	const user_language = navigator.language || navigator.userLanguage;
	const language_code = user_language.split("-");
	const user_lang = language_code[0];
	tx.setCurrentLocale(user_lang);

  const { uid } = useParams();
  const [loading, setLoading] = useState(false);
  const [userEmail, setUserEmail] = useState(""); 
  const [verifycode, setVerifycode] = useState(false);
  const [changeemail, setChangeemail] = useState(false);
  const [userOtp, setUserOtp] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [resetpass, setResetpass] = useState(false);
  const [emailerror, setEmailError] = useState(null);
  const [verifyerror, setVerifyerror] = useState(null);
  const [passerror, setPasserror] = useState(null);
  const [show, setShow] = useState(false);
  const [disable, setDisable] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const pwd = useT("New Password");

  function sendOtp() {
    if (isValidEmail(userEmail)) { 
		setDisable(true);
		setEmailError(null); 
	      axios.get(config.drupal_url + '/session/token').then(result => {
		    if (200 === result.status) {
					const csrfToken = result.data;
					const sendotpUrl = config.drupal_url + "/rest_pass?_format=json";
					const headers = {
					'Content-Type': 'application/json',
					'X-CSRF-Token': csrfToken
					}
					const data = {
					   "mail":userEmail
					  };
					let otpencodedString = Buffer.from(JSON.stringify(data)).toString('base64');
					const salt1 = "H3FilhsRp25VQNOi==";
					const salt2 = "Aj8758XJHRvbrUeJ==";
					let encryptedString = { "data": salt1 + otpencodedString + salt2 };

				   axios.post(sendotpUrl, encryptedString, {headers}) .then(response => {
						if(response.status === 200) {  
							if(response.data.message == "An email notification OTP has been sent to user"){
							setVerifycode(true);
							setVerifyerror("");
							setDisable(false);
							}else{
								setEmailError(t("User with this email does not exist!"));
								setDisable(false);
							}
						}
			       })
			}
		 })
			
	} else {
      setEmailError(t("Please enter a valid email address."));
    }
	
  }
	
function isValidEmail(userEmail) {
	const regex = /^[a-zA-Z0-9!#$%&'+^_`{}~-]+(?:\.[a-zA-Z0-9!#$%&'+^_`{}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/i;
    return regex.test(userEmail);
    
 }
  const changePassword = (e) => {
	e.preventDefault();
	setLoading(true);
    if (!password) {
		setLoading(false);
		setPasserror(t("A required field is missing. Please fill out all required fields and try again."));
		} 
	else if(password != confirmpassword) {
		setLoading(false);
		setPasserror(t("The password entry fields do not match. Please enter the same password in both fields and try again."));
	}else{
		  axios.get(config.drupal_url + '/session/token').then(result => {
		    if (200 === result.status) {
				const csrfToken = result.data;
				const newpassUrl = config.drupal_url + "/newpass?_format=json";
				const headers = {
				'Content-Type': 'application/json',
				'X-CSRF-Token': csrfToken
				}
				const data = {
				   "mail":userEmail,
				   "otp":userOtp,
				   "pass":password
				};
			    
				let otpencodedString = Buffer.from(JSON.stringify(data)).toString('base64');
				const salt1 = "H3FilhsRp25VQNOi==";
				const salt2 = "Aj8758XJHRvbrUeJ==";
			    let encryptedString = { "data": salt1 + otpencodedString + salt2 };

				axios.post(newpassUrl, encryptedString, {headers}) .then(response => {
					if(response.status === 200) {
						setLoading(false);     
						window.history.back();
					}
				}) 
			}
		  })	
				
	  }
  }
  
  function verifyOTP() {
	
	  if (userOtp) {
		setVerifyerror(null);
		setLoading(true);
         axios.get(config.drupal_url + '/session/token').then(result => {
			if (200 === result.status) {
				const csrfToken = result.data;		    
				const verifyotpUrl = config.drupal_url + "/verify_code?_format=json";
				const headers = {
				'Content-Type': 'application/json',
				'X-CSRF-Token': csrfToken
				}
				const data = {
				   "mail":userEmail,
				   "otp":userOtp
				};
                
				let otpencodedString = Buffer.from(JSON.stringify(data)).toString('base64');
				const salt1 = "H3FilhsRp25VQNOi==";
				const salt2 = "Aj8758XJHRvbrUeJ==";
			    let encryptedString = { "data": salt1 + otpencodedString + salt2 };
				
				axios.post(verifyotpUrl, encryptedString, {headers}) .then(response => {
					if(response.status === 200) {
					console.log(response.data.message);
						if(response.data.message == "OTP verfication success."){
							setLoading(false); 
							setVerifycode(false);				
							setChangeemail(true);
						}
						else if(response.data.message == "That code is incorrect. Please try again."){
							setVerifyerror(t("That code is incorrect. Please try again."));
							setLoading(false); 
						}
						else if(response.data.message == "That code is expired. Please request a new code."){
							setVerifyerror(t("That code is expired. Please request a new code."));
							setLoading(false); 
						}
						else if(response.data.message == "You've made too many incorrect attempts. Please try again later."){
							setVerifyerror(t("You've made too many incorrect attempts. Please try again later."));
							setLoading(false); 
						}
					}
				})
			}
		 })
		
	  }else{
		  setVerifyerror(t("That code is incorrect. Please try again."));
		  setLoading(false); 
	  }
  }
  const cancel = (e) => {
	  e.preventDefault();
      window.location.href = "/";
  }
  
  function mailchange(){
	  setUserEmail("");
	  setVerifycode(false);
	  setChangeemail(false);
  }
  const handleChange = (e) => {
	var pass_valstr = t("The password must be between 8 and 64 characters.Your password must have at least 3 of the following");
	    pass_valstr += ':<ul><li>- '+t("a lowercase letter")+'</li>';
		pass_valstr += '<li>- '+t("an uppercase letter")+'</li>';
		pass_valstr += '<li>- '+t("a number")+'</li>';
		pass_valstr += '<li>- '+t("a symbol")+'</li></ul>';

    $("#password_req").html(pass_valstr);
    setPassword(e.target.value);
	const regex_pass = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,64}$/;
		 if (regex_pass.test(e.target.value)) {
			$("#password_req").html("")
            return true;
		}

  };

  const handleChangeconfirm = (e) => {
	var confirmpass_valstr = t("The password must be between 8 and 64 characters.Your password must have at least 3 of the following");
	    confirmpass_valstr += ':<ul><li>- '+t("a lowercase letter")+'</li>';
		confirmpass_valstr += '<li>- '+t("an uppercase letter")+'</li>';
		confirmpass_valstr += '<li>- '+t("a number")+'</li>';
		confirmpass_valstr += '<li>- '+t("a symbol")+'</li></ul>';
    $("#cnpassword").html(confirmpass_valstr);
    setConfirmpassword(e.target.value);
	const regex_pass = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,64}$/;
		 if (regex_pass.test(e.target.value)) {
			$("#cnpassword").html("")
            return true;
		}

  };
  
  const sendpassword = (e) => {
	    setResetpass(true);
		setChangeemail(false);
		setVerifycode(false);
	    e.preventDefault();     
	
  };
  return (
    <>
	<LoadingOverlay active={loading} spinner text={t("Please wait while we processing the infromation.")}>
    <ToastContainer />
	<section class="password-reset">
		<header>
		<div class="top-heading"><T _str="eView® Content Configurator" /></div>
		<h1><T _str="Reset your password" /></h1>
		</header>
		  <div id="api" data-name="SelfAsserted">
			<div class="intro">
			  <p>{t("Please provide the following details.")}</p>
			</div>
			{!verifycode && !changeemail && !resetpass &&(
			<div class="verificationInfoText" id="email_intro">{t("Verification is necessary. Please click Send button.")}</div>)}
			{verifycode && (
			<div class="verificationInfoText" id="email_intro">{t("Verification code has been sent to your inbox. Please copy it to the input box below.")}</div>)}
		    {changeemail && (
			<div class="verificationInfoText" id="email_intro">{t("E-mail address verified. You can now continue.")}</div>)}
			<div class="verificationErrorText error" id="email_fail_retry" aria-hidden="false" role="alert" aria-live="polite">{verifyerror}</div>
			<div class="error pageLevel" id="passwordEntryMismatch" aria-hidden="false" role="alert" aria-live="polite" tabindex="1">{passerror}</div>
			<div id="attributeVerification" role="form">
			  <div class="attr" id="attributeList">
					<form class="attrEntry" encType="#" method="post" noValidate autoComplete="off" >
					  {!resetpass && (<div>
					  <label for="email"><T _str="Email Address" /></label>
					  <div class="error itemLevel" aria-hidden="true"><p>{emailerror}</p></div>
					  <input id="email" class="textInput" type="text" placeholder={t("Email Address")} value={userEmail} onChange={(e) => { setUserEmail(e.target.value) }} title="Email address that can be used to contact you." aria-required="true" aria-label="Please enter a valid email address., Email Address" />
					  <a href="#" data-target="#reset-email" onClick={handleShow} data-help={t("Email address that can be used to contact you.")} class="helpLink tiny">{t("What is this?")}</a> 
					   <Modal id="reset-email" show={show} onHide={handleClose} className="email-notif">
                         <div><T _str="Email address that can be used to contact you." /></div>
						 <div><button onClick={handleClose}><T _str="OK" /></button></div>
                      </Modal>
					  </div>)}
					  {verifycode && (<div><input id="email_ver_input" class="verifyInput" type="text" placeholder={t("Verification code")} value={userOtp} onChange={(e) => { setUserOtp(e.target.value) }} />
					  <div class="buttons verify">
						<div id="email_success_screenreader_msg" aria-label="E-mail address verified. You can now continue."></div>
						<button id="email_ver_but_verify" class="verifyButton" type="button" aria-label="Verify code" aria-hidden="false" onClick={() => verifyOTP()}>{t("Verify code")}</button>
						<button id="email_ver_but_resend" class="sendButton" type="button" disabled={disable} aria-label="Send new code" aria-hidden="false" onClick={() => sendOtp()}>{t("Send new code")}</button>
						 </div>
					  </div>
					  )}
					  {resetpass && (<div>
					  <div id="password_req"> </div>
					  <input id="change_password" class="changePassword" value={password}  onChange={handleChange} onBlur={handleChange} type="password" placeholder={pwd} />
					  <div id="cnpassword"> </div>
					  <input id="re_enter_password" class="reEnterPassword" value={confirmpassword} onChange={handleChangeconfirm} type="password" placeholder={t("Confirm New Password")} /></div>)}
					
					 {changeemail && !resetpass &&(
					 <div>
					  <button id="email_ver_but_edit" class="editButton" type="button" onClick={() => mailchange()}aria-label="Change e-mail" aria-hidden="false">{t("Change e-mail")}</button>
					 </div>
					  )}
					 
					 {!changeemail && !verifycode && !resetpass &&(<button id="email_ver_but_send" class="sendButton" type="button" disabled={disable} aria-label="Send verification code" aria-hidden="false" onClick={() => sendOtp()}>{t("Send verification code")}</button>)}
					<div class="buttons">
						{!changeemail && !resetpass &&(
						 <button id="continue" disabled="true" aria-label="Continue">{t("Continue")}</button>
						 )} 
						 {changeemail &&(
						  <button id="continue" aria-label="Continue"  onClick={(e) => sendpassword(e)}>{t("Continue")}</button>
						 )}
						 {resetpass &&(
						  <button id="continue" aria-label="Continue"  onClick={(e) => changePassword(e)}>{t("Continue")}</button>
						 )}
					    <button id="cancel" aria-label="Cancel" formnovalidate onClick={cancel}>{t("Cancel")}</button>
					 </div>
					</form>
			  </div>
			</div>
	  </div>
	</section>
	</LoadingOverlay>
    </>
  );
};
export default Reset;