/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: multiDeviceConfig.js
File Description: Configure multiple devices

Copyright 2022 - 2024 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
import { t } from "@transifex/native";
import { T } from "@transifex/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import { useHistory, useLocation } from "react-router-dom";
import device from "../css/device.module.css";
import "../css/OfflineDevices.css"
import config from "../utils/config";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import InputColor from 'react-input-color';

const MultiDeviceConfig = () => {
  const INFOTAINMENT_MSG_COUNT = 75;
  const location = useLocation();
  const history = useHistory();
  const selectedDevices = [];
  const selectedDeviceTypes = [];

  const [multidevicedata, setMultiDeviceData] = useState({
    live_feed_refresh: "",
    background_music: "",
    infotainment_message: "",
    deviceTheme: "",
    banner_bg_color: "",
    banner_text_color: "",
  });
  const [bgColor, setBgColor] = useState({});
  const [textColor, setTextColor] = useState({});
  const [initialBgColor, setInitialBgColor] = useState('#ffffff');
  const [initialTextColor, setInitialTextColor] = useState('#06599b');
  const [volume, setValume] = useState(50);
  const [offlineDevices, setofflineDevices] = useState([]);
  const [themesList, setThemesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alertMsg, setAlert] = useState({
    show: false,
    variant: "success",
    message: "",
  });
  if (!location.state) {
    window.location = '/';
  }
  location.state.forEach((element) => {
    selectedDevices.push(...element.screens);
    element.deviceTypes.forEach((deviceType) => {
      selectedDeviceTypes.push(deviceType);
    });
  });

  const isAllInfotainment = selectedDeviceTypes.every(
    (element) => element === "Infotainment"
  );
  const isAlleView = selectedDeviceTypes.every(
    (element) => element === "eView2"
  );
  const isAllFullcontent = selectedDeviceTypes.every(
    (element) => element === "Fullcontent"
  ); 

  var userDetails = localStorage.getItem("userdetails");
  var isCountryAdmin =
    userDetails && JSON.parse(userDetails).roles.includes("country_admin");

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        config.drupal_url +
        "/api/multiconfig/deviceconfiguration?ids=" +
        selectedDevices.join(","),
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        const offline_devices_list = response.data.offline_devices;
        setofflineDevices(offline_devices_list);
        const themesResData = response.data.theme;
        themesResData.forEach((key) => {
          key.path = key.path.replace(/&amp;/g, "&");
        });
        setThemesList(themesResData);
        setMultiDeviceData((prevData) => ({
          ...prevData,
          banner_bg_color: response.data.banner_bg_color,
        }));
        setInitialBgColor(response.data.banner_bg_color)
        setInitialTextColor(response.data.banner_text_color);
        setMultiDeviceData((prevData) => ({
          ...prevData,
          banner_text_color: response.data.banner_text_color,
        }));
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log("Error:", e);
      });
  }, []);

  function handleBgColorChange(bgColor) {
    setBgColor(bgColor);
    setMultiDeviceData((prevData) => ({
      ...prevData,
      banner_bg_color: bgColor.hex
    }));
  }

  function handleTextColorChange(textColor) {
    setTextColor(textColor);
    setMultiDeviceData((prevData) => ({
      ...prevData,
      banner_text_color: textColor.hex
    }));
  }
  
  const handleChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    switch (e.target.id) {
      case "field_live_feed_refresh_rate":
        setMultiDeviceData((prevData) => ({
          ...prevData,
          live_feed_refresh: value,
        }));
        break;
      case "background-music":
        setMultiDeviceData((prevData) => ({
          ...prevData,
          background_music: value,
        }));
        break;
      case "banner-text-color":
        setMultiDeviceData((prevData) => ({
          ...prevData,
          banner_text_color: value
        }));
        break;
      case "banner-bg-color":
        setMultiDeviceData((prevData) => ({
          ...prevData,
          banner_bg_color: value
        }));
        break;
      case "infotainment-message":
        const lines = value.split("\n");
        for (var i = 0; i < lines.length; i++) {
          if (lines[i].length <= 15) continue;
          var j = 0;
          var space = 15;
          while (j++ <= 15) {
            if (lines[i].charAt(j) === " ") space = j;
          }
          lines[i + 1] = lines[i].substring(space + 1) + (lines[i + 1] || "");
          lines[i] = lines[i].substring(0, space);
        }
        if (value.length > INFOTAINMENT_MSG_COUNT) {
          onHandleAlert({
            alertType: "danger",
            msg: t('Maximum @count characters are allowed.').replace('@count', 75),
          });
          return;
        }
        if (lines.length > 6) {
          onHandleAlert({
            alertType: "danger",
            msg: t('Maximum @count lines are allowed.').replace('@count', 6),
          });
          return;
        }
        setMultiDeviceData((prevData) => ({
          ...prevData,
          infotainment_message: lines.slice(0, 10).join("\n"),
        }));
        break;
      default:
        break;
    }
  };

  const onHandleAlert = ({ alertType, msg, id = "default" }) => {
    alertType === "danger"
      ? toast.error(t(msg), { toastId: id })
      : toast.success(t(msg), { toastId: id });
  }; 

  const handleMultiConfigSubmit = (e) => {
    e.preventDefault();
    if (multidevicedata.deviceTheme === "") {
      onHandleAlert({
        alertType: "danger",
        msg: "Select Theme field is required.",
      });
      return;
    }
    setLoading(true);
    const refreshRate =
      isCountryAdmin && multidevicedata.live_feed_refresh === ""
        ? "1440"
        : multidevicedata.live_feed_refresh;
    const bgMusic =
      (isAlleView || (!isAllInfotainment && !isAlleView && !isAllFullcontent)) &&
        multidevicedata.background_music === ""
        ? "false"
        : multidevicedata.background_music;

    const multiconfig_data = {
      device_id: selectedDevices,
      device_type: isAlleView
        ? "eView2"
        : isAllInfotainment
          ? "Infotainment"
        : isAllFullcontent
          ? "Fullcontent" 
          : "eView2_Infotainment",
      data: {
        refresh_rate: refreshRate,
        bg_music: bgMusic,
        bg_music_volume: isAllInfotainment ? "" : volume,
        infotainment_msg: multidevicedata.infotainment_message,
        selected_theme: multidevicedata.deviceTheme,
        banner_bg_color: multidevicedata.banner_bg_color,
        banner_text_color: multidevicedata.banner_text_color,
      },
    };
    axios
      .post(config.drupal_url + "/api/multiconfig/save", multiconfig_data, {
        withCredentials: true,
      })
      .then((res) => {
        setLoading(false);
        history.push(`/`);
        if (res.data.status == 'success') {
          toast.success(t('Device configuration has been updated successfully for online devices'));
        } else {
          toast.error(t('Error Updating the devices'));
        }

      })
      .catch((err) => {
        setLoading(false);
        history.push(`/`);
        onHandleAlert({
          alertType: "danger",
          msg: "Error Updating the devices",
        });
      });

  };

  return (
    <LoadingOverlay active={loading} spinner text={t('Processing')}>
      <ToastContainer />
      <div className={device["groups-heading-container Devicelist-heading"]}>
        <div className="offlinedevices-wrap">
          <div className="">
            <h2>
              eView<sup>&reg;</sup> - <T _str="Device Configuration" />
            </h2>
            {(offlineDevices.length > 0) && (
              <div
                id="edit-offline-devices-desc"
                className="form-item form-type-item form-group"
              >

                {t(
                  "Note: The following devices are offline. Playlist will be scheduled once the device is online."
                )}
              </div>
            )}
            <div className="display-devices-off edit-fld">
              {offlineDevices.map((offline, index) => (
                <div
                  id="edit-device"
                  className="form-item form-type-item form-group multiconfig-device"
                >
                  {offline.device_title}
                </div>
              ))}
            </div>
            <div className={`${device["mainActionButtons"]}`}>
              <button
                type="submit"
                value="Save"
                className={`${device["btn"]} ${device["submit-button"]}`}
                onClick={handleMultiConfigSubmit}
              >
                <T _str="Save" />
              </button>
              <button
                type="submit"
                value="Cancel"
                className={`${device["btn"]} ${device["cancel-btn"]}`}
                onClick={(e) => {
                  history.goBack();
                }}
              >
                <T _str="Cancel" />
              </button>
            </div>

          </div>
          <form className="addDeviceForm" id={`${device["MultiDeviceForm"]}`}>
            <div
              className={device["Device-Configuration-inner"]}
            >
              <div>
                {isCountryAdmin && (
                  <div className={device["form-item"]}>
                    <label className="control-label" for="edit-title-field">
                      <T _str="Live Feed Refresh Rate" />
                    </label>
                    <select
                      name="field_live_feed_refresh_rate"
                      onChange={handleChange}
                      id="field_live_feed_refresh_rate"
                    >
                      <option value="1440">{t("Once per day")}</option>
                      <option value="720">{t("Twice per day")}</option>
                      <option value="480">{t("Three times per day")}</option>
                      <option value="360">{t("Four times per day")}</option>
                      <option value="240">{t("Six times per day")}</option>
                    </select>
                  </div>
                )}
             
                {(isAlleView || (!isAllInfotainment && !isAlleView && !isAllFullcontent)) && (selectedDeviceTypes.includes('eView2')) && (
                  <div className={device["form-item"]}>
                    <label className="control-label" for="edit-title-field">
                      <T _str="Background Music" />
                    </label>
                    <select
                      name="field_multi_config_background_music_on"
                      onChange={handleChange}
                      id="background-music"
                    >
                      <option value="false">{t("OFF")}</option>
                      <option value="true">{t("ON")}</option>
                    </select>
                  </div>
                )}
              </div>
              {(isAlleView || (!isAllInfotainment && !isAlleView && !isAllFullcontent)) && (selectedDeviceTypes.includes('eView2')) && (
                <div className={`${device["form-item"]} ${device["message"]}`}>
                  <label
                    className={device["control-label"]}
                    for="edit-title-field"
                  >
                    <T _str="Background Music Volume in Percent" />
                  </label>
                  <Slider
                    min={0}
                    max={100}
                    value={volume}
                    step={1}
                    onChange={(e) => {
                      setValume(e);
                    }}
                    orientation="horizontal"
                  />
                </div>
              )}

              {(isAllInfotainment || (!isAllInfotainment && !isAlleView && !isAllFullcontent)) && (selectedDeviceTypes.includes('Infotainment')) && (
                <div className={`${device["form-item"]} ${device["message"]}`}>
                  <label
                    className={device["control-label"]}
                    for="edit-title-field"
                  >
                    <T _str="Infotainment Message" />
                  </label>
                  <textarea
                    class={device["form-textarea"]}
                    id="infotainment-message"
                    name="field_infotainment_message[und][0][value]"
                    cols="60"
                    rows="3"
                    value={multidevicedata.infotainment_message}
                    onChange={handleChange}
                  ></textarea>
                  {(multidevicedata.infotainment_message.length > 0) && (
                    <div id="">
                      <span class={device.cunt_num}>
                        {INFOTAINMENT_MSG_COUNT -
                          multidevicedata.infotainment_message.length}
                      </span>
                      <T _str="characters remaining" />
                    </div>
                  )}
                  <p className={device.limit_note}>
                    {t(
                      `Note: A maximum of 75 characters or 6 lines are allowed.`
                    )}
                  </p>
                </div>
              )}
              
              {!isAllFullcontent && (
              <>
              <div className={device["form-item"]}>
                <label
                  className="control-label"
                  for="banner-bg-color"
                >
                  <T _str="Banner Background Color" />
                </label>
                <span>
                  <InputColor
                    initialValue={initialBgColor}
                    onChange={handleBgColorChange}
                    id="color-picker"
                    className={device["margin-color-picker"]}
                  />
                  <input
                    className={device["banner-colors"]}
                    type="text"
                    name="banner_bg_color"
                    id="banner-bg-color"
                    value={multidevicedata.banner_bg_color}
                    size="60"
                    maxLength="255"
                    onChange={handleChange}
                  />
                </span>
              </div>
              <div className={device["form-item"]}>
                <label
                  className="control-label"
                  for="banner-bg-color"
                >
                  <T _str="Banner Text Color" />
                </label>
                <span>
                  <InputColor
                    initialValue={initialTextColor}
                    onChange={handleTextColorChange}
                    id="color-picker"
                    className={device["margin-color-picker"]}
                  />
                  <input
                    className={device["banner-colors"]}
                    type="text"
                    name="banner_text_color"
                    id="banner-text-color"
                    value={multidevicedata.banner_text_color}
                    size="60"
                    maxLength="255"
                    onChange={handleChange}
                  />
                </span>
              </div>
              </>
              )}
              <div className="fieldGroup-100">
                <label htmlFor="deviceTheme" className="deviceTheme">
                  <T _str="Select Theme" />
                  <span> *</span>
                </label>
                <div className="deviceTheme" id="deviceTheme">
                  {themesList.map((theme) => (
                    <img
                      style={{
                        cursor: "pointer",
                        marginRight: "20px",
                        padding: "5px",
                        border:
                          multidevicedata.deviceTheme === theme.nid
                            ? "2px solid red"
                            : "none",
                      }}
                      onClick={() => {
                        setMultiDeviceData((prevData) => ({
                          ...prevData,
                          deviceTheme: theme.nid,
                        }));
                      }}
                      src={theme.path}
                      width={100}
                      height={56}
                      alt="Theme Image"
                    />
                  ))}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </LoadingOverlay>
  );
};
export default MultiDeviceConfig;