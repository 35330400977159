/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: Register.js
File Description: Implements registration functionality of CMS portal

Copyright 2023,2024 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../utils/config";
import "../../css/register.css";
import LoadingOverlay from "react-loading-overlay";
import { t, tx } from "@transifex/native";
import { T, useT } from '@transifex/react';
import { useParams, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Buffer } from 'buffer';
import { appInsights, severity } from "../../AppInsights"; 

const Register = () => {
  tx.init({
		token: "1/b764923cd80f817a0b810d8688e7c8f4dc7c75eb",
	  });
 
  const user_language = navigator.language || navigator.userLanguage;
  const language_code = user_language.split("-");
  const user_lang = language_code[0];
  tx.setCurrentLocale(user_lang); 
  
  const { uid } = useParams();
  const [loading, setLoading] = useState(false);
  const intialValues = { email: "", password: "" };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [selectedItem1, setSelectedItem1] = useState("");
  const [verifycode, setVerifycode] = useState(false);
  const [changeemail, setChangeemail] = useState(false);
  const [userOtp, setUserOtp] = useState("");
  const [resetpass, setResetpass] = useState(false);
  const [emailerror, setEmailError] = useState(null);
  const [verifyerror, setVerifyerror] = useState(null);
  const [userEmail, setUserEmail] = useState("");
  
  const pwd = useT("*Password");

  function Otp(value) {
    setLoading(true);
    setUserOtp(value);
    edit_button();
    setLoading(false);
  }
  function sendOtp() {
    setLoading(true);
    document.getElementById("email").setAttribute("disabled", true);
    edit_button();
    if (isValidEmail(userEmail)) {
      setEmailError(null);
      const sendotpUrl = config.drupal_url + "/regis_pass?_format=json";
      const data = {
        "mail": userEmail
      };
      axios.get(config.drupal_url + '/session/token').then(result => {
        if (200 === result.status) {
          const csrfToken = result.data;
          const headers = {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken
          }
          let otpencodedString = Buffer.from(JSON.stringify(data)).toString('base64');
          const salt1 = "H3FilhsRp25VQNOi==";
          const salt2 = "Aj8758XJHRvbrUeJ==";
          let encryptedString = { "data": salt1 + otpencodedString + salt2 };

          axios.post(sendotpUrl, encryptedString, { headers }).then(response => {
            if (response.status === 200) {
              setVerifycode(true);
              setVerifyerror("");
              setLoading(false);
              edit_button();
            }
          })
        }
      })
    } else {
      setFormErrors({ "email": t("Please enter a valid email address.") });
      setLoading(false);
    }
    document.getElementById("email").removeAttribute("disabled");
  }

  function isValidEmail(userEmail) {
    const regex = /^[a-zA-Z0-9!#$%&'+^_`{}~-]+(?:\.[a-zA-Z0-9!#$%&'+^_`{}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/i;
    return regex.test(userEmail);

  }

  function verifyOTP() {
    setVerifyerror(null);
    setLoading(true);
    if (userOtp) {
      const verifyotpUrl = config.drupal_url + "/regi_verify?_format=json";
      const data = {
        "mail": userEmail,
        "otp": userOtp
      };
      axios.get(config.drupal_url + '/session/token').then(result => {
        if (200 === result.status) {
          const csrfToken = result.data;
          const headers = {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken
          }
          let otpencodedString = Buffer.from(JSON.stringify(data)).toString('base64');
          const salt1 = "H3FilhsRp25VQNOi==";
          const salt2 = "Aj8758XJHRvbrUeJ==";
          let encryptedString = { "data": salt1 + otpencodedString + salt2 };

          axios.post(verifyotpUrl, encryptedString, { headers }).then(response => {
            if (response.status === 200) {
              if (response.data.message == "OTP verfication success.") {
                setLoading(false);
                setVerifycode(false);
                document.getElementById("email").setAttribute("disabled", true);
                setChangeemail(true);
                setVerifyerror("");
				localStorage.setItem('userotpcheck', userOtp);			
                setUserOtp("");
              }
              else if (response.data.message == "That code is incorrect. Please try again.") {
                setVerifyerror(t("That code is incorrect. Please try again."));
                setLoading(false);
                edit_button();
              }
              else if (response.data.message == "That code is expired. Please request a new code.") {
                setVerifyerror(t("That code is expired. Please request a new code."));
                setLoading(false);
                edit_button();
              }
              else if (response.data.message == "You've made too many incorrect attempts. Please try again later.") {
                setVerifyerror(t("You've made too many incorrect attempts. Please try again later."));
                setLoading(false);
                edit_button();
              }
            }
          })
        }
      })
    } else {
      setVerifyerror(t("That code is incorrect. Please try again."));
      setLoading(false);
      edit_button();
    }
    edit_button();
  }
  function mailchange() {
    document.getElementById("email").value = "";
	document.getElementById("password").value = "";
	document.getElementById("reenterPassword").value = "";	
    edit_button();
    setUserEmail("");
    setFormValues({ ["email"]: "", ["password"]: "" });
    setVerifycode(false);
    setChangeemail(false);
    document.getElementById("email").removeAttribute("disabled");
    setVerifyerror("");
  }

  const submitForm = (data) => {
    const registerUrl = config.drupal_url + '/api/register?_format=hal_json';
    axios.get(config.drupal_url + '/session/token').then(result => {
      if (200 === result.status) {
        const csrfToken = result.data;
        axios.post(registerUrl, data, {
          headers: {
            "Content-Type": "application/json",
            'X-CSRF-Token': csrfToken,
          }
        }).then((res) => {
          if (res.data.message === "user created") {
            window.location.href = "/account/pending/"+res.data.lang;
          } else {
            let errors = {};
            errors.email = t(res.data.message);
            setFormErrors(errors);
            document.getElementById("email").focus();
            setLoading(false);
          }
        });
      }
    });
  };

  const handleChange = (e) => {  
    const { name, value } = e.target;
    if (e.target.name === "email") {
      setUserEmail(e.target.value);
    }
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault()
    const target = event.target;
    const data = {
      "name": [{ "value": target.email.value }],
      "mail": [{ "value": target.email.value }],
      "otp": [{ "value": localStorage.getItem("userotpcheck") }],
	  "pass": [{ "value": target.password.value }],
      "field_firstname": [{ "value": target.givenName.value }],
      "field_lastname": [{ "value": target.surname.value }],
      "field_companyname": [{ "value": target.extension_Company.value }],
      "field_street_address1": [{ "value": target.streetAddress.value }],
      "field_country_language": [{ "value": target.country.value }],
      "field_select_country": [target.country.value],
      "field_s_prefix": [target.prefix.value],
      "field_streetaddress": [{ "value": target.streetAddress.value }],
      "field_street_address2": [{ "value": target.extension_StreetAddress2.value }],
      "field_postal_code": [{ "value": target.postalCode.value }],
      "field_position": [{ "value": target.jobTitle.value }],
      "field_disable_status": ["4"],
      "field_ext1": [{ "value": target.extension_CountryCode1.value }],
      "field_mobile_number": [{ "value": target.extension_MobileNumber.value }],
      "field_ext2": [{ "value": target.extension_CountryCode2.value }],
      "field_alternate_number": [{ "value": target.extension_Phone.value }]
    };

    appInsights.trackTrace({
      message:
        "User registration with data:'" +
        JSON.stringify(data) +
        "' has been successfully sent" +
        "",
        severityLevel: severity.Information,
    });	

    let encodedString = Buffer.from(JSON.stringify(data)).toString('base64');

    const salt1 = "H3FilhsRp25VQNOi==";
    const salt2 = "Aj8758XJHRvbrUeJ==";
    let encryptedString = { "data": salt1 + encodedString + salt2 };
    //Form submission happens here
    setFormErrors(validate(formValues));
    if (Object.keys(formErrors).length == 0) {
      setIsSubmitting(true);
      setLoading(true);
      submitForm(encryptedString);
    }

  };

  const validate = (values) => {
    let errors = {};
    const regex = /^[a-zA-Z0-9!#$%&'+^_`{}~-]+(?:\.[a-zA-Z0-9!#$%&'+^_`{}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/i;
	var pass_valstr = t("The password must be between 8 and 64 characters.Your password must have at least 3 of the following");
	    pass_valstr += ':<ul><li>- '+t("a lowercase letter")+'</li>';
		pass_valstr += '<li>- '+t("an uppercase letter")+'</li>';
		pass_valstr += '<li>- '+t("a number")+'</li>';
		pass_valstr += '<li>- '+t("a symbol")+'</li></ul>';   
	if (!values.email) {
      errors.email = "";
    } else if (!regex.test(values.email)) {
      errors.email = t("Please enter a valid email address.");
    }
    const regex_pass = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,64}$/;
    if (!values.password) {
      errors.password = "";
    } else if (!regex_pass.test(values.password)) {
      errors.password = pass_valstr;
    }
    if (!values.reenterPassword) {
      errors.reenterPassword = "";
    } else if (!regex_pass.test(values.reenterPassword)) {
      errors.reenterPassword = pass_valstr;
    } else if (values.reenterPassword != values.password) {
      errors.reenterPassword = t("Please enter the same password in both fields.");
    }
    return errors;
  };

  const setCountryCode = function (links) {
    var country_language = document.getElementById("country").value;
    var country_code1 = document.getElementById("extension_CountryCode1");

    var country_code2 = document.getElementById("extension_CountryCode2");
    var Format_Phone1 = document.getElementById("extension_MobileNumber");
    var Format_Phone2 = document.getElementById("extension_Phone");

    switch (country_language) {
      case "Canada/103":
      case "Canada/98":
      case "United States/67":
      case "United States/154":
      case "United States/155":
        setSelectedItem('1');
        setSelectedItem1('1');
        Format_Phone1.value = '';
        Format_Phone2.value = '';


        break;
      case "China/99":
      case "China/70":
      case "XIZI China/114":
      case "XIZI China/115":
        setSelectedItem('86');
        setSelectedItem1('86');
        Format_Phone1.value = '';
        Format_Phone2.value = '';


        break;
      case "Hong Kong/23":
      case "Hong Kong/24":
        setSelectedItem('852');
        setSelectedItem1('852');
        Format_Phone1.value = '';
        Format_Phone2.value = '';
        break;
      case "Singapore/64":
        setSelectedItem('65');
        setSelectedItem1('65');
        Format_Phone1.value = '';
        Format_Phone2.value = '';
        break;

      case "France/48":
        setSelectedItem('33');
        setSelectedItem1('33');
        Format_Phone1.value = '';
        Format_Phone2.value = '';
        break;

      case "Austria/44":
        setSelectedItem('43');
        setSelectedItem1('43');
        Format_Phone1.value = '';
        Format_Phone2.value = '';
        break;
      case "Germany/49":
        setSelectedItem('49');
        setSelectedItem1('49');
        Format_Phone1.value = '';
        Format_Phone2.value = '';
        break;
      case "Switzerland/93":
      case "Switzerland/100":
      case "Switzerland/101":
        setSelectedItem('41');
        setSelectedItem1('41');
        Format_Phone1.value = '';
        Format_Phone2.value = '';
        break;
      case "United Kingdom/68":
        setSelectedItem('44');
        setSelectedItem1('44');
        Format_Phone1.value = '';
        Format_Phone2.value = '';
        break;
      case "Belgium/143":
      case "Belgium/72":
      case "Belgium/104":
        setSelectedItem('32');
        setSelectedItem1('32');
        Format_Phone1.value = '';
        Format_Phone2.value = '';
        break;
      case "Netherlands/85":
        setSelectedItem('31');
        setSelectedItem1('31');
        Format_Phone1.value = '';
        Format_Phone2.value = '';
        break;
      case "Spain/35":
      case "Spain/157":
        setSelectedItem('34');
        setSelectedItem1('34');
        Format_Phone1.value = '';
        Format_Phone2.value = '';
        break;
      case "Italy/53":
        setSelectedItem('39');
        setSelectedItem1('39');
        Format_Phone1.value = '';
        Format_Phone2.value = '';
        break;

      case "Denmark/144":
        setSelectedItem('45');
        setSelectedItem1('45');
        Format_Phone1.value = '';
        Format_Phone2.value = '';
        break;
      case "Sweden/145":
        setSelectedItem('46');
        setSelectedItem1('46');
        Format_Phone1.value = '';
        Format_Phone2.value = '';
        break;
      case "Norway/134":
        setSelectedItem('47');
        setSelectedItem1('47');
        Format_Phone1.value = '';
        Format_Phone2.value = '';
        break;
      case "Finland/146":
        setSelectedItem('358');
        setSelectedItem1('358');
        Format_Phone1.value = '';
        Format_Phone2.value = '';
        break;
      case "Estonia/147":
        setSelectedItem('372');
        setSelectedItem1('372');
        Format_Phone1.value = '';
        Format_Phone2.value = '';
        break;
      case "Poland/148":
        setSelectedItem('48');
        setSelectedItem1('48');
        Format_Phone1.value = '';
        Format_Phone2.value = '';
        break;
      case "Czech Republic/149":
        setSelectedItem('420');
        setSelectedItem1('420');
        Format_Phone1.value = '';
        Format_Phone2.value = '';
        break;
      case "Slovakia/150":
      case "Slovakia/89":
        setSelectedItem('421');
        setSelectedItem1('421');
        Format_Phone1.value = '';
        Format_Phone2.value = '';
        break;
      case "Ireland/52":
        setSelectedItem('353');
        setSelectedItem1('353');
        Format_Phone1.value = '';
        Format_Phone2.value = '';
        break;
      case "Luxembourg/153":
      case "Luxembourg/156":
      case "Luxembourg/84":
      case "Luxembourg/188":
        setSelectedItem('352');
        setSelectedItem1('352');
        Format_Phone1.value = '';
        Format_Phone2.value = '';
        break;
      case "India/59":
        setSelectedItem('91');
        setSelectedItem1('91');
        Format_Phone1.value = '';
        Format_Phone2.value = '';
        break;
      case "Japan/151":
      case "Japan/54":
        setSelectedItem('81');
        setSelectedItem1('81');
        Format_Phone1.value = '';
        Format_Phone2.value = '';
        break;
      case "Korea/158":
      case "Korea/152":
      case "Korea/55":
        setSelectedItem('82');
        setSelectedItem1('82');
        Format_Phone1.value = '';
        Format_Phone2.value = '';
        break;
      case "Australia/43":
        setSelectedItem('61');
        setSelectedItem1('61');
        Format_Phone1.value = '';
        Format_Phone2.value = '';
        break;
      case "Bahrain/169":
        setSelectedItem('973');
        setSelectedItem1('973');
        Format_Phone1.value = '';
        Format_Phone2.value = '';
        break;
      case "Kuwait/56":
        setSelectedItem('965');
        setSelectedItem1('965');
        Format_Phone1.value = '';
        Format_Phone2.value = '';
        break;
      case "Qatar/167":
        setSelectedItem('974');
        setSelectedItem1('974');
        Format_Phone1.value = '';
        Format_Phone2.value = '';
        break;
      case "Saudi Arabia/29":
        setSelectedItem('966');
        setSelectedItem1('966');
        Format_Phone1.value = '';
        Format_Phone2.value = '';
        break;
      case "UAE/166":
        setSelectedItem('971');
        setSelectedItem1('971');
        Format_Phone1.value = '';
        Format_Phone2.value = '';
        break;
      default:
        setSelectedItem('');
        setSelectedItem1('');
        Format_Phone1.value = '';
        Format_Phone2.value = '';
    }

  }
  const setPhoneFormat = function (dropdownChange) {
    if (dropdownChange == 1) {

      var country_code1 = document.getElementById("extension_CountryCode1").value;
      var Format_Phone2 = document.getElementById("extension_Phone");
      Format_Phone2.value = '';

      const elClone2 = Format_Phone2.cloneNode(true);
      Format_Phone2.parentNode.replaceChild(elClone2, Format_Phone2);

      //Cases 
      switch (country_code1) {
        case "1":
          const isNumericInput1 = (event) => {
            const key = event.keyCode;
            return ((key >= 48 && key <= 57) || // Allow number line
              (key >= 96 && key <= 105) // Allow number pad
            );
          };

          const isModifierKey1 = (event) => {
            const key = event.keyCode;
            return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
              (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
              (key > 36 && key < 41) || // Allow left, up, right, down
              (
                // Allow Ctrl/Command + A,C,V,X,Z
                (event.ctrlKey === true || event.metaKey === true) &&
                (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
              )
          };

          const enforceFormat1 = (event) => {
            // Input must be of a valid number format or a modifier key, and not longer than ten digits
            if (!isNumericInput1(event) && !isModifierKey1(event)) {
              event.preventDefault();
            }
          };

          const formatToPhone1 = (event) => {
            if (isModifierKey1(event)) { return; }

            // I am lazy and don't like to type things more than once
            const target = event.target;
            const input = event.target.value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
            const zip = input.substring(0, 3);
            const middle = input.substring(3, 6);
            const last = input.substring(6, 10);

            if (input.length > 6) { target.value = `(${zip}) ${middle} ${last}`; }
            else if (input.length > 3) { target.value = `(${zip}) ${middle}`; }
            else if (input.length > 0) { target.value = `(${zip}`; }
            else if (input.length == 0) { target.value = `${zip}`; }

          };

          const inputElement4 = document.getElementById('extension_Phone');

          inputElement4.addEventListener('keydown', enforceFormat1);
          inputElement4.addEventListener('keyup', formatToPhone1);
          inputElement4.addEventListener('paste', formatToPhone1);

          break;

        case "33":
          const isNumericInput5 = (event) => {
            const key = event.keyCode;
            return ((key >= 48 && key <= 57) || // Allow number line
              (key >= 96 && key <= 105) // Allow number pad
            );
          };

          const isModifierKey5 = (event) => {
            const key = event.keyCode;
            return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
              (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
              (key > 36 && key < 41) || // Allow left, up, right, down
              (
                // Allow Ctrl/Command + A,C,V,X,Z
                (event.ctrlKey === true || event.metaKey === true) &&
                (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
              )
          };

          const enforceFormat5 = (event) => {
            // Input must be of a valid number format or a modifier key, and not longer than ten digits
            if (!isNumericInput5(event) && !isModifierKey5(event)) {
              event.preventDefault();
            }
          };

          const formatToPhone5 = (event) => {
            if (isModifierKey5(event)) { return; }

            // I am lazy and don't like to type things more than once
            let target = event.target;
            let input = event.target.value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
            let zip = input.substring(0, 1);
            let zip1 = input.substring(1, 3);
            let middle = input.substring(3, 5);
            let zip2 = input.substring(5, 7);
            let last = input.substring(7, 9);

            if (input.length > 7) { target.value = `${zip} ${zip1} ${middle} ${zip2} ${last}`; }
            else if (input.length > 5) { target.value = `${zip} ${zip1} ${middle} ${zip2}`; }
            else if (input.length > 3) { target.value = `${zip} ${zip1} ${middle}`; }
            else if (input.length > 1) { target.value = `${zip} ${zip1}`; }
            else if (input.length > 0) { target.value = `${zip}`; }
            else if (input.length == 0) { target.value = `${zip}`; }
          };

          const inputElement12 = document.getElementById('extension_Phone');

          inputElement12.addEventListener('keydown', enforceFormat5);
          inputElement12.addEventListener('keyup', formatToPhone5);
          inputElement12.addEventListener('paste', formatToPhone5);

          break;
        case "43":
          const isNumericInput6 = (event) => {
            const key = event.keyCode;
            return ((key >= 48 && key <= 57) || // Allow number line
              (key >= 96 && key <= 105) // Allow number pad
            );
          };

          const isModifierKey6 = (event) => {
            const key = event.keyCode;
            return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
              (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
              (key > 36 && key < 41) || // Allow left, up, right, down
              (
                // Allow Ctrl/Command + A,C,V,X,Z
                (event.ctrlKey === true || event.metaKey === true) &&
                (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
              )
          };

          const enforceFormat6 = (event) => {
            // Input must be of a valid number format or a modifier key, and not longer than ten digits
            if (!isNumericInput6(event) && !isModifierKey6(event)) {
              event.preventDefault();
            }
          };

          const formatToPhone6 = (event) => {
            if (isModifierKey6(event)) { return; }

            // I am lazy and don't like to type things more than once
            const target = event.target;
            const input = event.target.value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
            const zip = input.substring(0, 3);

            const last = input.substring(3, 10);

            if (input.length > 3) { target.value = `${zip}  ${last}`; }

            else if (input.length > 0) { target.value = `${zip}`; }
            else if (input.length == 0) { target.value = `${zip}`; }
          };

          const inputElement14 = document.getElementById('extension_Phone');

          inputElement14.addEventListener('keydown', enforceFormat6);
          inputElement14.addEventListener('keyup', formatToPhone6);
          inputElement14.addEventListener('paste', formatToPhone6);
          break;
        case "49":
          const isNumericInput7 = (event) => {
            const key = event.keyCode;
            return ((key >= 48 && key <= 57) || // Allow number line
              (key >= 96 && key <= 105) // Allow number pad
            );
          };

          const isModifierKey7 = (event) => {
            const key = event.keyCode;
            return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
              (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
              (key > 36 && key < 41) || // Allow left, up, right, down
              (
                // Allow Ctrl/Command + A,C,V,X,Z
                (event.ctrlKey === true || event.metaKey === true) &&
                (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
              )
          };

          const enforceFormat7 = (event) => {
            // Input must be of a valid number format or a modifier key, and not longer than ten digits
            if (!isNumericInput7(event) && !isModifierKey7(event)) {
              event.preventDefault();
            }
          };

          const formatToPhone7 = (event) => {
            if (isModifierKey7(event)) { return; }

            // I am lazy and don't like to type things more than once
            const target = event.target;
            const input = event.target.value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
            const zip = input.substring(0, 4);

            const last = input.substring(4, 10);

            if (input.length > 4) { target.value = `${zip}  ${last}`; }

            else if (input.length > 0) { target.value = `${zip}`; }
            else if (input.length == 0) { target.value = `${zip}`; }
          };

          const inputElement16 = document.getElementById('extension_Phone');

          inputElement16.addEventListener('keydown', enforceFormat7);
          inputElement16.addEventListener('keyup', formatToPhone7);
          inputElement16.addEventListener('paste', formatToPhone7);
          break;
        case "65":
          const isNumericInput4 = (event) => {
            const key = event.keyCode;
            return ((key >= 48 && key <= 57) || // Allow number line
              (key >= 96 && key <= 105) // Allow number pad
            );
          };

          const isModifierKey4 = (event) => {
            const key = event.keyCode;
            return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
              (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
              (key > 36 && key < 41) || // Allow left, up, right, down
              (
                // Allow Ctrl/Command + A,C,V,X,Z
                (event.ctrlKey === true || event.metaKey === true) &&
                (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
              )
          };

          const enforceFormat4 = (event) => {
            // Input must be of a valid number format or a modifier key, and not longer than ten digits
            if (!isNumericInput4(event) && !isModifierKey4(event)) {
              event.preventDefault();
            }
          };

          const formatToPhone4 = (event) => {
            if (isModifierKey4(event)) { return; }

            // I am lazy and don't like to type things more than once
            const target = event.target;
            const input = event.target.value.replace(/\D/g, '').substring(0, 8); // First ten digits of input only
            const zip = input.substring(0, 4);

            const last = input.substring(4, 8);

            if (input.length > 4) { target.value = `${zip} ${last}`; }

            else if (input.length == 0) { target.value = `${zip}`; }
          };

          const inputElement10 = document.getElementById('extension_Phone');

          inputElement10.addEventListener('keydown', enforceFormat4);
          inputElement10.addEventListener('keyup', formatToPhone4);
          inputElement10.addEventListener('paste', formatToPhone4);
          break;
        case "86":
          const isNumericInput2 = (event) => {
            const key = event.keyCode;
            return ((key >= 48 && key <= 57) || // Allow number line
              (key >= 96 && key <= 105) // Allow number pad
            );
          };

          const isModifierKey2 = (event) => {
            const key = event.keyCode;
            return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
              (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
              (key > 36 && key < 41) || // Allow left, up, right, down
              (
                // Allow Ctrl/Command + A,C,V,X,Z
                (event.ctrlKey === true || event.metaKey === true) &&
                (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
              )
          };

          const enforceFormat2 = (event) => {
            // Input must be of a valid number format or a modifier key, and not longer than ten digits
            if (!isNumericInput2(event) && !isModifierKey2(event)) {
              event.preventDefault();
            }
          };

          const formatToPhone2 = (event) => {
            if (isModifierKey2(event)) { return; }

            // I am lazy and don't like to type things more than once
            const target = event.target;
            const input = event.target.value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
            const zip = input.substring(0, 3);
            const middle = input.substring(3, 7);
            const last = input.substring(7, 10);

            if (input.length > 7) { target.value = `${zip}  ${middle}  ${last}`; }
            else if (input.length > 3) { target.value = `${zip} ${middle}`; }
            else if (input.length > 0) { target.value = `${zip}`; }
            else if (input.length == 0) { target.value = `${zip}`; }
          };

          const inputElement6 = document.getElementById('extension_Phone');

          inputElement6.addEventListener('keydown', enforceFormat2);
          inputElement6.addEventListener('keyup', formatToPhone2);
          inputElement6.addEventListener('paste', formatToPhone2);
          break;
        case "852":
          const isNumericInput3 = (event) => {
            const key = event.keyCode;
            return ((key >= 48 && key <= 57) || // Allow number line
              (key >= 96 && key <= 105) // Allow number pad
            );
          };

          const isModifierKey3 = (event) => {
            const key = event.keyCode;
            return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
              (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
              (key > 36 && key < 41) || // Allow left, up, right, down
              (
                // Allow Ctrl/Command + A,C,V,X,Z
                (event.ctrlKey === true || event.metaKey === true) &&
                (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
              )
          };

          const enforceFormat3 = (event) => {
            // Input must be of a valid number format or a modifier key, and not longer than ten digits
            if (!isNumericInput3(event) && !isModifierKey3(event)) {
              event.preventDefault();
            }
          };

          const formatToPhone3 = (event) => {
            if (isModifierKey3(event)) { return; }

            // I am lazy and don't like to type things more than once
            const target = event.target;
            const input = event.target.value.replace(/\D/g, '').substring(0, 8); // First ten digits of input only
            const zip = input.substring(0, 4);

            const last = input.substring(4, 8);

            if (input.length > 4) { target.value = `${zip} ${last}`; }

            else if (input.length > 0) { target.value = `${zip}`; }
            else if (input.length == 0) { target.value = `${zip}`; }
          };

          const inputElement8 = document.getElementById('extension_Phone');

          inputElement8.addEventListener('keydown', enforceFormat3);
          inputElement8.addEventListener('keyup', formatToPhone3);
          inputElement8.addEventListener('paste', formatToPhone3);
          break;

      } //Switch Case Ends Here

    } // Drop Down 1 Ends Here
    if (dropdownChange == 2) {
      var country_code2 = document.getElementById("extension_CountryCode2").value;
      var Format_Phone1 = document.getElementById("extension_MobileNumber");
      Format_Phone1.value = '';
      const elClone = Format_Phone1.cloneNode(true);
      Format_Phone1.parentNode.replaceChild(elClone, Format_Phone1);
      //Cases
      switch (country_code2) {
        case "1":
          const isNumericInput1 = (event) => {
            const key = event.keyCode;
            return ((key >= 48 && key <= 57) || // Allow number line
              (key >= 96 && key <= 105) // Allow number pad
            );
          };

          const isModifierKey1 = (event) => {
            const key = event.keyCode;
            return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
              (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
              (key > 36 && key < 41) || // Allow left, up, right, down
              (
                // Allow Ctrl/Command + A,C,V,X,Z
                (event.ctrlKey === true || event.metaKey === true) &&
                (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
              )
          };

          const enforceFormat1 = (event) => {
            // Input must be of a valid number format or a modifier key, and not longer than ten digits
            if (!isNumericInput1(event) && !isModifierKey1(event)) {
              event.preventDefault();
            }
          };

          const formatToPhone1 = (event) => {
            if (isModifierKey1(event)) { return; }

            // I am lazy and don't like to type things more than once
            const target = event.target;
            const input = event.target.value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
            const zip = input.substring(0, 3);
            const middle = input.substring(3, 6);
            const last = input.substring(6, 10);

            if (input.length > 6) { target.value = `(${zip}) ${middle} ${last}`; }
            else if (input.length > 3) { target.value = `(${zip}) ${middle}`; }
            else if (input.length > 0) { target.value = `(${zip}`; }
            else if (input.length == 0) { target.value = `${zip}`; }

          };

          const inputElement4 = document.getElementById('extension_MobileNumber');

          inputElement4.addEventListener('keydown', enforceFormat1);
          inputElement4.addEventListener('keyup', formatToPhone1);
          inputElement4.addEventListener('paste', formatToPhone1);

          break;

        case "33":
          const isNumericInput5 = (event) => {
            const key = event.keyCode;
            return ((key >= 48 && key <= 57) || // Allow number line
              (key >= 96 && key <= 105) // Allow number pad
            );
          };

          const isModifierKey5 = (event) => {
            const key = event.keyCode;
            return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
              (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
              (key > 36 && key < 41) || // Allow left, up, right, down
              (
                // Allow Ctrl/Command + A,C,V,X,Z
                (event.ctrlKey === true || event.metaKey === true) &&
                (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
              )
          };

          const enforceFormat5 = (event) => {
            // Input must be of a valid number format or a modifier key, and not longer than ten digits
            if (!isNumericInput5(event) && !isModifierKey5(event)) {
              event.preventDefault();
            }
          };

          const formatToPhone5 = (event) => {
            if (isModifierKey5(event)) { return; }

            // I am lazy and don't like to type things more than once
            let target = event.target;
            let input = event.target.value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
            let zip = input.substring(0, 1);
            let zip1 = input.substring(1, 3);
            let middle = input.substring(3, 5);
            let zip2 = input.substring(5, 7);
            let last = input.substring(7, 9);

            if (input.length > 7) { target.value = `${zip} ${zip1} ${middle} ${zip2} ${last}`; }
            else if (input.length > 5) { target.value = `${zip} ${zip1} ${middle} ${zip2}`; }
            else if (input.length > 3) { target.value = `${zip} ${zip1} ${middle}`; }
            else if (input.length > 1) { target.value = `${zip} ${zip1}`; }
            else if (input.length > 0) { target.value = `${zip}`; }
            else if (input.length == 0) { target.value = `${zip}`; }
          };

          const inputElement12 = document.getElementById('extension_MobileNumber');

          inputElement12.addEventListener('keydown', enforceFormat5);
          inputElement12.addEventListener('keyup', formatToPhone5);
          inputElement12.addEventListener('paste', formatToPhone5);

          break;
        case "43":
          const isNumericInput6 = (event) => {
            const key = event.keyCode;
            return ((key >= 48 && key <= 57) || // Allow number line
              (key >= 96 && key <= 105) // Allow number pad
            );
          };

          const isModifierKey6 = (event) => {
            const key = event.keyCode;
            return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
              (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
              (key > 36 && key < 41) || // Allow left, up, right, down
              (
                // Allow Ctrl/Command + A,C,V,X,Z
                (event.ctrlKey === true || event.metaKey === true) &&
                (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
              )
          };

          const enforceFormat6 = (event) => {
            // Input must be of a valid number format or a modifier key, and not longer than ten digits
            if (!isNumericInput6(event) && !isModifierKey6(event)) {
              event.preventDefault();
            }
          };

          const formatToPhone6 = (event) => {
            if (isModifierKey6(event)) { return; }

            // I am lazy and don't like to type things more than once
            const target = event.target;
            const input = event.target.value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
            const zip = input.substring(0, 3);

            const last = input.substring(3, 10);

            if (input.length > 3) { target.value = `${zip}  ${last}`; }

            else if (input.length > 0) { target.value = `${zip}`; }
            else if (input.length == 0) { target.value = `${zip}`; }
          };

          const inputElement14 = document.getElementById('extension_MobileNumber');

          inputElement14.addEventListener('keydown', enforceFormat6);
          inputElement14.addEventListener('keyup', formatToPhone6);
          inputElement14.addEventListener('paste', formatToPhone6);
          break;
        case "49":
          const isNumericInput7 = (event) => {
            const key = event.keyCode;
            return ((key >= 48 && key <= 57) || // Allow number line
              (key >= 96 && key <= 105) // Allow number pad
            );
          };

          const isModifierKey7 = (event) => {
            const key = event.keyCode;
            return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
              (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
              (key > 36 && key < 41) || // Allow left, up, right, down
              (
                // Allow Ctrl/Command + A,C,V,X,Z
                (event.ctrlKey === true || event.metaKey === true) &&
                (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
              )
          };

          const enforceFormat7 = (event) => {
            // Input must be of a valid number format or a modifier key, and not longer than ten digits
            if (!isNumericInput7(event) && !isModifierKey7(event)) {
              event.preventDefault();
            }
          };

          const formatToPhone7 = (event) => {
            if (isModifierKey7(event)) { return; }

            // I am lazy and don't like to type things more than once
            const target = event.target;
            const input = event.target.value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
            const zip = input.substring(0, 4);

            const last = input.substring(4, 10);

            if (input.length > 4) { target.value = `${zip}  ${last}`; }

            else if (input.length > 0) { target.value = `${zip}`; }
            else if (input.length == 0) { target.value = `${zip}`; }
          };

          const inputElement16 = document.getElementById('extension_MobileNumber');

          inputElement16.addEventListener('keydown', enforceFormat7);
          inputElement16.addEventListener('keyup', formatToPhone7);
          inputElement16.addEventListener('paste', formatToPhone7);
          break;
        case "65":
          const isNumericInput4 = (event) => {
            const key = event.keyCode;
            return ((key >= 48 && key <= 57) || // Allow number line
              (key >= 96 && key <= 105) // Allow number pad
            );
          };

          const isModifierKey4 = (event) => {
            const key = event.keyCode;
            return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
              (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
              (key > 36 && key < 41) || // Allow left, up, right, down
              (
                // Allow Ctrl/Command + A,C,V,X,Z
                (event.ctrlKey === true || event.metaKey === true) &&
                (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
              )
          };

          const enforceFormat4 = (event) => {
            // Input must be of a valid number format or a modifier key, and not longer than ten digits
            if (!isNumericInput4(event) && !isModifierKey4(event)) {
              event.preventDefault();
            }
          };

          const formatToPhone4 = (event) => {
            if (isModifierKey4(event)) { return; }

            // I am lazy and don't like to type things more than once
            const target = event.target;
            const input = event.target.value.replace(/\D/g, '').substring(0, 8); // First ten digits of input only
            const zip = input.substring(0, 4);

            const last = input.substring(4, 8);

            if (input.length > 4) { target.value = `${zip} ${last}`; }

            else if (input.length == 0) { target.value = `${zip}`; }
          };

          const inputElement10 = document.getElementById('extension_MobileNumber');

          inputElement10.addEventListener('keydown', enforceFormat4);
          inputElement10.addEventListener('keyup', formatToPhone4);
          inputElement10.addEventListener('paste', formatToPhone4);
          break;
        case "86":
          const isNumericInput2 = (event) => {
            const key = event.keyCode;
            return ((key >= 48 && key <= 57) || // Allow number line
              (key >= 96 && key <= 105) // Allow number pad
            );
          };

          const isModifierKey2 = (event) => {
            const key = event.keyCode;
            return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
              (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
              (key > 36 && key < 41) || // Allow left, up, right, down
              (
                // Allow Ctrl/Command + A,C,V,X,Z
                (event.ctrlKey === true || event.metaKey === true) &&
                (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
              )
          };

          const enforceFormat2 = (event) => {
            // Input must be of a valid number format or a modifier key, and not longer than ten digits
            if (!isNumericInput2(event) && !isModifierKey2(event)) {
              event.preventDefault();
            }
          };

          const formatToPhone2 = (event) => {
            if (isModifierKey2(event)) { return; }

            // I am lazy and don't like to type things more than once
            const target = event.target;
            const input = event.target.value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
            const zip = input.substring(0, 3);
            const middle = input.substring(3, 7);
            const last = input.substring(7, 10);

            if (input.length > 7) { target.value = `${zip}  ${middle}  ${last}`; }
            else if (input.length > 3) { target.value = `${zip} ${middle}`; }
            else if (input.length > 0) { target.value = `${zip}`; }
            else if (input.length == 0) { target.value = `${zip}`; }
          };

          const inputElement6 = document.getElementById('extension_MobileNumber');

          inputElement6.addEventListener('keydown', enforceFormat2);
          inputElement6.addEventListener('keyup', formatToPhone2);
          inputElement6.addEventListener('paste', formatToPhone2);
          break;
        case "852":
          const isNumericInput3 = (event) => {
            const key = event.keyCode;
            return ((key >= 48 && key <= 57) || // Allow number line
              (key >= 96 && key <= 105) // Allow number pad
            );
          };

          const isModifierKey3 = (event) => {
            const key = event.keyCode;
            return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
              (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
              (key > 36 && key < 41) || // Allow left, up, right, down
              (
                // Allow Ctrl/Command + A,C,V,X,Z
                (event.ctrlKey === true || event.metaKey === true) &&
                (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
              )
          };

          const enforceFormat3 = (event) => {
            // Input must be of a valid number format or a modifier key, and not longer than ten digits
            if (!isNumericInput3(event) && !isModifierKey3(event)) {
              event.preventDefault();
            }
          };

          const formatToPhone3 = (event) => {
            if (isModifierKey3(event)) { return; }

            // I am lazy and don't like to type things more than once
            const target = event.target;
            const input = event.target.value.replace(/\D/g, '').substring(0, 8); // First ten digits of input only
            const zip = input.substring(0, 4);

            const last = input.substring(4, 8);

            if (input.length > 4) { target.value = `${zip} ${last}`; }

            else if (input.length > 0) { target.value = `${zip}`; }
            else if (input.length == 0) { target.value = `${zip}`; }
          };

          const inputElement8 = document.getElementById('extension_MobileNumber');

          inputElement8.addEventListener('keydown', enforceFormat3);
          inputElement8.addEventListener('keyup', formatToPhone3);
          inputElement8.addEventListener('paste', formatToPhone3);
          break;


      } // Till Here

    }

  }
  const country = function () {
    setCountryCode();
    setTimeout(() => {
      setPhoneFormat(1);
      setPhoneFormat(2);
    }, 500);

  }
  const extension_CountryCode1 = function (event) {
    setSelectedItem1(event.target.value);
    setPhoneFormat(1);
  }
  const extension_CountryCode2 = function (event) {
    setSelectedItem(event.target.value);
    setPhoneFormat(2);
  }


  const updateOrRemoveSpecificArray = function (id, array, isValid) {
    let index = array.findIndex(x => x === id);
    if (index > -1 && isValid) {
      array.splice(index, 1);
    } else if (!isValid && index === -1) {
      array.push(id);
    }
  }
  const displayErrorMessage = function (order, element, elementId) {

    let flag = true;
    if (elementId === "extension_AgreeToTerms_1" || elementId === "extension_AgreeToPrivacyPolicy_1") {

      if (element.checked) {
        flag = false;
      }
    } else if (elementId === "country") {
      if (element.value !== "") {
        flag = false;
      }
    } else {
      if (element.value.trim() === "") {
        // element.style.border = "1px solid red";
      } else {
        flag = false;
      }
    }

    return flag;
  }


  const validatefield = function () {

    let errorArray = [];
    setInterval(function () {
      let requiredFields = [
        { order: 0, elementId: "email" },
        { order: 1, elementId: "password" },
        { order: 2, elementId: "reenterPassword" },

        { order: 9, elementId: "givenName" },
        { order: 12, elementId: "surname" },
        { order: 5, elementId: "extension_Company" },

        { order: 12, elementId: "streetAddress" },
        { order: 13, elementId: "postalCode" },
        { order: 6, elementId: "country" },

        { order: 10, elementId: "extension_MobileNumber" },
        { order: 15, elementId: "jobTitle" },
        { order: 4, elementId: "extension_AgreeToTerms_1" },
        { order: 3, elementId: "extension_AgreeToPrivacyPolicy_1" }

      ];


      for (let i = 0; i < requiredFields.length; i++) {
        if (
          displayErrorMessage(
            requiredFields[i].order,
            document.getElementById(requiredFields[i].elementId),
            requiredFields[i].elementId
          )
        ) {

          updateOrRemoveSpecificArray(
            requiredFields[i].elementId,
            errorArray,
            false
          );
        } else {
          updateOrRemoveSpecificArray(
            requiredFields[i].elementId,
            errorArray,
            true
          );
        }
      }
      if (errorArray.length === 0 && document.getElementById("email_ver_but_edit")) {
        document.getElementById("continue").disabled = false;
        document.getElementById("continue").style = "";
      } else {
        document.getElementById("continue").disabled = true;
        document.getElementById("continue").style = "background-color: rgb(204, 204, 204); color: rgb(0, 0, 0); border: 1px solid transparent; cursor: not-allowed";

      }
    }, 1000);
  }
  const edit_button = function () {
    if (document.getElementById("email_ver_input")) {
      document.getElementById("email_ver_input").value = '';
    }
  }
  const init = function () {
    validatefield();
    document.getElementById("continue").disabled = true;
    document.getElementById("continue").style = "background-color: rgb(204, 204, 204); color: rgb(0, 0, 0); border: 1px solid transparent; cursor: not-allowed";
  }
  const navigateTo = function (linksArray, linkType) {
    let country_language = document.getElementById("country").value;
    let openURL;
    if (linkType == 'terms-of-use') {
      openURL = "https://www.otis.com/en/us/terms-use/";
    } else if (linkType == 'privacy-policy') {
      openURL = "https://www.otis.com/en/us/privacy-policy";
    }
    if (linksArray[country_language] && linksArray[country_language].link) {
      openURL = linksArray[country_language].link;
    }
    window.open(openURL, '_blank');
  }

  // To listen AgreeToTerms click
  const extAgree = function () {
    let lr_check = document.getElementById("extension_AgreeToTerms_1");
    let lr_checkVal = false;
    if (lr_check.checked) {
      lr_checkVal = true;
    }
    setTimeout(() => {
      lr_check.checked = lr_checkVal;
    }, 1000);
    let touLinks = {
      'Austria/44': {
        'label': 'Austria / German',
        'link': 'https://www.otis.com/de/at/rechtliches/nutzungsbedingungen'
      },
      'Canada/98': {
        'label': 'Canada / French',
        'link': 'https://www.otis.com/fr/ca/terms-use'
      },
      'Canada/103': {
        'label': 'Canada / English',
        'link': 'https://www.otis.com/en/us/terms-use'
      },
      'China/70': {
        'label': 'China / Simplified Chinese',
        'link': 'https://www.otis.com/zh/cn/terms-use'
      },
      'China/99': {
        'label': 'China / English',
        'link': 'https://www.otis.com/zh/cn/terms-use'
      },
      'XIZI China/114': {
        'label': 'China / Simplified Chinese OE',
        'link': 'https://www.otis.com/zh/cn/terms-use'
      },
      'XIZI China/115': {
        'label': 'China / English OE',
        'link': 'https://www.otis.com/zh/cn/terms-use'
      },
      'France/48': {
        'label': 'France / French',
        'link': 'https://www.otis.com/fr/fr/conditions-generales-dutilisation'
      },
      'Germany/49': {
        'label': 'Germany / German',
        'link': 'https://www.otis.com/de/de/nutzungsbedingungen/'
      },
      'Hong Kong/24': {
        'label': 'Hong Kong / Traditional Chinese',
        'link': 'https://www.otis.com/zh/hk/terms-use'
      },
      'Hong Kong/23': {
        'label': 'Hong Kong / English',
        'link': 'https://www.otis.com/en/hk/terms-use'
      },
      'Singapore/64': {
        'label': 'Singapore / English',
        'link': 'https://www.otis.com/en/sg/terms-use'
      },
      'United States/67': {
        'label': 'United States / English',
        'link': 'https://www.otis.com/en/us/terms-use/'
      }
    }
    navigateTo(touLinks, 'terms-of-use');
  };

  // To listen AgreeToPrivacyPolicy click
  const extprivacy = function () {
    let pp_check = document.getElementById("extension_AgreeToPrivacyPolicy_1");
    let pp_checkVal = false;
    if (pp_check.checked) {
      pp_checkVal = true;
    }
    setTimeout(function () { pp_check.checked = pp_checkVal; }, 1000);
    let privacyLinks = {
      'Austria/44': {
        'label': 'Austria / German',
        'link': 'https://www.otis.com/de/at/rechtliches/datenschutzerkl%C3%A4rung'
      },
      'Canada/98': {
        'label': 'Canada / French',
        'link': 'https://www.otis.com/fr/ca/privacy-policy'
      },
      'Canada/103': {
        'label': 'Canada / English',
        'link': 'https://www.otis.com/en/us/privacy-policy'
      },
      'China/70': {
        'label': 'China / Simplified Chinese',
        'link': 'https://www.otis.com/zh/cn/privacy-policy'
      },
      'China/99': {
        'label': 'China / English',
        'link': 'https://www.otis.com/zh/cn/privacy-policy'
      },
      'XIZI China/114': {
        'label': 'China / Simplified Chinese OE',
        'link': 'https://www.otis.com/zh/cn/privacy-policy'
      },
      'XIZI China/115': {
        'label': 'China / English OE',
        'link': 'https://www.otis.com/zh/cn/privacy-policy'
      },
      'France/48': {
        'label': 'France / French',
        'link': 'https://www.otis.com/en/us/global-legal/#French'
      },
      'Germany/49': {
        'label': 'Germany / German',
        'link': 'https://www.otis.com/de/de/datenschutzerkl%C3%A4rung/'
      },
      'Hong Kong/24': {
        'label': 'Hong Kong / Traditional Chinese',
        'link': 'https://www.otis.com/zh/hk/privacy-policy'
      },
      'Hong Kong/23': {
        'label': 'Hong Kong / English',
        'link': 'https://www.otis.com/en/hk/privacy-policy'
      },
      'Singapore/64': {
        'label': 'Singapore / English',
        'link': 'https://www.otis.com/en/sg/privacy-policy'
      },
      'United States/67': {
        'label': 'United States / English',
        'link': 'https://www.otis.com/en/us/privacy-policy'
      },
      'Switzerland/93': {
        'label': 'Switzerland / French',
        'link': 'https://www.otis.com/corporate/privacy-policy/'
      },
      'United Kingdom/68': {
        'label': 'United Kingdom / English',
        'link': 'https://www.otis.com/corporate/privacy-policy/'
      }


      //https://www.otis.com/corporate/privacy-policy/
    }
    navigateTo(privacyLinks, 'privacy-policy');
  };

  const cancel = function () {
    window.location.href = "/";
  }

  useEffect(() => {
    setLoading(true);
    init();
    setFormErrors(validate(formValues));
    setLoading(false);
  }, [formValues]);


  return (
    <>
      <LoadingOverlay active={loading} spinner text={t('Processing')}>
        <ToastContainer />
        <div className="register">
          <div className="page-heading">
            <h1 className="regTitle">
              <T _str="Request Access to eView® Content Configurator" /></h1>
          </div>
          <p>&nbsp;</p>
          <div className="reg-copy">
            <span id="regInfo">
              <p><T _str="Please enter your contact information. An Otis Representative will validate your contract information and provide access to your account. Expect access within 24-48 hours." /></p>
            </span>
          </div>
          <div className="profile-inner">
            <form encType="#" method="post" id="user-profile-form" acceptCharset="UTF-8" onSubmit={handleSubmit} noValidate autoComplete="off">
              <div id="edit-account" className="form-wrapper">
                <div id="attributeVerification" role="form">
                  <div className="error pageLevel" id="claimVerificationServerError" style={{ display: 'none' }} aria-hidden="true" />
                  <div className="verificationInfoText" id="email_intro">{emailerror}</div>
                  {verifycode && (
                    <div className="verificationInfoText" id="email_intro"></div>)}
                  {changeemail && (
                    <div className="verificationInfoText" id="email_intro"></div>)}
                  <div className="verificationErrorText error" id="email_fail_retry" aria-hidden="false" role="alert" aria-live="polite">{verifyerror}</div>
                  <div className="attr" id="attributeList">
                    <ul>
                      <li className="TextBox">
                        <div className="attrEntry validate">
                          <label>*Email</label>
                          <input type="email" name="email" id="email" value={formValues.email} title={t("Email address that can be used to contact you.")} required aria-required="true" onChange={handleChange} onBlur={handleChange} className={formErrors.email && "input-error"} placeholder={t("*Email")} pattern="^[a-zA-Z0-9!#$%&'+^_`{}~-]+(?:\.[a-zA-Z0-9!#$%&'+^_`{}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$" autoComplete="off" aria-autocomplete="none"/>
                          {formErrors.email && (
                            <span className="error">{t(formErrors.email)}</span>
                          )}
                          <div className="buttons verify" claim_id="email">
                            {!changeemail && !verifycode && !resetpass && (<button id="email_ver_but_send" className="sendButton" type="button" aria-label="Send verification code" aria-hidden="true" onClick={() => sendOtp()}>{t("Send verification code")}</button>)}
                            {verifycode && (<div>
                              <label id="email_ver_input_label" aria-hidden="true" style={{ display: 'inline' }} >{t("*Verification code")}</label>
                              <input id="email_ver_input" className="verifyInput" type="text" placeholder={t("*Verification code")} name="otp" value={userOtp} onChange={(e) => { Otp(e.target.value) }} />
                              <div className="buttons verify">
                                <div id="email_success_screenreader_msg" aria-label="E-mail address verified. You can now continue."></div>
                                <button id="email_ver_but_verify" className="verifyButton" type="button" aria-label="Verify code" aria-hidden="false" onClick={() => verifyOTP()}>{t("Verify code")}</button>
                                <button id="email_ver_but_resend" className="sendButton" type="button" aria-label="Send new code" aria-hidden="false" onClick={() => sendOtp()}>{t("Send new code")}</button>
                              </div>
                            </div>
                            )}

                            {changeemail && !resetpass && (
                              <div>
                                <button id="email_ver_but_edit" className="editButton" type="button" onClick={() => mailchange()} aria-label="Change e-mail" aria-hidden="false">{t("Change e-mail")}</button>
                              </div>
                            )}
                          </div>
                        </div>
                      </li>
                      <li className="Password">
                        <div className="attrEntry validate">
                          <label>{t("*Password")}</label>
                          <input id="password" name="password" className="textInput invalid" onChange={handleChange} onBlur={handleChange} type="password" placeholder={pwd} title={t("Enter new password")} required aria-required="true" autoComplete="off" aria-autocomplete="none" />
                          {formErrors.password && (
                            <span className="error" dangerouslySetInnerHTML={{ __html: formErrors.password }}></span>
                          )}
                        </div>
                      </li>
                      <li className="Password">
                        <div className="attrEntry validate">
                          <label>{t("*Confirm Password")}</label>
                          <input id="reenterPassword" name="reenterPassword" className="textInput invalid" onChange={handleChange} onBlur={handleChange} type="password" placeholder={t("*Confirm Password")} title={t("Confirm new password")} required aria-required="true" aria-autocomplete="none" />
                          {formErrors.reenterPassword && (
                            <span className="error" dangerouslySetInnerHTML={{ __html: formErrors.reenterPassword }}></span>
                          )}
                        </div>
                      </li>
                      <li className="DropdownSingleSelect">
                        <div className="attrEntry">
                          <label>Prefix</label>
                          <div className="error itemLevel" aria-hidden="true"><p /></div>
                          <select className="dropdown_single" id="extension_Prefix" name="prefix" onChange={handleChange} onBlur={handleChange}><option style={{ display: 'none' }} disabled="disabled" value selected>{t("Prefix")}</option><option value={0}>{t("Mr.")}</option><option value={1}>{t("Ms.")}</option></select>
                        </div>
                      </li>
                      <li className="TextBox">
                        <div className="attrEntry">
                          <label>{t("*First Name")}</label>
                          <div className="error itemLevel" aria-hidden="true"><p /></div>
                          <input id="givenName" name="givenName" className="textInput" type="text" placeholder={t("*First Name")} title={t("Your given name (also known as first name).")} required aria-required="true" onChange={handleChange} onBlur={handleChange} aria-autocomplete="none" />
                        </div>
                      </li>
                      <li className="TextBox">
                        <div className="attrEntry">
                          <label>{t("*Last Name")}</label>
                          <div className="error itemLevel" aria-hidden="true"><p /></div>
                          <input id="surname" name="surname" className="textInput" type="text" placeholder={t("*Last Name")} title={t("Your surname (also known as family name or last name).")} required aria-required="true" onChange={handleChange} onBlur={handleChange} aria-autocomplete="none" />
                        </div>
                      </li>
                      <li className="TextBox">
                        <div className="attrEntry">
                          <label>{t("*Company")}</label>
                          <div className="error itemLevel" aria-hidden="true"><p /></div>
                          <input id="extension_Company" name="extension_Company" className="textInput" type="text" placeholder={t("*Company")} required aria-required="true" onChange={handleChange} onBlur={handleChange}  aria-autocomplete="none" />
                        </div>
                      </li>
                      <li className="TextBox">
                        <div className="attrEntry">
                          <label>{t("*Street Address 1")}</label>
                          <div className="error itemLevel" aria-hidden="true"><p /></div>
                          <input id="streetAddress" name="streetAddress" className="textInput" type="text" placeholder={t("*Street Address 1")} title={t("The street address at which you are located.")} required aria-required="true" onChange={handleChange} onBlur={handleChange} aria-autocomplete="none" />
                        </div>
                      </li>
                      <li className="TextBox">
                        <div className="attrEntry">
                          <label>{t("Street Address 2")}</label>
                          <div className="error itemLevel" aria-hidden="true"><p /></div>
                          <input id="extension_StreetAddress2" name="extension_StreetAddress2" className="textInput" type="text" placeholder={t("Street Address 2")} title={t("Street Address 2")} onChange={handleChange} onBlur={handleChange} aria-autocomplete="none" />
                        </div>
                      </li>
                      <li className="TextBox">
                        <div className="attrEntry">
                          <label>{t("*Postal Code")}</label>
                          <div className="error itemLevel" aria-hidden="true"><p /></div>
                          <input id="postalCode" className="textInput" name="postalCode" type="text" placeholder={t("*Postal Code")} title={t("The postal code of your address.")} required aria-required="true" onChange={handleChange} onBlur={handleChange} aria-autocomplete="none" />
                        </div>
                      </li>
                      <li className="DropdownSingleSelect">
                        <div className="attrEntry">
                          <label htmlFor="country">{t("*Country / Language")}</label>
                          <div className="error itemLevel" aria-hidden="true"><p /></div>
                          <select className="dropdown_single" name="country" id="country" onChange={country} onBlur={country}  >
                            <option style={{ display: 'none' }} disabled="disabled" value="" selected>{t("*Country / Language")}</option>
                            <option value="Australia/43">{t("Australia / English")}</option>
                            <option value="Austria/44">{t("Austria / German")}</option>
                            <option value="Belgium/72">{t("Belgium / Dutch")}</option>
                            <option value="Belgium/143">{t("Belgium / English")}</option>
                            <option value="Belgium/104">{t("Belgium / French")}</option>
                            <option value="Bahrain/169">{t("Bahrain / English")}</option>
                            <option value="Canada/103">{t("Canada / English")}</option>
                            <option value="Canada/98">{t("Canada / French")}</option>
                            <option value="Czech Republic/149">{t("Czech Republic / English")}</option>
                            <option value="Denmark/144">{t("Denmark / English")}</option>
                            <option value="Estonia/147">{t("Estonia / English")}</option>
                            <option value="Finland/146">{t("Finland / English")}</option>
                            <option value="France/48">{t("France / French")}</option>
                            <option value="Germany/49">{t("Germany / German")}</option>
                            <option value="Hong Kong/23">{t("Hong Kong / English")}</option>
                            <option value="India/59">{t("India / English")}</option>
                            <option value="Ireland/52">{t("Ireland / English")}</option>
                            <option value="Italy/53">{t("Italy / Italian")}</option>
                            <option value="Japan/151">{t("Japan / English")}</option>
                            <option value="Japan/54">{t("Japan / Japanese")}</option>
                            <option value="Korea/152">{t("Korea / English")}</option>
                            <option value="Korea/55">{t("Korea / Korean")}</option>
                            <option value="Kuwait/56">{t("Kuwait / English")}</option>
                            <option value="Luxembourg/156">{t("Luxembourg / Dutch")}</option>
                            <option value="Luxembourg/84">{t("Luxembourg / French")}</option>
                            <option value="Luxembourg/153">{t("Luxembourg / English")}</option>
                            <option value="Luxembourg/188">{t("Luxembourg / Luxembourgish")}</option>
                            <option value="Netherlands/85">{t("Netherlands / Dutch")}</option>
                            <option value="Norway/134">{t("Norway / English")}</option>
                            <option value="Poland/148">{t("Poland / English")}</option>
                            <option value="Qatar/167">{t("Qatar / English")}</option>
                            <option value="Singapore/64">{t("Singapore / English")}</option>
                            <option value="Slovakia/150">{t("Slovakia / English")}</option>
                            <option value="Slovakia/89">{t("Slovakia / Slovakian")}</option>
                            <option value="Spain/35">{t("Spain / Spanish")}</option>
                            <option value="Sweden/145">{t("Sweden / English")}</option>
                            <option value="Switzerland/93">{t("Switzerland / French")}</option>
                            <option value="Switzerland/100">{t("Switzerland / German")}</option>
                            <option value="Switzerland/101">{t("Switzerland / Italian")}</option>
                            <option value="Saudi Arabia/29">{t("Saudi Arabia / English")}</option>
                            <option value="United Kingdom/68">{t("United Kingdom  / English")}</option>
                            <option value="United States/67">{t("United States / English")}</option>
                            <option value="United States/155">{t("United States / French")}</option>
                            <option value="United States/154">{t("United States / Spanish")}</option>
                            <option value="UAE/166">{t("UAE / English")}</option></select>
                        </div>
                      </li>
                      <li className="DropdownSingleSelect select-small">
                        <div className="attrEntry">
                          <label htmlFor="extension_CountryCode2">--</label>
                          <div className="error itemLevel" aria-hidden="true"><p /></div>
                          <select className="dropdown_single" id="extension_CountryCode2" name="extension_CountryCode2" onChange={extension_CountryCode2} onBlur={extension_CountryCode2} value={selectedItem}><option value selected>--</option><option value={+1}>+1</option><option value={+31}>+31</option><option value={+32}>+32</option><option value={+33}>+33</option><option value={+34}>+34</option><option value={+352}>+352</option><option value={+353}>+353</option><option value={+358}>+358</option><option value={+372}>+372</option><option value={+39}>+39</option><option value={+41}>+41</option><option value={+420}>+420</option><option value={+421}>+421</option><option value={+43}>+43</option><option value={+44}>+44</option><option value={+45}>+45</option><option value={+46}>+46</option><option value={+47}>+47</option><option value={+48}>+48</option><option value={+49}>+49</option><option value={+61}>+61</option><option value={+65}>+65</option><option value={+81}>+81</option><option value={+82}>+82</option><option value={+852}>+852</option><option value={+86}>+86</option><option value={+91}>+91</option><option value={+973}>+973</option><option value={+965}>+965</option><option value={+974}>+974</option><option value={+966}>+966</option><option value={+971}>+971</option></select>
                        </div>
                      </li>
                      <li className="TextBox number-small">
                        <div className="attrEntry">
                          <label htmlFor="extension_MobileNumber">{t("*Mobile Number")}</label>
                          <div className="error itemLevel" aria-hidden="true"><p /></div>
                          <input id="extension_MobileNumber" className="textInput" name="extension_MobileNumber" type="text" title={t("Please fill in this field.")} placeholder={t("*Mobile Number")} required aria-required="true" onChange={handleChange} onBlur={handleChange}  aria-autocomplete="none" /><div className="phoneHelp " />
                        </div>
                      </li>
                      <li className="DropdownSingleSelect select-small">
                        <div className="attrEntry">
                          <label htmlFor="extension_CountryCode1">--</label>
                          <div className="error itemLevel" aria-hidden="true"><p /></div>
                          <select className="dropdown_single" id="extension_CountryCode1" name="extension_CountryCode1" onChange={extension_CountryCode1} onBlur={extension_CountryCode1} value={selectedItem1} ><option value selected>--</option><option value={+1}>+1</option><option value={+31}>+31</option><option value={+32}>+32</option><option value={+33}>+33</option><option value={+34}>+34</option><option value={+352}>+352</option><option value={+353}>+353</option><option value={+358}>+358</option><option value={+372}>+372</option><option value={+39}>+39</option><option value={+41}>+41</option><option value={+420}>+420</option><option value={+421}>+421</option><option value={+43}>+43</option><option value={+44}>+44</option><option value={+45}>+45</option><option value={+46}>+46</option><option value={+47}>+47</option><option value={+48}>+48</option><option value={+49}>+49</option><option value={+61}>+61</option><option value={+65}>+65</option><option value={+81}>+81</option><option value={+82}>+82</option><option value={+852}>+852</option><option value={+86}>+86</option><option value={+91}>+91</option><option value={+973}>+973</option><option value={+965}>+965</option><option value={+974}>+974</option><option value={+966}>+966</option><option value={+971}>+971</option></select>
                        </div>
                      </li>
                      <li className="TextBox number-small">
                        <div className="attrEntry">
                          <label htmlFor="extension_Phone">{t("Alternate Number")}</label>
                          <div className="error itemLevel" aria-hidden="true"><p /></div>
                          <input id="extension_Phone" name="extension_Phone" className="textInput" type="text" placeholder={t("Alternate Number")} onChange={handleChange} onBlur={handleChange} aria-autocomplete="none" />
                        </div>
                      </li>
                      <li className="TextBox">
                        <div className="attrEntry">
                          <label htmlFor="jobTitle">{t("*Position")}</label>
                          <div className="error itemLevel" aria-hidden="true"><p /></div>
                          <input id="jobTitle" className="textInput" name="jobTitle" type="text" placeholder={t("*Position")} title={t("Position")} required aria-required="true" onChange={handleChange} onBlur={handleChange} aria-autocomplete="none" />
                        </div>
                      </li>
                      <li className="CheckboxMultiSelect">
                        <div className="attrEntry">
                          <label>{t("AgreeToTerms")}</label>
                          <div className="error itemLevel" aria-hidden="true"><p /></div>
                          <input id="extension_AgreeToTerms_1" name="extension_AgreeToTerms" type="checkbox" defaultValue={1} /><label htmlFor="extension_AgreeToTerms_1" onClick={extAgree}>{t("*Terms of Use")}</label>
                          <div className="touHelp">- {t("By checking the Terms of Use checkbox, you have read and agree to our Terms of Use.")}</div>
                        </div>
                      </li>
                      <li className="CheckboxMultiSelect">
                        <div className="attrEntry">
                          <label>{t("AgreeToPrivacyPolicy")}</label>
                          <div className="error itemLevel" aria-hidden="true"><p /></div>
                          <input id="extension_AgreeToPrivacyPolicy_1" name="extension_AgreeToPrivacyPolicy" type="checkbox" defaultValue={1} /><label htmlFor="extension_AgreeToPrivacyPolicy_1" onClick={extprivacy}>{t("*Privacy Policy")}</label>
                          <div className="privacyHelp">- {t("By checking the Privacy Policy checkbox, you have read and agree to our Privacy Policy, including our Cookie Use.")}</div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="buttons">
                    <button id="continue" aria-label="Continue" disabled style={{ backgroundColor: 'rgb(204, 204, 204)', color: 'rgb(0, 0, 0)', border: '1px solid transparent', cursor: 'not-allowed' }}>{t("Continue")}</button>
                    <button id="cancel" aria-label="Cancel" formNoValidate onClick={cancel}>{t("Cancel")}</button>
                  </div>
                  <div className="verifying-modal">
                    <div id="verifying_blurb" />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </LoadingOverlay>
    </>
  );
};
export default Register;
