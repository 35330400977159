/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: AddDevice.js
File Description: Implements add device functionality of CMS portal

Copyright 2022 - 2024 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
import { useState, useEffect } from 'react';
import { T, useT } from '@transifex/react';
import { t } from "@transifex/native"
import axios from 'axios';
import config from '../utils/config';
import LoadingOverlay from "react-loading-overlay";
import '../css/AddDevice.css';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Buffer } from 'buffer';

function AddDevice() {

  // Variables and States
  const tabTitle = useT("Create Device");
  const [countriesData, setCountriesData] = useState([]);
  const [themesData, setThemesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  // Form Data
  const [formData, setFormData] = useState({
    title: "",
    mpdid: "",
    buildingName: "",
    unitNumber: "",
    contractID: "",
    buildingID: "",
    streetAddress: "",
    deviceCity: "",
    deviceState: "",
    devicePin: "",
    deviceType: "infotainment-device",
    feedRefresh: "1440",
    bgMusic: false,
    bgMusicValue: 50,
    deviceCountry: "",
    deviceTheme: "",
    deviceStatus: "Disconnected",
    seqNumber: "00000",
    selectedTheme: "",
    themeDownloadID: "",
    themeDStatus: "",
    infoMsg: ""
  })

  // Get logged in user details
  let userDetails = localStorage.getItem("userdetails");
  let loggedInUserID = '';
  if (userDetails === null) {
    loggedInUserID = 1;
  } else {
    loggedInUserID = JSON.parse(userDetails).id;
  }

  useEffect(() => {
    // Tab title
    document.title = tabTitle + ' | CMS';

    // Fetch Counries
    const fetchCountries = async () => {
      await axios.get(config.drupal_url + '/api/get-countries', { withCredentials: true })
      .then(response => {
        setCountriesData(response.data.rows);
      })
      .catch(function (error) { })
    };
    fetchCountries();

    // Fetch Themes
    const fetchThemes = async () => {
      setLoading(true);
      await axios.get(config.drupal_url + '/api/themes', { withCredentials: true })
      .then(response => {
        const themesResData = response.data.rows;
        themesResData.forEach((key, index) => {
          key.theme = key.theme.replace(/&amp;/g, '&');
        });
        setThemesData(themesResData);
        setLoading(false);
      })
      .catch(function (error) { })
    };
    fetchThemes();
  }, [tabTitle])

  // Validate form
  const handleValidation = (formData) => {
    let formValid = true;
    if (formData.title === "") {
      let errorMsg = t('!name field is required.').replace('!name', t('Elevator Name'));
      toast.error(errorMsg);
      formValid = false;
    }
    if (formData.mpdid === "") {
      let errorMsg = t('!name field is required.').replace('!name', t('CP-ID'));
      toast.error(errorMsg);
      formValid = false;
    }
    if (formData.mpdid !== "") {
      // Format Validation
      let value = formData.mpdid;
      const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
      if(!regexExp.test(value)) {
        let errorMsg = t('Device UUID format is not valid.');
        toast.error(errorMsg);
        formValid = false;
      }
    }
    if (formData.buildingName === "") {
      let errorMsg = t('!name field is required.').replace('!name', t('Building Name (same as in G3MS)'));
      toast.error(errorMsg);
      formValid = false;
    }
    if (formData.unitNumber === "") {
      let errorMsg = t('!name field is required.').replace('!name', t('Unit Number (same as in G3MS)'));
      toast.error(errorMsg);
      formValid = false;
    }
    if (formData.deviceCountry === "") {
      let errorMsg = t('!name field is required.').replace('!name',  t('Country'));
      toast.error(errorMsg);
      formValid = false;
    }
    if (formData.deviceTheme === "") {
      let errorMsg = t('!name field is required.').replace('!name',  t('Select Theme'));
      toast.error(errorMsg);
      formValid = false;
    }

    return formValid;
  }

  // Duplicate CPID validation
  const duplicateCpId = async (uuid) => {
    let validCpId = true;
    const validateURL = config.drupal_url + '/api/validate-device-uuid?uuid='+uuid;
    await axios.get(validateURL).then((response) => {
      if (response.data.count > 0) {
        validCpId = false;
      }
    });
    return validCpId;
  }

  // Save Device
  const handleDeviceSubmit = (e) => {
    let validForm = handleValidation(formData);
    if (validForm) {
      duplicateCpId(formData.mpdid)
      .then((res) => {
        if (res) {
          axios.get(config.drupal_url + '/session/token').then(result => {
            console.log(result);
            if (200 === result.status) {
              const csrfToken = result.data;
              setLoading(true);
              // POST url
              const saveDeviceUrl = config.drupal_url + '/node?_format=hal_json';
              //let login = config.auth_username;
              //let password = config.auth_password;
              //const encodedString = Buffer.from(`${login}:${password}`).toString('base64');

              // Headers
              const headers = {
                "Content-Type": "application/hal+json",
                withCredentials: true,
                'X-CSRF-Token': csrfToken,
              }

              // Data
              let data = {
                "_links": {
                  "type": {
                    "href": config.rest_node_url +  "/rest/type/node/screen_configuration"
                  }
                },
                "title": {
                  "value": formData.title.trim()
                },
                "field_mpdid" : {
                  "value": formData.mpdid.trim()
                },
                "field_building_names": {
                  "value": formData.buildingName.trim()
                },
                "field_eserviceunitid": {
                  "value": formData.unitNumber.trim()
                },
                "field_contractid": {
                  "value": formData.contractID.trim()
                },
                "field_buildingid": {
                  "value": formData.buildingID.trim()
                },
                "field_unit_streetaddress": {
                  "value": formData.streetAddress.trim()
                },
                "field_unit_city": {
                  "value": formData.deviceCity.trim()
                },
                "field_unit_state": {
                  "value": formData.deviceState.trim()
                },
                "field_unit_postal_code": {
                  "value": formData.devicePin.trim()
                },
                "field_cpibdevicetype": {
                  "value": formData.deviceType
                },
                "field_live_feed_refresh_rate": {
                  "value": formData.feedRefresh
                },
                "field_country_nid": [{
                  "target_id": formData.deviceCountry
                }],
                "field_background_music_on": {
                  "value": formData.bgMusic
                },
                "field_background_music_volume_in": {
                  "value": formData.bgMusicValue
                },
                "field_infotainment_message": {
                  "value": formData.infoMsg
                },
                "field_select_theme": [{
                  "target_id": formData.deviceTheme
                }],
                "field_device_status": {
                  "value": formData.deviceStatus
                },
                "field_sequence_number": {
                  "value": formData.seqNumber.trim()
                },
                "field_selected_theme_id": {
                  "value": formData.selectedTheme.trim()
                },
                "field_theme_download_id": {
                  "value": formData.themeDownloadID.trim()
                },
                "field_theme_downloadstatus": {
                  "value": formData.themeDStatus.trim()
                },
                "uid": [{
                  "target_id": loggedInUserID
                }]
              };

              // POST CALL
              axios.post(saveDeviceUrl, JSON.stringify(data), {headers})
              .then((result) => {
                if (result.status == 201) {
                  setLoading(false);
                  history.push({
                    pathname: "/deviceslist",
                    device_title: formData.title
                  });
                }
              })
            }
          })
        } else {
          toast.error(t("Device ID already exists. Please use a different ID."));
        }
      })
    }
  }

  return (
    <LoadingOverlay active={loading} spinner text={t('Processing')}>
      <ToastContainer />
      <div className="headerSection">
        <h1 className="pageTitle">
          eView<sup>&reg;</sup> - <T _str="Create Device" />
        </h1>
        <button
          type="submit"
          value="Save"
          className="actions_button submit_form addDevices"
          onClick={handleDeviceSubmit}
        >
          <T _str="Save" />
        </button>
      </div>
      <div className="addDeviceWrapper" id="addDeviceWrapper">
        <form className="addDeviceForm" id="addDeviceForm">
          <div className="actions_div" id="actions_div">
          </div>
          <div className="fieldGroup-50">
            <div className="deviceFieldLeft">
              <label htmlFor="deviceTitle" className="title"><T _str="Elevator Name" /><span>*</span></label>
              <div className="deviceTitle" id="deviceTitle">
                <input
                  className="titleField element_input"
                  id="titleField"
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={(e) => setFormData({...formData, title: e.target.value})}
                />
              </div>
            </div>
            <div className="deviceFieldRight">
              <label htmlFor="mpdid" className="mpdid"><T _str="CP-ID" /><span> *</span></label>
              <div className="mpdid" id="mpdid">
                <input
                  className="mpdid element_input"
                  id="mpdid"
                  type="text"
                  name="mpdid"
                  value={formData.mpdid}
                  onChange={(e) => setFormData({...formData, mpdid: e.target.value})}
                />
              </div>
            </div>
          </div>
          <div className="fieldGroup-50">
            <div className="deviceFieldLeft">
              <label htmlFor="buildingName" className="buildingName"><T _str="Building Name (same as in G3MS)" /><span> *</span></label>
              <div className="buildingName" id="buildingName">
                <input
                  className="buildingName element_input"
                  id="buildingName"
                  type="text"
                  name="buildingName"
                  value={formData.buildingName}
                  onChange={(e) => setFormData({...formData, buildingName: e.target.value})}
                />
              </div>
            </div>
            <div className="deviceFieldRight">
              <label htmlFor="unitNumber" className="unitNumber"><T _str="Unit Number (same as in G3MS)" /><span> *</span></label>
              <div className="unitNumber" id="unitNumber">
                <input
                  className="unitNumber element_input"
                  id="unitNumber"
                  type="text"
                  name="unitNumber"
                  value={formData.unitNumber}
                  onChange={(e) => setFormData({...formData, unitNumber: e.target.value})}
                />
              </div>
            </div>
          </div>
          <div className="fieldGroup-50">
            <div className="deviceFieldLeft">
              <label htmlFor="contractID" className="contractID"><T _str="Contract ID" /></label>
              <div className="contractID" id="contractID">
                <input
                  className="contractID element_input"
                  id="contractID"
                  type="text"
                  name="contractID"
                  value={formData.contractID}
                  onChange={(e) => setFormData({...formData, contractID: e.target.value})}
                />
              </div>
            </div>
            <div className="deviceFieldRight">
              <label htmlFor="buildingID" className="buildingID"><T _str="Building ID" /></label>
              <div className="buildingID" id="buildingID">
                <input
                  className="buildingID element_input"
                  id="buildingID"
                  type="text"
                  name="buildingID"
                  value={formData.buildingID}
                  onChange={(e) => setFormData({...formData, buildingID: e.target.value})}
                />
              </div>
            </div>
          </div>
          <div className="fieldGroup-50">
            <div className="deviceFieldLeft">
              <label htmlFor="streetAddress" className="streetAddress"><T _str="Street Address" /></label>
              <div className="streetAddress" id="streetAddress">
                <input
                  className="streetAddress element_input"
                  id="streetAddress"
                  type="text"
                  name="streetAddress"
                  value={formData.streetAddress}
                  onChange={(e) => setFormData({...formData, streetAddress: e.target.value})}
                />
              </div>
            </div>
            <div className="deviceFieldRight">
              <label htmlFor="deviceCity" className="deviceCity"><T _str="City" /></label>
              <div className="deviceCity" id="deviceCity">
                <input
                  className="deviceCity element_input"
                  id="deviceCity"
                  type="text"
                  name="deviceCity"
                  value={formData.deviceCity}
                  onChange={(e) => setFormData({...formData, deviceCity: e.target.value})}
                />
              </div>
            </div>
          </div>
          <div className="fieldGroup-50">
            <div className="deviceFieldLeft">
              <label htmlFor="deviceState" className="deviceState"><T _str="State" /></label>
              <div className="deviceState" id="deviceState">
                <input
                  className="deviceState element_input"
                  id="deviceState"
                  type="text"
                  name="deviceState"
                  value={formData.deviceState}
                  onChange={(e) => setFormData({...formData, deviceState: e.target.value})}
                />
              </div>
            </div>
            <div className="deviceFieldRight">
              <label htmlFor="devicePin" className="devicePin"><T _str="Postal Code" /></label>
              <div className="devicePin" id="devicePin">
                <input
                  className="devicePin element_input"
                  id="devicePin"
                  type="text"
                  name="devicePin"
                  value={formData.devicePin}
                  onChange={(e) => setFormData({...formData, devicePin: e.target.value})}
                />
              </div>
            </div>
          </div>
          <div className="fieldGroup-50">
            <div className="deviceFieldLeft">
              <label htmlFor="deviceType" className="deviceType"><T _str="Device Type" /></label>
              <div className="deviceType" id="deviceType">
                <select
                  className="deviceType element_input"
                  id="deviceType"
                  name="deviceType"
                  value={formData.deviceType}
                  onChange={(e) => setFormData({...formData, deviceType: e.target.value})}
                >
                  <option value="none">{t('None')}</option>
                  <option value="eView2-device">{t('eView2')}</option>
                  <option value="infotainment-device">{t('Infotainment')}</option>
                  <option value="full-content">{t('Fullcontent')}</option>
                </select>
              </div>
            </div>
            <div className="deviceFieldRight">
              <label htmlFor="feedRefresh" className="feedRefresh"><T _str="Live Feed Refresh Rate" /></label>
              <div className="feedRefresh" id="feedRefresh">
                <select
                  className="feedRefresh element_input"
                  id="feedRefresh"
                  name="feedRefresh"
                  value={formData.feedRefresh}
                  onChange={(e) => setFormData({...formData, feedRefresh: e.target.value})}
                >
                  <option value="none">{t('None')}</option>
                  <option value="1440">{t('Once per day')}</option>
                  <option value="720">{t('Twice per day')}</option>
                  <option value="480">{t('Three times per day')}</option>
                  <option value="360">{t('Four times per day')}</option>
                  <option value="240">{t('Six times per day')}</option>
                </select>
              </div>
            </div>
          </div>
          {formData.deviceType === "eView2-device" &&
            <div className="fieldGroup-50">
              <div className="deviceFieldLeft">
                <label htmlFor="bgMusic" className="bgMusic"><T _str="Background Music" /></label>
                <div className="bgMusic" id="bgMusic">
                  <select
                    className="bgMusic element_input"
                    id="bgMusic"
                    name="bgMusic"
                    value={formData.bgMusic}
                    onChange={(e) => setFormData({...formData, bgMusic: e.target.value})}
                  >
                    <option value="true">{t('ON')}</option>
                    <option value="false">{t('OFF')}</option>
                  </select>
                </div>
              </div>
              <div className="deviceFieldRight">
                <label htmlFor="bgMusicValue" className="bgMusicValue"><T _str="Background Music Volume in Percent" /></label>
                <div className="bgMusicValue" id="bgMusicValue">
                  <input
                    className="bgMusicValue element_input"
                    id="bgMusicValue"
                    type="number"
                    name="bgMusicValue"
                    min={0}
                    max={100}
                    value={formData.bgMusicValue}
                    onChange={(e) => setFormData({...formData, bgMusicValue: e.target.value})}
                  />
                </div>
              </div>
            </div>
          }
          <div className="fieldGroup-50">
            <div className="deviceFieldLeft">
              <label htmlFor="deviceCountry" className="deviceCountry"><T _str="Country" /><span> *</span></label>
              <div className="deviceCountry" id="deviceCountry">
                <select
                  className="deviceCountry element_input"
                  id="deviceCountry"
                  name="deviceCountry"
                  value={formData.deviceCountry}
                  onChange={(e) => setFormData({...formData, deviceCountry: e.target.value})}
                >
                  <option value="none">-Select a value-</option>
                  {countriesData.map((country, index) => (
                    <option value={country.nid}>{country.title}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          {formData.deviceType === "infotainment-device" &&
            <div className="fieldGroup-50">
              <label htmlFor="infoMsg" className="infoMsg"><T _str="Infotainment Message" /></label>
              <div className="infoMsg" id="infoMsg">
                <textarea
                  className="infoMsg element_input"
                  id="infoMsg"
                  name="infoMsg"
                  value={formData.infoMsg}
                  onChange={(e) => setFormData({...formData, infoMsg: e.target.value})}
                ></textarea>
              </div>
              <div className="noteMsg">
                <p>Note: A maximum of 75 characters or 6 lines are allowed.</p>
              </div>
            </div>
          }
          <div className="fieldGroup-100">
            <label htmlFor="deviceTheme" className="deviceTheme"><T _str="Select Theme" /><span> *</span></label>
            <div className="deviceTheme" id="deviceTheme">
              {themesData.map((theme, index) => (
                <>
                  <input
                    type="radio"
                    value={theme.nid}
                    className="deviceTheme"
                    id="deviceTheme"
                    name="deviceTheme"
                    onChange={(e) => setFormData({...formData, deviceTheme: e.target.value})}
                  />
                  <img src={theme.theme} width={100} height={56} alt="Theme Image" />
                </>
              ))}
            </div>
          </div>
          <div className="fieldGroup-50">
            <div className="deviceFieldLeft">
              <label htmlFor="deviceStatus" className="deviceStatus"><T _str="Device Status" /></label>
              <div className="deviceStatus" id="deviceStatus">
                <select
                  className="deviceStatus element_input"
                  id="deviceStatus"
                  name="deviceStatus"
                  value={formData.deviceStatus}
                  onChange={(e) => setFormData({...formData, deviceStatus: e.target.value})}
                >
                  <option value="Connected">{t('Connected')}</option>
                  <option value="Disconnected">{t('Disconnected')}</option>
                </select>
              </div>
            </div>
            <div className="deviceFieldRight">
              <label htmlFor="seqNumber" className="seqNumber"><T _str="Sequence Number" /></label>
              <div className="seqNumber" id="seqNumber">
                <input
                  className="seqNumber element_input"
                  id="seqNumber"
                  type="text"
                  name="seqNumber"
                  value={formData.seqNumber}
                  onChange={(e) => setFormData({...formData, seqNumber: e.target.value})}
                />
              </div>
            </div>
          </div>
          <div className="fieldGroup-50">
            <div className="deviceFieldLeft">
              <label htmlFor="selectedTheme" className="selectedTheme"><T _str="selected theme id" /></label>
              <div className="selectedTheme" id="selectedTheme">
                <input
                  className="selectedTheme element_input"
                  id="selectedTheme"
                  type="text"
                  name="selectedTheme"
                  value={formData.selectedTheme}
                  onChange={(e) => setFormData({...formData, selectedTheme: e.target.value})}
                />
              </div>
            </div>
            <div className="deviceFieldRight">
              <label htmlFor="themeDownloadID" className="themeDownloadID"><T _str="Theme_download_id" /></label>
              <div className="themeDownloadID" id="themeDownloadID">
                <input
                  className="themeDownloadID element_input"
                  id="themeDownloadID"
                  type="text"
                  name="themeDownloadID"
                  value={formData.themeDownloadID}
                  onChange={(e) => setFormData({...formData, themeDownloadID: e.target.value})}
                />
              </div>
            </div>
          </div>
          <div className="fieldGroup-50">
            <div className="deviceFieldLeft">
              <label htmlFor="themeDStatus" className="themeDStatus"><T _str="Theme_downloadstatus" /></label>
              <div className="themeDStatus" id="themeDStatus">
                <textarea
                  className="themeDStatus
                  element_input"
                  id="themeDStatus"
                  name="themeDStatus"
                  value={formData.themeDStatus}
                  onChange={(e) => setFormData({...formData, themeDStatus: e.target.value})}
                ></textarea>
              </div>
            </div>
          </div>
        </form>
      </div>
    </LoadingOverlay>
  )
}

export default AddDevice