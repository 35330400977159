import React from 'react';
import style from './Screens.module.css';
import { Modal } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { useState, useEffect } from 'react';
import AddPlaylistsPop from '../Playlist/AddPlaylistsPop';
import { useCookies } from "react-cookie";
import { T } from '@transifex/react';
import '../../css/screens.css';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import $ from "jquery";
import { t } from '@transifex/native';
import axios from 'axios';
import config from '../../utils/config';
import LoadingOverlay from "react-loading-overlay";

const AddPlaylistsModal = (props) => {
  var userdetails = localStorage.getItem('userdetails');
  const customId = userdetails && JSON.parse(userdetails).id;

  const notify = (val) => {

    toast.error(val, {

      toastId: customId

    });

  }
  const [isloading, setLoading] = useState(false);
  const [unitRemoved, SetUnitRemoved] = useState(true);
  const [createPlaylistField, SetCreatePlaylistField] = useState(false);
  const [playlistName, setPlaylistName] = useState("")
  const history = useHistory();
  const [cookies, setCookie] = useCookies(["playlistName"]);

  var schedulePlaylistData = JSON.parse(localStorage.getItem('schedulePlaylist'));
  var scheduledScreenids = [...new Set(schedulePlaylistData.screenIds)];

  const handleNameChange = (e) => {
    setPlaylistName(e.target.value)
  }

  var offlineUnits = JSON.parse(localStorage.getItem("offlineUnits") || '[]');
  var typeCheck = '';
  var offline = [];
  props.screensData.forEach((group, index) => {
    group.screens.forEach((screens, index) => {
      if (scheduledScreenids.includes(screens.screen_id)) {
        if (!typeCheck) {
          typeCheck = screens.device_type;
        } else {
          if (screens.device_type !== typeCheck) {
            typeCheck = 'error';
          }
        }
        // Check device status
        if (offlineUnits.includes(screens.screen_id)) {
          offline.push([screens.screen_id] + '&&&&' + [screens.screen_title]);
        }
      }
    })
  })

  schedulePlaylistData.ScheduleScreenType = typeCheck;
  localStorage.setItem('schedulePlaylist', JSON.stringify(schedulePlaylistData));
  var offlineunits = [...new Set(offline)];

  const existingPlaylistCallback = () => {
    schedulePlaylistData.source = 'screens-page-existing-paylist';
    localStorage.setItem('schedulePlaylist', JSON.stringify(schedulePlaylistData));
    history.push('select-playlist');
  };

  const createPlaylistCallback = () => {
    SetCreatePlaylistField(true);
    schedulePlaylistData.source = 'screens-page-create-playlist';
    localStorage.setItem('schedulePlaylist', JSON.stringify(schedulePlaylistData));
    //history.push('create-playlist');
  };


  const removeunit = (nid) => {
    const upadtedUnits = schedulePlaylistData.screenIds.filter(function (x) {
      return x !== nid;
    });
    schedulePlaylistData.screenIds = upadtedUnits;
    localStorage.setItem('schedulePlaylist', JSON.stringify(schedulePlaylistData));
    SetUnitRemoved(!unitRemoved);
    return true;
  };

  const newPlaylistCallback = (e) => {
    // Validate playlist name.

    var name = $("#floorInfoName").val();
    var reg_exp = /^[A-Za-z0-9 ]+$/;
    var is_valid = reg_exp.test(name);
    if (name === "" || name === undefined) {
      notify(t('Empty playlist name not allowed. Please enter a name.'));
      return false;
    } else if (!is_valid) {
      notify(t('Playlist Name cannot include special characters. Please try again.'));
      return false;
    } else {
      setLoading(true);
      var uid = userdetails && JSON.parse(userdetails).id;
      const res1 = axios.get(config.drupal_url + "/api/validate-name?name=" + name + "&uid=" + uid, { 'withCredentials': true }).then(res1 => {
        if (res1.data.data === 0) {
          let path = 'create-playlist';
          //history.push(path);
          window.location = path;
          //localStorage.removeItem('selectedScreenData');
          setCookie('playlistName', playlistName);
          return true;
        } else {
          setLoading(false);
          notify(t('A playlist with the same name already exists. Please use a different name.'));
          return false;
        }
      })
        .catch(err => {
          notify(t("Please try later.If the error persists, please contact Otis support."));
          return false;

        });
    }

  };

  const wrapclass = createPlaylistField === true ? "createnew" : "nocreatenew";

  return (
    <LoadingOverlay active={isloading} spinner text={t('Processing')}>
    <div className='addPlaylistModal'>
      {typeCheck === 'error' && (
        <div className="modalMessage diffscreentype">
          <p><T _str="Please select same device type." /></p>
          <button
            className="button"
            onClick={(event) => props.closeModalpop()}>
            <T _str="Ok" />
          </button>
        </div>
      )}
      {typeCheck !== 'error' && (
        <>
          <Modal.Header closeButton>
            <Modal.Title><T _str="Do you want to" /> </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className={wrapclass}>

              <div className="AddPlaylistsbuttons">
                {wrapclass === "nocreatenew" ? (
                  <button className="createPlaylistBtn" type="submit" onClick={createPlaylistCallback}>
                    <T _str="Create New Playlist" />
                  </button>
                ) : (
                  <div className='createPlaylisttitle'><T _str="Create New Playlist" /></div>
                )}
                {createPlaylistField === true && (
                  <>
                    <div className="grayBG">
                      <h3 id="DirectoryName"><T _str="Playlist Name" /></h3>
                      <input type="text" name="playlistName" className="form-control" id="floorInfoName" onChange={handleNameChange} maxLength="25" />
                    </div>
                    <div className="start-button">
                      <button
                        className="button"
                        type="submit" id="LetStart"
                        onClick={newPlaylistCallback}>
                        <T _str="Let's Start" />
                      </button>
                    </div>
                  </>
                )}

                <div className="buttonSeperator"><T _str="OR" /></div>
                <button
                  className="existingPlaylistBtn"
                  type="submit"
                  onClick={existingPlaylistCallback}>
                  <T _str="Choose Existing Playlist" />
                </button>
              </div>
              {offlineunits.length > 0 && (
                <div className={style.selectedDevicesInfo}>
                  <p><T _str="Note: The following devices are offline. Playlist will be scheduled once the device is online." /></p>
                  {offlineunits.map((unit, index) => {
                    const unitsplit = unit.split('&&&&');
                    return (
                      <>
                        <div>
                          <button onClick={(event) => [removeunit(unitsplit[0]), props.offlineremoved(unitsplit[0], event)]} value={unitsplit[0]}>{unitsplit[1]}</button>
                        </div>
                      </>
                    )
                  })}
                </div>
              )}
            </div>
          </Modal.Body>
        </>
      )
      }
    </div>
    </LoadingOverlay>
  );
};

export default AddPlaylistsModal;
