/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: EditDashboardDevice.js
File Description: Updates settings of devices

Copyright 2022 - 2024 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
import { useState, useEffect } from "react";
import device from "../css/device.module.css";
import config from "../utils/config";
import { useHistory } from "react-router-dom";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import axios from "axios";
import { T } from "@transifex/react";
import { t } from "@transifex/native";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import InputColor from "react-input-color";
import { Modal } from "react-bootstrap";
import PlaylistsPreview from "../Components/Common/PlaylistsPreview";
import { FaTimesCircle } from "react-icons/fa";
import { validateHTMLColorHex } from "validate-color";
import { Buffer } from 'buffer';

function EditDashboardDevice(props) {
  const [editdevice, setEditdevice] = useState([]);
  const [currentValue, setCurrentValue] = useState([]);
  const [edittheme, setEdittheme] = useState([]);
  const [themevalue, setThemeValue] = useState([]);
  const [dptheme, setDpTheme] = useState("");
  const [oldthemevalue, setOldThemeValue] = useState([]);
  const [textareavalue, setTextAreaValue] = useState([]);
  const [slidervalue, setSliderValue] = useState(50);
  const [loading, setLoading] = useState(false);
  const nid = props.id;
  const [bgColor, setBgColor] = useState("");
  const [textColor, setTextColor] = useState("");
  const [initialBgColor, setInitialBgColor] = useState("#ffffff");
  const [initialTextColor, setInitialTextColor] = useState("#06599b");
  const [fullPreview, setFullPreview] = useState();
  const [screenId, setScreenId] = useState(nid);
  const [cpId, setCpId] = useState("");

  const [modalShow, setmodalShow] = useState(false);
  const modalHandleClose = () => setmodalShow(false);
  const modalHandleShow = (event) => {
    event.preventDefault();
    setLoading(true);
    /*const encodedString = Buffer.from(
      config.auth_username + ":" + config.auth_password
    ).toString("base64");
    const basicAuth = "Basic " + encodedString;*/
    axios
      .get(
        config.drupal_url +
          "/rest/api/get/playlist/preview/" +
          screenId +
          "/" +
          cpId,
        {
          headers: {
            "Content-Type": "application/json",
            withCredentials: true,
          },
        }
      )
      .then((response) => {
        if (response.data) {
          const PlaylistFullPreview = {
            layout: response.data.playlist_layout,
            date: response.data.curr_date
              ? response.data.curr_date
              : "20.08.2021",
            time: response.data.curr_time
              ? response.data.curr_time
              : "11:58 AM",
          };
          setFullPreview(PlaylistFullPreview);
          setLoading(false);
          setmodalShow(true);
        }
      })
      .catch((err) => {
        toast.error(t("Error retrieving preview content. Please try again."));
        setLoading(false);
      });
    return true;
  };

  let userDetails = localStorage.getItem("userdetails");
  var isCountryAdmin =
    userDetails && JSON.parse(userDetails).roles.includes("country_admin");
  let cid = "";
  let loggedInUserID = "";
  let userrole = "building_manager";
  if (userDetails !== null) {
    loggedInUserID = JSON.parse(userDetails).id;
    cid = JSON.parse(userDetails).cid;
    var userdetails = JSON.parse(localStorage.getItem("userdetails"));
    userdetails.roles.forEach((role) => {
      if (role === "country_admin") {
        userrole = "country_admin";
      }
      if (role === "super_admin") {
        userrole = "super_admin";
      }
    });
  }

  let history = useHistory();
  useEffect(() => {
    const livedata = async () => {
      setLoading(true);
      axios
        .get(config.drupal_url + "/deviceconfiguration/" + nid, {
          withCredentials: true,
        })
        .then((response) => {
          setLoading(false);
          setEditdevice(response.data.device);
          setThemeValue(response.data.device.theme);
          setCpId(response.data.device.cp_id);
          setEdittheme(response.data.theme_list);
          if (response.data.device.theme_url !== "") {
            setDpTheme(response.data.device.theme_url.replace(/&amp;/g, "&"));
          } else {
            setDpTheme("NA");
          }
          setOldThemeValue(response.data.device.old_theme_id);
          setSliderValue(response.data.device.background_musicvolume);
          setInitialBgColor(response.data.device.banner_bg_color);
          setInitialTextColor(response.data.device.banner_text_color);
        });
    };
    livedata();
  }, []);

  const changeLive = (refreshrate) => {
    setCurrentValue(refreshrate);
  };

  function handleBgColorChange(bgColor) {
    var rgb = "rgb(255, 255, 255)";
    var rgba = bgColor.rgba;
    rgb = rgb.match(
      /^rgb?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i
    );
    rgba = rgba.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );

    if (rgba != null && rgb != null) {
      var red = Math.round(rgba[1] * rgba[4] + rgb[1] * (1 - rgba[4]));
      var blue = Math.round(rgba[2] * rgba[4] + rgb[2] * (1 - rgba[4]));
      var green = Math.round(rgba[3] * rgba[4] + rgb[3] * (1 - rgba[4]));
      var result =
        rgba && rgba.length === 5
          ? "#" +
            ("0" + red.toString(16)).slice(-2) +
            ("0" + blue.toString(16)).slice(-2) +
            ("0" + green.toString(16)).slice(-2)
          : "";

      setBgColor(result);
      setEditdevice((prevData) => ({ ...prevData, banner_bg_color: result }));
    }
  }

  function handleTextColorChange(textColor) {
    console.log(textColor.hex);
    console.log(textColor.hex.length);
    console.log("dada");
    var rgb = "rgb(255, 255, 255)";
    var rgba = textColor.rgba;
    rgb = rgb.match(
      /^rgb?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i
    );
    rgba = rgba.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );

    if (rgba != null && rgb != null) {
      var red = Math.round(rgba[1] * rgba[4] + rgb[1] * (1 - rgba[4]));
      var blue = Math.round(rgba[2] * rgba[4] + rgb[2] * (1 - rgba[4]));
      var green = Math.round(rgba[3] * rgba[4] + rgb[3] * (1 - rgba[4]));
      var result =
        rgba && rgba.length === 5
          ? "#" +
            ("0" + red.toString(16)).slice(-2) +
            ("0" + blue.toString(16)).slice(-2) +
            ("0" + green.toString(16)).slice(-2)
          : "";
      setTextColor(result);
      setEditdevice((prevData) => ({ ...prevData, banner_text_color: result }));
    }
  }

  const handleChange = (e) => {
    const value = e.target.value;
    switch (e.target.id) {
      case "field_live_feed_refresh_rate":
        setEditdevice((prevData) => ({ ...prevData, feed_refreshrate: value }));
        break;
      case "edit-field-background-music-on-und":
        setEditdevice((prevData) => ({
          ...prevData,
          background_musicon: value,
        }));
        break;
      case "deviceTheme":
        setEditdevice((prevData) => ({ ...prevData, theme: themevalue }));
        break;
      case "banner-text-color":
        setEditdevice((prevData) => ({
          ...prevData,
          banner_text_color: value,
        }));
        break;
      case "banner-bg-color":
        setEditdevice((prevData) => ({ ...prevData, banner_bg_color: value }));
        break;
      case "edit-field-infotainment-message":
        const lines = value.split("\n");
        for (var i = 0; i < lines.length; i++) {
          if (lines[i].length <= 15) continue;
          var j = 0;
          var space = 15;
          while (j++ <= 15) {
            if (lines[i].charAt(j) === " ") space = j;
          }
          lines[i + 1] = lines[i].substring(space + 1) + (lines[i + 1] || "");
          lines[i] = lines[i].substring(0, space);
        }
        if (lines.length > 6) {
          return;
        }
        if (value.length > 75) {
          return;
        }
        setEditdevice((prevData) => ({
          ...prevData,
          infotainment_message: lines.slice(0, 10).join("\n"),
        }));
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !validateHTMLColorHex(editdevice.banner_bg_color) ||
      !validateHTMLColorHex(editdevice.banner_text_color) ||
      editdevice.banner_bg_color.length < 7 ||
      editdevice.banner_text_color.length < 7
    ) {
      alert(t("Color hex code value is invalid"));
      return false;
    }
    const device_data = {
      nid: nid,
      feed_refreshrate: editdevice.feed_refreshrate,
      background_musicon: editdevice.background_musicon,
      background_musicvolume: slidervalue,
      theme_nid: editdevice.theme,
      infotainment_message: editdevice.infotainment_message,
      old_theme_nid: oldthemevalue,
      banner_bg_color: editdevice.banner_bg_color,
      banner_text_color: editdevice.banner_text_color,
    };
    setLoading(true);
    axios
      .post(config.drupal_url + "/saveconfiguration", device_data, {
        withCredentials: true,
      })
      .then((res) => {
        setLoading(false);
        history.push(`/`);
        if (res.data.message == true) {
          toast.success(
            t("@type %title has been updated.")
              .replace("%title", res.data.title)
              .replace("@type", t("Device"))
          );
        } else if (res.data.message == "offline") {
          toast.info(
            res.data.title +
              " " +
              t(
                "is offline. Device configuration will be updated once the device is online."
              )
          );
        } else {
          toast.error(
            t("Error updating device") +
              ". " +
              res.data.title +
              " " +
              t(
                "Please try later.If the error persists, please contact Otis support."
              )
          );
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  if ($("[id^=edit-field-infotainment-message]").length) {
    const targettextarea = document.querySelector(
      "#edit-field-infotainment-message"
    );
    targettextarea.addEventListener("paste", (event) => {
      var self = $("[id^=edit-field-infotainment-message]");
      var charLim = 75;
      var charlimit = 15;
      setTimeout(function (e) {
        var new_stuff = $(self).val();
        $(self).val(new_stuff);
        if (event.target.value.length >= 75) {
          var msg = "Maximum 75 characters are allowed.";
          var slicedtxt = 75 - event.target.value.length;
          if (slicedtxt != 0) {
            var remove = event.target.value.slice(0, slicedtxt);
            $(self).val(remove);
          }
        }
        var lines = $(self).val().split("\n");
        for (var i = 0; i < lines.length; i++) {
          if (lines[i].length <= charlimit) continue;
          var j = 0;
          var space = charlimit;
          while (j++ <= charlimit) {
            if (lines[i].charAt(j) === " ") space = j;
          }
          lines[i + 1] = lines[i].substring(space + 1) + (lines[i + 1] || "");
          lines[i] = lines[i].substring(0, space);
        }
        $(self).val(lines.slice(0, 6).join("\n"));
      }, 0);
    });
    targettextarea.addEventListener("keypress", (event) => {
      var charLength = 75;
      var charlimit = 15;
      var infoval = event.target.value;
      if (event.target.value.length >= charLength) {
        event.preventDefault();
      }
      var text_remaining = charLength - infoval.length - 1;
      if (text_remaining < 0) {
        text_remaining = 0;
      }
      $("[class^=infotext]").css("display", "block");
      $("[id^=infotextareacount").html(text_remaining);
      var lines = infoval.split("\n");
      for (var i = 0; i < lines.length; i++) {
        if (lines[i].length < charlimit) continue;
        var j = 0;
        var space = charlimit;
        while (j++ <= charlimit) {
          if (lines[i].charAt(j) === " ") space = j;
        }
        lines[i + 1] = lines[i].substring(space + 1) + (lines[i + 1] || "");
        lines[i] = lines[i].substring(0, space);
      }
      $("[id^=edit-field-infotainment-message]").val(
        lines.slice(0, 6).join("\n")
      );
    });
  }

  $(".devicenodetheme").click(function () {
    $(this).find("input").prop("checked", true);
    setOldThemeValue(themevalue);
    setThemeValue($(this).find("input").val());
    var dpThemeURL = $(this).find("input").data("url");
    if (dpThemeURL !== "") {
      setDpTheme(dpThemeURL.replace(/&amp;/g, "&"));
    } else {
      setDpTheme("NA");
    }
    $(".devicenodetheme").removeClass("device_selected__1b2oj");
    $(this).addClass("device_selected__1b2oj");
  });

  const redirect = () => {
    history.push("/");
  };

  const handleTcChange = (e) => {
    var colorSelected = e.target.value;
    setTextColor(colorSelected);
    var isValidColor = false;
    if (validateHTMLColorHex(colorSelected)) {
      isValidColor = true;
    }
    if (isValidColor && colorSelected.length > 4) {
      setInitialTextColor(e.target.value);
    }
    setEditdevice((prevData) => ({
      ...prevData,
      banner_text_color: e.target.value,
    }));
  };

  const handleBcChange = (e) => {
    var colorSelected = e.target.value;
    setBgColor(colorSelected);
    var isValidColor = false;
    if (validateHTMLColorHex(colorSelected)) {
      isValidColor = true;
    }
    if (isValidColor && colorSelected.length > 4) {
      setInitialBgColor(colorSelected);
    }
    setEditdevice((prevData) => ({
      ...prevData,
      banner_bg_color: colorSelected,
    }));
  };

  return (
    <>
      <LoadingOverlay active={loading} spinner text={t("Processing")}>
        <div className={device["Device-Configuration-page"]}>
          <form
            method="post"
            id="edit-device-form"
            acceptCharset="UTF-8"
            onSubmit={handleSubmit}
          >
            <div
              className={device["groups-heading-container Devicelist-heading"]}
            >
              <h2>
                eView<sup>&reg;</sup> - <T _str="Device Configuration" />
              </h2>
              <div className={device["main-action-buttons"]}>
                <button
                  type="submit"
                  id="edit-cancel"
                  className="btn-cancel ms-1"
                  name="preview"
                  value="Preview"
                  onClick={(event) => [modalHandleShow(event)]}
                >
                  <T _str="Preview" />
                </button>
                <button
                  type="submit"
                  id={device.btnSave}
                  name="op"
                  value={t("Save")}
                  className="btn btn-success form-submit icon-before"
                >
                  <T _str="Save" />
                </button>
                <button
                  type="submit"
                  id="edit-cancel"
                  className="btn-cancel ms-1"
                  name="op"
                  value="Cancel"
                  onClick={redirect}
                >
                  <T _str="Cancel" />
                </button>
              </div>
            </div>
            {[editdevice].map((devices) => {
              let currentValue = devices.feed_refreshrate;
              let currentBGValue = devices.background_musicon;
              return (
                <>
                  <div className={device["Device-Configuration-inner"]}>
                    <div className={device["form-item"]}>
                      <label
                        className={device["control-label"]}
                        for="edit-title-field"
                      >
                        <T _str="Unit Number (same as in G3MS)" />
                      </label>
                      <input
                        className={device["text-full form-control form-text"]}
                        disabled="disabled"
                        type="text"
                        name="title_field[und][0][value]"
                        id="unit-number"
                        value={devices.unitid}
                        size="60"
                        maxLength="255"
                        onChange={handleChange}
                      />
                    </div>
                    <div>
                      <input
                        disabled="disabled"
                        type="hidden"
                        name="old_theme[und][0][value]"
                        id="old_theme"
                        value={devices.theme}
                        size="60"
                        maxLength="255"
                        onChange={handleChange}
                      />
                    </div>
                    {isCountryAdmin && (
                      <div className={device["form-item"]}>
                        <label
                          className="control-label text-white"
                          for="edit-title-field"
                        >
                          <T _str="Live Feed Refresh Rate" />
                        </label>
                        <select
                          name="field_live_feed_refresh_rate[und]"
                          id="field_live_feed_refresh_rate"
                          onChange={handleChange}
                          value={currentValue}
                        >
                          <option value="1440" selected="selected">
                            {t("Once per day")}
                          </option>
                          <option value="720">{t("Twice per day")}</option>
                          <option value="480">
                            {t("Three times per day")}
                          </option>
                          <option value="360">{t("Four times per day")}</option>
                          <option value="240">{t("Six times per day")}</option>
                        </select>
                      </div>
                    )}
                    {devices.cpibdevicetype != "full-content" && (
                      <>
                        <div className={device["form-item"]}>
                      <label className="control-label" for="banner-bg-color">
                        <T _str="Banner Background Color" />
                      </label>
                      <span>
                        <InputColor
                          initialValue={initialBgColor}
                          onChange={handleBgColorChange}
                          id="color-picker"
                          className={device["margin-color-picker"]}
                        />
                        <input
                          className={device["banner-colors"]}
                          type="text"
                          name="banner_bg_color"
                          id="banner-bg-color"
                          size="60"
                          value={bgColor}
                          maxLength="255"
                          onChange={handleBcChange}
                        />
                      </span>
                        </div>
                        <div className={device["form-item"]}>
                      <label className="control-label" for="banner-bg-color">
                        <T _str="Banner Text Color" />
                      </label>
                      <span>
                        <InputColor
                          initialValue={initialTextColor}
                          onChange={handleTextColorChange}
                          id="color-picker"
                          className={device["margin-color-picker"]}
                        />
                        <input
                          className={device["banner-colors"]}
                          type="text"
                          name="banner_text_color"
                          id="banner-text-color"
                          value={textColor}
                          size="60"
                          maxLength="255"
                          onChange={handleTcChange}
                        />
                      </span>
                        </div>
                      </>
                    )}
                    <div className="form-item"></div>
                    {devices.cpibdevicetype == "eView2-device" && (
                      <>
                        <div className={device["form-item"]}>
                          <label
                            className="control-label text-white"
                            for="edit-title-field"
                          >
                            <T _str="Background Music" />
                          </label>
                          <select
                            class="form-control form-select"
                            id="edit-field-background-music-on-und"
                            name="field_background_music_on[und]"
                            onChange={handleChange}
                            value={currentBGValue}
                          >
                            <option value="true">{t("ON")}</option>
                            <option value="false" selected="selected">
                              {t("OFF")}
                            </option>
                          </select>
                        </div>
                        <div className={device["form-item"]}>
                          <label
                            className={device["control-label text-white"]}
                            for="customRange1"
                          >
                            <T _str="Background Music Volume in Percent" />
                          </label>
                          <Slider
                            min={0}
                            max={100}
                            id="customRange1"
                            value={slidervalue}
                            step={1}
                            onChange={(e) => {
                              setSliderValue(e);
                            }}
                            orientation="horizontal"
                          />
                        </div>
                      </>
                    )}
                    {devices.cpibdevicetype !== "eView2-device" && devices.cpibdevicetype !== "full-content" && (
                      <div
                        className={`${device["form-item"]} ${
                          device["message"]
                        } ${!isCountryAdmin ? "bmtextarea" : ""}`}
                      >
                        <label
                          className={device["control-label text-white"]}
                          for="edit-title-field"
                        >
                          <T _str="Infotainment Message" />
                        </label>
                        <textarea
                          className={device["form-textarea"]}
                          id="edit-field-infotainment-message"
                          name="field_infotainment_message[und][0][value]"
                          cols="60"
                          rows="3"
                          value={devices.infotainment_message}
                          onChange={handleChange}
                        />
                        <div id={device.charcunt} className="infotext">
                          <span
                            id="infotextareacount"
                            className={device.cunt_num}
                          >
                            52
                          </span>
                          <T _str="characters remaining" />
                        </div>
                        <p className={device.limit_note}>
                          <T _str="Note: A maximum of 75 characters or 6 lines are allowed." />
                        </p>
                      </div>
                    )}
                    <div
                      className={`${device["form-item"]} ${device["select-theme"]}`}
                    >
                      <label
                        className={device["control-label text-white"]}
                        for="edit-title-field"
                      >
                        <T _str="Select Theme" /> *
                      </label>
                      {edittheme.map((theme, index) => (
                        <>
                          {(() => {
                            if (
                              theme.author === loggedInUserID ||
                              theme.users.includes(loggedInUserID) ||
                              theme.public === "1"
                            ) {
                              return (
                                <div
                                  className={`${device["theme-image"]} ${
                                    themevalue === theme.nid
                                      ? device["selected"]
                                      : ""
                                  } devicenodetheme`}
                                  id="deviceTheme"
                                  onClick={handleChange}
                                  data-loggedin={loggedInUserID}
                                >
                                  <input
                                    type="radio"
                                    value={theme.nid}
                                    className="deviceTheme"
                                    name="deviceTheme"
                                    checked={themevalue === theme.nid}
                                    onChange={handleChange}
                                    data-url={theme.file_url}
                                  />
                                  <img
                                    src={theme.path}
                                    width={100}
                                    height={56}
                                    id="deviceTheme"
                                    alt="Theme Image"
                                  />
                                </div>
                              );
                            }
                          })()}
                        </>
                      ))}
                    </div>
                  </div>
                </>
              );
            })}
          </form>
        </div>
        <Modal
          show={modalShow}
          onHide={modalHandleClose}
          className="pl-preview"
        >
          <Modal.Body>
            <div
              className="playlistPreview modalClose"
              onClick={modalHandleClose}
            >
              <FaTimesCircle />
            </div>
            <PlaylistsPreview
              playlistsData="NA"
              othersPlaylistsData={[]}
              fullPreview={fullPreview}
              id="NA"
              screenId={screenId}
              cpid={cpId}
              infomsg="NA"
              source="dc_preview"
              devicetheme={dptheme}
              bannerbgcolor={bgColor}
              bannertextcolor={textColor}
            />
          </Modal.Body>
        </Modal>
      </LoadingOverlay>
    </>
  );
}

export default EditDashboardDevice;
