import './FloatHeader.css';
import { T, useT } from '@transifex/react';
import { t } from "@transifex/native";
import axios from "axios";
import config from '../utils/config';
import LoadingOverlay from "react-loading-overlay";
import React, { useState } from "react";

function FloatHeader() {
  const flag = (localStorage.getItem('parentuser')) ? JSON.parse(localStorage.getItem('parentuser')).id : ''
  const msg = useT('You are accessing %user account').replace('%user', localStorage.getItem('imp_username'))
  const [loading, setLoading] = useState(false);
  const handleReturnUser = () => {
    setLoading(true);
    // Prevent setting userdetails to localstorage in GetUserStatus method during impersonation
    // This is unset in Dashboard page
    localStorage.setItem("setUserdetailsBlock", "true");
    axios.get(config.drupal_url + '/userdetails?unmask=1&uid=' + flag, { withCredentials: true })
      .then((response) => {
        localStorage.setItem('userdetails', JSON.stringify(response.data))
        localStorage.setItem("userlang", response.data.language)
        localStorage.setItem('parentuser', '')
        localStorage.setItem('imp_user', null)
        localStorage.setItem('imp_username', '')
        setTimeout(
          function () {
            setLoading(false)
            let rurl = config.drupal_url + '/'
            window.location.replace(rurl)
          },
          1000);
      });
  }

  return (
    (flag) ?
      <LoadingOverlay active={loading} spinner text={t('Processing')}>
        <div>
          <div class="masquerade-switch-back" id="admin-sticky-message">
            <div class="admin-sticky-inner">
              <div class="admin-sticky-inner-text">{msg}</div>
              <ul class="links"><li class="menu-17077 first last"><a href="#!" onClick={handleReturnUser}><T _str="Return to my account" /></a></li></ul>
            </div>
          </div>
        </div>
      </LoadingOverlay>
      : <div></div>
  );
}

export default FloatHeader;