/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: ManageUsers.js
File Description: Implements manageusers functionality

Copyright 2021 - 2024 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
import { t } from "@transifex/native";
import axios from "axios";
import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import ActiveUsers from "../Components/UserManagment/ActiveUsers";
import HistoryUsers from "../Components/UserManagment/HistoryUsers";
import PendingUsers from "../Components/UserManagment/PendingUsers";
import "../css/device-list.css";
import config from "../utils/config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AccessDenied from "../Components/Common/AccessDenied";

function ManageUsers() {
  const [tabIndex, setTabIndex] = useState(0);
  const [totalPendingUsers, settotalpendinglUsers] = useState();
  const [totalActiveUsers, settotalActiveUsers] = useState();
  const [totalHistoryUsers, settotalHistoryUsers] = useState();
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(0);

  const [userdata, setUseredata] = useState([]);
  const [page, setPage] = useState("pending");
  const [showAlert, setShowAlert] = useState({
    show: false,
    alertType: "",
    msg: "",
  });

  useEffect(() => {
    var mgtabid = JSON.parse(
      localStorage.getItem("mguserslist")
      );
      if (mgtabid !== null) {
         var editprofile = JSON.parse(localStorage.getItem('mguserslist')).editprofile ? JSON.parse(localStorage.getItem('mguserslist')).editprofile : 0;
          var mgselectedtab = JSON.parse(localStorage.getItem('mguserslist')).selectedtab ? JSON.parse(localStorage.getItem('mguserslist')).selectedtab : 0;
          setTabIndex(mgselectedtab); 
          if (mgselectedtab == 0) {
            setPage("pending");
          }
          if (mgselectedtab == 1) {
            setPage("active");
          }
          if (mgselectedtab == 2) {
            setPage("history");
          }
          
    }
   
  }, []);
   
  var userDetails = localStorage.getItem("userdetails");
  var countryid = userDetails && JSON.parse(userDetails).cid;
  const userId = userDetails && JSON.parse(userDetails).id;
  let userrole = "building_manager";
  if (userDetails !== null) {
    var userdetails = JSON.parse(localStorage.getItem("userdetails"));
    userdetails.roles.forEach((role) => {
      if (role === "country_admin") {
        userrole = "country_admin";
      }
      if (role === "super_admin") {
        userrole = "super_admin";
      }
    });
  }

  async function fetchAllUsers() {
    await Promise.all([
      await fetchUsers("active", true),
      await fetchUsers("history", true),
    ]);
  }

  useEffect(() => {
    fetchAllUsers();
  }, []);

  const onSwitchTab = (index) => {
    setTabIndex(index);
    setPageNo(0);
    if (index == 0) {
      setPage("pending");
    }
    if (index == 1) {
      setPage("active");
    }
    if (index == 2) {
      setPage("history");
    }

    const mgusersData = {
      selectedtab: index,
       };
    localStorage.setItem(
        "mguserslist",
        JSON.stringify(mgusersData)
      );

  };

  const applyFilters = (firstName, email, company, status) => {
    fetchUsers(page, false, firstName, email, company, status);
  };

  const resetFilters = () => {
    if (pageNo === 0) {
      fetchUsers(page);
    } else {
      setPageNo(0);
    }
  };

  useEffect(() => {
    fetchUsers(page);
  }, [pageNo,page]);

  const fetchUsers = async (
    currentPage,
    forCount = false,
    firstName = "",
    email = "",
    company = "",
    status = "All"
  ) => {
    if (!forCount) setLoading(true);
    const hasFilters =
      firstName !== "" || email !== "" || company !== "" || status !== "All";
    axios
      .get(
        config.drupal_url +
          `/api/users/${currentPage}/${countryid}?items_per_page=5&page=${
            hasFilters ? 0 : pageNo
          }&field_firstname_value=${firstName}&mail=${email}&field_companyname_value=${company}&field_disable_status_value=${status}`,
        {
          withCredentials: true,
        }
      )
      .then((reponse) => {
        // let users = reponse.data.rows.filter((user) => user.uid !== userId);
        var users = reponse.data.rows;
        /** if (currentPage == "history") {
          users = users.filter(
            (user) =>
              user.field_disable_status &&
              user.field_disable_status.lenght !== 0
          );
        }*/
        if (!forCount) {
          if (pageNo === 0 || hasFilters) {
            setUseredata([...users]);
          } else {
            const list = [...userdata, ...users];
            setUseredata([...list]);
          }
        }

        if (firstName === "" && email === "" && company === "") {
          if (currentPage == "pending") {
            settotalpendinglUsers(reponse.data.pager["total_items"]);
          } else if (currentPage == "active") {
            settotalActiveUsers(reponse.data.pager["total_items"]);
          } else if (currentPage == "history") {
            settotalHistoryUsers(reponse.data.pager["total_items"]);
          }
        }
        if (!forCount) setTimeout(() => {
            setLoading(false);
        }, 2000);
      })

      .catch(function (error) {
        console.error("There was an error!", error);
      });
  };

  const handleOnSubmit = (e, role, users) => {
    e.preventDefault();
    if (e.target.id === "Approve" && !role) {
      onHandleAlert({
        alertType: "danger",
        msg: "Please select the user's role",
        id: "submit",
      });
      return;
    }

    const approvaldata = {
      rid: e.target.id === "Approve" ? role : "",
      uid: users,
      eventtype: e.target.id,
    };
    setLoading(true);
    axios
      .post(
        config.drupal_url + "/user-managment/pendinguser/action",
        approvaldata,
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        setLoading(false);
        const length = response.data.rows.length;
        if (length == 0) {
          onHandleAlert({
            alertType: "success",
            msg: `Successfully ${
              e.target.id === "Approve"
                ? "approved"
                : e.target.id === "deny"
                ? "denied"
                : e.target.id === "Reset"
                ? "set status to re-register for"
                : // :  e.target.id === "disable" || e.target.id === "ResetDisable"
                  //? "disabled"
                  ""
            } selected user(s)`,
          });
        }
        if (length > 0) {
          response.data.rows.map((devicecount) => {
            if (devicecount.status == "yes") {
              onHandleAlert({
                alertType: "danger",
                msg: "Cannot disable user(s) with associated units",
              });
            } else {
              onHandleAlert({
                alertType: "success",
                msg: "Successfully disabled selected user(s)",
              });
            }
          });
        }
        
       setTimeout(() => {
          window.location.reload();
        }, 2000);
        fetchUsers(page);
   
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onHandleAlert = ({ alertType, msg, id = "default" }) => {
    {
      alertType === "danger"
        ? toast.error(t(msg), { toastId: id })
        : toast.success(t(msg), { toastId: id });
    }
  };

  if (userrole === "building_manager") {
    return <AccessDenied title="Access Denied" />;
  }
  {
    console.log(userdata);
  }
  return (
    <LoadingOverlay active={loading} spinner text={t("Processing")}>
      <ToastContainer />
      <div className="manage-users">
        <div className="groups-heading-container Devicelist-heading">
          <h2>
            eView<sup>&reg;</sup> - {t("Manage Users")}
          </h2>
        </div>
        <Tabs selectedIndex={tabIndex} onSelect={(index) => onSwitchTab(index)}>
          <TabList>
            <Tab>
              {t("Pending Requests")} (
              {totalPendingUsers && `${totalPendingUsers}`})
            </Tab>
            <Tab>
              {t("Active Users")} {totalActiveUsers && `(${totalActiveUsers})`}
            </Tab>
            <Tab>
              {t("View History")}{" "}
              {totalHistoryUsers && `(${totalHistoryUsers})`}
            </Tab>
          </TabList>

          <TabPanel>
            <PendingUsers
              users={userdata}
              handleOnSubmit={handleOnSubmit}
              isLoading={loading}
              totalUsers={totalPendingUsers}
              onLoadMore={() => {
                setPageNo((prev) => ++prev);
              }}
              onSearch={(email, company) => applyFilters("", email, company)}
              onResetFilter={resetFilters}
            />
          </TabPanel>

          {/* Active users tab */}

          <TabPanel>
            <ActiveUsers
              users={userdata}
              totalUsers={totalActiveUsers}
              handleOnSubmit={handleOnSubmit}
              isLoading={loading}
              onLoadMore={() => {
                setPageNo((prev) => ++prev);
              }}
              onSearch={(firstName, email, company) =>
                applyFilters(firstName, email, company)
              }
              onResetFilter={resetFilters}
            />
          </TabPanel>

          {/* View History users tab */}
          <TabPanel>
            <HistoryUsers
              users={userdata}
              handleOnSubmit={handleOnSubmit}
              isLoading={loading}
              onHandleAlert={onHandleAlert}
              totalUsers={totalHistoryUsers}
              onLoadMore={() => {
                setPageNo((prev) => ++prev);
              }}
              onSearch={(email, company, status) =>
                applyFilters("", email, company, status)
              }
              onResetFilter={resetFilters}
            />
          </TabPanel>
        </Tabs>
      </div>
    </LoadingOverlay>
  );
}

export default ManageUsers;
