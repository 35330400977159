/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: AddPlaylistsPop.js
File Description: implements create-playlist popup functionality of CMS portal

Copyright 2021 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import axios from 'axios'
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import $ from "jquery";
import { t } from '@transifex/native';
import config from '../../utils/config';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import LoadingOverlay from "react-loading-overlay";


const AddPlaylistsPop = (props) => {
  var userdetails = localStorage.getItem('userdetails');
  const customId = userdetails && JSON.parse(userdetails).id;

  const notify = (val) => {

    toast.error(val, {

      toastId: customId

    });

  }
  const [isloading, setLoading] = useState(false);

  const history = useHistory();
  const [playlistName, setPlaylistName] = useState("")
  const [cookies, setCookie] = useCookies(["playlistName"]);

  const newPlaylistCallback = (e) => {
    // Validate playlist name.

    var name = $("#floorInfoName").val();
    var reg_exp = /^[A-Za-z0-9 ]+$/;
    var is_valid = reg_exp.test(name);
    if (name === "" || name === undefined) {
      notify(t('Empty playlist name not allowed. Please enter a name.'));
      return false;
    } else if (!is_valid) {
      notify(t('Playlist Name cannot include special characters. Please try again.'));
      return false;
    } else {
      setLoading(true);
      var uid = userdetails && JSON.parse(userdetails).id;
      const res1 = axios.get(config.drupal_url + "/api/validate-name?name=" + name + "&uid=" + uid, { 'withCredentials': true }).then(res1 => {
        if (res1.data.data === 0) {
        let path = 'create-playlist';
        //history.push(path);
        window.location = path;
        //localStorage.removeItem('selectedScreenData');
        setCookie('playlistName', playlistName);
        return true;
        } else {
          setLoading(false);
          notify(t('A playlist with the same name already exists. Please use a different name.'));
          return false;
        }
      })
        .catch(err => {
          notify(t("Please try later.If the error persists, please contact Otis support."));
          return false;

        });
    }

  };

  const handleNameChange = (e) => {
    setPlaylistName(e.target.value)
  }


  return (
    <LoadingOverlay active={isloading} spinner text={t('Processing')}>
          <div className="playlistModal" id="playlistModal">
      <ToastContainer />
      <Modal.Header closeButton>
        <Modal.Title><h4 class="modal-title">{t("Create New Playlist")}</h4></Modal.Title>
      </Modal.Header>

      <Modal.Body>

        <div className="grayBG">
          <h3 id="DirectoryName">{t("Playlist Name")}</h3>
          <input type="text" name="playlistName" className="form-control" id="floorInfoName" onChange={handleNameChange} maxLength="25" />
        </div>
        <div className="start-button">
          <button className="button"
            type="submit" id="LetStart"
            onClick={newPlaylistCallback}>
            {t("Let's Start")}
          </button>
        </div>



      </Modal.Body>
    </div>
    </LoadingOverlay>


  );
};

export default AddPlaylistsPop;
