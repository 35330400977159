import React from 'react';
import { Modal } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { T } from '@transifex/react';
import { t } from "@transifex/native"
import config from '../../utils/config';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import LoadingOverlay from "react-loading-overlay";
import { Buffer } from 'buffer';

const DeleteGroups = (props) => {
    const {setRefreshKey} = props;    
	const [loading, setLoading] = useState(false);
	
    const DeleteGroup = async (event)=>{
        event.preventDefault();
        setLoading(true);
        axios.get(config.drupal_url + '/session/token').then(result => {
            if (200 === result.status) {
                const csrfToken = result.data;
                const deleteUrl = config.drupal_url + "/node/" + props.groupdata + "?_format=hal_json";
                //const encodedString = Buffer.from(config.auth_username + ':' + config.auth_password).toString('base64')
                const headers = {
                "Content-Type": "application/hal+json",
                withCredentials: true,
                'X-CSRF-Token': csrfToken,
                }
                axios.delete(deleteUrl, {headers})
                .then(response => {
                if(response.status == 204) {
                    setLoading(false);
                    var str = t("@type %title was successfully deleted.");
                    var mapObj = { "@type": t("Group"), "%title": props.groupTitle };
                    str = str.replace(/@type|%title/gi, function (matched) {
                        return mapObj[matched];
                    });
                    toast.success(str);
                    props.handleCancel(event);
                    setRefreshKey(oldKey => oldKey +1);
                }
                })
            }
        })
    }

return (
    <LoadingOverlay active={loading} spinner text={t('Processing')}>
    <div>
      <div className="groups-popup-container">
            <div className="popup-title">
                <T _str="Delete" /> <T _str="Group" />
                <Modal.Header closeButton></Modal.Header>
            </div>
            <div className="popup-content">
            <form>
                <div><T _str="This action cannot be undone." /></div>
                <div class="form-actions form-wrapper form-group" id="edit-actions">
                <button type="submit" id="edit-submit" 
                    name="op" value="Delete" 
                    onClick={(event) => DeleteGroup(event)} 
                    class="btn btn-danger form-submit icon-before">
                    <span class="icon glyphicon glyphicon-trash" aria-hidden="true">
                    </span><T _str="Delete" />
                </button>
                <button type="submit"  
                onClick={(event) => [props.handleCancel(event)]} 
                 id="edit-cancel" name="op" value="Cancel" 
                 className="btn btn-default form-submit icon-before">
                     <span className="icon glyphicon glyphicon-remove" aria-hidden="true"></span>
                     <T _str="Cancel"/></button>
                 </div>
            </form>
            </div>
        </div>
    </div>
    </LoadingOverlay>
 );
}

export default DeleteGroups;