import React, { useEffect, useState } from "react";
import { t } from "@transifex/native";
import UserTable from "./UserTable";
import TableFooter from "./TableFooter";
import axios from "axios";
import config from "../../utils/config";

const ActiveUsers = ({
  users,
  totalUsers,
  onLoadMore,
  handleOnSubmit,
  isLoading,
  onSearch,
  onResetFilter,
}) => {
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [suggestionsunitid, setSuggestionsunitid] = useState([]);
  const [suggestionscompanyname, setSuggestionscompanyname] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [suggestionfirstname, setSuggestionsfirstname] = useState([]);
  const [allSearchUsers, setAllSearchUsers] = useState([]);

  const userDetails = localStorage.getItem("userdetails");
  const countryid = userDetails && JSON.parse(userDetails).cid;

  useEffect(() => {
    setSelectedUsers([]);
    fetchActiveUsers();
  }, []);

  const fetchActiveUsers = async () => {
    axios
      .get(config.drupal_url + `/api/users/active/${countryid}`, {
        withCredentials: true,
      })
      .then((response) => setAllSearchUsers([...response.data.rows]));
  };

  const onInputChange = async (e) => {
    const value = e.target.value;
    if (allSearchUsers.length === 0) await fetchActiveUsers();
    if (e.target.id == "user-email") {
      setEmail(value);
      let useremailmatches = [];
      useremailmatches = allSearchUsers.filter((device) => {
        const regex = new RegExp(`${value}`, "gi");
        return device.mail.match(regex);
      });
      setSuggestionsunitid(value.length === 0 ? [] : useremailmatches);
    } else if (e.target.id == "company-name") {
      setCompany(value);
      let companynamematches = allSearchUsers.filter((device) => {
        const regex = new RegExp(`${value}`, "gi");
        return (
          device.field_companyname && device.field_companyname.match(regex)
        );
      });
      setSuggestionscompanyname(value.length === 0 ? [] : companynamematches);
    } else if (e.target.id === "first-name") {
      setFirstName(value);
      let firstnamematches = allSearchUsers.filter((device) => {
        const regex = new RegExp(`${value}`, "gi");
        return device.field_firstname.match(regex);
      });
      setSuggestionsfirstname(value.length === 0 ? [] : firstnamematches);
    }
  };

  const onSuggestHandleremailid = (unitid) => {
    setEmail(unitid);
    setSuggestionsunitid([]);
  };
  const onSuggestHandlerCompanyname = (unitid) => {
    setCompany(unitid);
    setSuggestionscompanyname([]);
  };

  const onSuggestHandlerfirstname = (unitid) => {
    setFirstName(unitid);
    setSuggestionsfirstname([]);
  };

  const resetFields = () => {
    setEmail("");
    setCompany("");
    setFirstName("");
  };

  const onAllUsersSelectionChange = (e) => {
    setSelectedUsers([]);
    setIsAllSelected(e.target.checked);
    if (e.target.checked) {
      users.map((user) => {
        selectedUsers.push(user.uid);
      });
      setSelectedUsers(selectedUsers);
    }
  };

  const onUserSelectionChange = (e) => {
    if (selectedUsers.includes(e.target.value)) {
      const arr = selectedUsers.filter((uid) => e.target.value != uid);
      setSelectedUsers(arr);
      setIsAllSelected(false);
    } else {
      const arr = [...selectedUsers, e.target.value];
      setSelectedUsers(arr);
      if (arr.length === users.length) {
        setIsAllSelected(true);
      }
    }
  };
  const clearFirstname = (event) => {
    setSuggestionsunitid([false]);
    setSuggestionscompanyname([false]);
  };
  const clearEmail = (event) => {
    setSuggestionscompanyname([false]);
    setSuggestionsfirstname([false]);
  };
  const clearCompany = (event) => {
    setSuggestionsunitid([false]);
    setSuggestionsfirstname([false]);
  };
  return (
    <>
      <div className="views-exposed-widgets">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSearch(firstName, email, company);
          }}
        >
          <div className="views-exposed-widget unit-number">
            <label for="unit-number"> {t("First Name")} </label>
            <div className="views-widget">
              <div className="input-group">
                <input
                  className="form-control form-text"
                  type="text"
                  id="first-name"
                  name="field_firstname"
                  onChange={onInputChange}
                  value={firstName}
                  size="30"
                  maxlength="128"
                  onFocus={(event) => clearFirstname(event)}
                />
                <div
                  id="list-dropdown"
                  style={{
                    display: suggestionfirstname == "false" ? "none" : "block",
                  }}
                >
                  {suggestionfirstname &&
                    suggestionfirstname.map((unitidsuggestion, i) => (
                      <span
                        className="list-items"
                        key={i}
                        onClick={() =>
                          onSuggestHandlerfirstname(
                            unitidsuggestion.field_firstname
                          )
                        }
                      >
                        {unitidsuggestion.field_firstname}
                      </span>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="views-exposed-widget unit-number">
            <label for="unit-number"> {t("Email")} </label>
            <div className="views-widget">
              <div className="input-group">
                <input
                  className="form-control form-text"
                  type="text"
                  id="user-email"
                  name="field_eserviceunitid"
                  onChange={onInputChange}
                  value={email}
                  size="30"
                  maxlength="128"
                  onFocus={(event) => clearEmail(event)}
                />
                <div
                  id="list-dropdown"
                  style={{
                    display: suggestionsunitid == "false" ? "none" : "block",
                  }}
                >
                  {suggestionsunitid &&
                    suggestionsunitid.map((unitidsuggestion, i) => (
                      <span
                        className="list-items"
                        key={i}
                        onClick={() =>
                          onSuggestHandleremailid(unitidsuggestion.mail)
                        }
                      >
                        {unitidsuggestion.mail}
                      </span>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="views-exposed-widget unit-number">
            <label for="unit-number"> {t("Company")} </label>
            <div className="views-widget">
              <div className="input-group">
                <input
                  className="form-control form-text"
                  type="text"
                  id="company-name"
                  name="field_companyname"
                  onChange={onInputChange}
                  value={company}
                  size="30"
                  maxlength="128"
                  onFocus={(event) => clearCompany(event)}
                />
                <div
                  id="list-dropdown"
                  style={{
                    display:
                      suggestionscompanyname == "false" ? "none" : "block",
                  }}
                >
                  {suggestionscompanyname &&
                    suggestionscompanyname.map((unitidsuggestion, i) => (
                      <span
                        className="list-items"
                        key={i}
                        onClick={() =>
                          onSuggestHandlerCompanyname(
                            unitidsuggestion.field_companyname
                          )
                        }
                      >
                        {unitidsuggestion.field_companyname}
                      </span>
                    ))}
                </div>
              </div>
            </div>
          </div>

          <div className="views-exposed-widget views-submit-button">
            <button
              type="submit"
              id="edit-submit-devices-list"
              name=""
              value="Search"
              className="btn btn-primary form-submit"
            >
              {t("Search")}
            </button>
            <button
              type="button"
              id="active"
              name="op"
              value="Reset"
              onClick={(e) => {
                e.preventDefault();
                resetFields();
                onResetFilter();
                if (users.length !== selectedUsers) {
                  setIsAllSelected(false);
                }
              }}
              className="btn btn-default form-submit"
            >
              {t("Reset")}
            </button>
          </div>
        </form>
      </div>

      <UserTable
        activeUsersTab={true}
        users={users}
        onAllUsersSelection={onAllUsersSelectionChange}
        onUserSelection={onUserSelectionChange}
        isAllSelected={isAllSelected}
        selectedUsers={selectedUsers}
        noDataMsg={t("No active users")}
      />
      {users.length === 0 && <h1></h1>}
      {users.length !== 0 && (
        <div className="assignclass  active-users">
          {selectedUsers.length === 0 ? (
            <span className="assign-default-message user-default-message">
              {t("Please select at least one user to view actions")}
            </span>
          ) : (
            <div class="waiting-actions">
              <div class="default-actions">
                <button
                  type="button"
                  id="disable"
                  class="button"
                  onClick={(e) => handleOnSubmit(e, "", selectedUsers)}
                >
                  {t("Disable")}
                </button>
              </div>
            </div>
          )}
        </div>
      )}
      {users.length !== 0 && (
        <TableFooter
          totalItems={totalUsers}
          loadedItems={users.length}
          isLoading={isLoading}
          onLoadMore={onLoadMore}
        />
      )}
    </>
  );
};

export default ActiveUsers;
