import React, { useEffect, useState, useContext } from 'react';
import { LibContext } from './LibraryContext';
import config from '../../utils/config';
import { T } from '@transifex/react';
import Pagination from '../Common/Pagination';
import LoadingOverlay from "react-loading-overlay";
import { t } from "@transifex/native";
import { dateformat } from '../../utils/dateFormat';

const Content = props => {

  const [currentPage, setCurrentPage] = useState(1);
  const [nodesPerPage] = useState(8);
  const paginate = pageNumber => setCurrentPage(pageNumber);
  const [media, setMedia] = useState([]);
  const [title, setTitle] = useState('');
  const [mtype, SetMtype] = useState('')
  const [sortm, SetSortm] = useState('')
  const [loading, setLoading] = useState(false);
  const [tid, SetTid] = useState('')
  const [defid, SetDefid] = useState('')
  const { dispatchContEvent } = useContext(LibContext);
  const defaulttid = props.defaulttid;
  const userid = JSON.parse(localStorage.getItem("userdetails")).id;
  var userrole = "bm";
  var cid = "";
  var fetchUrl = "";
  JSON.parse(localStorage.getItem("userdetails")).roles.forEach(role => {
    if (role === "country_admin") {
      userrole = "country_admin";
    }
  });
  if (userrole === "bm" && props.vocabulary === 'default_library') {
    cid = JSON.parse(localStorage.getItem("userdetails")).cid;
    fetchUrl = config.drupal_url + '/api/country/media?_format=json&cid=' + cid;
  } else {
    fetchUrl = config.drupal_url + '/library_media?_format=json&uid_raw=' + userid;
  }
  const fetchMedia = async () => {
    if (defaulttid !== '') {
      try {
        setLoading(true);
        let response = await fetch(fetchUrl + '&tid_raw=' + defaulttid);
        let json = await response.json();
        sortAsc(json, 'created_1')
        setLoading(false)
        return { success: true, data: json };

      } catch (error) {
        return { success: false };
      }
    } else {
      return { success: false };
    }
  }

  const sortAsc = (arr, field) => {
    return arr.sort((a, b) => {
      if (a[field] < b[field]) { return 1; }
      if (b[field] < a[field]) { return -1; }
      return 0;
    })
  }

  const sortTitleAsc = (arr, field) => {
    return arr.sort((a, b) => {
      if (a[field] > b[field]) { return 1; }
      if (b[field] > a[field]) { return -1; }
      return 0;
    })
  }

  const fetchMediaLatest = async () => {
    try {
      let sortfilter = 'changed_1'
      setTitle(props.search);
      SetMtype(props.mediatype);
      SetSortm(props.sortmedia);
      SetTid(props.term.tid);
      SetDefid(props.defaulttid);
      let url = '';

      if (props.term.tid === '' || props.term.tid === 'undefined' || props.term.tid === undefined) {
        url = fetchUrl + '&tid_raw=' + defaulttid
      } else {
        url = fetchUrl + '&tid_raw=' + props.term.tid
      }

      if (props.search.trim() !== '') {
        url += '&title=' + props.search
      }
      if (props.sortmedia !== '') {
        sortfilter = props.sortmedia
      }
      if (props.mediatype !== '') {
        url += '&field_tab_switcher_value=' + props.mediatype
      }
      setLoading(true)
      let response = await fetch(url);
      let json = await response.json();
      if (sortfilter === 'title') {
        sortTitleAsc(json, sortfilter);
      } else {
        sortAsc(json, sortfilter)
      }
      setMedia(json)
      setLoading(false)
      return { success: true, data: json };
    } catch (error) {
      return { success: false };
    }
  }

  if (title !== props.search || mtype !== props.mediatype || sortm !== props.sortmedia || tid !== props.term.tid || defid !== props.defaulttid) {
    fetchMediaLatest()
  }

  useEffect(() => {
    (async () => {
      let res = await fetchMedia();
      if (res.success) {
        setMedia(res.data);
      }
    })();
  }, []);
  const indexOfLastGroup = currentPage * nodesPerPage;
  const indexOfFirstGroup = indexOfLastGroup - nodesPerPage;
  const currentGroup = media.slice(indexOfFirstGroup, indexOfLastGroup);

  return (
    <LoadingOverlay active={loading} spinner text={t('Processing')}>
      <div className="item-list">
        {currentGroup.length === 0 &&
          <div><T _str="No content added yet" /></div>
        }
        {currentGroup.map((img, index) => {
          var updatedOn = dateformat(img.changed, 'library');
          return (
          <div key={index}>
            <ul>
              <li className={(props.viewtype === 'ListViewWrapper') ? "views-row-list" : "views-row"}>
                <div className="library-item-content">
                  <span>
                    <div className="form-item form-type-checkbox checkbox">
                      <label className="control-label" htmlFor="edit-views-bulk-operations">
                        <input className="vbo-select form-checkbox" type="checkbox" id="edit-views-bulk-operations-0" name={img.title} onClick={(e) => dispatchContEvent(e)} value={img.nid} />
                      </label>
                    </div>
                  </span>
                </div>
                <div className="library-item-file ">
                  <div className={img.field_tab_switcher === 'Video' ? 'img-content-block playicon' : 'img-content-block'}>
                    <img src={img.field_mediaimage.replace(/&amp;/g, '&')} data-url={img.field_mediaimage} tid={img.nid} alt="Media Image" />
                  </div>
                </div>
                <div className="library-item-title">
                  <span>{img.title}</span>
                </div>
                <div className="library-item-date">
                  <span><T _str="Create date" />: </span>
                  <span>{updatedOn}</span>
                </div>
                <div className="library-item-size ">
                  <span><T _str="Size" />: </span>
                  <span>{img.filesize}</span>
                </div>
              </li>
            </ul>
          </div>
          )
          })}

      </div>
      <div className="col-12 ms-auto">
        {(media.length > 0) ?
          <Pagination
            itmesPerPage={nodesPerPage}
            totalItems={media.length}
            paginate={paginate}
            currentPage={currentPage}
          />
          : null
        }
      </div>
    </LoadingOverlay>
  );
}

export default Content;
