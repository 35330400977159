/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: AddNodeMedia.js
File Description: Implements library functionality of CMS portal

Copyright 2022,2023 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
import { useState, useEffect } from 'react';
import { T, useT } from '@transifex/react';
import '../css/Themes.css';
import FontAwesome from 'react-fontawesome'
import $ from "jquery";
import LoadingOverlay from "react-loading-overlay";
import config from '../utils/config';
import axios from 'axios'
import { useHistory } from "react-router-dom";
import { t } from "@transifex/native"
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Buffer } from 'buffer';

function AddNodeMedia(props) {

  // Variables and States
  const tabTitle = useT("Create Themes");
  const titleExists = useT("Theme with same name already exists. Please use a different name.");
  const [formData, setFormData] = useState({
    title: "",
    themeFile: "",
    videoFile: "",
    mediaType: "image"
  })
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [validations, setValidations] = useState({"VideoMaxAllowedSize": ""});
  const [term, setTerm] = useState({'tid': ''});
  const [token, setToken] = useState('');
  
  useEffect(() => {
    // Browser Tab Title
    document.title = tabTitle + ' | CMS';

    const fetchCSRF = async () => {
      await axios.get(config.drupal_url + '/session/token').then(result => {
        if (200 === result.status) {
          const csrfToken = result.data;
          setToken(csrfToken);
        }
      })
    };
    fetchCSRF();

    // Fetch Validations
    const fetchValidations = async () => {
      setLoading(true);
      await axios.get(config.drupal_url + '/rest/api/get/validations?_format=hal_json', { withCredentials: true })
      .then(response => {
        setValidations({"VideoMaxAllowedSize": response.data.VideoMaxAllowedSize});
        setLoading(false);
      })
      .catch(function (error) {
        toast.error("Unable to fetch data" + error);
      })
    };
    fetchValidations();

    const fetchULTerm = async () => {
      await axios.get(config.drupal_url + '/api/library-category-terms', { withCredentials: true })
      .then(response => {
        response.data.forEach((key, index) => {
          if (key.name === 'User Library') {
            setTerm({'tid': key.tid});
          }
        });
      })
      .catch(function (error) {
        toast.error("Unable to fetch data" + error);
      })
    };
    fetchULTerm();
  }, [])

  // Get logged in user details
  let userDetails = localStorage.getItem("userdetails");
  let loggedInUserID = '';
  if (userDetails === null) {
    loggedInUserID = 1;
  } else {
    loggedInUserID = JSON.parse(userDetails).id;
  }

  // Handle Theme Submit and Validations.
  const handleThemeSubmit = (e) => {
    e.preventDefault();
    // Title empty Validation
    // if (formData.title === "") {
    //   toast.error(titleEmpty);
    //   $("label.title_label").addClass("error-class");
    // } else {
    //   $("label.title_label").removeClass("error-class");
    // }
    // // Theme File Empty validation
    // if (formData.themeFile === "") {
    //   toast.error(fileEmpty);
    //   $("label.upload_label").addClass("error-class");
    // } else {
    //   // Image type valiations.
    //   const allowedFileTypes = ['image/jpeg', 'image/png'];
    //   if (!allowedFileTypes.includes(formData.themeFile.type)) {
    //     toast.error(t("The selected file %filename cannot be uploaded. File type must be %extensions.").replace('%filename', formData.themeFile.name).replace('%extensions', 'png, jpg, jpeg, jfif'))
    //     return false;
    //   } else {
    //     $("label.upload_label").removeClass("error-class");
    //   }
    // }

    // // Theme File Empty validation
    // if (formData.mediaType === "video") {
    //   if (formData.videoFile === "") {
    //     toast.error(videoEmpty);
    //     $("label.upload_video").addClass("error-class");
    //   } else {
    //     // Image type valiations.
    //     const videoFileSize = formData.videoFile.size/1024/1024;
    //     const allowedFileTypes = ['video/mp4'];
    //     if (!allowedFileTypes.includes(formData.videoFile.type)) {
    //       toast.error(t("The selected file %filename cannot be uploaded. File type must be %extensions.").replace('%filename', formData.videoFile.name).replace('%extensions', 'mp4'))
    //       return false;
    //     } else if (videoFileSize > validations.VideoMaxAllowedSize) {
    //       toast.error(t("The selected file @filename cannot be uploaded. File size cannot exceed @maxFileSize.").replace('@filename', formData.videoFile.name).replace('@maxFileSize', '150 MB'))
    //       return false;
    //     }
    //     else {
    //       var file = formData.videoFile;
    //       var height = "", width = "";
    //       var reader  = new FileReader();
    //       reader.readAsDataURL(file);
    //       reader.onload = function () {
    //         var dataUrl =  reader.result;
    //         var videoId = "videoMain";
    //         var $videoEl = $('<video id="' + videoId + '"></video>');
    //         //$("body").append($videoEl);
    //         $videoEl.attr('src', dataUrl);

    //         var videoTagRef = $videoEl[0];
    //         videoTagRef.addEventListener('loadedmetadata', function(e) {
    //           var height = videoTagRef.videoHeight;
    //           var width = videoTagRef.videoWidth;
    //           if (height > validations.VideoMaxHeight || width > validations.VideoMaxWidth) {
    //             toast.error("Video Dimensions issue");
    //             return false;
    //           } else {
    //             setVideoValid(true);          
    //           }
    //         });
    //       };
    //       $("label.upload_label").removeClass("error-class");
    //     }
    //   }
    // } else {
    //   setVideoValid(true);
    // }

    // Save Media
    if (formData.title && formData.themeFile) {
      var fReader = new FileReader();
      fReader.readAsDataURL(formData.themeFile);
      fReader.onload = function (e) {
        var image = new Image();
        image.src = e.target.result;
        image.onload = function () {
          var height = this.height;
          var width = this.width;
          if (height < 150 || width < 470) {
            toast.error(t("The specified file %name could not be uploaded.").replace('%name', formData.themeFile.name));
            toast.error(t("The image is too small; the minimum dimensions are %dimensions pixels.").replace('%dimensions', '470x150'));
            return false;
          } else {
            setLoading(true);
            let login = config.auth_username;
            let password = config.auth_password;
            //const encodedString = Buffer.from(`${login}:${password}`).toString('base64');
            const fileSaveUrl = config.drupal_url + '/api/node/article/field_image';
            let reader = new FileReader();
            reader.readAsArrayBuffer(formData.themeFile);
            reader.onabort = () => {console.log("file reader aborted")}
            reader.onerror = () => {console.log('error with file reader')}
            reader.onload = () => {
              const arrayStr = reader.result;
              axios.post(fileSaveUrl, arrayStr, {
                headers : {
                  "Content-Type": "application/octet-stream",
                  "Accept": "application/vnd.api+json",
                  "Content-Disposition": `file; filename="${formData.themeFile.name}"`,
                  withCredentials: true,
                  'X-CSRF-Token': token,
                }
              }).then((res) => {
                const imageFid = res.data.data.attributes.drupal_internal__fid;
                
                // Headers
                const headers = {
                  "Content-Type": "application/hal+json",
                  withCredentials: true,
                  'X-CSRF-Token': token,
                }

                // Upload Video
                if (formData.mediaType === "video") {
                  const fileSaveUrl = config.drupal_url + '/api/node/article/field_video_upload';
                  let videoReader = new FileReader();
                  videoReader.readAsArrayBuffer(formData.videoFile);
                  videoReader.onabort = () => {console.log("file reader aborted")}
                  videoReader.onerror = () => {console.log('error with file reader')}
                  videoReader.onload = () => {
                    const videoStr = videoReader.result;
                    axios.post(fileSaveUrl, videoStr, {
                      headers : {
                        "Content-Type": "application/octet-stream",
                        "Accept": "application/vnd.api+json",
                        "Content-Disposition": `file; filename="${formData.videoFile.name}"`,
                        withCredentials: true,
                        'X-CSRF-Token': token,
                      }
                    }).then((res) => {
                      const videoFid = res.data.data.attributes.drupal_internal__fid;
                      var data = {
                        "_links": {
                          "type": {
                            "href": config.rest_node_url +  "/rest/type/node/media"
                          }
                        },
                        "title": {
                          "value": formData.title.trim()
                        },
                        "field_tab_switcher": {
                          value: formData.mediaType
                        },
                        "field_mediaimage": [{
                          "target_id": imageFid,
                        }],
                        "field_upload_video": [{
                          "target_id": videoFid
                        }],
                        "field_categories": [{
                          "target_id": term.tid,
                        }],
                        "uid": [{
                          "target_id": loggedInUserID
                        }]
                      };
                      // POST url
                      const saveMediaUrl = config.drupal_url + '/node?_format=hal_json';
                      axios.post(saveMediaUrl, JSON.stringify(data), {headers})
                      .then((result) => {
                        if (result.status === 201) {
                          setLoading(false);
                          history.push({
                            pathname: "/theme-list",
                            theme_title: formData.title
                          });
                        }
                      })
                      .catch(function (error) {
                        // refine this
                        if (error.response.status === 422) {
                          setLoading(false);
                          toast.error(titleExists);
                        }
                      })
                    })
                  }
                } else {
                  // Data
                  var data = {
                    "_links": {
                      "type": {
                        "href": config.rest_node_url +  "/rest/type/node/media"
                      }
                    },
                    "title": {
                      "value": formData.title.trim()
                    },
                    "field_tab_switcher": {
                      value: formData.mediaType
                    },
                    "field_mediaimage": [{
                      "target_id": imageFid,
                    }],
                    "field_categories": [{
                      "target_id": term.tid,
                    }],
                    "uid": [{
                      "target_id": loggedInUserID
                    }]
                  };
                  // POST url
                  const saveMediaUrl = config.drupal_url + '/node?_format=hal_json';
                  axios.post(saveMediaUrl, JSON.stringify(data), {headers})
                  .then((result) => {
                    if (result.status === 201) {
                      setLoading(false);
                      history.push({
                        pathname: "/media",
                        media_title: formData.title.trim(),
                      });
                    }
                  })
                  .catch(function (error) {
                    // refine this
                    if (error.response.status === 422) {
                      setLoading(false);
                      toast.error(titleExists);
                    }
                  })
                }
              });
            }
          }
        };
      };
    }
  }

  // Hide File instructions
  $("span.question-circle").on("click", function() {
    if ($("div#upload-instructions").hasClass("hide-instructions")) {
      $("div#upload-instructions").removeClass("hide-instructions");
    }
    else {
      $("div#upload-instructions").addClass("hide-instructions");
    }
  })
  
  return (
    <LoadingOverlay active={loading} spinner text={t('Processing')}>
      <ToastContainer />
      <div className="headerSection">
        <h1 className="pageTitle">
          eView<sup>&reg;</sup> - <T _str="Create Themes" />
        </h1>
      </div>
      <div className="add-theme-forms">
        <form className="themes_form" onSubmit={handleThemeSubmit}>
          <div className="title_div theme_padding_div" id="title_div">
            <label htmlFor="title_field" className="title_label element_label"><T _str="Title" /> <span>*</span></label>
            <input
              className="title_field element_input"
              id="title_field"
              type="text"
              name="title"
              value={formData.title}
              onChange={(e) => setFormData({...formData, title: e.target.value})}
            />
          </div>
          <div className="mediaType">
            <input
              type="radio"
              value="image"
              name="mediaType"
              checked={formData.mediaType === 'image'}
              onChange={(e) => setFormData({...formData, mediaType: e.target.value})}
            /> Image
            <input
              type="radio"
              value="video"
              name="mediaType"
              checked={formData.mediaType === 'video'}
              onChange={(e) => setFormData({...formData, mediaType: e.target.value})}
            /> Video
          </div>
          <div className="upload_div theme_padding_div" id="upload_div">
            <label htmlFor="upload_field" className="upload_label element_label"><T _str="Upload image" /> <span>*</span></label>
            <input
              className="upload_field element_input"
              id="upload_field"
              type="file"
              name="selectedFile"
              onChange={(e) => setFormData({...formData, themeFile: e.target.files[0]})}
            />
          </div>
          {formData.mediaType === 'video' && 
            <div className="upload_div theme_padding_div" id="upload_div">
              <label htmlFor="upload_video" className="upload_video element_label"><T _str="Upload video" /> <span>*</span></label>
              <input
                className="upload_field element_input"
                id="upload_field"
                type="file"
                name="selectedFile"
                onChange={(e) => setFormData({...formData, videoFile: e.target.files[0]})}
              />
            </div>
          }  
          <div className="actions_div" id="actions_div">
            <button
              type="submit"
              value="Save"
              className="actions_button submit_form"
            ><FontAwesome name="check" size="lg" />
              <T _str="Save" />
            </button>
          </div>
        </form>
      </div>
    </LoadingOverlay>
  );
}

export default AddNodeMedia;
