/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: Devicelist.js
File Description: Lists the devices of CMS portal

Copyright 2021 - 2023 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import AddUseremail from '../Components/Devices/AddUseremail';
import Deviceslistunassigned from './Deviceslistunassigned';
import 'react-tabs/style/react-tabs.css';
import { useState, useEffect } from 'react';
import config from '../utils/config';
import "../css/device-list.css";
import axios from "axios";
import { T, useT } from '@transifex/react';
import { t } from "@transifex/native"
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import LoadingOverlay from "react-loading-overlay";
import { useLocation } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import AccessDenied from "../Components/Common/AccessDenied";

function Deviceslist(props) {
  const perPage = 5;
  const tabTitle = useT("Devices");
  useEffect(() => {
    document.title = tabTitle + ' | CMS';
  }, [tabTitle]);
  const [totalItems, settotalItems] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItemsun, settotalItemsun] = useState([]);
  const [grandtotal, setgrandtotal] = useState([]);
  const [page, setPage] = useState(0);
  const [searchpage, setSearchPage] = useState(0);
  const [searchload, setsearchload] = useState(0);
  const [loading, setLoading] = useState(false);
  const [unitid, setUnitid] = useState([]);
  const [cpid, setCpid] = useState([]);
  const [street, setStreet] = useState([]);
  const [city, setCity] = useState([]);
  const [useremail, setUseremail] = useState([]);
  const [userid, setUserid] = useState([]);
  const [devicedata, setDevicedata] = useState([]);
  const [devicedataall, setDevicedataall] = useState([]);
  const [deviesemail, setdevicesemail] = useState([]);
  const location = useLocation();

  const [suggestionsunitid, setSuggestionsunitid] = useState([]);
  const [suggestionscpid, setSuggestionscpid] = useState([]);
  const [suggestionsstreetadrress, setSuggestionsstreetadrress] = useState([]);
  const [suggestionscity, setSuggestionscity] = useState([]);
  const [suggestionsemail, setSuggestionsemail] = useState([]);
  const [unitIdsChecked, setUnitIdsChecked] = useState([]);
  const [toggleState, setToggleState] = useState(1);
  const [allChecked, setAllChecked] = useState(false);
  const [sort, SetSort] = useState('changed');
  const [order, SetOrder] = useState('DESC');
  const [tabIndex, setTabIndex] = useState(0);

  var token = localStorage.getItem("userdetails")
  var countryid = token && JSON.parse(token).cid
  let userrole = 'building_manager';
  if (token !== null) {
    var userdetails = JSON.parse(localStorage.getItem('userdetails'));
    userdetails.roles.forEach(role => {
      if (role === "country_admin") {
        userrole = "country_admin";
      }
      if (role === "super_admin") {
        userrole = "super_admin";
      }
    });
  }

  const toggleTab = (index) => {
    setToggleState(index)
  }

  const deviceselectedTab = (index) => {
    setTabIndex(index);
    const devicelistData = {
      selectedtab: index,
    };
    localStorage.setItem(
      "devicelist",
      JSON.stringify(devicelistData)
    );
  }

  const regExpEscape = (literal_string) => {
    return literal_string.replace(/[-[\]{}()*+!<=:?.\/\\^$|#\s,]/g, '\\$&');
  }
  useEffect(() => {
    setLoading(true);
    if (location.device_title !== undefined && location.device_title !== "") {
      toast.success(t("@type %title has been created.").replace('@type', t('Device')).replace('%title', location.device_title));
    }
    
    var devicetabid = JSON.parse(
      localStorage.getItem("devicelist")
    );
    if (devicetabid !== null) {
      let editdevice = JSON.parse(localStorage.getItem('devicelist')).editdevice ? JSON.parse(localStorage.getItem('devicelist')).editdevice : 0;
      if (editdevice)
             {
          var deviceselectedtab = JSON.parse(localStorage.getItem('devicelist')).selectedtab ? JSON.parse(localStorage.getItem('devicelist')).selectedtab : 0;
             setTabIndex(deviceselectedtab);
             devicetabid.selectedtab = deviceselectedtab; 
             devicetabid.editdevice = JSON.parse(localStorage.getItem('devicelist')).editdevice;
             localStorage.setItem(
            "devicelist",
            JSON.stringify(devicetabid) 
          );
       }
      
    }  

    const fetchDevices = async () => {

      if (perPage != 5) {
        axios.get(config.drupal_url + `/api/deviceslist?field_assign_user_target_id_op=not+empty&field_eserviceunitid_value=${unitid}&field_mpdid_value=${cpid}&field_unit_city_value=${city}&field_unit_streetaddress_value=${street}&field_country_nid_target_id=${countryid}&sort_by=${sort}&sort_order=${order}&field_assign_user_target_id[value]=${userid}`, { withCredentials: true })
          .then((reponse) => {
            setDevicedata([...devicedata, ...reponse.data.rows]);
            setTotalPages(reponse.data.pager.total_pages);
            settotalItems(reponse.data.pager.total_items);
            setgrandtotal(reponse.data.pager.total_items);
            setLoading(false);
          })
          .catch(function (error) {
            console.error('There was an error!', error);
          })
      } else {
        if (searchpage === 0) {
          if (searchload === 0) {
            axios.get(config.drupal_url + `/api/deviceslist?field_assign_user_target_id_op=not+empty&field_country_nid_target_id=${countryid}&field_mpdid_value=${cpid}&sort_by=${sort}&sort_order=${order}&field_assign_user_target_id[value]=${userid}&items_per_page=${perPage}&page=${page}`, { withCredentials: true })
              .then((reponse) => {
                setDevicedata([...devicedata, ...reponse.data.rows]);
                setTotalPages(reponse.data.pager.total_pages);
                setgrandtotal(reponse.data.pager.total_items);
                settotalItems(reponse.data.pager.total_items);
                setLoading(false);
              })
              .catch(function (error) {
                console.error('There was an error!', error);
              })
          } else {
            if (userid > 0) {
              axios.get(config.drupal_url + `/api/deviceslist?field_assign_user_target_id_op==&field_assign_user_target_id[value]=${userid}&field_mpdid_value=${cpid}&field_eserviceunitid_value=${unitid}&field_unit_city_value=${city}&field_unit_streetaddress_value=${street}&field_country_nid_target_id=${countryid}&sort_by=${sort}&sort_order=${order}&items_per_page=${perPage}&page=${page}`, { withCredentials: true })
                .then((reponse) => {
                  setDevicedata([...devicedata, ...reponse.data.rows]);
                  setTotalPages(reponse.data.pager.total_pages);
                  settotalItems(reponse.data.pager.total_items);
                  setLoading(false);
                })
                .catch(function (error) {
                  console.error('There was an error!', error);
                })
            } else {
              axios.get(config.drupal_url + `/api/deviceslist?field_assign_user_target_id_op=not+empty&field_eserviceunitid_value=${unitid}&field_mpdid_value=${cpid}&field_unit_city_value=${city}&field_unit_streetaddress_value=${street}&field_country_nid_target_id=${countryid}&sort_by=${sort}&sort_order=${order}&field_assign_user_target_id[value]=${userid}&items_per_page=${perPage}&page=${page}`, { withCredentials: true })
                .then((reponse) => {
                  setDevicedata([...devicedata, ...reponse.data.rows]);
                  setTotalPages(reponse.data.pager.total_pages);
                  settotalItems(reponse.data.pager.total_items);
                  setLoading(false);
                })
                .catch(function (error) {
                  console.error('There was an error!', error);
                })
            }
          }
        }
      }
    }
    fetchDevices();

  }, [page]);

  useEffect(() => {
    const fetchDevicesun = async () => {
      axios.get(config.drupal_url + `/api/deviceslist?field_assign_user_target_id_op=empty&&field_eserviceunitid_value=${unitid}&field_mpdid_value=${cpid}&field_unit_city_value=${city}&field_unit_streetaddress_value=${street}&field_country_nid_target_id=${countryid}&field_assign_user_target_id[value]=${userid}&sort_by=${sort}&sort_order=${order}`)
        .then((reponse) => {
          settotalItemsun(reponse.data.pager.total_items);
          setLoading(false);
        })
        .catch(function (error) {
          console.error('There was an error!', error);
        })
    }
    fetchDevicesun();
  }, []);

  useEffect(() => {
    const fetchDeviceseall = async () => {
      axios.get(config.drupal_url + `/api/deviceslist?field_assign_user_target_id_op=not+empty&field_eserviceunitid_value=${unitid}&field_mpdid_value=${cpid}&field_unit_city_value=${city}&field_unit_streetaddress_value=${street}&field_country_nid_target_id=${countryid}&sort_by=${sort}&sort_order=${order}&field_assign_user_target_id[value]=${userid}`, { withCredentials: true })
        .then((reponse) => {
          setDevicedataall([...devicedataall, ...reponse.data.rows]);
          setLoading(false);
        })
    }
    fetchDeviceseall();
  }, []);

  const onChangeHandlerunitid = (unitid) => {
    setUnitid(unitid);
    let eserviceunitidmatches = []
    if (unitid) {
      const regex = new RegExp(`${unitid}`, 'i');
      eserviceunitidmatches = devicedataall.filter(devicedataall => regex.test(devicedataall.field_eserviceunitid));
    }
    const unique = [...new Set(eserviceunitidmatches.map(item => item.field_eserviceunitid))];
    setSuggestionsunitid(unique)
  }

  const onChangeHandlercpid = (cpid) => {
    setCpid(cpid);
    let cpidmatches = []
    if (cpid) {
      const regex = new RegExp(`${cpid}`, 'i');
      cpidmatches = devicedataall.filter(devicedataall => regex.test(devicedataall.field_mpdid));
    }
    const unique = [...new Set(cpidmatches.map(item => item.field_mpdid))];
    setSuggestionscpid(unique)
  }

  const onChangeHandlerstreet = (street) => {
    setStreet(street);
    let streetaddressmatches = []
    if (street) {
      const regex = new RegExp(`${street}`, 'i');
      streetaddressmatches = devicedataall.filter(devicedataall => regex.test(devicedataall.field_unit_streetaddress));
    }
    const unique = [...new Set(streetaddressmatches.map(item => item.field_unit_streetaddress))];
    setSuggestionsstreetadrress(unique)
  }

  const onChangeHandlercity = (city) => {
    setCity(city)
    let citymatches = []
    if (city) {
      const regex = new RegExp(`${city}`, 'i');
      citymatches = devicedataall.filter(devicedataall => regex.test(devicedataall.field_unit_city));
    }
    const unique = [...new Set(citymatches.map(item => item.field_unit_city))];
    const trimmedCities = [];
    // Trim extra spaces in and around city names
    unique.forEach((cityname, index) => {
      const trimmed = cityname.replace(/\s{2,}/g,' ').trim();
      trimmedCities.push(trimmed);
    });
    // remove duplicates
    const uniqueTrimmedCities = [...new Set(trimmedCities)];
    setSuggestionscity(uniqueTrimmedCities);
  }

  const handleSortChange = (sortvalue) => {
    SetSort(sortvalue)

  }
  const handleOrderChange = (ordervalue) => {
    SetOrder(ordervalue)

  }

  const search = (e) => {
    e.preventDefault();
    const devicefilter = async () => {
      setLoading(true);
      if (userid > 0) {
        setSearchPage(searchpage + 1);
        setsearchload(searchload + 1)
        setPage(0);
        axios.get(config.drupal_url + `/api/deviceslist?field_assign_user_target_id_op==&field_assign_user_target_id[value]=${userid}&field_eserviceunitid_value=${unitid}&field_mpdid_value=${cpid}&field_unit_city_value=${city}&field_unit_streetaddress_value=${street}&field_country_nid_target_id=${countryid}&sort_by=${sort}&sort_order=${order}&items_per_page=${perPage}&page=0`, { withCredentials: true })
          .then((reponse) => {
            setDevicedata(reponse.data.rows);
            setTotalPages(reponse.data.pager.total_pages);
            settotalItems(reponse.data.pager.total_items);
            setLoading(false);
          })
          .catch(function (error) {
            console.error('There was an error!', error);
          })
      } else if (document.getElementById("edit-mail").value != "" && userid == "") {
        setDevicedata([]);

      } else {
        setSearchPage(searchpage + 1);
        setsearchload(searchload + 1)
        setPage(0);
        axios.get(config.drupal_url + `/api/deviceslist?field_assign_user_target_id_op=not+empty&field_eserviceunitid_value=${unitid}&field_mpdid_value=${cpid}&field_unit_city_value=${city}&field_unit_streetaddress_value=${street}&field_country_nid_target_id=${countryid}&sort_by=${sort}&sort_order=${order}&items_per_page=${perPage}&page=0`, { withCredentials: true })
          .then((reponse) => {
            setDevicedata(reponse.data.rows);
            setTotalPages(reponse.data.pager.total_pages);
            settotalItems(reponse.data.pager.total_items);
            setLoading(false);
          })
          .catch(function (error) {
            console.error('There was an error!', error);
          })

      }

    }

    devicefilter();

  }
  const refreshPage = async () => {
    window.location.reload(false)
  }




  const handleCheckedunitid = (unitid, event) => {
    const unitChecked = [...unitIdsChecked];
    if (unitChecked.includes(event.target.id)) {
      var index = unitChecked.indexOf(event.target.id);
      unitChecked.splice(index, 1);
    } else {
      unitChecked.push(event.target.id);
    }
    setUnitIdsChecked(unitChecked);

  };

  const handleAllCheck = e => {
    if (allChecked) {
      setAllChecked(false);
      return setUnitIdsChecked([]);
    }
    setAllChecked(true);
    return setUnitIdsChecked(devicedata.map(row => row.nid));
  };



  useEffect(() => {
    const fetchDevicesemail = async () => {
      axios.get(config.drupal_url + '/Userslist/' + countryid, { withCredentials: true })
        .then(response => {
          setdevicesemail(response.data)
        })
    }
    fetchDevicesemail();
  }, []);

  const onChangeHandleremail = (useremail) => {
    setUseremail(useremail)
    let emailmatches = []
    if (useremail) {
      const regex = new RegExp(`${regExpEscape(useremail)}`, 'gi');
      emailmatches = deviesemail.filter(deviesemail => regex.test(deviesemail.mail) || regex.test(deviesemail.uid));
    }
    setUserid([])
    setSuggestionsemail(emailmatches)
  }

  const onSuggestHandlerunitid = (unitid) => {
    setUnitid(unitid)
    setSuggestionsunitid([false])
  }
  const onSuggestHandlercpid = (cpid) => {
    setCpid(cpid)
    setSuggestionscpid([false])
  }
  const onSuggestHandlerstreet = (street) => {
    setStreet(street)
    setSuggestionsstreetadrress([false])
  }

  const onSuggestHandlercity = (city) => {
    setCity(city)
    setSuggestionscity([false]);
  }
  const onSuggestHandleremail = (useremail, uid) => {
    setUseremail(useremail)
    setUserid(uid)
    setSuggestionsemail([false]);
  }

  const buttonclicked = (val) => {
    setLoading(val);
  }

  const clearUnitid = (event) => {
    setSuggestionsstreetadrress([false])
    setSuggestionscity([false])
    setSuggestionsemail([false])
    setSuggestionscpid([false])
  }
  const clearCpid = (event) => {
    setSuggestionsunitid([false])
    setSuggestionsstreetadrress([false])
    setSuggestionscity([false])
    setSuggestionsemail([false])
  }
  const clearStreetadress = (event) => {
    setSuggestionsunitid([false])
    setSuggestionscity([false])
    setSuggestionsemail([false])
    setSuggestionscpid([false])
  }
  const clearCity = (event) => {
    setSuggestionsunitid([false])
    setSuggestionsstreetadrress([false])
    setSuggestionsemail([false])
    setSuggestionscpid([false])
  }
  const clearEmail = (event) => {
    setSuggestionsunitid([false])
    setSuggestionscity([false])
    setSuggestionsstreetadrress([false])
    setSuggestionscpid([false])
  }

  if (userrole === "building_manager") {
    return (
      <AccessDenied title="Access Denied" />
    )
  }

  return (
    <>

      <div className="groups-heading-container Devicelist-heading">
        <ToastContainer />
        <h2>eView<sup>&reg;</sup> - <T _str="Devices" /></h2>
      </div>
      <LoadingOverlay active={loading} spinner text={t('Processing')}>
        <Tabs selectedIndex={tabIndex} onSelect={(index) => deviceselectedTab(index)} className="device-list-tabs">
          <TabList>
            <Tab onClick={() => toggleTab(1)}><T _str="Devices Assigned" />({grandtotal})</Tab>
            <Tab onClick={() => toggleTab(2)}><T _str="Devices Unassigned" /> ({totalItemsun})</Tab>
          </TabList>
          <TabPanel>
            <div className="views-exposed-widgets">
              <form onSubmit={search}>
                <div className="views-exposed-widget unit-number">
                  <label for="unit-number"><T _str="Unit Number" /></label>
                  <div className="views-widget">
                    <div className="input-group">
                      <input className="form-control form-text" type="text" id="unit-number" onFocus={(event) => clearUnitid(event)} name="field_eserviceunitid" onChange={(e) => onChangeHandlerunitid(e.target.value)} value={unitid} size="30" maxlength="128" />
                      <div id="list-dropdown" style={{ display: suggestionsunitid == "false" ? "none" : "block" }}>
                        {(suggestionsunitid.length == 0 && unitid != "") ?
                          <span className="list-items">
                            {t("No results found. Please try again.")}
                          </span> :
                          suggestionsunitid.map((unitidsuggestion, i) =>
                            <span className="list-items" key={i} onClick={() => onSuggestHandlerunitid(unitidsuggestion)} >{unitidsuggestion}</span>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="views-exposed-widget cpid">
                  <label for="cpid"><T _str="CP-ID" /></label>
                  <div className="views-widget">
                    <div className="input-group">
                      <input className="form-control form-text" type="text" id="cpid" onFocus={(event) => clearCpid(event)} name="field_mpdid" onChange={(e) => onChangeHandlercpid(e.target.value)} value={cpid} size="30" maxlength="128" />
                      <div id="list-dropdown" style={{ display: suggestionscpid == "false" ? "none" : "block" }}>
                        {(suggestionscpid.length == 0 && cpid != "") ?
                          <span className="list-items">
                            {t("No results found. Please try again.")}
                          </span> :
                          suggestionscpid.map((cpidsuggestion, i) =>
                            <span className="list-items" key={i} onClick={() => onSuggestHandlercpid(cpidsuggestion)} >{cpidsuggestion}</span>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="views-exposed-widget StreetAddress">
                  <label for="street-address"><T _str="Street Address" /></label>
                  <div className="views-widget">
                    <div className="input-group">
                      <input className="form-control form-text" type="text" id="street-address" onFocus={(event) => clearStreetadress(event)} name="field_unit_streetaddress" onChange={(e) => onChangeHandlerstreet(e.target.value)} value={street} size="30" maxlength="128" autocomplete="OFF" aria-autocomplete="list" />
                      <div id="list-dropdown" style={{ display: suggestionsstreetadrress == "false" ? "none" : "block" }}>
                        {(suggestionsstreetadrress.length == 0 && street != "") ?
                          <span className="list-items">
                            {t("No results found. Please try again.")}
                          </span> : suggestionsstreetadrress.map((streetsuggestion, i) =>
                            <span className="list-items" key={i} onClick={() => onSuggestHandlerstreet(streetsuggestion)} >{streetsuggestion}</span>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="views-exposed-widget city">
                  <label for="city"><T _str="City" /></label>
                  <div className="views-widget">
                    <div className="input-group">
                      <input className="form-control form-text" type="text" id="city" onFocus={(event) => clearCity(event)} name="field_unit_city" onChange={(e) => onChangeHandlercity(e.target.value)} value={city} size="30" maxlength="128" autocomplete="OFF" aria-autocomplete="list" />
                      <div id="list-dropdown" style={{ display: suggestionscity == "false" ? "none" : "block" }}>
                        {(suggestionscity.length == 0 && city != "") ?
                          <span className="list-items">
                            {t("No results found. Please try again.")}
                          </span> : suggestionscity.map((citysuggestion, i) =>
                            <span className="list-items" key={i} onClick={() => onSuggestHandlercity(citysuggestion)} >{citysuggestion}</span>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="views-exposed-widget email">
                  <label for="email"><T _str="E-mail" /></label>
                  <div className="views-widget">
                    <div className="input-group">
                      <input className="form-control form-text" type="text" id="edit-mail" onFocus={(event) => clearEmail(event)} name="field_assign_user" onChange={(e) => onChangeHandleremail(e.target.value)} datauserid={userid} value={useremail} size="30" maxlength="128" autocomplete="OFF" aria-autocomplete="list" />
                      <div id="list-dropdown" style={{ display: suggestionsemail == "false" ? "none" : "block" }}>
                        {(suggestionsemail.length == 0 && useremail != "") ?
                          <span className="list-items">
                            {t("No results found. Please try again.")}
                          </span> : suggestionsemail.map((emailsuggestion, i) =>
                            <span className="list-items" key={i} onClick={() => onSuggestHandleremail(emailsuggestion.mail, emailsuggestion.uid)} >{emailsuggestion.mail}</span>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="views-exposed-widget sort-by">
                  <label for="sort-by"><T _str="Sort by" /></label>
                  <div className="views-widget">
                    <div className="input-group">
                      <select value={sort} onChange={(val) => handleSortChange(val.target.value)} className="form-control form-select" id="edit-sort-by" name="sort_by"><option value="changed" selected="selected">{t("Updated date")}</option><option value="field_eserviceunitid_value">{t("Unit Number")}</option></select>
                    </div>
                  </div>
                </div>
                <div className="views-exposed-widget sort-order">
                  <label for="sort-by"><T _str="Sort Order" /></label>
                  <div className="views-widget">
                    <div className="input-group">
                      <select onChange={(val) => handleOrderChange(val.target.value)} className="form-control form-select" id="edit-sort-order" name="sort_order"><option value="ASC">{t("Asc")}</option><option value="DESC" selected="selected">{t("Desc")}</option></select>
                    </div>
                  </div>
                </div>

                <div className="views-exposed-widget views-submit-button">
                  <button type="submit" id="edit-submit-devices-list" name="" value="Search" className="btn btn-primary form-submit"><T _str="Search" /></button>
                  <button type="submit" id="edit-reset" name="op" value="Reset" onClick={refreshPage} className="btn btn-default form-submit"><T _str="Reset" /></button>
                </div>
              </form>
            </div>
            {(loading === false && devicedata.length === 0) && (
              <div class="device-not-found">  <T _str="No Devices Found" /></div>
            )}
            {(devicedata.length > 0) && (
              <div className="tbody-div">
                <table className="views-table cols-7 table table-hover table-striped">
                  <thead><tr>
                    <th className="views-field views-row-selector" scope="col"><div className="form-item form-type-checkbox checkbox"> <label className="control-label"><input className="views-row-selector-table-select-all form-checkbox" type="checkbox" value="1" checked={allChecked}
                      onChange={handleAllCheck} /></label></div></th>
                    <th className="views-field field-eserviceunitid" scope="col"><T _str="Unit Number" /></th>
                    <th className="views-field field-mpdids" scope="col"><T _str="CP-ID" /> </th>
                    <th className="views-field field-unit-streetaddress" scope="col"><T _str="Street Address" /></th>
                    <th className="views-field field-unit-city" scope="col"><T _str="City" /> </th>
                    <th className="views-field field-assign-user" scope="col"><T _str="Email" /></th>
                    <th className="views-field edit-node" scope="col"><T _str="Actions" /></th>
                  </tr></thead>
                  <tbody>
                    {devicedata.map((row, index) => {
                      return (
                        <tr className="views-row box" key={index}>
                          <td className="views-field views-row-selector"><div className="form-item form-item-views-row-selector-0 form-type-checkbox checkbox">
                            <label className="control-label" for="edit-views-row-selector-0">
                              <input className="views-row-selector-select form-checkbox" type="checkbox" id={row.nid} checked={unitIdsChecked.includes(row.nid) ? 1 : 0}
                                onChange={(event) => handleCheckedunitid(index, event)} name="views_row_selector[0]" value={row.nid} /></label></div></td>
                          <td className="views-field field-eserviceunitid">{row.field_eserviceunitid}</td>
                          <td className="views-field "> {row.field_mpdid}</td>
                          <td className="views-field field-unit-streetaddress">{row.field_unit_streetaddress}</td>
                          <td className="views-field field-unit-city">{row.field_unit_city}</td>
                          <td className="views-field field-assign-user"> {row.field_assign_user.split(',').slice(0, 2).join(",")}
                            {row.field_assign_user.split(',').length <= 2 ? '' : <div><div class="email-moretooltip" data-tip={row.field_assign_user.split(',').slice(2, row.field_assign_user.split(',').length).join(",<br />")} data-html={true} data-offset="{'top': 10, 'left': 50}">+ {row.field_assign_user.split(',').length - 2} {t('Others')}</div><ReactTooltip effect='solid' className='extraClass' place={'bottom'} delayHide={1000} getContent={(dataTip) => `${dataTip}`} /></div>}

                          </td>
                          <td className="views-field edit-node"><a href={'/deviceconfiguration/' + row.nid}><T _str="View" /></a></td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            )}
            <div className="assignclass"><div class="assignclass-child">
              <span className="assign-default-message" style={{ display: unitIdsChecked.length ? 'none' : 'block' }}>
                <T _str="Please select at least one device to view actions" /></span>
              {unitIdsChecked.length && <AddUseremail useremail={suggestionsemail} checkedunitid={unitIdsChecked} buttonclicked={buttonclicked} />}
            </div>
            </div>
            <div className="device-list-footer">
              {(perPage * (page + 1)) < totalItems && <button className="btn-load-more" onClick={() => { setPage(page + 1); setSearchPage(0) }}>{loading ? '' : <T _str='Load more' />}</button>}
              {totalItems !== 0 && <span className="list-count">{t('Displaying @start - @end of @total').replace('@start', 1).replace('@end', (perPage * (page + 1)) < totalItems ? perPage * (page + 1) : totalItems).replace('@total', totalItems)}</span>}
            </div>
          </TabPanel>
          <TabPanel>
            <Deviceslistunassigned />
          </TabPanel>
        </Tabs>
      </LoadingOverlay>
    </>
  )
}

export default Deviceslist;