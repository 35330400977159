/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: AddMercuryUser.js
File Description: Implements add mercury user functionality of CMS portal

Copyright 2024 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
import React, { useState, useEffect } from 'react';
import { T, useT } from '@transifex/react';
import { t } from "@transifex/native";
import $ from "jquery";
import LoadingOverlay from "react-loading-overlay";
import FontAwesome from 'react-fontawesome';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import config from '../utils/config';
import "../css/livecontent.css";
import 'react-toastify/dist/ReactToastify.css';
import "../css/improved_multi_select.css";
import ReactTooltip from 'react-tooltip';
import { Buffer } from 'buffer';

function AddMercuryUser({ data, state }) {
  const tabTitle = useT("Manage Mercury Users");
  const [languagesData, setLanguagesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const hiddenFileInput = React.useRef(null);

  const handleClick = event => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };

  const [formData, setFormData] = useState({
    assignedusers: "",
    users: ""
  })
  
  const regExpEscape = (literal_string) => {
    return literal_string.replace(/[-[\]{}()*+!<=:?.\/\\^$|#\s,]/g, '\\$&');
  }
  // Get logged in user details
  let userDetails = localStorage.getItem("userdetails");
  let loggedInUserID = '';
  let countryId = '';
  let countryName = '';
  var userrole = 'building_manager';
  if (userDetails === null) {
    loggedInUserID = 1;
    countryId = 10;
    countryName = 'United States';
  } else {
    loggedInUserID = JSON.parse(userDetails).id;
    countryId = JSON.parse(userDetails).cid;
    countryName = JSON.parse(userDetails).country;
    JSON.parse(localStorage.getItem("userdetails")).roles.forEach(role => {
      if (role === "country_admin") {
        userrole = "country_admin";
      }
    });
  }

   const handleValidation = (formData) => {
    let formValid = true;
    //let errorMsg = '';
    $("label").removeClass("error-class");
    $(".error-msg").remove();
	 if (userrole === 'country_admin') {
      let selectedOptions = $('#edit-field-assign-user-und').val();
      formData.users = selectedOptions;
     if (formData.users.length === 0 && formData.assignedusers === null ) {
        let errorMsg = t('!name field is required.').replace('!name', t('Assign Mercury user'));
        $("label.assign-users-label").addClass("error-class");
        $("#improvedselect-edit-field-assign-user-und").after('<p class="error-msg" style="color:red;">' + errorMsg + '</p>');
        formValid = false;
      }
    }
     return formValid;
  }

  // Browser Tab Title
  useEffect(() => {
    document.title = tabTitle + ' | CMS';
  }, [tabTitle])

  useEffect(() => {
     setLoading(true);
    var userOptions = '';
	  axios.get(config.drupal_url + '/getmercuryusers?_format=json', { withCredentials: true })
	          .then(response => {
				const mercurydata = response.data.uid;
				  formData.assignedusers=mercurydata;
	  axios.get(config.drupal_url + '/getusersdata/' + countryId, { withCredentials: true })
      .then(userresponse => {
	//let userArr = mercurydata.split(',');
        var userOptions = '';
        $.each(userresponse.data, function (key, value) {
               if ($.inArray(value.uid, mercurydata) > -1) {
                  userOptions = '<option value="' + value.uid + '" selected>' + value.mail + '</option>'
                } else {
                  userOptions = '<option value="' + value.uid + '">' + value.mail + '</option>'
                }
                $('#edit-field-assign-user-und').append(userOptions);
              });
      }).then(userresponse => {
        const assignwidget = $("#improvedselect-edit-field-assign-user-und").length;
        if (assignwidget === 0) {
          $('#edit-field-assign-user-und').parent().append('<div class="improvedselect" sid="' + $('#edit-field-assign-user-und').attr('id') + '" id="improvedselect-' + $('#edit-field-assign-user-und').attr('id') + '"><div class="improvedselect-text-wrapper"><input type="text" class="improvedselect_filter" sid="' + $('#edit-field-assign-user-und').attr('id') + '" prev="" /></div><ul class="improvedselect_sel"></ul><ul class="improvedselect_all"></ul><div class="improvedselect_control"><span class="add" sid="' + $('#edit-field-assign-user-und').attr('id') + '">&gt;</span><span class="del" sid="' + $('#edit-field-assign-user-und').attr('id') + '">&lt;</span><span class="add_all" sid="' + $('#edit-field-assign-user-und').attr('id') + '">&raquo;</span><span class="del_all" sid="' + $('#edit-field-assign-user-und').attr('id') + '">&laquo;</span></div><div class="clear" /></div>');
          var improvedselect_id = $('#edit-field-assign-user-und').attr('id');
          $('#edit-field-assign-user-und').find('option').each(function () {
            if ($(this).attr('selected')) {
              $('#improvedselect-' + improvedselect_id + ' .improvedselect_sel').append('<li so="' + $(this).attr('value') + '">' + $(this).text() + '</li>');
            }
            else {
              $('#improvedselect-' + improvedselect_id + ' .improvedselect_all').append('<li so="' + $(this).attr('value') + '">' + $(this).text() + '</li>');
            }
          });
          $('#improvedselect-' + improvedselect_id + ' li').click(function () {
            $(this).toggleClass('selected');
          });

          setLoading(false);
          $('#edit-field-assign-user-und').hide();
          $('.improvedselect_filter').keyup(function () {
            var text = $(this).val();
            if (text.length && text !== $(this).attr('prev')) {
              $(this).attr('prev', text);
              var patt = new RegExp(`${regExpEscape(text)}`, 'i');
              $('#improvedselect-' + $(this).attr('sid') + ' .improvedselect_all li').each(function () {
                var str = $(this).text();
                if (str.match(patt)) {
                  $(this).show();
                }
                else {
                  $(this).hide();
                }
              });
            }
            else {
              $(this).attr('prev', '')
              $('#improvedselect-' + $(this).attr('sid') + ' .improvedselect_all li').each(function () {
                $(this).show();
              });
            }
          });

          // Add selected items.
          $('.improvedselect .add').click(function () {
            var sid = $(this).attr('sid');
            $('#improvedselect-' + sid + ' .improvedselect_all .selected').each(function () {
              $(this).removeClass('selected');
              $(this).show();
              $('#improvedselect-' + sid + ' .improvedselect_sel').append($(this));
            });
            improvedselectUpdate(sid);
          });

          // Remove selected items.
          $('.improvedselect .del').click(function () {
            var sid = $(this).attr('sid');
            $('#improvedselect-' + sid + ' .improvedselect_sel .selected').each(function () {
              $(this).removeClass('selected');
              $('#improvedselect-' + sid + ' .improvedselect_all').append($(this));
            });
            improvedselectUpdate(sid);
          });

          // Remove all filtered items.
          $('.improvedselect .add_all').click(function () {
            var sid = $(this).attr('sid');
            $('#improvedselect-' + sid + ' .improvedselect_all li').each(function () {
              if ($(this).css('display') !== 'none') {
                $(this).removeClass('selected');
                $('#improvedselect-' + sid + ' .improvedselect_sel').append($(this));
              }
            });
            improvedselectUpdate(sid);
          });

          // Remove all items.
          $('.improvedselect .del_all').click(function () {
            var sid = $(this).attr('sid');
            $('#improvedselect-' + sid + ' input').val('');
            $('#improvedselect-' + sid + ' input').attr('prev', '');
            $('#improvedselect-' + sid + ' .improvedselect_sel li').each(function () {
              $(this).removeClass('selected');
              $('#improvedselect-' + sid + ' .improvedselect_all').append($(this));
            });
            $('#improvedselect-' + sid + ' .improvedselect_all li').each(function () {
              $(this).show();
            });
            improvedselectUpdate(sid);
          });
        }
      });
       })
  }, []);

  function improvedselectUpdate(sid) {
    $('#' + sid + ' option:selected').attr("selected", false);
    $('#improvedselect-' + sid + ' .improvedselect_sel li').each(function () {
      $('#' + sid + ' [value="' + $(this).attr('so') + '"]').attr("selected", "selected");
    });
    $('#' + sid).find('option').each(function () {
      if ($(this).attr("selected")) {
        $('#improvedselect-' + sid + ' .improvedselect_sel').append($('#improvedselect-' + sid + ' .improvedselect_sel [so="' + $(this).attr('value') + '"]'));
      }
      else {
        $('#improvedselect-' + sid + ' .improvedselect_all').append($('#improvedselect-' + sid + ' .improvedselect_all [so="' + $(this).attr('value') + '"]'));
      }
    });
  }

  const notify = (val) => {
    toast.error(val, {
      toastId: loggedInUserID
    });
  }

  const handleFeedSubmit = (e) => {
    e.preventDefault();
	    let validForm = handleValidation(formData);
    if (validForm) {
      setLoading(true);
              const saveMediaUrl = config.drupal_url + '/addmercuryuser?_format=json';
              axios.post(saveMediaUrl, JSON.stringify(formData), { withCredentials: true })
                .then((result) => {
                  if (result.data.success && result.data.success === 'Mercuryuserisadded') {
                    setLoading(false);
                    var str = t("Users has been updated.");
                     toast.success(str);
			  setTimeout(() => {
              setLoading(true);
              window.location.reload();
            }, 5000);
                  }
                })
                .catch(function (error) {
                  setLoading(false);
                  toast.error(error.response);
                })
   }
  }
  return (
    <LoadingOverlay active={loading} spinner text={t('Processing')}>
      <ToastContainer />
      <div className="create-live-content">
        <div className="page-heading">
          <h2>
            eView<sup>&reg;</sup> -
            <T _str="Manage Mercury Users" />
          </h2>
        </div>
        <div className="live-content-inner">
        
          <form onSubmit={(e) =>
            handleFeedSubmit(e)}>
          
              <br />
            {userrole === 'country_admin' &&
              <div className="form-item form-item-field-assign-user-und form-type-select form-group mb-2">
                <label className="control-label assign-users-label mb-2" htmlFor="edit-field-assign-user-und">
                  <T _str="Assign Mercury user" />
                  <span className="form-required" title="This field is required.">*</span>
                </label>
                <select
                  multiple="multiple"
                  name="field_assign_user"
                  className="form-control form-select required improvedselect-processed"
                  id="edit-field-assign-user-und">
                </select>
              </div>
            }
			<br />
            <div className="form-actions form-wrapper" id="edit-actions">
              <button type="submit" id="edit-submit" name="op" value="Save" className="btn btn-success form-submit icon-before">
                <span className="icon glyphicon glyphicon-ok" aria-hidden="true"></span>
                <T _str="Save" />
              </button>

            </div>
          </form>
        </div>
      </div >
    </LoadingOverlay >
  );
}

export default AddMercuryUser;
