/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: Playlists.js
File Description: Implements playlist listing functionality of CMS portal

Copyright 2021 - 2024 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { t } from "@transifex/native";
import defaultimg from "../../Assets/no-image.jpg";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { FaTimesCircle } from "react-icons/fa";
import { Modal } from "react-bootstrap";
import AddPlaylistsPop from "../../Components/Playlist/AddPlaylistsPop";
import config from "../../utils/config";
import "../../Components/Playlist/Playlists.css";
import { useHistory } from "react-router-dom";
import PlaylistsPreview from "../../Components/Common/PlaylistsPreview";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingOverlay from "react-loading-overlay";
import { T, useT } from "@transifex/react";
import { dateformat } from "../../utils/dateFormat";
import { Buffer } from "buffer";
import { appInsights, severity } from "../../AppInsights";

function Playlist() {
  const tabTitle = useT("Playlists");
  useEffect(() => {
    document.title = tabTitle + " | CMS";
  }, [tabTitle]);

  const userdetails = localStorage.getItem("userdetails");
  const customId = userdetails && JSON.parse(userdetails).id;
  let appinsights_flag = localStorage.getItem("appinsights");
  var parenId = "";
  if (localStorage.getItem("parentuser")) {
    var parentuser = localStorage.getItem("parentuser");
    parenId = parentuser && JSON.parse(parentuser).id;
  } else {
    parenId = customId;
  }
  const disablesetasdeafult =
    userdetails && JSON.parse(userdetails).setasdeafult;
  var country_name = userdetails && JSON.parse(userdetails).country;

  const notify = (val) => {
    toast.error(val, {
      toastId: customId,
    });
  };

  //const encodedString = Buffer.from(config.auth_username + ':' + config.auth_password).toString('base64');
  //const basicAuth = 'Basic ' + encodedString;
  const [isloading, setLoading] = useState(false);

  const [data, setData] = useState([]);
  const [flag, setFlag] = useState(0);
  const [perPage] = useState(10);
  const [page, setPage] = useState(0);
  const [totalItems, settotalItems] = useState([]);
  const [errorMessage, setErrorMessage] = useState(" ");
  const [status, setStatus] = useState(" ");
  const [value, setValue] = useState(" ");
  const [count, setCount] = useState(0);
  const [defaultcount, setDefaultcount] = useState(0);
  const [countrydefaultcount, setCountryDefaultcount] = useState(0);
  const [testnum, setTestnum] = useState(0);
  const [slicedata, setSlicedata] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [defaultdata, setDefaultdata] = useState([]);
  const [countrydefaultdata, setCountryDefaultdata] = useState([]);
  const [modalShow, setmodalShow] = useState(false);
  const modalHandleClose = () => setmodalShow(false);
  const modalHandleShow = () => setmodalShow(true);
  const [showPreview, setShowPreview] = useState(false);
  const previewModalClose = () => setShowPreview(false);
  const [modalPlaylistId, setModalPlaylistId] = useState();
  const [modalsource, setModalsource] = useState("");
  const showPlaylistPreview = (event, id, source) => {
    setModalPlaylistId(id);
    setShowPreview(true);
    setModalsource(source);
    return null;
  };
  const history = useHistory();
  localStorage.removeItem("schedulePlaylist");

  const AddScreensRedirect = (title, id, type) => {
    const schedulePlaylistData = {
      PlaylistId: id,
      PlaylistTitle: title,
      PlaylistType: type,
      screenIds: [],
      source: "playlist-page-add-screen",
    };
    localStorage.removeItem("schedulePlaylist");
    localStorage.setItem(
      "schedulePlaylist",
      JSON.stringify(schedulePlaylistData)
    );
    history.push("/add-screens");
  };

  const handleClick = (key) => {
    let testnum = key.pd.nid;
    setTestnum(testnum);
  };

  const alertbox = (pd) => {
    setLoading(true);
    // Check if the playlist is scheduled in any unit(s)
    // If yes, the user is not allowed to delete this playlist
    let url =
      config.drupal_url + "/api/playlist-in-use-check?id=" + pd.pd["nid"];
    axios
      .get(url, { withCredentials: true })
      .then((response) => {
        setLoading(false);
        if (response.data.data === "0") {
          confirmAlert({
            title: t("Are you sure you want to delete %title?").replace(
              "%title",
              pd.pd["title"]
            ),
            message: t("This action cannot be undone."),
            buttons: [
              {
                label: t("Delete"),
                onClick: () => {
                  setLoading(true);
                  const url = config.drupal_url + "/api/node/playlist/";
                  const nid = pd.pd["uuid"];
                  axios
                    .get(config.drupal_url + "/session/token")
                    .then((result) => {
                      if (result.status === 200) {
                        const csrfToken = result.data;
                        axios
                          .delete(url + nid, {
                            headers: {
                              "Content-Type": "application / vnd.api + json",
                              "Cache-Control": "no-cache",
                              Pragma: "no-cache",
                              Expires: "0",
                              "X-CSRF-Token": csrfToken,
                              withCredentials: true,
                            },
                          })
                          .then((response) => {
                            setErrorMessage(" ");
                            var str = t(
                              "@type %title was successfully deleted."
                            );
                            var mapObj = {
                              "@type": t("Playlist"),
                              "%title": pd.pd["title"],
                            };
                            str = str.replace(
                              /@type|%title/gi,
                              function (matched) {
                                return mapObj[matched];
                              }
                            );
                            setLoading(false);
                            toast.success(str);
                            let result = data.filter((pd) => pd.uuid !== nid);
                            setCount(count - 1);
                            const slice2 = result;
                            setSlicedata(slice2);
                            setData(result);
                            setLoading(true);
                            setTimeout(function () {
                              window.location.reload(true);
                            }, 5000);
                            //if (appinsights_flag == 1) {
                              appInsights.trackTrace({
                                message:
                                  "The playlist '" +
                                  pd.pd["title"] +
                                  "' with nid:" +
                                  pd.pd["nid"] +
                                  " has been successfully deleted by user with uid:" +
                                  parenId,
                                severityLevel: severity.Information,
                              });
                            //}
                          })
                          .catch((error) => {
                            setLoading(false);
                            toast.error(
                              t(
                                "Please try later.If the error persists, please contact Otis support."
                              )
                            );
                            //if (appinsights_flag == 1) {
                              appInsights.trackException({
                                error,
                                severityLevel: severity.Information,
                              });
                            //}
                          });
                      }
                    });
                },
              },
              {
                label: t("Cancel"),
                onClick: () => {
                  return true;
                },
              },
            ],
          });
        } else {
          toast.error(
            "Playlist is currently scheduled on unit(s) and cannot be deleted."
          );
        }
      })
      .catch((err) => {
        toast.error(
          t(
            "Please try later.If the error persists, please contact Otis support."
          )
        );
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setPage(0);
    setData("");
    setValue(value);
    if (page === 0) {
      getData();
    }
  };

  const handleSearch = (event) => {
    let value = event.target.value;

    setValue(value);
  };

  const refreshPage = async () => {
    window.location.reload(false);
  };

  const getDefaultData = async () => {
    let id = userdetails && JSON.parse(userdetails).id;
    let url = config.drupal_url + "/api/v1/default-playlist-data/" + id;
    const res1 = await axios
      .get(url, { withCredentials: true })
      .then((res1) => {
        const defaultdata = res1.data;
        setDefaultdata(defaultdata);
        const defaultcount = defaultdata.length;
        setDefaultcount(defaultcount);
      })
      .catch((err) => {
        toast.error(
          t(
            "Please try later.If the error persists, please contact Otis support."
          )
        );
        setData("");
      });
  };

  const getCountryDefaultData = async () => {
    let cid = userdetails && JSON.parse(userdetails).cid;
    let url =
      config.drupal_url + "/api/v1/country-default-playlist-data/" + cid;
    const res1 = await axios
      .get(url, { withCredentials: true })
      .then((res1) => {
        const countrydefaultdata = res1.data;
        setCountryDefaultdata(countrydefaultdata);
        const countrydefaultcount = countrydefaultdata.length;
        setCountryDefaultcount(countrydefaultcount);
      })
      .catch((err) => {
        toast.error(
          t(
            "Please try later.If the error persists, please contact Otis support."
          )
        );
        setData("");
      });
  };

  const getData = async () => {
    setLoading(true);
    let id = userdetails && JSON.parse(userdetails).id;
    let cid = userdetails && JSON.parse(userdetails).cid;

    let url;

    if (
      userdetails &&
      JSON.parse(userdetails).roles.includes("country_admin") === true
    ) {
      if (!value || value.trim().length === 0) {
        url =
          config.drupal_url +
          "/api/v1/country-playlists/" +
          cid +
          "?items_per_page=10&page=" +
          page;
      } else {
        url =
          config.drupal_url +
          "/api/v1/country-playlists/" +
          cid +
          "?title=" +
          value +
          "&items_per_page=10&page=" +
          page;
      }
    } else {
      if (!value || value.trim().length === 0) {
        url =
          config.drupal_url +
          "/api/v1/playlist-data/" +
          id +
          "?items_per_page=10&page=" +
          page;
      } else {
        url =
          config.drupal_url +
          "/api/v1/playlist-data/" +
          id +
          "?title=" +
          value +
          "&items_per_page=10&page=" +
          page;
      }
    }

    const res = await axios
      .get(url, { withCredentials: true })
      .then((res) => {
        let newdata;
        if (flag === 1) {
          newdata = [...data, ...res.data.rows];
        } else {
          newdata = res.data.rows;
        }

        const count = newdata.length;
        if (count === 0) {
          notify(t("No Playlists Found"));
        }
        const slice = newdata;
        setSlicedata(slice);

        setData(newdata);
        settotalItems(parseInt(res.data.pager.total_items));
        setCount(parseInt(res.data.pager.total_items));
        setLoading(false);
      })
      .catch((err) => {
        toast.error(
          t(
            "Please try later.If the error persists, please contact Otis support."
          )
        );
        setData("");
      });
  };

  const setasdefault = async (pd) => {
    setLoading(true);
    let roles = userdetails && JSON.parse(userdetails).roles;
    let cid = userdetails && JSON.parse(userdetails).cid;
    let id = userdetails && JSON.parse(userdetails).id;
    const post_data = {
      nid: pd.pd.nid,
      roles: roles,
      country_id: cid,
      uid: id,
    };
    axios.get(config.drupal_url + "/session/token").then((result) => {
      if (200 === result.status) {
        const csrfToken = result.data;
        const res1 = axios
          .post(
            config.drupal_url + "/rest/api/post/set-as-default",
            post_data,
            {
              headers: {
                "Content-Type": "application/json",
                withCredentials: true,
                "X-CSRF-Token": csrfToken,
              },
            }
          )
          .then((res1) => {
            if (res1.data === "disallow") {
              toast.error(
                t(
                  "Banner text is not allowed for the country default playlist. Please edit the playlist and try again."
                )
              );
              setLoading(false);
              return false;
            } else if (res1.data === "error") {
              toast.error(
                t(
                  "Please try later.If the error persists, please contact Otis support."
                )
              );
              setLoading(false);
              return false;
            } else if (res1.data === "inprogress") {
              toast.error(
                t(
                  "An operation is already in progress, please try again after sometime."
                )
              );
              setLoading(false);
              return false;
            }
            let url = config.drupal_url + "/api/node/playlist/";
            const body = {
              data: {
                type: "node--playlist",
                id: pd.pd.uuid,
                attributes: {
                  field_set_as_default: 1,
                },
              },
            };

            const res = axios
              .patch(url + pd.pd.uuid, body, {
                headers: {
                  "Content-Type": "application/vnd.api+json",
                  withCredentials: true,
                  "X-CSRF-Token": csrfToken,
                },
              })
              .then((res) => {
                toast.success(
                  t("Successfully set the country default playlist.")
                );
                setLoading(true);
                setTimeout(function () {
                  window.location.reload(true);
                }, 5000);
                return true;
              })
              .catch((err) => {
                toast.error(
                  t(
                    "Please try later.If the error persists, please contact Otis support."
                  )
                );
                setData("");
                setLoading(false);
                return false;
              });
          })
          .catch((err) => {
            toast.error(
              t(
                "Please try later.If the error persists, please contact Otis support."
              )
            );
            setData("");
            setLoading(false);
            return false;
          });
      }
    });
  };

  const extractString = (val) => {
    val = parseInt(val.replace(/[^0-9]/g, " "));
    if (isNaN(val)) {
      val = 0;
    }
    return val;
  };

  useEffect(() => {
    getDefaultData();

    getCountryDefaultData();

    getData();

    return () => {
      setErrorMessage(" ");
      setData(" ");
      setFlag(0);
      setStatus(" ");
    };
  }, [page]);
  let default_present = "";
  if (defaultdata || countrydefaultdata) {
    default_present = "default-present";
  } else {
    default_present = "";
  }

  const preveiwTip = t("Click to see preview");
  const indexOfLastPlaylist = currentPage * perPage * (page + 1);
  const indexOfFirstPlaylist = indexOfLastPlaylist - perPage * (page + 1);
  const playlistsslicedata = slicedata.slice(
    indexOfFirstPlaylist,
    indexOfLastPlaylist
  );
  return (
    <>
      <ToastContainer />

      <div className={"main-container " + default_present}>
        <div>
          <div className="col">
            <div className="row">
              <div className="col-sm-8 col-12">
                <h1>
                  eView<sup>&reg;</sup> - {t("Playlists")}
                </h1>
                <p>
                  {t("You have")} {count + defaultcount} {t("playlists")}.
                </p>
                <h3 className="Playlistsubtitle">
                  {t(
                    "Default playlist will appear on any device if no playlist has been scheduled."
                  )}
                </h3>
                {errorMessage && data.length !== 0 && (
                  <div className="error" style={{ color: "red" }}>
                    {" "}
                    {errorMessage}{" "}
                  </div>
                )}
                {status && (
                  <span style={{ color: "green" }}>
                    {" "}
                    <b> {status} </b>{" "}
                  </span>
                )}
              </div>
              <div className="col text-right play-btn-dc">
                <button
                  onClick={modalHandleShow}
                  type="button"
                  className="btn btn-success"
                >
                  {" "}
                  <i className="fa fa-plus-circle" aria-hidden="true"></i>
                  {t("Create New Playlist")}
                </button>
              </div>
            </div>
          </div>

          <LoadingOverlay
            className="inner-scroll-active"
            active={isloading}
            spinner
            text={t("Processing")}
          >
            <div className="col">
              <div className="row playlist-searchblock-xs">
                <div className="playlist-main-block">
                  {userdetails &&
                    JSON.parse(userdetails).roles.includes("country_admin")
                      .length}
                  {countrydefaultdata.map((pd, index) => {
                    var updatedOn = dateformat(pd.changed, "playlist");
                    var content = pd.contentArea.split("$$");
                    var firstcontentImage = content[0];
                    var firstcontentImageURL = firstcontentImage.replace(
                      /&amp;/g,
                      "&"
                    );
                    return (
                      <>
                        {userdetails &&
                          JSON.parse(userdetails).roles.includes(
                            "country_admin"
                          ) === true && (
                            <div
                              className="playlists-card playlists-default"
                              key={index}
                            >
                              <a
                                href="#!"
                                className="playlists-img"
                                title={preveiwTip}
                                onClick={(event) => [
                                  showPlaylistPreview(event, pd.nid, "country"),
                                ]}
                              >
                                {firstcontentImageURL ? (
                                  <img
                                    src={firstcontentImageURL}
                                    className="img-rounded"
                                    alt="playlist"
                                  />
                                ) : (
                                  <img
                                    src={defaultimg}
                                    className="img-rounded"
                                    alt="playlist"
                                  />
                                )}
                                <i className="fa fa-eye"></i>
                              </a>
                              {testnum !== pd.nid && (
                                <div className="playlists-details">
                                  <div className="row info-list">
                                    <div className="col-sm-8">
                                      <h4>{pd.title}</h4>
                                      <p>
                                        {t("Last Updated")}: {updatedOn}
                                      </p>
                                      {extractString(pd.scheduled) !== 0 && (
                                        <p>
                                          {t("Scheduled on")}{" "}
                                          {extractString(pd.scheduled)}{" "}
                                          {t("Units")}
                                        </p>
                                      )}
                                      <p>
                                        {t("Currently playing on")}{" "}
                                        {extractString(pd.current)} {t("Units")}
                                      </p>
                                    </div>
                                    <div className="col-sm-4 info-list-settings">
                                      <a
                                        key={index}
                                        id={pd.nid}
                                        onClick={(key) => handleClick({ pd })}
                                        href="#!"
                                      >
                                        <i className="fa fa-cog"></i>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {testnum === pd.nid && (
                                <div className="playlists-details">
                                  <div className="row edit-list">
                                    <div className="col-sm-4 info-list-bx">
                                      <a
                                        href="#!"
                                        class="disabled"
                                        aria-disabled="true"
                                      >
                                        <i className="fa fa-check-circle playlist-radio-chk"></i>
                                        <p>{t("Set as Default")}</p>
                                      </a>
                                    </div>
                                    <div className="col-sm-4 info-list-bx">
                                      <a
                                        href="#!"
                                        onClick={(event) =>
                                          AddScreensRedirect(
                                            pd.title,
                                            pd.nid,
                                            pd.layout
                                          )
                                        }
                                      >
                                        <i className="fa fa-laptop radio-default"></i>
                                        <p>{t("Add Screens")}</p>
                                      </a>
                                    </div>
                                    <div className="col-sm-4 info-list-bx">
                                      <a
                                        href={
                                          "/edit-playlist?id=" +
                                          pd.nid +
                                          "&default=allow"
                                        }
                                      >
                                        <i className="fa fa-edit"></i>
                                        <p>{t("Edit")}</p>
                                      </a>
                                    </div>
                                    <div className="col-sm-4 info-list-bx">
                                      <a
                                        href="#!"
                                        class="disabled"
                                        aria-disabled="true"
                                      >
                                        <i className="fa fa-trash"></i>
                                        <p>{t("Delete")}</p>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        {userdetails &&
                          JSON.parse(userdetails).roles.includes(
                            "country_admin"
                          ) !== true &&
                          document.querySelector(".default_playlist_bm") ===
                            null && (
                            <div
                              className="playlists-card playlists-default"
                              key={index}
                            >
                              <a
                                href="#!"
                                className="playlists-img"
                                title={preveiwTip}
                                onClick={(event) => [
                                  showPlaylistPreview(event, pd.nid, "country"),
                                ]}
                              >
                                {firstcontentImageURL ? (
                                  <img
                                    src={firstcontentImageURL}
                                    className="img-rounded"
                                    alt="playlist"
                                  />
                                ) : (
                                  <img
                                    src={defaultimg}
                                    className="img-rounded"
                                    alt="playlist"
                                  />
                                )}
                                <i className="fa fa-eye"></i>
                              </a>
                              {testnum !== pd.nid && (
                                <div className="playlists-details">
                                  <div className="row info-list">
                                    <div className="col-sm-8">
                                      <h4>{pd.title}</h4>
                                      <p>
                                        {t("Last Updated")}: {updatedOn}
                                      </p>
                                      {extractString(pd.scheduled) !== 0 && (
                                        <p>
                                          {t("Scheduled on")}{" "}
                                          {extractString(pd.scheduled)}{" "}
                                          {t("Units")}
                                        </p>
                                      )}
                                      <p>
                                        {t("Currently playing on")}{" "}
                                        {extractString(pd.current)} {t("Units")}
                                      </p>
                                    </div>
                                    <div className="col-sm-4 info-list-settings">
                                      <a
                                        key={index}
                                        id={pd.nid}
                                        onClick={(key) => handleClick({ pd })}
                                        href="#!"
                                      >
                                        <i className="fa fa-cog"></i>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {testnum === pd.nid && (
                                <div className="playlists-details">
                                  <div className="row edit-list">
                                    <div className="col-sm-4 info-list-bx">
                                      <a
                                        href="#!"
                                        class="disabled"
                                        aria-disabled="true"
                                      >
                                        <i className="fa fa-check-circle playlist-radio-chk"></i>
                                        <p>{t("Set as Default")}</p>
                                      </a>
                                    </div>
                                    <div className="col-sm-4 info-list-bx">
                                      <a
                                        href="#!"
                                        class="disabled"
                                        aria-disabled="true"
                                      >
                                        <i className="fa fa-laptop radio-default"></i>
                                        <p>{t("Add Screens")}</p>
                                      </a>
                                    </div>
                                    <div className="col-sm-4 info-list-bx">
                                      <a
                                        href="#!"
                                        class="disabled"
                                        aria-disabled="true"
                                      >
                                        <i className="fa fa-edit"></i>
                                        <p>{t("Edit")}</p>
                                      </a>
                                    </div>
                                    <div className="col-sm-4 info-list-bx">
                                      <a
                                        href="#!"
                                        class="disabled"
                                        aria-disabled="true"
                                      >
                                        <i className="fa fa-trash"></i>
                                        <p>{t("Delete")}</p>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                      </>
                    );
                  })}

                  {defaultdata.map((pd, index) => {
                    var updatedOn = dateformat(pd.changed, "playlist");
                    var content = pd.contentArea.split("$$");
                    var firstcontentImage = content[0];
                    var firstcontentImageURL = firstcontentImage.replace(
                      /&amp;/g,
                      "&"
                    );
                    return (
                      userdetails &&
                      !JSON.parse(userdetails).roles.includes(
                        "administrator"
                      ) &&
                      !JSON.parse(userdetails).roles.includes(
                        "country_admin"
                      ) &&
                      !JSON.parse(userdetails).roles.includes(
                        "sales_representative"
                      ) && (
                        <div
                          className="playlists-card default_playlist_bm"
                          key={index}
                        >
                          <a
                            href="#!"
                            className="playlists-img"
                            title={preveiwTip}
                            onClick={(event) => [
                              showPlaylistPreview(event, pd.nid, "building"),
                            ]}
                          >
                            <img
                              src={firstcontentImageURL}
                              alt="playlist"
                              className="img-rounded"
                            />
                            <i className="fa fa-eye"></i>
                          </a>
                          {testnum !== pd.nid && (
                            <div className="playlists-details">
                              <div className="row info-list">
                                <div className="col-sm-8">
                                  <h4>{pd.title}</h4>
                                  <p>
                                    {t("Last Updated")}: {updatedOn}
                                  </p>
                                  {extractString(pd.scheduled) !== 0 && (
                                    <p>
                                      {t("Scheduled on")}{" "}
                                      {extractString(pd.scheduled)} {t("Units")}
                                    </p>
                                  )}
                                  <p>
                                    {t("Currently playing on")}{" "}
                                    {extractString(pd.current)} {t("Units")}
                                  </p>
                                </div>
                                <div className="col-sm-4 info-list-settings">
                                  <a
                                    key={index}
                                    id={pd.nid}
                                    onClick={(key) => handleClick({ pd })}
                                    href="#!"
                                  >
                                    <i className="fa fa-cog"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          )}
                          {testnum === pd.nid && (
                            <div className="playlists-details">
                              <div className="row edit-list">
                                <div className="col-sm-4 info-list-bx">
                                  <a
                                    href="#!"
                                    class="disabled"
                                    aria-disabled="true"
                                  >
                                    <i className="fa fa-check-circle playlist-radio-chk"></i>
                                    <p>{t("Set as Default")}</p>
                                  </a>
                                </div>
                                <div className="col-sm-4 info-list-bx">
                                  <a
                                    href="#!"
                                    onClick={(event) =>
                                      AddScreensRedirect(
                                        pd.title,
                                        pd.nid,
                                        pd.layout
                                      )
                                    }
                                  >
                                    <i className="fa fa-laptop"></i>
                                    <p>{t("Add Screens")}</p>
                                  </a>
                                </div>
                                <div className="col-sm-4 info-list-bx">
                                  <a
                                    href={
                                      "/edit-playlist?id=" +
                                      pd.nid +
                                      "&banner_allow=allow"
                                    }
                                  >
                                    <i className="fa fa-edit"></i>
                                    <p>{t("Edit")}</p>
                                  </a>
                                </div>
                                <div className="col-sm-4 info-list-bx">
                                  <a
                                    href="#!"
                                    onClick={(key) => alertbox({ pd })}
                                  >
                                    <i className="fa fa-trash"></i>
                                    <p>{t("Delete")}</p>
                                  </a>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )
                    );
                  })}
                </div>

                <div className="col play-search-nm">
                  <div className="d-flex_ p-2 text-right flex justify-content-center v-align">
                    <div className="btn-group ">
                      <form
                        onSubmit={handleSubmit}
                        autoComplete="off"
                        className="playlist-search-top"
                      >
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            name="search"
                            onChange={(event) => handleSearch(event)}
                          />
                          <button
                            type="submit"
                            id="edit-submit-playlist"
                            name=""
                            value="Search"
                            className="btn btn-primary form-submit"
                          >
                            <T _str="Search" />
                          </button>
                          <button
                            type="submit"
                            id="edit-reset"
                            name="op"
                            value="Reset"
                            onClick={refreshPage}
                            className="btn btn-default form-submit"
                          >
                            <T _str="Reset" />
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="block-container">
              <div className="row">
                {playlistsslicedata.map((pd, index) => {
                  var updatedOn = dateformat(pd.changed, "playlist");
                  var content = pd.contentArea.split("$$");
                  var firstcontentImage = content[0];
                  var firstcontentImageURL = firstcontentImage.replace(
                    /&amp;/g,
                    "&"
                  );
                  return (
                    <div className="playlist-main-block" key={index}>
                      <div className="playlists-card">
                        <a
                          href="#!"
                          className="playlists-img"
                          title={preveiwTip}
                          onClick={(event) => [
                            showPlaylistPreview(event, pd.nid, "playlist"),
                          ]}
                        >
                          {firstcontentImageURL ? (
                            <img
                              src={firstcontentImageURL}
                              className="img-rounded"
                              alt="playlist"
                            />
                          ) : (
                            <img
                              src={defaultimg}
                              className="img-rounded"
                              alt="playlist"
                            />
                          )}
                          <i className="fa fa-eye"></i>
                        </a>
                        {testnum !== pd.nid && (
                          <div className="playlists-details">
                            <div className="row info-list">
                              <div className="playlist-content-hold">
                                <h4>{pd.title}</h4>
                                <p>
                                  {t("Last Updated")}: {updatedOn}
                                </p>
                                {extractString(pd.scheduled) !== 0 && (
                                  <p>
                                    {t("Scheduled on")}{" "}
                                    {extractString(pd.scheduled)} {t("Units")}
                                  </p>
                                )}
                                <p>
                                  {t("Currently playing on")}{" "}
                                  {extractString(pd.current)} {t("Units")}
                                </p>
                              </div>
                              <div className="playlist-icon-hold">
                                <div className="info-list-settings">
                                  <a
                                    key={index}
                                    id={pd.nid}
                                    onClick={(key) => handleClick({ pd })}
                                    href="#!"
                                  >
                                    <i className="fa fa-cog"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {testnum === pd.nid && (
                          <div className="playlists-details">
                            <div className="row edit-list">
                              <div className="col-sm-4 info-list-bx">
                                <a
                                  href="#!"
                                  class={
                                    disablesetasdeafult == 1 ? "disabled" : ""
                                  }
                                  onClick={(key) =>
                                    disablesetasdeafult == 0
                                      ? setasdefault({ pd })
                                      : (key) => false
                                  }
                                  aria-disabled={
                                    disablesetasdeafult == 1 ? "true" : ""
                                  }
                                >
                                  <i className="fa fa-check-circle"></i>
                                  <p>{t("Set as Default")}</p>
                                </a>
                              </div>
                              <div className="col-sm-4 info-list-bx">
                                <a
                                  href="#!"
                                  onClick={(event) =>
                                    AddScreensRedirect(
                                      pd.title,
                                      pd.nid,
                                      pd.layout
                                    )
                                  }
                                >
                                  <i className="fa fa-laptop"></i>
                                  <p>{t("Add Screens")}</p>
                                </a>
                              </div>
                              <div className="col-sm-4 info-list-bx">
                                <a href={"/edit-playlist?id=" + pd.nid}>
                                  <i className="fa fa-edit"></i>
                                  <p>{t("Edit")}</p>
                                </a>
                              </div>
                              <div className="col-sm-4 info-list-bx">
                                <a
                                  href="#!"
                                  onClick={(key) => alertbox({ pd })}
                                >
                                  <i className="fa fa-trash"></i>
                                  <p>{t("Delete")}</p>
                                </a>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </LoadingOverlay>
          <div className="">
            <div className="row">
              <div
                className={`justify-content-end ${
                  perPage * (page + 1) > totalItems
                    ? "load-more-end"
                    : "load-more-playlists"
                }`}
              >
                {perPage * (page + 1) < totalItems && (
                  <button
                    className="btn-load-more"
                    onClick={() => {
                      setPage(page + 1);
                      setFlag(1);
                    }}
                  >
                    Load more
                  </button>
                )}

                {totalItems > 0 && (
                  <span className="list-count">
                    {t("Displaying @start - @end of @total")
                      .replace("@start", 1)
                      .replace(
                        "@end",
                        perPage * (page + 1) < totalItems
                          ? perPage * (page + 1)
                          : totalItems
                      )
                      .replace("@total", totalItems)}
                  </span>
                )}
              </div>
            </div>
          </div>
          <Modal
            show={modalShow}
            onHide={modalHandleClose}
            className="pl-preview"
          >
            <AddPlaylistsPop />
          </Modal>

          <Modal
            show={showPreview}
            onHide={previewModalClose}
            className="custom_pl"
          >
            <Modal.Body>
              <div
                className="playlistPreview modalClose"
                onClick={previewModalClose}
              >
                <FaTimesCircle />
              </div>
              {modalsource === "playlist" && (
                <PlaylistsPreview
                  playlistsData={data}
                  othersPlaylistsData={[]}
                  fullPreview="NA"
                  id={modalPlaylistId}
                  screenId="NA"
                  infomsg="NA"
                  source="playlist"
                  countryname={country_name}
                />
              )}
              {modalsource === "country" && (
                <PlaylistsPreview
                  playlistsData={countrydefaultdata}
                  othersPlaylistsData={[]}
                  fullPreview="NA"
                  id={modalPlaylistId}
                  screenId="NA"
                  infomsg="NA"
                  source="playlist"
                  countryname={country_name}
                />
              )}
              {modalsource === "building" && (
                <PlaylistsPreview
                  playlistsData={defaultdata}
                  othersPlaylistsData={[]}
                  fullPreview="NA"
                  id={modalPlaylistId}
                  screenId="NA"
                  infomsg="NA"
                  source="playlist"
                  countryname={country_name}
                />
              )}
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default Playlist;
