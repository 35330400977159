import React from 'react';
import { T } from '@transifex/react';

const Pagination = ({ itmesPerPage, totalItems, paginate, currentPage }) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalItems / itmesPerPage); i++) {
    pageNumbers.push(i);
  }

  return (

    <nav className="pagination">
      {pageNumbers.length > 1 && (
        <ul>
          <li key='99' className={currentPage === 1 ? 'disabled' : ''}>
            <a href="#!" onClick={() => paginate(1)} className='page-link'>« <T _str="first" /></a>
          </li>
          <li key='100' className={currentPage === 1 ? 'disabled' : ''}>
            <a href="#!" onClick={() => paginate(currentPage - 1)}>‹ <T _str="previous" /></a>
          </li>
          {pageNumbers.map(number => (
            <li key={number} className='page-item'>
              <a href="#!" onClick={() => paginate(number)} className={currentPage === number ? 'page-link active' : 'page-link'}>
                {number}
              </a>
            </li>
          ))}
          <li key='101' className={currentPage === pageNumbers.length ? 'disabled' : ''}>
            <a href="#!" onClick={() => paginate(currentPage + 1)} className='page-link'><T _str="next" /> ›</a>
          </li>
          <li key='102' className={currentPage === pageNumbers.length ? 'disabled' : ''}>
            <a href="#!" onClick={() => paginate(pageNumbers.length)} className='page-link'><T _str="last" /> »</a>
          </li>
        </ul>
      )}
    </nav>
  );
};

export default Pagination;