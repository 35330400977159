import { Treebeard } from 'react-treebeard';
import React, { useEffect, useState, useContext } from 'react';
import { LibContext } from '../Library/LibraryContext';
import config from '../../utils/config';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Folders = (props) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [cursor, setCursor] = useState(false);
  const [userlib, setUserlib] = useState([]);
  const [userlibLoaded, setUserlibLoaded] = useState(false);
  const { dispatchLibEvent } = useContext(LibContext);
  const userid = JSON.parse(localStorage.getItem("userdetails")).id;
  const fetchUrl = config.drupal_url + '/user_library?_format=json&field_user_target_id=' + userid;

  const fetchUserLib = async () => {
    try {
      setLoading(true)
      let response = await fetch(fetchUrl + '&vid=' + props.vocabulary);
      let json = await response.json();
      setLoading(false)
      return { success: true, data: json };
    } catch (error) {
      toast.error("Error fetching folder" + error);
      return { success: false };
    }
  }
  useEffect(() => {
    (async () => {
      setUserlibLoaded(false);
      let res = await fetchUserLib();
      if (res.success) {
        let list = res.data;
        list['children'] = list['nothing'];
        var map = {}, node, roots = [], i;
        for (i = 0; i < list.length; i += 1) {
          map[list[i].tid] = i; // initialize the map
          list[i].children = []; // initialize the children
        }
        for (i = 0; i < list.length; i += 1) {
          node = list[i];
          if (node.parent_target_id !== '') {
            // if you have dangling branches check that map[node.parentId] exists
            if (typeof list[map[node.parent_target_id]] !== 'undefined') {
              list[map[node.parent_target_id]].children.push(node);
            }
          } else {
            roots.push(node);
          }
        }
        const finalData = {
          "name": "",
          "children": roots,
          "active": true,
          "toggled": true
        }
        setUserlib(finalData);
        setUserlibLoaded(true);
      }
    })();
  }, []);

  const onToggle = (node, toggled) => {
    dispatchLibEvent({ 'tid': node.tid, 'name': node.name, 'vid': props.vocabulary });
    if (cursor) {
      cursor.active = false;
    }
    node.active = true;
    if (node.children) {
      node.toggled = toggled;
    }
    setCursor(node);
    setData(Object.assign({}, data))
  }

  return (
    <>
      <ToastContainer />
      <Treebeard data={userlib} onToggle={onToggle} />
    </>
  )
}

export default Folders;