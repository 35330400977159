import Menu from './Menu';
import Footer from './Footer';
import FloatHeader from './FloatHeader';

function PageLayout(props) {
  return (
    <div className="page-layout">
      {window.location.pathname !== '/register' && window.location.pathname !== '/reset' && <Menu />}
      <div className="container">
        {window.location.pathname !== '/register' && window.location.pathname !== '/reset' && <FloatHeader />}
        <main>
          {props.children}
        </main>
      </div>
      {window.location.pathname !== '/register' && window.location.pathname !== '/reset' && <Footer />}
    </div>
  );
}

export default PageLayout;