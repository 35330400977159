import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { t } from "@transifex/native";

export const IdleTimeOutModal = ({showModal, handleClose, handleLogout, remainingTime}) => {

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>{t('Your session is about to expire. Do you want to reset it?')}</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
            <Button variant="danger" onClick={handleLogout}>
                {t('Logout')}
            </Button>
            <Button variant="primary" onClick={handleClose}>
            {t('Reset')}
            </Button>
            </Modal.Footer>
        </Modal>
    )
}