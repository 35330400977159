/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: Library.js
File Description: Implements library functionality

Copyright 2021 - 2023 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
import UserLibrary from '../Components/Library/UserLibrary';
import { LibContext, MediaContext } from '../Components/Library/LibraryContext';
import Fileupload from '../Components/Library/Fileupload';
import Content from '../Components/Library/Content';
import { ModalwithInput, ModalNodeDelete } from '../Components/Library/ModalPopup';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useState, useEffect } from 'react';
import "../css/library.css";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import config from '../utils/config';
import { T, useT } from '@transifex/react';
import LoadingOverlay from "react-loading-overlay";
import { t } from "@transifex/native";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from "react-router-dom";
import $ from "jquery";
import { Buffer } from 'buffer';
import { appInsights, severity } from "../AppInsights";

function Library(props) {

  const tabTitle = useT("Library");
  const [toggleState, setToggleState] = useState(1);
  const [togglemState, setTogglemState] = useState(1);
  const [togglePopState, setTogglePopState] = useState(1);
  const [vocabulary, setVocabulary] = useState('');
  const [defaulttid, setDefaultTid] = useState('');
  const [libtabs, setLibTabs] = useState([]);
  const uploadTitle = useT("Upload")
  const renameTitle = useT("Rename")
  const deleteTitle = useT("Delete")
  const copyTitle = useT("Copy")
  const moveTitle = useT("Move")
  const newFolderTitle = useT("New Folder")
  const renameFolderTitle = useT("Rename Folder")
  const deleteFolderTitle = useT("Delete Folder")
  let isCountryAdmin = false;
  const location = useLocation();

  const roles = JSON.parse(localStorage.getItem("userdetails")).roles
  if (roles && roles.includes("country_admin") > 0) {
    isCountryAdmin = true
  }

  //get library tab ids
  const fetchLibraryTabs = async () => {
    try {
      setLoading(true)
      let response = await fetch(config.drupal_url + '/api/library-category-terms?_format=json');
      let json = await response.json();
      setLoading(false)
      return { success: true, data: json };
    } catch (error) {
      return { success: false };
    }
  }

  // Get logged in user details
  let userDetails = localStorage.getItem("userdetails");
  let cid = '';
  let loggedInUserID = '';
  if (userDetails !== null) {
    loggedInUserID = JSON.parse(userDetails).id;
    cid = JSON.parse(userDetails).cid
  }
  let appinsights_flag = localStorage.getItem("appinsights");  
  var parenId ="";
  if(localStorage.getItem("parentuser")){
       var parentuser = localStorage.getItem("parentuser");
       parenId = parentuser && JSON.parse(parentuser).id;
   }else{
       parenId = loggedInUserID;
   }
  const notify = (val) => {
    toast.error(val, {
      toastId: loggedInUserID
    });
  }

  const notifySuccess = (val) => {
    toast.success(val, {
      toastId: loggedInUserID
    });
  }

  const [validations, setValidations] = useState({ "VideoMaxAllowedSize": "", "ImageMaxAllowedSize":"", "MaxAllowedFPS": "", "MaxAllowedBitrate":"" });
  useEffect(() => {
    // Fetch Validations
    const fetchValidations = async () => {
      setLoading(true);
      await axios.get(config.drupal_url + '/rest/api/get/validations?_format=hal_json', { withCredentials: true })
        .then(response => {
          setValidations({ "VideoMaxAllowedSize": response.data.VideoMaxAllowedSize, "ImageMaxAllowedSize": response.data.ImageMaxAllowedSize, "MaxAllowedFPS": response.data.MaxAllowedFPS, "MaxAllowedBitrate": response.data.MaxAllowedBitrate });
          setLoading(false);
        })
        .catch(function (error) {
          notify(t("Please try later.If the error persists, please contact Otis support."));
          setLoading(false);
        })
    };
    fetchValidations();
  }, [])

  useEffect(() => {
    document.title = tabTitle + ' | CMS';
    (async () => {
      let res = await fetchLibraryTabs();
      if (res.success) {
        let list = res.data;
        let defaultid = list.filter(function (arr) {
          return arr.name === 'User Library'
        });
        setDefaultTid(defaultid[0].tid)
        setLibTabs(list)
      }
    })();
    if (location.media_title !== undefined && location.media_title !== "") {
      notifySuccess(t("@type %title has been created.").replace('@type', t('Upload content to library')).replace('%title', location.media_title));
    }
  }, []);

  const setDefaultId = (tname) => {
    let defaultid = libtabs.filter(function (arr) {
      return arr.name === tname
    });
    if (defaultid.length > 0) {
      setDefaultTid(defaultid[0].tid)
    }
  }

  const togglemTab = (index) => {
    $('input:checked').each(function () {
      $(this).prop('checked', false);
    });
    medianodes = [];
    setMediaNode(medianodes);
    if (index === 1) {
      setVocabulary('category');
      setDefaultId('User Library');
      setTerm({ 'tid': '', 'name': '', 'vid': 'category' })
    }
    if (index === 2) {
      setVocabulary('default_library');
      setDefaultId('Default Content');
      setTerm({ 'tid': '', 'name': '', 'vid': 'default_library' })
    }
    if (index === 3) {
      setVocabulary('messages');
      setDefaultId('User Messages');
      setTerm({ 'tid': '', 'name': '', 'vid': 'messages' })
    }
    setTogglemState(index)
  }

  const togglePopTab = (index) => {
    if (index === 1) {
      setVocabulary('category');
      setDefaultId('User Library');
      setTerm({ 'tid': '', 'name': '', 'vid': 'category' })
    }
    if (index === 2) {
      setVocabulary('default_library');
      setDefaultId('Default Content');
      setTerm({ 'tid': '', 'name': '', 'vid': 'default_library' })
    }
    if (index === 3) {
      setVocabulary('messages');
      setDefaultId('User Messages');
      setTerm({ 'tid': '', 'name': '', 'vid': 'messages' })
    }
    setTogglePopState(index)
  }

  const toggleTab = (index) => {
    setToggleState(index)
    setUploadMediaContent("");
  }

  //const encodedString = Buffer.from(config.auth_username + ':' + config.auth_password).toString('base64');
  const [loading, setLoading] = useState(false);
  const [term, setTerm] = useState({ 'tid': '', 'name': '', 'vid': 'category' });
  const [uploadMediaContent, setUploadMediaContent] = useState("");
  const [search, setSearch] = useState("");
  const [mediatype, setMediaType] = useState("");
  const [sortmedia, setSortMedia] = useState("");
  const [name, setName] = useState("");
  const [medianode, setMediaNode] = useState([]);
  const [mediatitle, setMediaTitle] = useState("");


  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [rename, setRename] = useState(false);
  const handleRenameClose = () => setRename(false);
  const handleRenameShow = () => setRename(true);

  const [add, setAdd] = useState(false);
  const handleAddClose = () => setAdd(false);
  const handleAddShow = () => setAdd(true);

  const [renamepop, setRenamePop] = useState(false);
  const handleRenamePopClose = () => setRenamePop(false);
  const showRenamePop = () => setRenamePop(true);

  const [nodedeletepop, setDeleteNodePop] = useState(false);
  const handleDeletePopClose = () => setDeleteNodePop(false);
  const showDeletePop = () => setDeleteNodePop(true);

  const [showupload, setShowupload] = useState(false);
  const showUploadPop = () => setShowupload(true);
  const handleShowUploadPopClose = () => setShowupload(false);

  const [renamenodeval, setRenameNodeValue] = useState("");

  const [showcopy, setShowCopy] = useState(false);
  const handleCopyShow = () => setShowCopy(true);
  const handleCopyClose = () => setShowCopy(false);

  const [showmove, setMoveCopy] = useState(false);
  const handleMoveShow = () => setMoveCopy(true);
  const handleMoveClose = () => setMoveCopy(false);

  let medianodes = [...medianode];
  const nameChange = (e) => {
    setName(e.target.value);
  }

  const addChange = (e) => {
    setAdd(e.target.value);
  }

  const searchMedia = (e) => {
    setTimeout(
      function () {
        setSearch(e.target.value);
      },
      1000
    );
  }

  const filterMediaType = (e) => {
    setMediaType(e.target.value);
  }

  const sortMedia = (e) => {
    setSortMedia(e.target.value);
  }

  const [validTitle, setValidTitle] = useState(false);
  const uploadTitleChange = (e) => {
    const title = e.target.value;
    setMediaTitle(title);
    const userid = JSON.parse(localStorage.getItem("userdetails")).id;
    const tmid = (term.tid === '') ? defaulttid : term.tid
    let vTitleUrl = '/api/media/title?filter[uid]=' + userid + '&filter[tid]=' + tmid + '&filter[title]=' + title;
    axios.get(config.drupal_url + vTitleUrl, { withCredentials: true })
      .then((response) => {
        if (response.data.data.length !== 0) {
          setValidTitle(false);
        } else {
          setValidTitle(true);
        }
      })
  }

  const dispatchLibEvent = (selectedTerm) => {
    setTerm(selectedTerm);
  }

  const dispatchMediaEvent = (selectedMediaContent) => {
    setUploadMediaContent(selectedMediaContent);
  }

  const dispatchContEvent = (selectedMediaNode) => {
    setRenameNodeValue(selectedMediaNode.target.name)
    if (selectedMediaNode.target.checked) {
      medianodes.push(selectedMediaNode.target.value);
      setMediaNode(medianodes);
    } else {
      setMediaNode(medianodes.filter(function (node) {
        return node !== selectedMediaNode.target.value
      }))
    }
  }

  const handleViewType = (e) => {
    return localStorage.setItem("gridtype", e.target.id)
  }

  const handleFolderDeleteVal = () => {
    if (term.tid === '') {
      notify(t('Select the folder you want delete'));
    }
    else {
      handleShow()
    }
  }

  const handleFolderRenameVal = () => {
    if (term.tid === '') {
      notify(t('Select a folder you want to rename'));
    }
    else {
      handleRenameShow()
    }
  }

  const handleDeleteVal = () => {
    if (medianode.length < 1) {
      notify(t('Select at least one file'));
    } else {
      showDeletePop()
    }
  }

  const handleMoveVal = () => {
    if (medianode.length < 1) {
      notify(t('Select at least one file'))
    }
    else {
      handleMoveShow()
    }
  }

  const handleMoveSaveClick = async () => {
    setLoading(true)
    const tmid = (term.tid === '') ? defaulttid : term.tid
    axios.get(config.drupal_url + '/cms_library/library_move?_format=json&id=' + medianode + '&tid=' + tmid, { withCredentials: true })
      .then(response => {
        if (response.data.message === 'move_success') {
          setLoading(false);
          handleMoveClose();
          window.location.reload(false);
        } else if (response.data.message === 'same_tid') {
          notify(t("Source and destination folders are same please use another destination folder."));
          setLoading(false);
        } else {
          setLoading(false);
          handleMoveClose();
          notify(t("Please try later.If the error persists, please contact Otis support."));
        }
      })
      .catch(function (error) {
        setLoading(false);
        handleMoveClose();
        notify(t("Please try later.If the error persists, please contact Otis support."));
      })
  }

  const handleCopyVal = () => {
    if (medianode.length < 1) {
      notify(t('Select at least one file'))
    }
    else {
      handleCopyShow()
    }
  }

  const handleCopySaveClick = async () => {
    setLoading(true);
    const userid = JSON.parse(localStorage.getItem("userdetails")).id;
    const tmid = (term.tid === '') ? defaulttid : term.tid
    axios.get(config.drupal_url + '/cms_library/library_resource?_format=json&id=' + medianode + '&tid=' + tmid + '&uid=' + userid, { withCredentials: true })
      .then(response => {
        if (response.data.message === 'copy_success') {
          setLoading(false);
          handleCopyClose();
          window.location.reload(false);
        } else {
          setLoading(false);
          handleCopyClose();
          notify(t("Please try later.If the error persists, please contact Otis support."));
        }
      })
      .catch(function (error) {
        handleCopyClose();
        notify(t("Please try later.If the error persists, please contact Otis support."));
        setLoading(false);
      })
  }

  const handleMediaRename = (e) => {
    if (medianode.length < 1) {
      notify(t('Select a file you want to rename'));
    } else if (medianode.length > 1) {
      notify(t('Select only one file'));
    }
    else {
      showRenamePop()
    }
  }

  const HandleRenameChange = (e) => {
    setRenameNodeValue(e.target.value)
  }

  const HandleUploadClick = (e) => {
    showUploadPop()
  }

  const handleFileUpload = () => {
    const tmid = (term.tid === '') ? defaulttid : term.tid
    const userid = JSON.parse(localStorage.getItem("userdetails")).id;
    if (mediatitle === '') {
      notify(t('!name field is required.').replace('!name', t('Title')));
      return null;
    }
    if (uploadMediaContent.fid === undefined || uploadMediaContent.fid === '') {
      notify(t('!name field is required.').replace('!name', t('Upload File')));
      return null;
    }
    if (!validTitle) {
      notify(t("Title already exists in this folder."));
      return null;
    }
    var tabSwitcher = "image";
    var data = {
      "_links": {
        "type": {
          "href": config.rest_node_url + '/rest/type/node/media'
        }
      },
      "title": {
        "value": mediatitle
      },
      "field_tab_switcher": {
        value: tabSwitcher
      },
      "field_mediaimage": [{
        "target_id": `${uploadMediaContent.fid}`,
      }],
      "uid": [{ "target_id": userid }],
      "field_categories": [{ "target_id": tmid }]
    };
    if (toggleState === 2) {
      tabSwitcher = 'video';
      if (uploadMediaContent.vfid === undefined || uploadMediaContent.vfid === "") {
        notify(t('!name field is required.').replace('!name', t('Upload video')));
        return false;
      }
      if (uploadMediaContent.fid === undefined || uploadMediaContent.fid === "") {
        notify(t('!name field is required.').replace('!name', t('Upload File')));
        return null;
      }
      data = {
        "_links": {
          "type": {
            "href": config.rest_node_url + '/rest/type/node/media'
          }
        },
        "title": {
          "value": mediatitle
        },
        "field_tab_switcher": {
          value: tabSwitcher
        },
        "field_mediaimage": [{
          "target_id": `${uploadMediaContent.fid}`,
        }],
        "field_upload_video": [{
          "target_id": `${uploadMediaContent.vfid}`,
        }],
        "uid": [{ "target_id": userid }],
        "field_categories": [{ "target_id": tmid }]
      };
    }
    setLoading(true)
    const saveMediaUrl = config.drupal_url + '/node?_format=hal_json';
    axios.get(config.drupal_url + '/session/token').then(result => {
      if (200 === result.status) {
        const csrfToken = result.data;
        const headers = {
          "Content-Type": "application/hal+json",
          withCredentials: true,
          'X-CSRF-Token': csrfToken,
        }
        axios.post(saveMediaUrl, JSON.stringify(data), { headers })
          .then((res) => {
            notifySuccess(t("@type %title has been created.").replace('@type', t('Upload content to library')).replace('%title', mediatitle));
            setTimeout(function () {
              window.location.reload(false);
            }, 5000);
          })
      }
    });
    handleShowUploadPopClose()
  }

  const handleDeleteNodeSubmit = async () => {
    setLoading(true)
    const tmid = (term.tid === '') ? defaulttid : term.tid
    axios.get(config.drupal_url + '/cms_library/library_delete?_format=json&id=' + medianode, { withCredentials: true })
      .then(response => {
        if (response.data.message === 'delete_success') {
          setLoading(false);
          handleDeletePopClose();
          window.location.reload(false);
        } else if (response.data.message === 'no_delete') {
          setLoading(false);
          handleDeletePopClose();
          notify(t("Image(s) currently used in playlist(s) cannot be deleted."));
        } else {
          setLoading(false);
          handleDeletePopClose();
          notify(t("Please try later.If the error persists, please contact Otis support."));
        }
      })
      .catch(function (error) {
		//if (appinsights_flag == 1) {
        appInsights.trackException({ error , severityLevel: severity.Information});	
        //}		
        setLoading(false);
        handleDeletePopClose();
        notify(t("Please try later.If the error persists, please contact Otis support."));
      })
  }

  const handleMediaRenameSubmit = (e) => {
    if (renamenodeval == '') {
      notify(t("please enter a name to rename"));
      return false;
    }
    setLoading(true)
    const postData = JSON.stringify({
      'type': "media",
      'title': [renamenodeval]
    })
    axios.get(config.drupal_url + '/session/token').then(result => {
      if (200 === result.status) {
        const csrfToken = result.data;
        const headers = {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken,
          withCredentials: true,
        };
        axios.patch(config.drupal_url + '/node/' + medianode + '?_format=hal_json', postData, { headers })
          .then((response) => {
            if (response.status === 200) {
              handleRenamePopClose()
              setLoading(false)
              window.location.reload(false);
            } else {
              notify(t("Please try later.If the error persists, please contact Otis support."));
              setLoading(false);
            }
          })
          .catch(function (error) {
            notify(t("Please try later.If the error persists, please contact Otis support."));
            setLoading(false);
          })
      }
    });
  }

  const handleClick = () => {
    if (add === "" || (typeof add === "boolean") || (add instanceof Boolean)) {
      notify(t("Enter folder name"));
      return false;
    } else {
      // Duplicate title validate
      setLoading(true);
      const userid = JSON.parse(localStorage.getItem("userdetails")).id;
      const dupUrl = config.drupal_url + '/api/term-title?_format=json' + '&vid=' + term.vid + '&uid=' + userid + '&title=' + add;
      axios.get(dupUrl, { withCredentials: true })
        .then(response => {
          if (response.data.length !== 0) {
            notify(t("Folder Name already exist"));
            setLoading(false);
            return false;
          } else {
            let postData = "";
            if (term.tid === '') {
              postData = JSON.stringify({
                'vid': term.vid,
                'name': [add],
                "field_user": [userid]
              })
            } else {
              postData = JSON.stringify({
                'vid': term.vid,
                'parent': [term.tid],
                'name': [add],
                "field_user": [userid]
              })
            }

            axios.get(config.drupal_url + '/session/token').then(result => {
              if (200 === result.status) {
                const csrfToken = result.data;
                const headers = {
                  'Content-Type': 'application/json',
                  'X-CSRF-Token': csrfToken,
                  withCredentials: true,
                };
                axios.post(config.drupal_url + '/taxonomy/term?_format=json', postData, { headers })
                  .then((response) => {
                    if (response.status === 201) {
                      handleAddClose()
                      setLoading(false)
                      window.location.reload(false);
                    } else {
                      notify(t("Please try later.If the error persists, please contact Otis support."));
                      setLoading(false);
                    }
                    return false;
                  })
                  .catch(function (error) {
                    notify(t("Please try later.If the error persists, please contact Otis support."));
                    setLoading(false);
                  })
              }
            });
          }
        })
    }
  }

  const handleRenameClick = () => {
    if (name == '') {
      notify(t("Enter folder name"));
      return false;
    }
    setLoading(true);
    axios.get(config.drupal_url + '/session/token')
      .then(result => {
        if (result.status === 200) {
          const csrfToken = result.data;
          const headers = {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken,
            withCredentials: true,
          };
          let data = JSON.stringify({
            'vid': term.vid,
            'name': [name],
          });
          axios.patch(config.drupal_url + '/taxonomy/term/' + term.tid + '?_format=json', data, { headers })
            .then((response) => {
              if (response.status === 200) {
                handleRenameClose();
                setLoading(false)
                setName("");
                window.location.reload(false);
              } else {
                notify(t("Please try later.If the error persists, please contact Otis support."));
                setLoading(false);
              }
            })
            .catch(function (error) {
              notify(t("Please try later.If the error persists, please contact Otis support."));
              setLoading(false);
            })
        }
      });
  }

  const handleDeleteClick = () => {
    setLoading(true)
    if (term.tid === '') { return null; }
    axios.get(config.drupal_url + '/session/token').then(result => {
      if (200 === result.status) {
        const csrfToken = result.data;
        const headers = {
          'Content-Type': 'application/hal+json',
          'X-CSRF-Token': csrfToken,
          withCredentials: true,
        }
        axios.delete(config.drupal_url + '/taxonomy/term/' + term.tid + '?_format=json', { headers })
          .then((response) => {
            if (response.status === 204) {
              handleClose()
              setLoading(false)
              window.location.reload(false);
            } else {
              notify(t("Please try later.If the error persists, please contact Otis support."));
              setLoading(false);
            }
          })
          .catch(function (error) {
            notify(t("Please try later.If the error persists, please contact Otis support."));
            setLoading(false);
          })
      }
    });
  }

  return (
    <LoadingOverlay active={loading} spinner text={t('Processing')}>
      <ToastContainer />
      <div className="library-page page-media media">
        <Modal show={show} onHide={handleClose} className="library-delete-popup">
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body><T _str="Are you sure you want to delete the selected folder and content in this folder ?" /></Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              <T _str="Cancel" />
            </Button>
            <Button variant="primary" onClick={handleDeleteClick}>
              <T _str="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={rename} onHide={handleRenameClose} className='add-folder'>
          <Modal.Header closeButton>
            <Modal.Title><T _str="Rename Folder" /></Modal.Title>
          </Modal.Header>
          <Modal.Body><T _str="Folder Name" /> <input type='text' className='full-input' value={name === '' ? term.name : name} onChange={nameChange} /> </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleRenameClick}>
              <T _str="Ok" />
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={add} onHide={handleAddClose} className='add-folder'>
          <Modal.Header closeButton>
            <Modal.Title><T _str="Create New Folder" /></Modal.Title>
          </Modal.Header>
          <Modal.Body><T _str="Folder Name" /> <input type='text' className='full-input' onChange={addChange} /> </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleClick}>
              <T _str="Ok" />
            </Button>
          </Modal.Footer>
        </Modal>


        <Modal show={showupload} onHide={handleShowUploadPopClose} className='library-upload'>
          <Modal.Header closeButton>
            <Modal.Title><T _str="Upload content to library" /></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <T _str="Title" />*<input type='text' className='full-input' maxLength={25} onChange={uploadTitleChange} /><br /><br />
            {(vocabulary !== 'messages') ?
              <Tabs>
                <div className="media_tabs">
                  <TabList>
                    <Tab onClick={() => toggleTab(1)}>{t('Image').toUpperCase()}</Tab>
                    <Tab onClick={() => toggleTab(2)}>{t('Video').toUpperCase()}</Tab>
                  </TabList>
                </div>
                <MediaContext.Provider value={{ uploadMediaContent, dispatchMediaEvent }}>
                  <TabPanel key="1" >
                    <Fileupload
                      tab={'image'}
                      vocabulary={vocabulary}
					  ImageMaxAllowedSize={validations.ImageMaxAllowedSize !== '' ? validations.ImageMaxAllowedSize : 10}
                    ></Fileupload>
                  </TabPanel>
                  <TabPanel key="2" >
                    <Fileupload
                      tab={'video'}
                      VideoMaxAllowedSize={validations.VideoMaxAllowedSize !== '' ? validations.VideoMaxAllowedSize : 100}
					  ImageMaxAllowedSize={validations.ImageMaxAllowedSize !== '' ? validations.ImageMaxAllowedSize : 10}
            MaxAllowedFPS={validations.MaxAllowedFPS !== '' ? validations.MaxAllowedFPS : 24}
					  MaxAllowedBitrate={validations.MaxAllowedBitrate !== '' ? validations.MaxAllowedBitrate : 2.5}
                    ></Fileupload>
                  </TabPanel>
                </MediaContext.Provider>
              </Tabs>
              :
              <Tabs>
                <MediaContext.Provider value={{ uploadMediaContent, dispatchMediaEvent }}>
                  <TabPanel key="1" >
                    <Fileupload tab={'image'} upload={'image'}></Fileupload>
                  </TabPanel>
                </MediaContext.Provider>
              </Tabs>
            }
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" onClick={handleFileUpload} color="primary">
              <T _str="Save" />
            </Button>
          </Modal.Footer>
        </Modal>

        <ModalwithInput show={renamepop} onhide={handleRenamePopClose} changeHandle={HandleRenameChange} nodeValue={renamenodeval} clickHandle={handleMediaRenameSubmit}></ModalwithInput>
        <ModalNodeDelete show={nodedeletepop} onhide={handleDeletePopClose} clickHandle={handleDeleteNodeSubmit}></ModalNodeDelete>

        <Modal show={showmove} onHide={handleMoveClose}>
          <Modal.Header closeButton>
            <Modal.Title><T _str="Move" /></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div >
              <div className="library-left-section-pop">
                <Tabs>
                  <TabList>
                    <Tab title={useT('User Library')} onClick={() => togglePopTab(1)}><T _str="User Library" /></Tab>
                    <Tab title={useT('Default Content')} onClick={() => togglePopTab(2)}><T _str="Default Content" /></Tab>
                    <Tab title={useT('User Messages')} onClick={() => togglePopTab(3)}><T _str="User Messages" /></Tab>
                  </TabList>
                  <LibContext.Provider value={{ term, dispatchLibEvent }}>
                    <TabPanel key="1" >
                      <UserLibrary vocabulary={'category'}></UserLibrary>
                    </TabPanel>
                    <TabPanel key="2" >
                      <UserLibrary vocabulary={vocabulary}></UserLibrary>
                    </TabPanel>
                    <TabPanel key="3" >
                      <UserLibrary vocabulary={vocabulary}></UserLibrary>
                    </TabPanel>
                  </LibContext.Provider>
                </Tabs>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleMoveSaveClick}>
              <T _str="Ok" />
            </Button>
            <Button variant="secondary" onClick={handleMoveClose}>
              <T _str="Cancel" />
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showcopy} onHide={handleCopyClose}>
          <Modal.Header closeButton>
            <Modal.Title><T _str="Copy" /></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div >
              <div className="library-left-section-pop">
                <Tabs>
                  <TabList>
                    <Tab title={useT('User Library')} onClick={() => togglePopTab(1)}><T _str="User Library" /></Tab>
                    <Tab title={useT('Default Content')} onClick={() => togglePopTab(2)}><T _str="Default Content" /></Tab>
                    <Tab title={useT('User Messages')} onClick={() => togglePopTab(3)}><T _str="User Messages" /></Tab>
                  </TabList>
                  <LibContext.Provider value={{ term, dispatchLibEvent }}>
                    <TabPanel key="1" >
                      <UserLibrary vocabulary={'category'}></UserLibrary>
                    </TabPanel>
                    <TabPanel key="2" >
                      <UserLibrary vocabulary={vocabulary}></UserLibrary>
                    </TabPanel>
                    <TabPanel key="3" >
                      <UserLibrary vocabulary={vocabulary}></UserLibrary>
                    </TabPanel>
                  </LibContext.Provider>
                </Tabs>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleCopySaveClick}>
              <T _str="Ok" />
            </Button>
            <Button variant="secondary" onClick={handleCopyClose}>
              <T _str="Cancel" />
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="groups-heading-container library-heading">
          <h2>eView<sup>&reg;</sup> -  <T _str="Library" /></h2>
          <span id="Dashboard_SubTitle"> <T _str="Use the library to manage your files and folders" /></span>

        </div>
        <div className="library-inner">

          <div className="library-left-section">
            <Tabs>
              <TabList>
                <Tab title={useT('User Library')} onClick={() => togglemTab(1)} ><T _str="User Library" /></Tab>
                <Tab title={useT('Default Content')} onClick={() => togglemTab(2)}><T _str="Default Content" /></Tab>
                <Tab title={useT('User Messages')} onClick={() => togglemTab(3)}><T _str="User Messages" /></Tab>
              </TabList>
              <LibContext.Provider value={{ term, dispatchLibEvent }}>
                <TabPanel key="1" >
                  <UserLibrary vocabulary={vocabulary === '' ? 'category' : vocabulary}></UserLibrary>
                </TabPanel>
                <TabPanel key="2" >
                  <UserLibrary vocabulary={vocabulary}></UserLibrary>
                </TabPanel>
                <TabPanel key="3" >
                  <UserLibrary vocabulary={vocabulary}></UserLibrary>
                </TabPanel>
              </LibContext.Provider>
            </Tabs>
          </div>

          <div className="library-right-section">
            <div className="lib-manage-tools" id="filesTools">
              {(!isCountryAdmin && vocabulary === 'default_library') ?
                <div className="lib-feature-tools blur" id="user-tools">
                  <div className="lib-upload dimmed-div" title={uploadTitle}><span className="upload-icon"   ></span><p><T _str="Upload" /></p></div>
                  <div className="lib-rename dimmed-div" title={renameTitle}><span className="rename-icon" > </span><p><T _str="Rename" /></p></div>
                  <div className="lib-del dimmed-div" title={deleteTitle}><span className="delete-icon" > </span><p><T _str="Delete" /></p></div>
                  <div className="lib-copy dimmed-div" title={copyTitle}><span className="copy-icon" > </span><p><T _str="Copy" /></p></div>
                  <div className="lib-move dimmed-div" title={moveTitle}><span className="move-icon" > </span><p><T _str="Move" /></p></div>
                  <div className="folder-btns-wrapper">
                    <div className="lib-new-folder dimmed-div" title={newFolderTitle}><span className="new-folder-icon" > </span><p><T _str="New Folder" /></p></div>
                    <div className="lib-rename-folder dimmed-div" title={renameFolderTitle}><span className="rename-folder-icon" > </span><p><T _str="Rename Folder" /></p></div>
                    <div className="lib-delete-folder dimmed-div" title={deleteFolderTitle}><span className="delete-folder-icon" > </span><p><T _str="Delete Folder" /></p></div>
                  </div>
                </div>
                :
                <div className="lib-feature-tools" id="user-tools">
                  <div className="lib-upload dimmed-div" title={uploadTitle}><span className="upload-icon" onClick={HandleUploadClick} ></span><p onClick={HandleUploadClick} ><T _str="Upload" /></p></div>
                  <div className="lib-rename dimmed-div" title={renameTitle}><span className="rename-icon" onClick={handleMediaRename}> </span><p onClick={handleMediaRename}><T _str="Rename" /></p></div>
                  <div className="lib-del dimmed-div" title={deleteTitle}><span className="delete-icon" onClick={handleDeleteVal}> </span><p onClick={handleDeleteVal}><T _str="Delete" /></p></div>
                  <div className="lib-copy dimmed-div" title={copyTitle}><span className="copy-icon" onClick={() => handleCopyVal()}> </span><p onClick={() => handleCopyVal()}><T _str="Copy" /></p></div>
                  <div className="lib-move dimmed-div" title={moveTitle}><span className="move-icon" onClick={() => handleMoveVal()}> </span><p onClick={() => handleMoveVal()}><T _str="Move" /></p></div>
                  <div className="folder-btns-wrapper">
                    <div className="lib-new-folder dimmed-div" title={newFolderTitle}><span className="new-folder-icon" onClick={() => handleAddShow()}> </span><p onClick={() => handleAddShow()}><T _str="New Folder" /></p></div>
                    <div className="lib-rename-folder dimmed-div" title={renameFolderTitle}><span className="rename-folder-icon" onClick={() => handleFolderRenameVal()}> </span><p onClick={() => handleFolderRenameVal()}><T _str="Rename Folder" /></p></div>
                    <div className="lib-delete-folder dimmed-div" title={deleteFolderTitle}><span className="delete-folder-icon" onClick={() => handleFolderDeleteVal()}> </span><p onClick={() => handleFolderDeleteVal()}><T _str="Delete Folder" /></p></div>
                  </div>
                </div>
              }
              <div className="folder-btns-wrapper-right"><span className="new-messages-icon"> </span><div className="lib-new-usermessage dimmed-div" title={useT("New Message")}><a href="add/media" className="date-select New-message"><T _str="New Message" />...</a></div></div>
              <div className="library-last-selection"><label id="Library_ViewBy"><T _str="View by" /></label>
                <a id="ThumbviewWrapper" className={(localStorage.getItem("gridtype") !== 'ListViewWrapper') ? 'lib-thumbview-selected active' : 'lib-thumbview-normal'} href="" onClick={handleViewType} title={useT("Thumbnails")}><T _str="Thumbnails" /></a>
                <a id="ListViewWrapper" className={(localStorage.getItem("gridtype") === 'ListViewWrapper') ? 'lib-listview-normal active' : 'lib-listview-normal'} href="" onClick={handleViewType} title={useT("List")}><T _str="List" /></a>
              </div>
            </div>

            <div className="views-exposed-widgets clearfix">
              <div id="edit-combine-wrapper" className="views-exposed-widget">
                <label htmlFor="edit-combine">  <T _str="Search Title" /> </label>
                <div className="views-widget">
                  <div className="form-item form-type-textfield">
                    <input className="form-control form-text ctools-auto-submit-processed" onChange={searchMedia} type="text" id="edit-combine" name="combine" size="30" maxLength="128" />
                  </div>
                </div>
              </div>
              <div id="edit-filetype-wrapper" className="views-exposed-widget views-widget-filter-type_1">
                <label htmlFor="edit-filetype"> <T _str="Type" /></label>
                <div className="views-widget"><div className="form-item form-item-filetype form-type-select">
                  <select className="form-control form-select" onChange={filterMediaType} id="edit-filetype" name="FileType">
                    <option value="All" defaultValue={"All"}>- {useT("Any")} -</option>
                    <option value="image">{useT("Image")}</option>
                    <option value="video">{useT("Video")}</option>
                  </select>
                </div>
                </div>
              </div>
              <div className="views-exposed-widget views-widget-sort-by">
                <div className="form-item form-item-sort-by form-type-select">
                  <label className="control-label" htmlFor="edit-sort-by"><T _str="Sort by" /></label>
                  <select className="form-control form-select" onChange={sortMedia} id="edit-sort-by" name="sort_by">
                    <option value="changed_1" defaultValue={"changed_1"}>{useT("Upload date")}</option>
                    <option value="filesize_1">{useT("Size")}</option>
                    <option value="title">{useT("Title")}</option>
                  </select>
                </div>
              </div>
              <div className="views-exposed-widget views-widget-sort-order"></div></div>
            <div className="view-content">
              <LibContext.Provider value={{ term, dispatchContEvent }}>
                <Content term={term} defaulttid={defaulttid} vocabulary={vocabulary} search={search} viewtype={localStorage.getItem("gridtype")} mediatype={mediatype} sortmedia={sortmedia}></Content>
              </LibContext.Provider>
            </div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
}

export default Library;
