import axios from "axios";
import config from "../utils/config";
import { tx } from "@transifex/native";
import { useParams } from "react-router-dom";

const GetUserStatus = () =>
  new Promise((resolve, reject) => {
    tx.init({
      token: "1/b764923cd80f817a0b810d8688e7c8f4dc7c75eb",
    });

    const USER_DETAILS = localStorage.getItem("userdetails");
    const USER_LANG = localStorage.getItem("userlang");


    var rand = (new Date()).getTime();
    axios
      .get(config.drupal_url + "/checkuser/status?time=" + rand, { withCredentials: true })
      .then((response) => {
        //appinsights enable/disable flag	
        localStorage.setItem("appinsights", response.data.appinsights_enable);
        if (response.data.logged_in === false) {
          var page = false;

          if (window.location.href.indexOf("account/pending") > -1) {
            page = true;
          }
          if (window.location.href.indexOf("account/disable") > -1) {
            page = true;
          }
          if (window.location.href.indexOf("account/denied") > -1) {
            page = true;
          }
          if (window.location.href.indexOf("session/limit") > -1) {
            page = true;
          }
          if (page === false) {
            if (window.location.pathname != "/register" || window.location.pathname != "/reset") {
              var token = localStorage.getItem("userdetails");
              if (token) {
                //var logouturl = config.drupal_url + "/standalone?status=logout";
                var userid = JSON.parse(localStorage.getItem("userdetails")).id;
                var logouturl = config.drupal_url + "/standalone?status=logout&uid=" + userid;
                window.location = encodeURI(logouturl);
                localStorage.clear();
              }
            }
          }
        } else {
          var setUserdetailBlockCheck = localStorage.getItem("setUserdetailsBlock");
          // Prevent setting userdetails to localstorage during impersonation
          if (setUserdetailBlockCheck === undefined || setUserdetailBlockCheck === 'false') {
            localStorage.setItem("userdetails", JSON.stringify(response.data.user_details));
          }
          localStorage.setItem(
            "userlang",
            response.data.user_details.language
          );
          localStorage.setItem('session_time', new Date());
          tx.setCurrentLocale(response.data.user_details.language);
        }
        resolve("Success");
      })
      .catch((error) => {
        //Set default language
        tx.setCurrentLocale("en");
        console.log("check login error", error);
        reject("failed");
      });


    if (USER_DETAILS && USER_LANG) {


      if (window.location.pathname === "/register") {
        window.location.href = "/profile";
      }
      tx.setCurrentLocale(USER_LANG);
      resolve("Success");
    }
  });

export default GetUserStatus;