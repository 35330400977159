/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: AddThemes.js
File Description: Implements add theme functionality of CMS portal

Copyright 2022,2023 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
import { useState, useEffect } from "react";
import { T, useT } from "@transifex/react";
import "../../css/Themes.css";
import FontAwesome from "react-fontawesome";
import $ from "jquery";
import LoadingOverlay from "react-loading-overlay";
import config from "../../utils/config";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { t } from "@transifex/native";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactTooltip from "react-tooltip";
import { Buffer } from 'buffer';

function AddThemes(props) {
  // Variables and States
  const tabTitle = useT("Create Themes");
  const titleEmpty = useT("!name field is required.").replace(
    "!name",
    t("Title")
  );
  const fileEmpty = useT("!name field is required.").replace(
    "!name",
    t("Upload themes")
  );
  const titleExists = useT(
    "Theme with same name already exists. Please use a different name."
  );
  const AssignuserEmpty = useT("Assign user field is required.");
  const [formData, setFormData] = useState({
    title: "",
    themeFile: "",
    users: "",
  });
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [imgTitle, setImgTitle] = useState("");
  const [token, setToken] = useState("");

  // Browser Tab Title
  useEffect(() => {
    document.title = tabTitle + " | CMS";
    const fetchCSRF = async () => {
      await axios.get(config.drupal_url + "/session/token").then((result) => {
        if (200 === result.status) {
          const csrfToken = result.data;
          setToken(csrfToken);
        }
      });
    };
    fetchCSRF();
  }, [tabTitle]);

  const regExpEscape = (literal_string) => {
    return literal_string.replace(/[-[\]{}()*+!<=:?.\/\\^$|#\s,]/g, '\\$&');
  }
  // Get logged in user details
  let userDetails = localStorage.getItem("userdetails");
  let cid = "";
  let loggedInUserID = "";
  let userrole = "building_manager";
  let countryName = "";
  let countryId = "";
  if (userDetails !== null) {
    loggedInUserID = JSON.parse(userDetails).id;
    cid = JSON.parse(userDetails).cid;
    countryId = JSON.parse(userDetails).cid;
    countryName = JSON.parse(userDetails).country;
    var userdetails = JSON.parse(localStorage.getItem("userdetails"));
    userdetails.roles.forEach((role) => {
      if (role === "country_admin") {
        userrole = "country_admin";
      }
      if (role === "super_admin") {
        userrole = "super_admin";
      }
    });
  } else {
    loggedInUserID = 1;
    countryId = 10;
    countryName = "United States";
  }

  const notify = (val) => {
    toast.error(val, {
      toastId: loggedInUserID,
    });
  };
  useEffect(() => {
    //window.GetMap();
    setLoading(true);
    var userOptions = "";
    axios
      .get(config.drupal_url + "/Userslist/" + countryId, {
        withCredentials: true,
      })
      .then((response) => {
        $.each(response.data, function (key, value) {
          userOptions =
            '<option key={key} value="' +
            value.uid +
            '">' +
            value.mail +
            "</option>";
          $("#edit-field-assign-user-und").append(userOptions);
        });
      })
      .then((response) => {
        const assignwidget = $(
          "#improvedselect-edit-field-assign-user-und"
        ).length;
        if (assignwidget === 0) {
          $("#edit-field-assign-user-und")
            .parent()
            .append(
              '<div class="improvedselect" sid="' +
                $("#edit-field-assign-user-und").attr("id") +
                '" id="improvedselect-' +
                $("#edit-field-assign-user-und").attr("id") +
                '"><div class="improvedselect-text-wrapper"><input type="text" class="improvedselect_filter" sid="' +
                $("#edit-field-assign-user-und").attr("id") +
                '" prev="" /></div><ul class="improvedselect_sel"></ul><ul class="improvedselect_all"></ul><div class="improvedselect_control"><span class="add" sid="' +
                $("#edit-field-assign-user-und").attr("id") +
                '">&gt;</span><span class="del" sid="' +
                $("#edit-field-assign-user-und").attr("id") +
                '">&lt;</span><span class="add_all" sid="' +
                $("#edit-field-assign-user-und").attr("id") +
                '">&raquo;</span><span class="del_all" sid="' +
                $("#edit-field-assign-user-und").attr("id") +
                '">&laquo;</span></div><div class="clear" /></div>'
            );
          var improvedselect_id = $("#edit-field-assign-user-und").attr("id");
          $("#edit-field-assign-user-und")
            .find("option")
            .each(function () {
              if ($(this).attr("selected")) {
                $(
                  "#improvedselect-" +
                    improvedselect_id +
                    " .improvedselect_sel"
                ).append(
                  '<li so="' +
                    $(this).attr("value") +
                    '">' +
                    $(this).text() +
                    "</li>"
                );
              } else {
                $(
                  "#improvedselect-" +
                    improvedselect_id +
                    " .improvedselect_all"
                ).append(
                  '<li so="' +
                    $(this).attr("value") +
                    '">' +
                    $(this).text() +
                    "</li>"
                );
              }
            });
          $("#improvedselect-" + improvedselect_id + " li").click(function () {
            $(this).toggleClass("selected");
          });

          setLoading(false);
          $("#edit-field-assign-user-und").hide();
          $(".improvedselect_filter").keyup(function () {
            var text = $(this).val();
            if (text.length && text !== $(this).attr("prev")) {
              $(this).attr("prev", text);
              var patt = new RegExp(`${regExpEscape(text)}`, "i");
              $(
                "#improvedselect-" +
                  $(this).attr("sid") +
                  " .improvedselect_all li"
              ).each(function () {
                var str = $(this).text();
                if (str.match(patt)) {
                  $(this).show();
                } else {
                  $(this).hide();
                }
              });
            } else {
              $(this).attr("prev", "");
              $(
                "#improvedselect-" +
                  $(this).attr("sid") +
                  " .improvedselect_all li"
              ).each(function () {
                $(this).show();
              });
            }
          });

          // Add selected items.
          $(".improvedselect .add").click(function () {
            var sid = $(this).attr("sid");
            $("#improvedselect-" + sid + " .improvedselect_all .selected").each(
              function () {
                $(this).removeClass("selected");
                $(this).show();
                $("#improvedselect-" + sid + " .improvedselect_sel").append(
                  $(this)
                );
              }
            );
            improvedselectUpdate(sid);
          });

          // Remove selected items.
          $(".improvedselect .del").click(function () {
            var sid = $(this).attr("sid");
            $("#improvedselect-" + sid + " .improvedselect_sel .selected").each(
              function () {
                $(this).removeClass("selected");
                $("#improvedselect-" + sid + " .improvedselect_all").append(
                  $(this)
                );
              }
            );
            improvedselectUpdate(sid);
          });

          // Remove all filtered items.
          $(".improvedselect .add_all").click(function () {
            var sid = $(this).attr("sid");
            $("#improvedselect-" + sid + " .improvedselect_all li").each(
              function () {
                if ($(this).css("display") !== "none") {
                  $(this).removeClass("selected");
                  $("#improvedselect-" + sid + " .improvedselect_sel").append(
                    $(this)
                  );
                }
              }
            );
            improvedselectUpdate(sid);
          });

          // Remove all items.
          $(".improvedselect .del_all").click(function () {
            var sid = $(this).attr("sid");
            $("#improvedselect-" + sid + " input").val("");
            $("#improvedselect-" + sid + " input").attr("prev", "");
            $("#improvedselect-" + sid + " .improvedselect_sel li").each(
              function () {
                $(this).removeClass("selected");
                $("#improvedselect-" + sid + " .improvedselect_all").append(
                  $(this)
                );
              }
            );
            $("#improvedselect-" + sid + " .improvedselect_all li").each(
              function () {
                $(this).show();
              }
            );
            improvedselectUpdate(sid);
          });
        }
      });
  }, []);

  function improvedselectUpdate(sid) {
    $("#" + sid + " option:selected").attr("selected", false);
    $("#improvedselect-" + sid + " .improvedselect_sel li").each(function () {
      $("#" + sid + ' [value="' + $(this).attr("so") + '"]').attr(
        "selected",
        "selected"
      );
    });
    $("#" + sid)
      .find("option")
      .each(function () {
        if ($(this).attr("selected")) {
          $("#improvedselect-" + sid + " .improvedselect_sel").append(
            $(
              "#improvedselect-" +
                sid +
                ' .improvedselect_sel [so="' +
                $(this).attr("value") +
                '"]'
            )
          );
        } else {
          $("#improvedselect-" + sid + " .improvedselect_all").append(
            $(
              "#improvedselect-" +
                sid +
                ' .improvedselect_all [so="' +
                $(this).attr("value") +
                '"]'
            )
          );
        }
      });
  }

  // Handle Theme Submit and Validations.
  const handleThemeSubmit = (e) => {
    e.preventDefault();
    // Title empty Validation
    if (formData.title === "") {
      notify(titleEmpty);
      formData.title = "";
      $("label.title_label").addClass("error-class");
    } else {
      $("label.title_label").removeClass("error-class");
    }
    // Theme File Empty validation
    if (formData.themeFile === "") {
      notify(fileEmpty);
      $("label.upload_label").addClass("error-class");
    } else {
      // Image type valiations.
      const allowedFileTypes = ["image/jpeg", "image/png"];
      if (!allowedFileTypes.includes(formData.themeFile.type)) {
        notify(
          t(
            "The selected file %filename cannot be uploaded. File type must be %extensions."
          )
            .replace("%filename", formData.themeFile.name)
            .replace("%extensions", "png, jpg, jpeg")
        );
        return false;
      } else {
        $("label.upload_label").removeClass("error-class");
      }
    }
    if (userrole === "country_admin") {
      let selectedOptions = $("#edit-field-assign-user-und").val();
      formData.users = selectedOptions;
      //const arr = selectedOptions.split(",");

      if (formData.users.length === 0) {
        notify(AssignuserEmpty);
        $("label.assign-users-label").addClass("error-class");
        return false;
      } else {
        $("label.assign-users-label").removeClass("error-class");
      }
    } else if (userrole === "building_manager") {
      formData.users = [loggedInUserID];
    }

    // Save Theme
    if (formData.title && formData.themeFile) {
      setLoading(true);
      const titleValidate = "?cid=" + cid + "&title=" + formData.title;
      axios
        .get(config.drupal_url + "/api/themes" + titleValidate, {
          withCredentials: true,
        })
        .then((response) => {
          if (response.data.rows.length === 0) {
            var fReader = new FileReader();
            fReader.readAsDataURL(formData.themeFile);
            fReader.onload = function (e) {
              var image = new Image();
              image.src = e.target.result;
              image.onload = function () {
                var height = this.height;
                var width = this.width;
                if (height < 1080 || width < 1920) {
                  notify(
                    t(
                      "The specified file %name could not be uploaded."
                    ).replace("%name", formData.themeFile.name) +
                      " " +
                      t(
                        "The image is too small; the minimum dimensions are %dimensions pixels."
                      ).replace("%dimensions", "1920x1080")
                  );
                  setLoading(false);
                  return false;
                } else if (height > 1080 || width > 1920) {
                  notify(
                    t(
                      "The specified file %name could not be uploaded."
                    ).replace("%name", formData.themeFile.name) +
                      " " +
                      t("Image resolution must be %dimensions pixels.").replace(
                        "%dimensions",
                        "1920x1080"
                      )
                  );
                  setLoading(false);
                  return false;
                } else {
                  setLoading(true);
                  //let login = config.auth_username;
                  //let password = config.auth_password;
                 /* const encodedString = Buffer.from(
                    `${login}:${password}`
                  ).toString("base64");*/
                  const fileSaveUrl =
                    config.drupal_url + "/api/node/article/field_image";
                  let reader = new FileReader();
                  reader.readAsArrayBuffer(formData.themeFile);
                  reader.onabort = () => {
                    console.log("file reader aborted");
                  };
                  reader.onerror = () => {
                    console.log("error with file reader");
                  };
                  reader.onload = () => {
                    const arrayStr = reader.result;
                    axios
                      .post(fileSaveUrl, arrayStr, {
                        headers: {
                          "Content-Type": "application/octet-stream",
                          Accept: "application/vnd.api+json",
                          "Content-Disposition": `file; filename="${formData.themeFile.name}"`,
                          withCredentials: true,
                          "X-CSRF-Token": token,
                        },
                      })
                      .then((res) => {
                        // Save Media node starts
                        // Headers
                        const headers = {
                          "Content-Type": "application/hal+json",
                          withCredentials: true,
                          "X-CSRF-Token": token,
                        };
                        //Getting Assinged users list
                        let term_ids = [];
                        for (const uid of formData.users) {
                          term_ids.push({ target_id: uid });
                        }
                        // Data
                        let data = {
                          _links: {
                            type: {
                              href:
                                config.rest_node_url +
                                "/rest/type/node/add_themes",
                            },
                          },
                          title: {
                            value: formData.title.trim(),
                          },
                          field_upload_themes: [
                            {
                              target_id: `${res.data.data.attributes.drupal_internal__fid}`,
                            },
                          ],
                          "field_assign_user": term_ids,
                          uid: [
                            {
                              target_id: loggedInUserID,
                            },
                          ],
                        };
                        // POST url
                        const saveMediaUrl =
                          config.drupal_url + "/node?_format=hal_json";
                        axios
                          .post(saveMediaUrl, JSON.stringify(data), { headers })
                          .then((result) => {
                            if (result.status === 201) {
                              setLoading(false);
                              history.push({
                                pathname: "/theme-list",
                                theme_title: formData.title,
                              });
                            }
                          })
                          .catch(function (error) {
                            notify(
                              t(
                                "Please try later.If the error persists, please contact Otis support."
                              )
                            );
                            setLoading(false);
                          });
                      });
                  };
                }
              };
            };
          } else {
            setLoading(false);
            notify(titleExists);
          }
        })
        .catch(function (error) {
          notify(
            t(
              "Please try later.If the error persists, please contact Otis support."
            )
          );
          setLoading(false);
        });
    }
  };

  const fileUploadButton = (e) => {
    e.preventDefault();
    document.getElementById("upload_field").click();
  };

  const onFileChange = async (e) => {
    const uFile = e.target.files;
    if (uFile.length !== 0) {
      setLoading(true);
      const allowedFileTypes = ['image/jpeg', 'image/png', 'image/jpg'];
      const imageFileSize = e.target.files[0].size / 1024 / 1024;
      if (!allowedFileTypes.includes(e.target.files[0].type)) {
        notify(t("The selected file %filename cannot be uploaded. File type must be %extensions.").replace('%filename', e.target.files[0].name).replace('%extensions', 'png, jpg, jpeg, jfif'))
        setLoading(false);
        return false;
      } else if (imageFileSize > 50) {
        notify(t("The selected file @filename cannot be uploaded. File size cannot exceed @maxFileSize.").replace('@filename', e.target.files[0].name).replace('@maxFileSize', '50 MB'))
        setLoading(false);
        return false;
      } else {
        var file = e.target.files[0];
        var file_name = file.name.replace(/\s/g, "");
        var file_name_wo_extn = file_name.split('.').slice(0, -1).join('.');

        // Filename validation for special characters
        var reg_exp = /^[0-9a-zA-Z]+$/;
        var is_valid = reg_exp.test(file_name_wo_extn);
        if (!is_valid) {
          notify(t('Filename cannot include special characters. Please try again.'));
          setLoading(false);
          return false;
        }
      }
      var fReader = new FileReader();
      fReader.fileName = e.target.files[0].name;
      fReader.readAsDataURL(e.target.files[0]);
      fReader.onload = function (e) {
        var img = new Image();
        img.src = e.target.result;
        img.onload = function () {
          var height = this.height;
          var width = this.width;
          if (height < 1080 || width < 1920) {
            notify(
              t(
                "The specified file %name could not be uploaded."
              ).replace("%name", uFile[0].name) +
              " " +
              t(
                "The image is too small; the minimum dimensions are %dimensions pixels."
              ).replace("%dimensions", "1920x1080")
            );
            setFormData({ ...formData, themeFile: "" });
            setImgTitle("");
            setLoading(false);
            return false;
          } else if (height > 1080 || width > 1920) {
            notify(
              t(
                "The specified file %name could not be uploaded."
              ).replace("%name", uFile[0].name) +
              " " +
              t("Image resolution must be %dimensions pixels.").replace(
                "%dimensions",
                "1920x1080"
              )
            );
            setFormData({ ...formData, themeFile: "" });
            setImgTitle("");
            setLoading(false);
            return false;
          }
          
        }
      }
      setLoading(false);
      setFormData({ ...formData, themeFile: e.target.files[0] });
      setImgTitle(e.target.files[0].name);
    } else {
      setFormData({ ...formData, themeFile: "" });
      setImgTitle("");
    }
  };

  $("#upload-instructions ul li").each(function () {
    var $this = $(this);
    var t = $this.html();
    var newString = t
      .replace("!size", "<strong>50 MB</strong>")
      .replace("!extensions", "<strong>png jpg jpeg</strong>")
      .replace("%dimensions", "<strong>1920x1080</strong>");
    $this.html(newString);
  });

  return (
    <LoadingOverlay active={loading} spinner text={t("Processing")}>
      <ToastContainer />
      <div className="headerSection">
        <h1 className="pageTitle">
          eView<sup>&reg;</sup> - <T _str="Create Themes" />
        </h1>
      </div>
      <div className="add-theme-form">
        <form className="themes_form">
          <div className="title_div theme_padding_div" id="title_div">
            <label htmlFor="title_field" className="title_label element_label">
              <T _str="Title" /> <span>*</span>
            </label>
            <input
              className="title_field element_input"
              id="title_field"
              type="text"
              name="title"
              value={formData.title}
              maxLength={25}
              onChange={(e) =>
                setFormData({ ...formData, title: e.target.value })
              }
            />
          </div>
          <div className="upload_div theme_padding_div" id="upload_div">
            <label
              htmlFor="upload_field"
              className="upload_label element_label"
            >
              <T _str="Upload themes" /> <span>*</span>
            </label>
            <input
              className="upload_field element_input"
              id="upload_field"
              type="file"
              name="selectedFile"
              onChange={onFileChange}
            />
            <div className="element_input">
              <button onClick={fileUploadButton}>
                <T _str="Choose File" />
              </button>
              <label id="selectedfilename">
                {imgTitle ? imgTitle : t("No file chosen")}
              </label>
            </div>
            <div className="help-block">
              <a
                data-tip={"custom show"}
                data-event={"click focus"}
                data-for={"tooltip"}
              >
                <FontAwesome
                  name="question-circle"
                  size="lg"
                  className="question-circle"
                />
                <T _str="More information" />
              </a>
              <ReactTooltip
                id={"tooltip"}
                effect="solid"
                globalEventOff="click"
                clickable={true}
                place="bottom"
                getContent={function () {
                  return (
                    <div id="upload-instructions" className="popover-cs">
                      <h3 className="upload-instructions-title">
                        <T _str="File requirements" />
                      </h3>
                      <div className="arrow-cs"></div>
                      <ul>
                        <li>{t("File must be smaller than !size.")}</li>
                        <li>{t("Allowed file types: !extensions.")}</li>
                        <li>
                          {t("Image resolution must be %dimensions pixels.")}
                        </li>
                      </ul>
                    </div>
                  );
                }}
              />
            </div>
          </div>
          {userrole === 'country_admin' &&
            <div className="form-item form-item-field-assign-user-und form-type-select form-group mb-2">
              <label className="control-label assign-users-label mb-2" htmlFor="edit-field-assign-user-und">
                <T _str="Assign user" />
                <span className="form-required" title="This field is required.">*</span>
              </label>
              <select
                multiple="multiple"
                name="field_assign_user"
                className="form-control form-select required improvedselect-processed"
                id="edit-field-assign-user-und">
              </select>
            </div>
          }
          <div className="actions_div" id="actions_div">
            <button
              type="submit"
              value="Save"
              className="actions_button submit_form"
              onClick={handleThemeSubmit}
            >
              <FontAwesome name="check" size="lg" />
              <T _str="Save" />
            </button>
          </div>
        </form>
      </div>
    </LoadingOverlay>
  );
}

export default AddThemes;
