import React, { useState } from 'react';
import { FaTv } from 'react-icons/fa';
import { FaPen } from 'react-icons/fa';
import { FaRegEye } from 'react-icons/fa';
import { FaTimesCircle } from 'react-icons/fa';
import style from './DashboardPlaylistsCard.module.css';
import PlaylistsPreview from '../Common/PlaylistsPreview';
import { Modal } from 'react-bootstrap';
import config from '../../utils/config';
import { useHistory } from "react-router-dom";
import { T } from '@transifex/react';
import { t } from "@transifex/native";
import { dateformat } from '../../utils/dateFormat';

const DashboardPlaylistsCard = ({ playlistsData }) => {

  const history = useHistory();
  const [modalShow, setmodalShow] = useState(false);
  const [modalPlaylistId, setModalPlaylistId] = useState();
  const modalHandleClose = () => setmodalShow(false);
  const modalHandleShow = (event, id) => {
    setModalPlaylistId(id);
    setmodalShow(true);
    return true;
  }
  const AddScreensRedirect = (title, id, type) => {
    const schedulePlaylistData = {
      PlaylistId: id,
      PlaylistTitle: title,
      PlaylistType: type,
      screenIds: []
    }
    localStorage.removeItem('schedulePlaylist');
    localStorage.setItem('schedulePlaylist', JSON.stringify(schedulePlaylistData));
    history.push('/add-screens');
  };
  const userdetails = localStorage.getItem('userdetails');
  var country_name = userdetails && JSON.parse(userdetails).country;
  const preveiwTip = t("Click to see preview");
  return (
    <>
      <div className={style.DashboardPlaylistsCard}>
        <ul className='list-group'>
          {playlistsData.map((playlist, index) => {
            var updatedOn = dateformat(playlist.updatedOn, 'dashboard');
            var content = playlist.contentArea.split("$$");
            var firstcontentImage = content[0];
            var firstcontentImageURL = firstcontentImage.replace(/&amp;/g, '&');
            return (
              <li key={playlist.id} className='list-group-item'>
                <a href="#!" className={style.image}
                  data-modal={playlist.id}
                  onClick={(event) => [modalHandleShow(event, playlist.nid)]}
                  title={preveiwTip} >
                  <img src={firstcontentImageURL} alt="" />
                  <div className={style.previewicon}><FaRegEye /></div>
                </a>
                <div className={style.title}>{playlist.playlistTitle}</div>
                <div className={style.updated}><T _str="Last Update" />: {updatedOn}</div>
                <div className={style.actions}>
                  <a href="#!"
                    className={style.addScreens}
                    title={t('Add Screens')}
                    onClick={(event) => AddScreensRedirect(playlist.playlistTitle, playlist.nid, playlist.layout)}>
                    <FaTv /><T _str="Add Screens" />
                  </a>
                  <a className={style.edit} title={t('edit')} href={"/edit-playlist?id=" + playlist.nid}><FaPen /><T _str="edit" /></a>
                </div>
              </li>
            )
          })}
        </ul>
      </div >
      <Modal show={modalShow} onHide={modalHandleClose} className="pl-preview">
        <Modal.Body>
          <div className="playlistPreview modalClose" onClick={modalHandleClose} ><FaTimesCircle /></div>
          <PlaylistsPreview
            playlistsData={playlistsData}
            othersPlaylistsData={[]}
            fullPreview='NA'
            id={modalPlaylistId}
            screenId='NA'
            infomsg='NA'
            source="dashboard_playlist"
            bannerbgcolor='NA'
            bannertextcolor='NA'
            countryname={country_name}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DashboardPlaylistsCard;
