import React from "react";
import { t } from "@transifex/native";
const TableFooter = ({ totalItems, loadedItems, onLoadMore, isLoading }) => {
  return (
    <div className="device-list-footer">
      {totalItems > 5 && loadedItems !== Number(totalItems) && (
        <button className="btn-load-more" id="active" onClick={onLoadMore}>
           { /*(perPage * (page + 1)) < totalItems && <button className="btn-load-more" onClick={() => { setPage(page + 1); setSearchPage(0) }}>{loading ? '' : <T _str='Load more' />}</button> */}
          {isLoading ? t("Loading") : t("Load more")}
        </button>
      )}
      <span className="list-count">
        {" "}
        {t("Displaying @start - @end of @total")
          .replace("@start", 1)
          .replace("@end", loadedItems)
          .replace("@total", totalItems)}
      </span>
    </div>
  );
};
export default TableFooter;
