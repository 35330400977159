import axios from "axios";
import config from "./config";
/*
 * Once user closes browser and stays inactive, after 20min session will destory
 * From server side, after 20min inactivity if he opens browser we are destorying
 * JWT token and clearing localstorage.
 *
*/
export const SESSION_TIME = 'session_time';
export function UserSession() {
  if (window.location.pathname != "/register" || window.location.pathname != "/reset") {
    axios.interceptors.request.use((request) => {
      const LAST_SESSION = localStorage.getItem(SESSION_TIME);
      if (LAST_SESSION) {
        const DIFF_IN_SECONDS = parseInt(
          (Math.abs(new Date() - new Date(LAST_SESSION)) / 1000)
        );
        if (DIFF_IN_SECONDS > 1200) {
          logoutDestory();
        } else {
          localStorage.setItem(SESSION_TIME, new Date());
        }
      } else {
        localStorage.setItem(SESSION_TIME, new Date());
      }
      return request;
    }, (err) => {
      return Promise.reject(err);
    });
  }
}

export function logoutDestory() {
  if (window.location.pathname != "/register" || window.location.pathname != "/reset") {
    var token = localStorage.getItem("userdetails");
    if (token) {
      var logouturl = config.drupal_url + "/standalone?status=logout";
      window.location = encodeURI(logouturl);
      localStorage.clear();
    }
  }
}