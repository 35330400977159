import { useState, useEffect } from "react";
import { FaCog } from "react-icons/fa";
import { FaListUl } from "react-icons/fa";
import { FaRegCalendarAlt } from "react-icons/fa";
import { FaRegEye } from "react-icons/fa";
import { FaTimesCircle } from "react-icons/fa";
import { FaExclamationCircle } from "react-icons/fa";
import UnopDropdown from "unop-react-dropdown";
import { Modal } from "react-bootstrap";
import PlaylistsPreview from "../Common/PlaylistsPreview";
import { useHistory } from "react-router-dom";
import { T } from "@transifex/react";
//import Devicestatus from './Devicestatus';
import { t } from "@transifex/native";
import config from "../../utils/config";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";
import { propTypes } from "react-bootstrap/esm/Image";
import { FaCheckCircle } from "react-icons/fa";
import { Buffer } from 'buffer';

const ScreensAccordionActions = ({
  screenId,
  schedulesData,
  playlistsData,
  othersPlaylistsData,
  cpid,
  infomsg,
  themedownload,
  devicetheme,
  countryDefaultPlaylist,
  screenStatusData,
  bannerbgcolor,
  bannertextcolor,
}) => {
  let history = useHistory();
  const [modalShow, setmodalShow] = useState(false);
  const [currentlyPlaying, setCurrentlyPlaying] = useState([]);
  const [notification, setNotification] = useState([]);
  const modalHandleClose = () => setmodalShow(false);
  const [modalPlaylistId, setModalPlaylistId] = useState();
  const [fullPreview, setFullPreview] = useState();
  const [isloading, setLoading] = useState(false);
  const userdetails = localStorage.getItem('userdetails');
  var country_name = userdetails && JSON.parse(userdetails).country;

  const modalHandleShow = (event, id) => {
    setLoading(true);
    /*const encodedString = Buffer.from(
      config.auth_username + ":" + config.auth_password
    ).toString("base64");
    const basicAuth = "Basic " + encodedString;*/
    axios
      .get(
        config.drupal_url +
        "/rest/api/get/playlist/preview/" +
        screenId +
        "/" +
        cpid,
        {
          headers: {
            "Content-Type": "application/json",
            withCredentials: true,
          },
        }
      )
      .then((response) => {
        if (response.data) {
          const PlaylistFullPreview = {
            layout: response.data.playlist_layout,
            date: response.data.curr_date
              ? response.data.curr_date
              : "20.08.2021",
            time: response.data.curr_time
              ? response.data.curr_time
              : "11:58 AM",
          };
          setFullPreview(PlaylistFullPreview);
          setLoading(false);
          setmodalShow(true);
        }
      })
      .catch((err) => {
        toast.error(t("Error retrieving preview content. Please try again."));
        setLoading(false);
      });
    setModalPlaylistId(id);
    return true;
  };

  var trans = "";
  var Pen = t("Pending");
  var Err = t("Error");
  useEffect(() => {
    const updatedPlaying = [...currentlyPlaying];
    const updatenotification = [...notification];
    let customPresentArr = {};

    schedulesData.some(function (schedule, index) {
      customPresentArr[screenId] = false;
      if (schedule.screen_id == screenId) {
        // Custom schedule
        if (schedule.field_forever === "2") {
          customPresentArr[screenId] = true;
          return true;
        }
      }
    });

    schedulesData.forEach((schedule, index) => {
      if (schedule.screen_id == screenId) {
        if (
          schedule.transmit === "SUCCESS" ||
          schedule.transmit === "success"
        ) {
          const playing = {
            screen: screenId,
            id: schedule.playlist_id,
            title: schedule.playlist_title,
          };
          if (customPresentArr[screenId] === true) {
            if (schedule.field_forever === "2") {
              updatedPlaying.push(playing);
              setCurrentlyPlaying(...currentlyPlaying, updatedPlaying);
            }
          } else {
            updatedPlaying.push(playing);
            setCurrentlyPlaying(...currentlyPlaying, updatedPlaying);
          }
        } else {
          const newnotification = {
            type: schedule.transmit === "Pending" ? "Pending" : "Error",
            title: schedule.playlist_title,
          };
          updatenotification.push(newnotification);
          setNotification(...notification, updatenotification);
        }
      }
    });
  }, []);

  const screenCalendarRedirect = () => {
    history.push("/calendar?screen_id=" + screenId);
  };
  const editScreenRedirect = () => {
    history.push("/screen/" + screenId);
  };
  const notif = t("Notification");
  const preveiwTip = t("Click to see preview");
  return (
    <>
      <LoadingOverlay active={isloading} spinner text={t("Processing")}>
        <ToastContainer />
        <div className="ScreensAccordionActions">
          <div className="actionIcons">
            <button href="#!" onClick={editScreenRedirect}>
              <FaCog />
            </button>
            {themedownload === "FAIL" && (
              <div className="themedownload">
                <UnopDropdown
                  hover
                  trigger={
                    <a className="themedownloaderror" href="#!">
                      1
                    </a>
                  }
                >
                  <div className="UnopDropdownInner">
                    <div className="notEmptyPlaylist">
                      <T _str="Theme download failed" />
                    </div>
                  </div>
                </UnopDropdown>
              </div>
            )}
          </div>

          <div className="actionIcons">
            <UnopDropdown
              hover
              trigger={
                <a href="#!">
                  <FaListUl />
                </a>
              }
            >
              <div className="UnopDropdownInner">
                <div className="notEmptyPlaylist">
                  {currentlyPlaying.length > 0 ||
                    countryDefaultPlaylist.length > 0 ? (
                    <>
                      <T _str="Currently Active" />
                    </>
                  ) : (
                    <>
                      <T _str="No Playlists Found" />
                    </>
                  )}
                  {currentlyPlaying.length > 0 && (
                    <>
                      {currentlyPlaying.map((playing, index) => {
                        return (
                          <div className="ActivePlaylist" key={index}>
                            {playing.title}
                            <a
                              href="#!"
                              data-modal={playing.id}
                              onClick={(event) => [
                                modalHandleShow(event, playing.id),
                              ]}
                              title={preveiwTip}
                            >
                              <FaRegEye />
                            </a>
                          </div>
                        );
                      })}
                    </>
                  )}
                  {currentlyPlaying.length == 0 &&
                    countryDefaultPlaylist.length > 0 && (
                      <>
                        <div className="ActivePlaylist default">
                          {countryDefaultPlaylist[0].playlistTitle} (
                          <T _str="Default" />)
                          <a
                            href="#!"
                            data-modal={countryDefaultPlaylist[0].nid}
                            onClick={(event) => [
                              modalHandleShow(
                                event,
                                countryDefaultPlaylist[0].nid
                              ),
                            ]}
                            title={preveiwTip}
                          >
                            <FaRegEye />
                          </a>
                        </div>
                      </>
                    )}
                </div>
              </div>
            </UnopDropdown>
          </div>
          <div className="actionIcons">
            <button href="#!" onClick={screenCalendarRedirect}>
              <FaRegCalendarAlt />
            </button>
          </div>

          {notification.length > 0 && (
            <div className="actionIcons">
              <UnopDropdown
                hover
                trigger={
                  <a className="error" href="#!">
                    <FaExclamationCircle />
                  </a>
                }
              >
                {notification.map((playing, index) => {
                  trans = playing.type === "Pending" ? Pen : Err;
                  return (
                    <>
                      {index === 0 && (
                        <div className="UnopDropdownInner" key={index}>
                          <div className="notification">
                            <div className="notificationContent">
                              <div className="title">{notif}</div>
                              <div className="body">
                                {playing.title}
                                <span className="error">{trans} *</span>
                              </div>
                            </div>
                            {playing.type === "Error" && (
                              <div className="notificationFooter">
                                <span className="error">* </span>
                                <T _str="Please try later.If the error persists, please contact Otis support." />
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}
              </UnopDropdown>
            </div>
          )}
          {screenStatusData.map((screenstatus, index) => {
            return (
              <>
                {screenstatus.entity_id === screenId && (
                  <div className="actionIcons">
                    {" "}
                    <a
                      title={
                        screenstatus.field_device_status_value === "Connected"
                          ? t("Device online")
                          : t("Device offline")
                      }
                      className={
                        screenstatus.field_device_status_value === "Connected"
                          ? "online"
                          : "offline"
                      }
                      href="#!"
                    >
                      <FaCheckCircle />
                    </a>
                  </div>
                )}
              </>
            );
          })}

          <Modal
            show={modalShow}
            onHide={modalHandleClose}
            className="pl-preview"
          >
            <Modal.Body>
              <div
                className="playlistPreview modalClose"
                onClick={modalHandleClose}
              >
                <FaTimesCircle />
              </div>
              <PlaylistsPreview
                playlistsData={playlistsData}
                othersPlaylistsData={othersPlaylistsData}
                fullPreview={fullPreview}
                id={modalPlaylistId}
                screenId={screenId}
                cpid={cpid}
                infomsg={infomsg}
                devicetheme={devicetheme}
                countryDefaultPlaylist={countryDefaultPlaylist}
                source="dashboard_screen"
                bannerbgcolor={bannerbgcolor}
                bannertextcolor={bannertextcolor}
                countryname={country_name}
              />
            </Modal.Body>
          </Modal>
        </div>
      </LoadingOverlay>
    </>
  );
};

export default ScreensAccordionActions;
