/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: index.js
File Description: index file

Copyright 2021 - 2022 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import Login from "./Pages/Login";
import "./fonts/HelveticaNeue/HelveticaNeueLTStd-Lt.otf";
import "./index.css";
import GetUserStatus from "./utils/GetUserStatus";
import { UserSession } from "./utils/SessionManagement";


const rootElement = document.getElementById("root");
const AppElement = () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
	
const LoginAppElement = () => (
  <BrowserRouter>
    <Login />
  </BrowserRouter>
);

   const USER_DETAILS = localStorage.getItem("userdetails");

	UserSession();
    GetUserStatus()
    .then(() => {
	  if (!USER_DETAILS && !(window.location.href.indexOf("register") > -1)
		  && !USER_DETAILS && !(window.location.href.indexOf("account/pending") > -1) 
		  && !USER_DETAILS && !(window.location.href.indexOf("session/limit") > -1) 
	      && !USER_DETAILS && !(window.location.href.indexOf("account/denied") > -1)
		  && !USER_DETAILS && !(window.location.href.indexOf("account/disable") > -1)
		  && !USER_DETAILS && !(window.location.href.indexOf("reset") > -1)) {
			if(window.location.pathname != "/"){ 
				 window.location.pathname = "/";
			}
		  ReactDOM.render(<LoginAppElement />, rootElement);
	   }
	   else
	   {
      ReactDOM.render(<AppElement />, rootElement);
	   }

  })
  .catch((e) => {
    ReactDOM.render(<AppElement />, rootElement);
  });