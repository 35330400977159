import {format} from 'date-fns';
import moment, { weekdays } from 'moment';

export function dateformat(date, page) {
  var userdata = localStorage.getItem("userdetails");
  var requiredFormat = userdata && JSON.parse(userdata).dateformat;

  // dateformat:"MM/DD/YYYY"
  var datePiece = date.split("/");
  // Convert date to mm/dd/yyyy format
  var DateMDY = datePiece[1] + '/' + datePiece[0] + '/' + datePiece[2];

  const allowedDateFormats = [
    'MM/DD/YYYY',
    'YYYY/MM/DD',
    'DD-MM-YYYY',
    'MM-DD-YYYY',
    'YYYY-MM-DD',
    'DD.MM.YYYY',
    'MM.DD.YYYY',
    'YYYY.MM.DD'
  ];

  if(allowedDateFormats.includes(requiredFormat)) {
    var formatedDate = moment(DateMDY).format(requiredFormat);
    return formatedDate;
  } else {
    if(page == 'users') {
      // Manage users page should display "27-Sept-2022", if format is not set in app settings 
      var formatedDate = moment(DateMDY).format("DD-MMM-YYYY");
      return formatedDate;
    }
    return date;
  }
}
