/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: AddWeatherFeeds.js
File Description: Implements add weather feed functionality of CMS portal

Copyright 2021 - 2023 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
import React, { useState, useEffect } from 'react';
import { T, useT } from '@transifex/react';
import { t } from "@transifex/native"
import $ from "jquery";
import LoadingOverlay from "react-loading-overlay";
import FontAwesome from 'react-fontawesome';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import config from '../utils/config';
import "../css/livecontent.css"
import 'react-toastify/dist/ReactToastify.css';
import ReactTooltip from 'react-tooltip';
import { Buffer } from 'buffer';

function AddWeatherFeeds() {
  
  window.long = config.AzureMapFixlongitude;
  window.lati = config.AzureMapFixlatitude;
  window.subscriptionkey = config.AzureMapKey;

	const tabTitle = useT("Create live content");
	const [languagesData, setLanguagesData] = useState([]);
  const [loading, setLoading] = useState(false);
	const history = useHistory();
  const hiddenFileInput = React.useRef(null);

  const handleClick = event => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };

  const [formData, setFormData] = useState({
	  title: "",
	  feed_type:"weather",
	  preview_image: "", 
	  language:"",
	  metric:"",
	  lat:"",
	  lon:"",
	  image_id:""
	})

  // Browser Tab Title
  useEffect(() => {
    document.title = tabTitle + ' | CMS';
  }, [tabTitle])

  // Browser Tab Title
	useEffect(() => {
    fetchLanguages();
    window.GetMap();
	}, [])


  const handleCancel = () => {
    history.push('/live-content-management');
  }

   // Fetch Languages
  const fetchLanguages = async () => {
    await axios.get(config.drupal_url + '/languagelist', { withCredentials: true })
    .then(response => {
      setLanguagesData(response.data);
    })
    .catch(function (error) { })
  };

  // Validate form
  const handleValidation = (formData) => {
    let formValid = true;
    //let errorMsg = '';
    $("label").removeClass("error-class");
    $(".error-msg").remove();
    if (formData.title.trim() == "") {
      let errorMsg = t('!name field is required.').replace('!name',t('Title'))+"\n";
      $("label.title-label").addClass("error-class");
      $("#edit-title").after('<p class="error-msg" style="color:red;">'+errorMsg+'</p>');
      formValid = false;
    }
    if ((formData.title).includes(':')) {
      let errorMsg = t('!name cannot include colon (:).').replace('!name',t('Title'));
      $("label.title-label").addClass("error-class");
      $("#edit-title").after('<p class="error-msg" style="color:red;">'+errorMsg+'</p>');
      formValid = false;
    }
    if (formData.preview_image == "") {
      let errorMsg = t('!name field is required.').replace('!name',t('Preview Image'))+"\n";
      $("label.mediaimage-label").addClass("error-class");
      $("#edit-field-mediaimage-upload").parent().after('<p class="error-msg" style="color:red;">'+errorMsg+'</p>');
      formValid = false;
    }else{
      var previewImagefileSize = parseFloat(formData.preview_image.size / (1024 * 1024)).toFixed(2);

      if($.inArray(formData.preview_image.type, ['image/png','image/jpg','image/PNG','image/jpeg','image/jfif']) == -1) {
        var errorMsgStr = t("The selected file %filename cannot be uploaded. File type must be %extensions.");
        var mapObj = {"%filename":formData.preview_image.name,"%extensions":"png jpg jpeg"};
        errorMsgStr = errorMsgStr.replace(/%filename|%extensions/gi, function(matched){
          return mapObj[matched];
        });
        $("label.mediaimage-label").addClass("error-class");
        $("#edit-field-mediaimage-upload").parent().after('<p class="error-msg" style="color:red;">'+errorMsgStr+'</p>');
        formValid = false;
      }
  
      if(previewImagefileSize>50){
        var errorMsgStr = t("The selected file @filename cannot be uploaded. File size cannot exceed @maxFileSize.");
        var mapObj = {"@filename":formData.preview_image.name,"@maxFileSize":"50 MB"};
        errorMsgStr = errorMsgStr.replace(/@filename|@maxFileSize/gi, function(matched){
          return mapObj[matched];
        });
        $("label.mediaimage-label").addClass("error-class");
        $("#edit-field-mediaimage-upload").parent().after('<p class="error-msg" style="color:red;">'+errorMsgStr+'</p>');
        formValid = false;
      } 
    }
  
  
    if (formData.language == "") {
      let errorMsg =  t('!name field is required.').replace('!name',t('Language'))+"\n";
      $("label.language-label").addClass("error-class");
      $("#edit-field-language").after('<p class="error-msg" style="color:red;">'+errorMsg+'</p>');
      formValid = false;
    }

    formData.lat = $("#edit-field-location1-lat").val().trim();
    formData.lon = $("#edit-field-location1-lon").val().trim();

    if (formData.lat == "") {
      let errorMsg = t('!name field is required.').replace('!name',t('Latitude'))+"\n";
      $("label.lat-label").addClass("error-class");
      $("#edit-field-location1-lat").after('<p class="error-msg" style="color:red;">'+errorMsg+'</p>');
      formValid = false;
    }
    if(formData.lat != "" &&  !isLatitude(formData.lat)){
      let errorMsg = 'Invalid value '+t('Latitude');
      $("label.lat-label").addClass("error-class");
      $("#edit-field-location1-lat").after('<p class="error-msg" style="color:red;">'+errorMsg+'</p>');
      formValid = false;
    }

    if (formData.lon == "") {
      let errorMsg = t('!name field is required.').replace('!name',t('Longitude'))+"\n";
      $("label.long-label").addClass("error-class");
      $("#edit-field-location1-lon").after('<p class="error-msg" style="color:red;">'+errorMsg+'</p>');
      formValid = false;
    }
    if(formData.lon != "" &&  !isLongitude(formData.lon)){
      let errorMsg = 'Invalid value '+t('Longitude');
      $("label.lat-label").addClass("error-class");
      $("#edit-field-location1-lon").after('<p class="error-msg" style="color:red;">'+errorMsg+'</p>');
      formValid = false;
    }

    if (formData.metric == "") {
      let errorMsg = t('!name field is required.').replace('!name',t('Metric'))+"\n";
      $("label.metric-label").addClass("error-class");
      $("#edit-field-metric").after('<p class="error-msg" style="color:red;">'+errorMsg+'</p>');
      formValid = false;
    }
    return formValid;
  }

  function isLatitude(lat) {
    return isFinite(lat) && Math.abs(lat) <= 90;
  }
  
  function isLongitude(lng) {
    return isFinite(lng) && Math.abs(lng) <= 180;
  }

  let reader = new FileReader(); 
  const handleFileChange = event =>  {
    const imageFile = event.target.files[0];
    const imageFilname = event.target.files[0].name;    
    $('#fileName').html(imageFilname);
    setFormData({
      ...formData,
      preview_image:event.target.files[0]
    })
    if($.inArray(event.target.files[0].type,
      ['image/png','image/jpg','image/PNG','image/jpeg','image/jfif']) == -1) {
        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {
    //------------- Resize img code ----------------------------------
            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);

            var MAX_WIDTH = 960;
            var MAX_HEIGHT = 800;
            var width = img.width;
            var height = img.height;

            if (width > height) {
              if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
              }
            } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        ctx.canvas.toBlob((blob) => {
          const file = new File([blob], imageFilname, {
              type: 'image/jpeg',
              lastModified: Date.now()
          });
          setFormData({
            ...formData,
            preview_image:file
        })
        }, 'image/jpeg', 1);
      };
      //debugger
      img.src = e.target.result;
    };
    reader.readAsDataURL(imageFile);
   }
  }

  const handleWeatherFeedSubmit = (e) => {
    let validForm = handleValidation(formData);
    if (validForm) {
        setLoading(true);
        //const encodedString = Buffer.from(config.auth_username + ':' + config.auth_password).toString('base64');
        const fileSaveUrl = config.drupal_url + '/api/node/article/field_image';
        axios.get(config.drupal_url + '/session/token').then(result => {
          if (200 === result.status) {
            const csrfToken = result.data;
            axios.post(fileSaveUrl, formData.preview_image, {
              headers : {
                "Content-Type": "application/octet-stream",
                "Accept": "application/vnd.api+json",
                "Content-Disposition": `file; filename="${formData.preview_image.name}"`,
                withCredentials: true,
                'X-CSRF-Token': csrfToken,
              }
            }).then((res) => {
              formData.image_id = res.data.data.attributes.drupal_internal__fid;
              //Trim values before saving
              formData.title = formData.title.trim();
              formData.lat = formData.lat.trim();
              formData.lon = formData.lon.trim();

              // POST url
              const saveMediaUrl = config.drupal_url + '/createlivecontent?_format=json';
              axios.post(saveMediaUrl, JSON.stringify(formData), { withCredentials: true } )
              .then((result) => {
                if(result.data.success && result.data.success == 'LiveContentCreated'){
                  setLoading(false);
                  var str = t("@type %title has been created.");
                  var mapObj = {"@type":t("Live Content"),"%title":formData.title};
                  str = str.replace(/@type|%title/gi, function(matched){
                    return mapObj[matched];
                  });
                  toast.success(str);                  
                  toast.success(t('The preview image was resized to fit the maximum dimensions.')); 
                  setTimeout(() => {
                    setLoading(true);
                    window.location.assign('/live-content-management');
                  }, 5000);
                }else if(result.data.error && result.data.error == 'AlreadyExists'){                  
                  setLoading(false);
                  toast.error('Feed with same name already exists. Please use a different name.');
                  return false;
                }
              })
              .catch(function (error) {
                setLoading(false);
                toast.error(error.response);
              })
            });
          }
        });
    }
  }

  setTimeout(() => {
    $('#upload-instructions ul li').each(function () {
      var $this = $(this);
      var t = $this.html();
      var newString = t.replace("!size", "<strong>50 MB</strong>")
        .replace('!extensions', "<strong>png jpg jpeg</strong>")
        .replace('!max', '<strong>960x800</strong>');
      $this.html(newString);
    });
  }, 500);

return (
<LoadingOverlay active={loading} spinner text={t('Processing')}>
<ToastContainer />
<div className="create-live-content">
   <div className="page-heading">
      <h2>eView<sup>&reg;</sup> -  <T _str="Create live content"/></h2>
   </div>
   <div className="live-content-inner">
      <a href="/live-content-management" class="go-to"><T _str="Go to Manage Live Content"/></a>
      <div className="form-item form-item-title">
        <label className="control-label title-label" for="edit-title">
          <T _str="Title"/>  
          <span className="form-required" >*</span>
        </label>
        <input className="form-control form-text required" 
        type="text" id="edit-title" 
        name="title" defaultValue="" 
        size="60" 
        maxlength="25" 
        onChange={(e) => setFormData({...formData, title: e.target.value})} />
      </div>
      <div className="form-item form-type-select">
        <label className="control-label" for="edit-field-feed-type">
           <T _str="Feed Type"/> 
           <span className="form-required" >*</span>
        </label>
         <select className="form-control form-select required" 
                 id="edit-field-feed-type" 
                 name="field_feed_type" 
                 onChange={(e) => setFormData({...formData, feed_type: e.target.value})}>
                <option value="weather">{t('weather')}</option>
         </select>
      </div>
      <div className="form-item form-type-managed-file preview-img-btn">
          <label className="control-label mediaimage-label" 
                for="edit-field-mediaimage-upload">
                <T _str="Preview Image"/> 
                <span className="form-required" >*</span>
          </label>
          <div className="image-widget form-managed-file clearfix">
            <div className="image-widget-data">
            <button className='btn-preview' onClick={(e)=>handleClick(e)}>
            {t('Choose File')}
            </button>
			            <input className="form-control form-file" 
                  type="file" 
                  id="edit-field-mediaimage-upload" 
                  name="files[field_mediaimage]" 
                  size="22" 
                  ref={hiddenFileInput}
                  style={{display: 'none'}}
                  onChange={(e) => handleFileChange(e)}  />
               <span className="btn-preview-ip" id="fileName">{t('No file chosen')}</span>
               <div className="help-block help-block-cs">
                    <a data-tip={'custom show'} data-event={'click focus'} data-for={'tooltip'}>
                      <FontAwesome name="question-circle" size="lg" className="question-circle" />
                      <T _str="More information" />
                    </a>
                    <ReactTooltip
                      id={'tooltip'}
                      effect="solid"
                      globalEventOff='click'
                      clickable={true}
                      place="bottom"
                      getContent={function () {
                        return (
                          <div id="upload-instructions" className="popover-cs">
                            <h3 className="upload-instructions-title"><T _str="File requirements" /></h3>
                            <div className="arrow-cs"></div>
                            <ul>
                              <li>{t('File must be smaller than !size.')}</li>
                              <li>{t('Allowed file types: !extensions.')}</li>
                              <li>{t('Images must be smaller than !max pixels.')}</li>
                            </ul>
                          </div>
                        )
                      }}
                    />
                  </div>
            </div>
         </div>
      </div>
      <div class="form-item form-item-field-language form-type-select">
         <label class="control-label language-label" for="edit-field-language">
           <T _str="Language"/> 
           <span class="form-required" >*</span>
         </label>
         <select class="form-control form-select required" 
                 id="edit-field-language" 
                 name="field_language" 
                 onChange={(e) => setFormData({...formData, language: e.target.value})}>
                <option value="">- {t('Select a value')} -</option>
                {Object.keys(languagesData).map((keyName, i) => (
                  <option value={keyName}>{t(languagesData[keyName])}</option>
                ))}           
         </select>
      </div>
      <div class="form-item form-item-field-metric form-type-select">
         <label class="control-label metric-label" for="edit-field-metric">
           <T _str="Metric"/> 
           <span class="form-required" >*</span>
          </label>
         <select class="form-control form-select required" id="edit-field-metric" 
                 name="field_metric" 
                 onChange={(e) => setFormData({...formData, metric: e.target.value})}>
                <option value="">- {t('Select a value')} -</option>
                <option value="true">{t('Centigrade based display')}</option>
                <option value="false">{t('Fahrenheit based display')}</option>
         </select>
      </div>
      <fieldset class="field-set">
         <p> <a href="#edit-field-location1-body" class="panel-title fieldset-legend" data-toggle="collapse" aria-expanded="true"><T _str="Define Location" /></a></p>
         <div class="help-block">
            <p><T _str="To set a location for the map, click/drag the pin or marker of the map, or enter the latitude/longitude in the field provided."/></p>
         </div>
         <div class="form-item form-item-field-location1-lat form-type-textfield">
           <label class="control-label lat-label" for="edit-field-location1-lat">
             <T _str="Latitude" /> <span class="form-required" >*</span>
           </label>
		      <input data-lat-delta="0" 
                data-lat-field-id="1453" 
                class="bing-map-field-watch-change form-control form-text required" 
                type="text" id="edit-field-location1-lat" 
                name="field_location1[lat]" defaultValue="" size="60" maxlength="128"  
                onChange={(e) => setFormData({...formData, lat: e.target.value})} />
          </div>
         <div class="form-item form-item-field-location1-lon form-type-textfield">
           <label class="control-label long-label" for="edit-field-location1-lon">
             <T _str="Longitude" /> 
             <span class="form-required" >*</span>
            </label>
            <input data-lng-delta="0" 
              data-lng-field-id="1453" 
              class="bing-map-field-watch-change form-control form-text required" 
              type="text" id="edit-field-location1-lon" 
              name="field_location1[0][lon]" defaultValue="" size="60" maxlength="128"  
              onChange={(e) => setFormData({...formData, lon: e.target.value})} />
          </div>
      </fieldset> 
      <div className="livecontentmap">
      <T _str="Map Preview"/>
          <div id="mapDiv" className='mt-2 mb-5'></div>
      </div>
   </div>
   <div className="form-actions form-wrapper mb-2" id="edit-actions">
      <button type="submit"  onClick={(e) =>handleWeatherFeedSubmit(e)} id="edit-submit" name="op" value="Save" className="btn btn-success form-submit icon-before"><span className="icon glyphicon glyphicon-ok" aria-hidden="true"></span><T _str="Save"/></button>
      <button type="submit"  onClick={(e) =>handleCancel()}  id="edit-cancel" name="op" value="Cancel" className="btn btn-default form-submit icon-before"><span className="icon glyphicon glyphicon-remove" aria-hidden="true"></span><T _str="Cancel"/></button>
      </div>
</div>
</LoadingOverlay>
);
}
export default AddWeatherFeeds;