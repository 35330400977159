import style from "./Footer.module.css";
import { T } from "@transifex/react";

function Footer() {
  return (
    <div className={style.footer}>
      <ul>
        <li key="1">
          <a href="/eview-license">
            eView<sup>&reg;</sup> <T _str="License" />
          </a>
        </li>
        <li key="2">
          <a href="/cms-license">
            <T _str="CMS License" />
          </a>
        </li>
        <li key="3" className={style.version}>
          <T _str="Version" />: NAA31718ABA{" "}
        </li>
      </ul>
    </div>
  );
}

export default Footer;
