import React, { useEffect, useState } from "react";
import { t } from "@transifex/native";
import UserTable from "./UserTable";
import TableFooter from "./TableFooter";
import axios from "axios";
import config from "../../utils/config";

const PendingUsers = ({
  users,
  totalUsers,
  onLoadMore,
  handleOnSubmit,
  isLoading,
  onSearch,
  onResetFilter,
}) => {
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [suggestionsunitid, setSuggestionsunitid] = useState([]);
  const [suggestionscompanyname, setSuggestionscompanyname] = useState([]);
  const [role, setRole] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedUsersDetails, setSelectedUsersDetails] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [enableCountryAdmin, setEnableCountryAdmin] = useState(false);
  const [allSearchUsers, setAllSearchUsers] = useState([]);

  const userDetails = localStorage.getItem("userdetails");
  const countryid = userDetails && JSON.parse(userDetails).cid;

  useEffect(() => {
    setSelectedUsers([]);
    fetchPendingUsers();
  }, []);

  const fetchPendingUsers = async () => {
    axios
      .get(config.drupal_url + `/api/users/pending/${countryid}`, {
        withCredentials: true,
      })
      .then((response) => setAllSearchUsers([...response.data.rows]));
  };

  const onInputChange = async (e) => {
    const value = e.target.value;
    if (allSearchUsers.length === 0) await fetchPendingUsers();
    if (e.target.id == "user-email") {
      setEmail(value);
      let useremailmatches = [];
      useremailmatches = allSearchUsers.filter((device) => {
        const regex = new RegExp(`${value}`, "gi");
        return device.mail.match(regex);
      });
      setSuggestionsunitid(value.length === 0 ? [] : useremailmatches);
    } else if (e.target.id == "company-name") {
      setCompany(value);
      let companynamematches = allSearchUsers.filter((device) => {
        const regex = new RegExp(`${value}`, "gi");
        return (
          device.field_companyname && device.field_companyname.match(regex)
        );
      });
      setSuggestionscompanyname(value.length === 0 ? [] : companynamematches);
    }
  };

  const onSuggestHandleremailid = (unitid) => {
    setEmail(unitid);
    setSuggestionsunitid([]);
  };
  const onSuggestHandlerCompanyname = (unitid) => {
    setCompany(unitid);
    setSuggestionscompanyname([]);
  };

  const onRoleChange = (e) => {
    setRole(e.target.value);
  };

  const resetFields = () => {
    setEmail("");
    setCompany("");
  };

  const onAllUsersSelectionChange = (e) => {
    setSelectedUsers([]);
    setSelectedUsersDetails([]);
    setIsAllSelected(e.target.checked);
    if (e.target.checked) {
      setSelectedUsersDetails(users);
      users.map((user) => {
        selectedUsers.push(user.uid);
      });
      setSelectedUsers(selectedUsers);
    }
  };

  const onUserSelectionChange = (e) => {
    if (selectedUsers.includes(e.target.value)) {
      const arr = selectedUsers.filter((uid) => e.target.value != uid);
      const userDetails = selectedUsersDetails.filter(
        (user) => e.target.value != user.uid
      );
      setSelectedUsers(arr);
      setSelectedUsersDetails(userDetails);
      setIsAllSelected(false);
    } else {
      const arr = [...selectedUsers, e.target.value];
      setSelectedUsers(arr);
      if (arr.length === users.length) {
        setIsAllSelected(true);
      }
      const userDetails = users.find((user) => user.uid === e.target.value);
      setSelectedUsersDetails([...selectedUsersDetails, userDetails]);
    }
  };

  useEffect(() => {
    handleCountryAdminFlag();
  }, [selectedUsersDetails]);

  const handleCountryAdminFlag = () => {
    const status = selectedUsersDetails.every((user) => {
      if (
        user.mail.toLowerCase().includes("@otis.com") ||
        user.mail.toLowerCase().includes("@fr.otis.com") ||
        user.mail.toLowerCase().includes("@otiselevator.com")
      ) {
        return true;
      }
    });

    if ((!status && role === "2") || selectedUsersDetails.length === 0) {
      setRole(null);
    }
    setEnableCountryAdmin(status);
  };

  const clearEmail = () => {
    setSuggestionsunitid([]);
  };
  const clearCompany = () => {
    setSuggestionscompanyname([]);
  };

  return (
    <>
      <div className="views-exposed-widgets">
        <form
          id="pending"
          onSubmit={(e) => {
            e.preventDefault();
            onSearch(email, company);
          }}
        >
          <div className="views-exposed-widget unit-number">
            <label for="unit-number">{t("Email")} </label>
            <div className="views-widget">
              <div className="input-group">
                <input
                  className="form-control form-text"
                  type="text"
                  id="user-email"
                  name="field_eserviceunitid"
                  onChange={onInputChange}
                  value={email}
                  size="30"
                  maxlength="128"
                  onFocus={clearEmail}
                />
                <div
                  id="list-dropdown"
                  style={{
                    display: suggestionsunitid == "false" ? "none" : "block",
                  }}
                >
                  {suggestionsunitid &&
                    suggestionsunitid.map((unitidsuggestion, i) => (
                      <span
                        className="list-items"
                        key={i}
                        onClick={() =>
                          onSuggestHandleremailid(unitidsuggestion.mail)
                        }
                      >
                        {unitidsuggestion.mail}
                      </span>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="views-exposed-widget unit-number">
            <label for="unit-number"> {t("Company")} </label>
            <div className="views-widget">
              <div className="input-group">
                <input
                  className="form-control form-text"
                  type="text"
                  id="company-name"
                  name="field_companyname"
                  onChange={onInputChange}
                  value={company}
                  size="30"
                  maxlength="128"
                  onFocus={clearCompany}
                />
                <div
                  id="list-dropdown"
                  style={{
                    display:
                      suggestionscompanyname == "false" ? "none" : "block",
                  }}
                >
                  {suggestionscompanyname &&
                    suggestionscompanyname.map((unitidsuggestion, i) => (
                      <span
                        className="list-items"
                        key={i}
                        onClick={() =>
                          onSuggestHandlerCompanyname(
                            unitidsuggestion.field_companyname
                          )
                        }
                      >
                        {unitidsuggestion.field_companyname}
                      </span>
                    ))}
                </div>
              </div>
            </div>
          </div>

          <div className="views-exposed-widget views-submit-button">
            <button
              type="submit"
              id="edit-submit-devices-list"
              name=""
              value="Search"
              className="btn btn-primary form-submit"
            >
              {t("Search")}
            </button>
            <button
              type="button"
              id="edit-reset"
              name="op"
              value="Reset"
              onClick={(e) => {
                e.preventDefault();
                resetFields();
                onResetFilter();
                if (users.length !== selectedUsers) {
                  setIsAllSelected(false);
                }
              }}
              className="btn btn-default form-submit"
            >
              {t("Reset")}
            </button>
          </div>
        </form>
      </div>

      <UserTable
        users={users}
        onAllUsersSelection={onAllUsersSelectionChange}
        onUserSelection={onUserSelectionChange}
        isAllSelected={isAllSelected}
        selectedUsers={selectedUsers}
        noDataMsg={t("No pending requests")}
      />

      {users.length !== 0 && (
        <div className="assignclass  pending">
          {selectedUsers.length === 0 ? (
            <span className="assign-default-message user-default-message">
              {t("Please select at least one user to view actions")}
            </span>
          ) : (
            <div class="waiting-actions">
              <div id="edit-userrole">
                <input
                  type="radio"
                  value="1"
                  name="role"
                  id="Building-Manager"
                  onChange={onRoleChange}
                  checked={role === "1" ? true : false}
                />{" "}
                <label for="Building-Manager">{t("Building Manager")}</label>
                <input
                  disabled={!enableCountryAdmin}
                  type="radio"
                  value="2"
                  name="role"
                  id="Country-Admin"
                  onChange={onRoleChange}
                  checked={role === "2" ? true : false}
                />{" "}
                <label for="Country Admin">{t("Country Admin")}</label>
              </div>

              <div class="default-actions">
                <button
                  type="button"
                  id="Approve"
                  class="button"
                  onClick={(e) => handleOnSubmit(e, role, selectedUsers)}
                >
                  {t("Approve")}
                </button>
                <span class="or-action" id="action-selection">
                  {t("Or")}
                </span>
                <button
                  type="button"
                  id="deny"
                  class="button"
                  onClick={(e) => handleOnSubmit(e, "", selectedUsers)}
                >
                  {t("Deny")}
                </button>
              </div>
            </div>
          )}
        </div>
      )}
      {users.length !== 0 && (
        <TableFooter
          totalItems={totalUsers}
          loadedItems={users.length}
          isLoading={isLoading}
          onLoadMore={onLoadMore}
        />
      )}
    </>
  );
};

export default PendingUsers;
