/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: Profile.js
File Description: update profile functionality

Copyright 2021 - 2023 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../utils/config";
import "../css/profile.css";
import LoadingOverlay from "react-loading-overlay";
import { Alert } from "react-bootstrap";
import { t,tx } from "@transifex/native";
import { T, useT } from '@transifex/react';
import { useParams, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


const Profile = () => {
  const history = useHistory();
  const { uid } = useParams();
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [userData, setUserData] = useState();
  const [selectedCountry, setSelectedCountry] = useState();


  const INITIAL_ERROR_MSGS = {
    first_name: false,
    last_name: false,
    company_name: false,
    street_address1: false,
    position: false,
  };
  const [errorMsgs, setErrorMsgs] = useState(INITIAL_ERROR_MSGS);
   
  var mgusersData = JSON.parse(
    localStorage.getItem("mguserslist")
  );
  if (mgusersData) {
    mgusersData.editprofile = 1;
	   localStorage.setItem(
      "mguserslist",
      JSON.stringify(mgusersData)
    );
	}

  useEffect(() => {
    setLoading(true);
    const path =
      uid === "undefined" ? "/api/user/profile" : `/api/user/profile/${uid}`;
    axios
      .get(config.drupal_url + path, { withCredentials: true })
      .then((res) => {
        const userprofile = res.data.rows[0];
        setUserData(userprofile);
        setSelectedCountry(userprofile.country_language[0].id);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
    if (uid) setReadOnly(true);
    else setReadOnly(false);
  }, [uid]);

  const onHandleAlert = () => {
    setShowAlert(true);
    toast.success(t("The changes have been saved."));
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    switch (e.target.id) {
      case "first-name":
        setUserData((prevData) => ({ ...prevData, first_name: value }));
        break;
      case "last-name":
        setUserData((prevData) => ({ ...prevData, last_name: value }));
        break;
      case "company-name":
        setUserData((prevData) => ({ ...prevData, company_name: value }));
        break;
      case "street-address":
        setUserData((prevData) => ({ ...prevData, street_address: value }));
        break;
      case "street-address-1":
        setUserData((prevData) => ({ ...prevData, street_address1: value }));
        break;
      case "country":
        setSelectedCountry(value);
        break;
      case "position":
        setUserData((prevData) => ({ ...prevData, position: value }));
        break;
      default:
        break;
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const user = {
      fname: userData.first_name,
      lname: userData.last_name,
      companyname: userData.company_name,
      clang: selectedCountry,
      streetaddress: userData.street_address,
      streetadd2: userData.street_address1,
      position: userData.position,
    };
    const user_profile_data = { mainData: user };

    setErrorMsgs({
      first_name: !user.fname || user.fname == "" ? true : false,
      last_name: !user.lname || user.lname == "" ? true : false,
      company_name: !user.companyname || user.companyname == "" ? true : false,
      street_address1: !user.streetadd2 || user.streetadd2 == "" ? true : false,
      position: !user.position || user.position == "" ? true : false,
    });

    if (
      user.fname !== "" &&
      user.lname !== "" &&
      user.companyname !== "" &&
      user.streetadd2 !== "" &&
      user.position !== ""
    ) {
      setLoading(true);
      axios
        .post(config.drupal_url + "/userprofile/update", user_profile_data, {
          withCredentials: true,
        })
        .then((res) => {
          const USER_LANG = localStorage.getItem("userlang");
          setLoading(false);
          onHandleAlert();
           console.log(res.data.status);
          if(res.data.status == 'Re-register') {
            localStorage.clear();
            window.location.reload(1);
          }
          if (USER_LANG !== res.data.user_lang) {
            localStorage.setItem("userlang", res.data.user_lang);
            //tx.setCurrentLocale(res.data.user_lang);
           window.location.reload(1);

          }
        })
        .catch((err) => {
          console.log("Error while updating the user profile", err);
          setLoading(false);
        });
    }
  };

  return (
    <LoadingOverlay active={loading} spinner text={t('Processing')}>

        <ToastContainer />

      <div classNameName="create-live-content">
        <div classNameName="page-heading">
          <h2>
            eView<sup>&reg;</sup> - {uid ? userData?.mail : <T _str="Edit User" />}
          </h2>
        </div>
        <div classNameName="profile-inner">
          <form
            enctype="#"
            method="post"
            id="user-profile-form"
            accept-charset="UTF-8"
            onSubmit={handleSubmit}
          >
            <div id="edit-account" className="form-wrapper">
              <div className="form-item form-item-mail form-type-textfield form-disabled">
                <label className="control-label" for="edit-mail--2">
                   <T _str="E-mail address" />
                  <span
                    className="form-required"
                    title="This field is required."
                  >
                    *
                  </span>
                </label>
                <input
                  disabled={true}
                  className="form-control form-text required"
                  type="text"
                  id="edit-mail"
                  name="mail"
                  value={userData?.mail}
                  size="60"
                  maxlength="254"
                />
              </div>
              <div className="form-item form-type-textfield">
                <label className="control-label" for="edit-field-firstname">
                  <T _str="First Name" />
                  <span
                    className="form-required"
                    title="This field is required."
                  >
                    *
                  </span>
                </label>
                <input
                  disabled={readOnly}
                  className="text-full form-control form-text required"
                  type="text"
                  id="first-name"
                  name="fname"
                  value={userData?.first_name}
                  onChange={handleChange}
                  size="60"
                  maxlength="255"
                />
                {errorMsgs.first_name && (
                  <span style={{ color: "red" }}>
                    {t('!name field is required.').replace('!name',t('First Name'))}


                  </span>
                )}
              </div>
              <div className="form-item form-item-field-lastname form-type-textfield">
                <label className="control-label" for="edit-field-lastname">
                   <T _str="Last Name" />
                  <span
                    className="form-required"
                    title="This field is required."
                  >
                    *
                  </span>
                </label>
                <input
                  disabled={readOnly}
                  className="text-full form-control form-text required"
                  type="text"
                  id="last-name"
                  name="lname"
                  value={userData?.last_name}
                  onChange={handleChange}
                  size="60"
                  maxlength="255"
                />
                {errorMsgs.last_name && (
                  <span style={{ color: "red" }}>{t('!name field is required.').replace('!name',t('Last Name'))}</span>
                )}
              </div>
              <div className="form-item form-item-field-companyname form-type-textfield">
                {" "}
                <label className="control-label" for="edit-field-companyname">
                   <T _str="Company Name" />
                  <span
                    className="form-required"
                    title="This field is required."
                  >
                    *
                  </span>
                </label>
                <input
                  disabled={readOnly}
                  className="text-full form-control form-text required"
                  type="text"
                  id="company-name"
                  name="companyname"
                  value={userData?.company_name}
                  onChange={handleChange}
                  size="60"
                  maxlength="255"
                />
                {errorMsgs.company_name && (
                  <span style={{ color: "red" }}>{t('!name field is required.').replace('!name',t('Company Name'))}</span>
                )}
              </div>
              <div className="form-item form-item-field-streetaddress form-type-textfield">
                {" "}
                <label className="control-label" for="edit-field-streetaddress">
                    <T _str="StreetAddress" />
                </label>
                <input
                  disabled={readOnly}
                  className="text-full form-control form-text"
                  type="text"
                  id="street-address"
                  name="field_streetaddress"
                  value={userData?.street_address}
                  onChange={handleChange}
                  size="60"
                  maxlength="255"
                />
              </div>
              <div className="form-item form-item-field-street-address1 form-type-textarea">
                {" "}
                <label
                  className="control-label"
                  for="edit-field-street-address1"
                >
                   <T _str="Street Address 1" />
                  <span
                    className="form-required"
                    title="This field is required."
                  >
                    *
                  </span>
                </label>
                <div className="form-textarea-wrapper resizable textarea-processed resizable-textarea">
                  <textarea
                    disabled={readOnly}
                    className="text-full form-control form-textarea required"
                    id="street-address-1"
                    name="field_street_address1"
                    cols="60"
                    rows="5"
                    value={userData?.street_address1}
                    onChange={handleChange}
                  />
                  {errorMsgs.street_address1 && (
                    <span style={{ color: "red" }}>
                      {t('!name field is required.').replace('!name',t('Street Address 1'))}
                    </span>
                  )}
                  <div className="grippie"></div>
                </div>
                <div className="form-item form-item-field-position form-type-textfield">
                </div>
                <div className="form-item form-item-field-country-language form-type-select">
                {" "}
                  <label
                    className="control-label"
                    for="edit-field-country-language"
                  >
                       <T _str="Country/Language" />
                    <span
                      className="form-required"
                      title="This field is required."
                    >
                      *
                    </span>
                  </label>
                  <select
                    disabled={readOnly}
                    className="form-control form-select required"
                    id="country"
                    name="clang"
                    value={selectedCountry}
                    onChange={handleChange}
                  >
                    {userData?.country_language.map((country) => (
                      <option key={country.id} value={country.id}>
                       {t(country.name)}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-item form-item-field-position form-type-textfield">
                  {" "}
                  <label className="control-label" for="edit-field-position">
                      <T _str="Position" />
                    <span
                      className="form-required"
                      title="This field is required."
                    >
                      *
                    </span>
                  </label>
                  <input
                    disabled={readOnly}
                    className="text-full form-control form-text required"
                    type="text"
                    id="position"
                    name="field_position"
                    value={userData?.position}
                    onChange={handleChange}
                    size="60"
                    maxlength="255"
                  />
                  {errorMsgs.position && (
                    <span style={{ color: "red" }}>{t('!name field is required.').replace('!name',t('Position'))} </span>
                  )}
                </div>
                {readOnly ? (

                  <a href="/manage-users" className="icon btn glyphicon glyphicon-ok" aria-hidden="true"><T _str="Back" /></a>

                )  : (
                  <div className="form-actions form-wrapper" id="edit-actions">
                    <button
                      type="submit"
                      id="edit-submit"
                      name="op"
                      value={t("Save")}
                      className="btn btn-success form-submit icon-before"
                    >
                      <span
                        className="icon glyphicon glyphicon-ok"
                        aria-hidden="true"
                      ></span>{" "}
                       <T _str="Save" />
                    </button>
                    <button
                      type="button"
                      id="edit-cancel"
                      name="op"
                      value={t("Cancel")}
                      className="btn btn-default form-submit icon-before"
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      <span
                        className="icon glyphicon glyphicon-remove"
                        aria-hidden="true"
                      ></span>{" "}
                       <T _str="Cancel" />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </LoadingOverlay>
  );
};
export default Profile;
