/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: AddScrens.js
File Description: Implements schedule playlist to device functionality

Copyright 2021 - 2024 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { Carousel } from 'react-responsive-carousel';
import config from '../utils/config';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { T, useT } from '@transifex/react';
import { t } from "@transifex/native"
import LoadingOverlay from "react-loading-overlay";
import noimage from './../Assets/no-image.jpg';
import { dateformat } from '../utils/dateFormat';

function AddScrens(props) {
  const tabTitle = useT("Add Screens");
  const [loading, setLoading] = useState(false);
  const [screensData, setScreensData] = useState([]);
  const [checkedGroups, setcheckedGroups] = useState([]);
  const [checkedGroupsData, setCheckedGroupsData] = useState([]);
  const [noScreens, setNoScreens] = useState(false);
  const history = useHistory();

  var userdetails = JSON.parse(localStorage.getItem('userdetails'));
  var schedulePlaylistData = JSON.parse(localStorage.getItem('schedulePlaylist'));

  // Browser Tab Title
  useEffect(() => {
    document.title = tabTitle + ' | CMS';
  }, [])

  useEffect(() => {
    const fetchScreens = async () => {
      setLoading(true);
      await axios.get(config.drupal_url + '/api/views/add-screens/' + userdetails.id)
        .then(response => {
          const devicesData = response.data;
          // Keep screens of only seleted type
          devicesData.forEach((group, index) => {
            const screenTypeFiltered = [];
            group.screens.forEach((screen, index) => {
              let PlaylistType = '';
              if ((schedulePlaylistData.PlaylistType.toString() === 'Banner Layout') ||
                (schedulePlaylistData.PlaylistType.toString() === 'eView2')) {
                PlaylistType = 'eView2';
              } else if (schedulePlaylistData.PlaylistType.toString() === 'Fullcontent'){
                PlaylistType = 'Fullcontent';
              } else {
                PlaylistType = 'Infotainment';
              }
              if (screen.device_type === PlaylistType) {
                screenTypeFiltered.push(screen);
              }
            });
            group.screens = screenTypeFiltered;
          });
          // remove the groups with no screens
          const screenDataFiltered = [];
          devicesData.forEach((group, index) => {
            if (group.screens.length !== 0) {
              screenDataFiltered.push(group);
            }
          });
          setScreensData(screenDataFiltered);
          setLoading(false);
        })
        .catch(function (error) { })
    };
    fetchScreens();
    // Remove the already selected screens if user comes back to this page
    schedulePlaylistData.screenIds = [];
    localStorage.setItem('schedulePlaylist', JSON.stringify(schedulePlaylistData));
  }, []);

  // Handle group selection
  const handleCheckedGroups = (clickedgroupId, event) => {
    // Handle group checkbox
    const groupsChecked = [...checkedGroups];
    if (groupsChecked.includes(clickedgroupId)) {
      var index = groupsChecked.indexOf(clickedgroupId);
      groupsChecked.splice(index, 1);
    } else {
      groupsChecked.push(clickedgroupId);
    }
    setcheckedGroups(groupsChecked);
    // Add/Remove group and screens id
    const checkedboxesData = [...checkedGroupsData];
    if (event.target.checked) {
      var checkedScreensGroups = [];
      checkedboxesData.forEach((group, index) => {
        checkedScreensGroups.push(group.groupid);
      });
      const innerScreens = [];
      const cpIDs = [];
      screensData.forEach((group, index) => {
        if (group.groupid == clickedgroupId) {
          // Remove aready selected screens and add all the screens in the group
          if (checkedScreensGroups.includes(clickedgroupId)) {
            var groupindex = checkedboxesData.indexOf(clickedgroupId);
            checkedboxesData.splice(groupindex, 1);
          }
          group.screens.forEach((screen, index) => {
            innerScreens.push(screen.screen_id);
            cpIDs.push(screen.cpid);
          });
        }
      });
      checkedboxesData.push({
        groupid: clickedgroupId,
        screens: innerScreens,
        cpIDs: cpIDs,
      });
    } else {
      checkedboxesData.forEach((group, index) => {
        if (group.groupid === clickedgroupId) {
          checkedboxesData.splice(index, 1);
        }
      });
    }
    setCheckedGroupsData(checkedboxesData);
    return true;

  };

  // Handle screen selection
  const handleCheckedScreens = (
    clickedScreenId,
    clickedgroupId,
    event
  ) => {
    const groupId = clickedgroupId.toString();
    // Add/Remove group and screens id
    const checkedboxesData = [...checkedGroupsData];
    var checkedScreensGroups = [];
    checkedboxesData.forEach((group, index) => {
      checkedScreensGroups.push(group.groupid);
    });
    if (event.target.checked === true) {
      if (checkedScreensGroups.includes(groupId)) {
        checkedboxesData.forEach((group, index) => {
          if (group.groupid === groupId) {
            const checkedGroupScreens = [...group.screens];
            checkedGroupScreens.push(clickedScreenId);
            group.screens = checkedGroupScreens;
          }
        });
      } else {
        const innerScreens = [];
        innerScreens.push(clickedScreenId);
        checkedboxesData.push({
          groupid: groupId,
          screens: innerScreens,
        });
      }
    } else {
      checkedboxesData.forEach((group, index) => {
        if (group.groupid === parseInt(groupId)) {
          const checkedGroupScreens = [...group.screens];
          if (checkedGroupScreens.length > 1) {
            var position = checkedGroupScreens.indexOf(clickedScreenId);
            checkedGroupScreens.splice(position, 1);
            group.screens = checkedGroupScreens;
          } else {
            checkedboxesData.splice(index, 1);
          }
        }
      });
    }
    setCheckedGroupsData(checkedboxesData);
    return null;
  };

  const SchedulePlaylist = () => {
    const checkedScreensData = [...checkedGroupsData];
    const checkedGroupScreens = [];
    checkedScreensData.forEach((group, index) => {
      group.screens.forEach((screens, index) => {
        if (!checkedGroupScreens.includes(screens)) {
          checkedGroupScreens.push(screens);
        }
      })
    })
    if (checkedGroupScreens.length == 0) {
      toast.error(t('Please select at least one screen.'));
    } else {
      schedulePlaylistData.screenIds = checkedGroupScreens;
      schedulePlaylistData.ScheduleType = 'forever';
      schedulePlaylistData.ScheduleTypeData = '';
      schedulePlaylistData.Days = [];
      localStorage.setItem('schedulePlaylist', JSON.stringify(schedulePlaylistData));
      history.push('schedule');
    }
  }

  return (
    <div>
      <ToastContainer />
      <LoadingOverlay active={loading} spinner text={t('Processing')}>
        {/* Header Section */}
        <div className="headerSection add-screens-header">
          <h1 className="pageTitle">
            <T _str="Assign Screens to Playlist" />
          </h1>
          <div className="head-action-button">
            <button
              onClick={SchedulePlaylist}
              className="btn btn-success form-submit">
              <T _str="Next" />: <T _str="Schedule Playlist" />
            </button>
          </div>
          <div className="subtitle">
            <T _str="Playlist will be added to selected screens" /><br />
            <T _str="Select screens and click Next to schedule playlists" />
          </div>
        </div>
        {(loading === false && screensData.length === 0) && (
          <div className='no-data'><T _str="No Screens Found" /></div>
        )}
        {/* Select Playlist Section */}
        {screensData.length !== 0 && (
          <div className='add-screens group-list-main'>
            <Carousel>
              {screensData.map((group, index) => {
                return (
                  <div>
                    <h3 className="group-name">
                      <span class="groups-label"><T _str="Groups" /></span>
                      {group.grouptitle}</h3>
                    <div class="group-inner add-screens-page">
                      <div className="group-header">
                        <input type="checkbox"
                          id={group.groupid}
                          checked={checkedGroups.includes(group.groupid) ? 1 : 0}
                          onChange={(event) => [handleCheckedGroups(group.groupid, event)]}
                        />
                        <T _str="Select All" />
                      </div>
                      <div className='group-wrapper'>
                        {group.screens.map((screen, index) => {
                          var updatedOn = dateformat(screen.updated, 'addScreens');
                          const themeData = screen.device_theme_blob;
                          //const themearray = themeData ? themeData.split("\"") : "";
                          const themeURL = themeData.replace(/&amp;/g, '&');
                          const screenId = screen.screen_id.toString();
                          localStorage.setItem('boxCreated', 'false');
                          return (
                            <div className='group-wrapper-here'>
                              <ul className="group-list">
                                <li key={index}>
                                  <article className='group-list-numbe'>
                                    <div className='group-list-img'>
                                      {themeURL ? (
                                        <img typeof='foaf:Image' className='img-responsive' src={themeURL} width='100' height='56' alt='' />
                                      ) : (
                                        <img src={noimage} alt="No Theme" className='img-responsive' width='100' height='56' />
                                      )}
                                    </div>
                                    <div className='screen-data'>
                                      <div className='screen-data-checkbox'>
                                        {checkedGroupsData.map((checks, index) => {
                                          if (checks.groupid === group.groupid) {
                                            var selectedGroupsScreens = checks.screens;
                                            localStorage.setItem('boxCreated', 'true');
                                            return (
                                              <input type="checkbox"
                                                id={screen.screen_id}
                                                checked={selectedGroupsScreens.includes(screenId) ? 1 : 0}
                                                onChange={(event) => [handleCheckedScreens(screen.screen_id, group.groupid, event)]}
                                              />
                                            )
                                          }
                                        })}
                                        {localStorage.getItem('boxCreated') === 'false' && (
                                          <input type="checkbox"
                                            id={screen.screen_id}
                                            onChange={(event) => [handleCheckedScreens(screen.screen_id, group.groupid, event)]}
                                          />
                                        )}
                                      </div>
                                      <div className='screen-data-info'>
                                        <div className='group-list-title'>{screen.screen_title}</div>
                                        <div className='group-list-info'>
                                          <div className='group-list-info-label'>CP-ID: </div>
                                          <div className='group-list-info-field-items'>{screen.cpid}</div>
                                          {screen.deck_type === config.deck_upper && (
                                            <>
                                              <div className='group-list-info-label'>Deck-type: </div>
                                              <div className='group-list-info-field-items'>{t('Upper Deck')}</div>
                                              </>
                                           )}
                                           {screen.deck_type === config.deck_lower && (
                                            <>
                                              <div className='group-list-info-label'>Deck-type: </div>
                                              <div className='group-list-info-field-items'>{t('Lower Deck')}</div>
                                              </>
                                           )}
                                          <div className='group-list-info-label'><T _str="Updated" />: </div>
                                          <div className='group-list-info-field-items'>{updatedOn}</div>
                                        </div>
                                      </div>
                                    </div>
                                  </article>
                                </li>
                              </ul>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                )
              })}
            </Carousel>
          </div >
        )
        }
      </LoadingOverlay>
    </div >
  );
}

export default AddScrens;
