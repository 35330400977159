/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: UnitReport.js
File Description: Export the units and their schedules to excel

Copyright 2023,2024 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useState, useEffect } from 'react';
import config from '../utils/config';
import "../css/units-report.css";
import axios from "axios";
import { T, useT } from '@transifex/react';
import { t } from "@transifex/native"
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import LoadingOverlay from "react-loading-overlay";
import { useLocation } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import AccessDenied from "../Components/Common/AccessDenied";
import { CSVLink } from "react-csv";
import excel from './../Assets/excel-icon.png';

function UnitsReport(props) {
  const perPage = 5;
  const tabTitle = useT("Units Report");
  useEffect(() => {
    document.title = tabTitle + ' | CMS';
  }, [tabTitle]);
  const [totalItems, settotalItems] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItemsun, settotalItemsun] = useState([]);
  const [grandtotal, setgrandtotal] = useState([]);
  const [page, setPage] = useState(0);
  const [searchpage, setSearchPage] = useState(0);
  const [searchload, setsearchload] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [unitid, setUnitid] = useState([]);
  const [cpid, setCpid] = useState([]);
  const [city, setCity] = useState([]);
  const [useremail, setUseremail] = useState([]);
  const [userid, setUserid] = useState([]);
  const [devicedata, setDevicedata] = useState([]);
  const [searchdata, setSearchdata] = useState([]);
  const [devicedataall, setDevicedataall] = useState([]);
  const [scheduleaall, setScheduleaall] = useState([]);
  const [deviesemail, setdevicesemail] = useState([]);
  const [exportdata, setExportdata] = useState(0);
  const location = useLocation();

  const [suggestionsunitid, setSuggestionsunitid] = useState([]);
  const [suggestionscpid, setSuggestionscpid] = useState([]);
  const [suggestionscity, setSuggestionscity] = useState([]);
  const [suggestionsemail, setSuggestionsemail] = useState([]);
  const [unitIdsChecked, setUnitIdsChecked] = useState([]);
  const [toggleState, setToggleState] = useState(1);
  const [allChecked, setAllChecked] = useState(false);
  const [sort, SetSort] = useState('changed');
  const [order, SetOrder] = useState('DESC');
  const [tabIndex, setTabIndex] = useState(0);
  const [exportfull, setExportfull] = useState(0);
  const [fulldata, setFulldata] = useState(0);
  const [currentcountrydefault, setCurrentCountryDefault] = useState([]);
  const [globaldefault, setGlobaldefault] = useState([]);



  var token = localStorage.getItem("userdetails")
  var countryid = token && JSON.parse(token).cid
  let userrole = 'building_manager';
  if (token !== null) {
    var userdetails = JSON.parse(localStorage.getItem('userdetails'));
    userdetails.roles.forEach(role => {
      if (role === "country_admin") {
        userrole = "country_admin";
      }
      if (role === "super_admin") {
        userrole = "super_admin";
      }
    });
  }


  const toggleTab = (index) => {
    setToggleState(index)
  }

  const deviceselectedTab = (index) => {
    setTabIndex(index);
    const devicelistData = {
      selectedtab: index,
    };
    localStorage.setItem(
      "devicelist",
      JSON.stringify(devicelistData)
    );
  }

  const regExpEscape = (literal_string) => {
    return literal_string.replace(/[-[\]{}()*+!<=:?.\/\\^$|#\s,]/g, '\\$&');
  }
  useEffect(() => {
    setLoading(true);
    if (location.device_title !== undefined && location.device_title !== "") {
      toast.success(t("@type %title has been created.").replace('@type', t('Device')).replace('%title', location.device_title));
    }

    var devicetabid = JSON.parse(
      localStorage.getItem("devicelist")
    );
    if (devicetabid !== null) {
      let editdevice = JSON.parse(localStorage.getItem('devicelist')).editdevice ? JSON.parse(localStorage.getItem('devicelist')).editdevice : 0;
      if (editdevice) {
        var deviceselectedtab = JSON.parse(localStorage.getItem('devicelist')).selectedtab ? JSON.parse(localStorage.getItem('devicelist')).selectedtab : 0;
        setTabIndex(deviceselectedtab);
        devicetabid.selectedtab = deviceselectedtab;
        devicetabid.editdevice = JSON.parse(localStorage.getItem('devicelist')).editdevice;
        localStorage.setItem(
          "devicelist",
          JSON.stringify(devicetabid)
        );
      }

    }

    const fetchDevices = async () => { 
      if (perPage != 5) {
        axios.get(config.drupal_url + `/api/unitsreport?field_eserviceunitid_value=${unitid}&field_mpdid_value=${cpid}&field_unit_city_value=${city}&field_country_nid_target_id=${countryid}&sort_by=${sort}&sort_order=${order}&field_assign_user_target_id[value]=${userid}`, { withCredentials: true })
          .then((reponse) => {
            setDevicedata([...devicedata, ...reponse.data.rows]);
            setTotalPages(reponse.data.pager.total_pages);
            settotalItems(reponse.data.pager.total_items);
            setgrandtotal(reponse.data.pager.total_items);
            setLoading(false);
          })
          .catch(function (error) {
            console.error('There was an error!', error);
			      setLoading(false);
          })
      } else {
        if (searchpage === 0) {
          if (searchload === 0) {
            axios.get(config.drupal_url + `/api/unitsreport?field_country_nid_target_id=${countryid}&field_mpdid_value=${cpid}&sort_by=${sort}&sort_order=${order}&field_assign_user_target_id[value]=${userid}&items_per_page=${perPage}&page=${page}`, { withCredentials: true })
              .then((reponse) => {
                setDevicedata([...devicedata, ...reponse.data.rows]);
                setTotalPages(reponse.data.pager.total_pages);
                setgrandtotal(reponse.data.pager.total_items);
                settotalItems(reponse.data.pager.total_items);
                setLoading(false);
              })
              .catch(function (error) {
                console.error('There was an error!', error);
				setLoading(false);
              })
          } else {
            if (userid > 0) {
              axios.get(config.drupal_url + `/api/unitsreport?field_assign_user_target_id[value]=${userid}&field_mpdid_value=${cpid}&field_eserviceunitid_value=${unitid}&field_unit_city_value=${city}&field_country_nid_target_id=${countryid}&sort_by=${sort}&sort_order=${order}&items_per_page=${perPage}&page=${page}`, { withCredentials: true })
                .then((reponse) => {
                  setDevicedata([...devicedata, ...reponse.data.rows]);
                  setTotalPages(reponse.data.pager.total_pages);
                  settotalItems(reponse.data.pager.total_items);
                  setLoading(false);
                })
                .catch(function (error) {
                  console.error('There was an error!', error);
				  setLoading(false);
                })
            } else {
              axios.get(config.drupal_url + `/api/unitsreport?field_eserviceunitid_value=${unitid}&field_mpdid_value=${cpid}&field_unit_city_value=${city}&field_country_nid_target_id=${countryid}&sort_by=${sort}&sort_order=${order}&field_assign_user_target_id[value]=${userid}&items_per_page=${perPage}&page=${page}`, { withCredentials: true })
                .then((reponse) => {
                  setDevicedata([...devicedata, ...reponse.data.rows]);
                  setTotalPages(reponse.data.pager.total_pages);
                  settotalItems(reponse.data.pager.total_items);
                  setLoading(false);
                })
                .catch(function (error) {
                  console.error('There was an error!', error);
				  setLoading(false);
                })
            }
          }
        }
      }
    }
    fetchDevices();

  }, [page]);



  useEffect(() => {
    const fetchDeviceseall = async () => {
      axios.get(config.drupal_url + `/api/fullunitreports?field_country_nid_target_id=${countryid}&sort_by=${sort}&sort_order=${order}&field_assign_user_target_id[value]=${userid}`, { withCredentials: true })
        .then((response) => {
          setDevicedataall(response.data.rows);
     
        })
     axios.get(config.drupal_url + `/api/fullschedulesreports/${countryid}`, { withCredentials: true })
        .then((response) => {
          setScheduleaall(response.data.rows);
        })
    }
    fetchDeviceseall();


  }, []);
  

    const device_schedule = devicedataall.map((deviceall) => {
      const match = scheduleaall.filter(scheduleall => scheduleall.field_screen_id === deviceall.nid);
      return match? { ...deviceall, scdata:match}:deviceall;
        
    });
  


  useEffect(() => {
    const fetchCurrentCountryDefault = async () => {
      axios.get(config.drupal_url + `/api/countrydevices?country_hash=${userdetails.country_hash}`, { withCredentials: true })
        .then((reponse) => {
           let defaultcurrent = reponse.data;
          setCurrentCountryDefault([...currentcountrydefault, ...reponse.data]);
          //if(defaultcurrent.length > 0){
            
            const excludedevice = devicedata.filter(
            (row) => !defaultcurrent.some((result) => row.field_mpdid === result.DeviceID)
            );
           
           const exclude_device = excludedevice.map((newroow) => {return newroow.field_mpdid;})
           let exclude_devices = exclude_device.toString();
            
            if(excludedevice.length > 0){
              axios.get(config.drupal_url + `/api/globaldevices?global_default=${exclude_devices}`, { withCredentials: true })
                .then((reponse) => {
                  console.log(reponse);
                  setGlobaldefault([...globaldefault, ...reponse.data]);
                  setLoading(false);
    
                })
            
         }
        //}
           setLoading(false);
      
        
        })
    }
    fetchCurrentCountryDefault();
  }, [devicedata]);





  const onChangeHandlerunitid = (unitid) => {
    setUnitid(unitid);
    let eserviceunitidmatches = []
    if (unitid) {
      const regex = new RegExp(`${unitid}`, 'i');
      eserviceunitidmatches = devicedataall.filter(devicedataall => regex.test(devicedataall.field_eserviceunitid));
    }
    const unique = [...new Set(eserviceunitidmatches.map(item => item.field_eserviceunitid))];
    setSuggestionsunitid(unique)
  }

  const onChangeHandlercpid = (cpid) => {
    setCpid(cpid);
    let cpidmatches = []
    if (cpid) {
      const regex = new RegExp(`${cpid}`, 'i');
      cpidmatches = devicedataall.filter(devicedataall => regex.test(devicedataall.field_mpdid));
    }
    const unique = [...new Set(cpidmatches.map(item => item.field_mpdid))];
    setSuggestionscpid(unique)
  }

  const onChangeHandlercity = (city) => {
    setCity(city)
    let citymatches = []
    if (city) {
      const regex = new RegExp(`${city}`, 'i');
      citymatches = devicedataall.filter(devicedataall => regex.test(devicedataall.field_unit_city));
    }
    const unique = [...new Set(citymatches.map(item => item.field_unit_city))];
    const trimmedCities = [];
    // Trim extra spaces in and around city names
    unique.forEach((cityname, index) => {
      const trimmed = cityname.replace(/\s{2,}/g, ' ').trim();
      trimmedCities.push(trimmed);
    });
    // remove duplicates
    const uniqueTrimmedCities = [...new Set(trimmedCities)];
    setSuggestionscity(uniqueTrimmedCities);
  }

  const handleSortChange = (sortvalue) => {
    SetSort(sortvalue)

  }
  const handleOrderChange = (ordervalue) => {
    SetOrder(ordervalue)

  }

  const search = (e) => {
    e.preventDefault();
    const devicefilter = async () => {
      setLoading(true);
	    setExportfull(1);
      if (userid > 0) {
        setSearchPage(searchpage + 1);
        setsearchload(searchload + 1)
        setPage(0);
        axios.get(config.drupal_url + `/api/unitsreport?field_assign_user_target_id_op==&field_assign_user_target_id[value]=${userid}&field_eserviceunitid_value=${unitid}&field_mpdid_value=${cpid}&field_unit_city_value=${city}&field_country_nid_target_id=${countryid}&sort_by=${sort}&sort_order=${order}`, { withCredentials: true })
          .then((reponse) => {
            setSearchdata(reponse.data.rows);
            setLoading(false);
          })
          .catch(function (error) {
            console.error('There was an error!', error);
          })
      } else if (document.getElementById("edit-mail").value != "" && userid == "") {
        setSearchdata([]);

      } else {
        setSearchPage(searchpage + 1);
        setsearchload(searchload + 1)
        setPage(0);
        axios.get(config.drupal_url + `/api/unitsreport?field_eserviceunitid_value=${unitid}&field_mpdid_value=${cpid}&field_unit_city_value=${city}&field_country_nid_target_id=${countryid}&sort_by=${sort}&sort_order=${order}`, { withCredentials: true })
          .then((reponse) => {
            setSearchdata(reponse.data.rows);
            setLoading(false);
          })
          .catch(function (error) {
            console.error('There was an error!', error);
          })

      }
      if (userid > 0) {
        setSearchPage(searchpage + 1);
        setsearchload(searchload + 1)
        setPage(0);
        axios.get(config.drupal_url + `/api/unitsreport?field_assign_user_target_id_op==&field_assign_user_target_id[value]=${userid}&field_eserviceunitid_value=${unitid}&field_mpdid_value=${cpid}&field_unit_city_value=${city}&field_country_nid_target_id=${countryid}&sort_by=${sort}&sort_order=${order}&items_per_page=${perPage}&page=0`, { withCredentials: true })
          .then((reponse) => {
            setDevicedata(reponse.data.rows);
            setTotalPages(reponse.data.pager.total_pages);
            settotalItems(reponse.data.pager.total_items);
            setLoading(false);
          })
          .catch(function (error) {
            console.error('There was an error!', error);
          })
      } else if (document.getElementById("edit-mail").value != "" && userid == "") {
        setDevicedata([]);

      } else {
        setSearchPage(searchpage + 1);
        setsearchload(searchload + 1)
        setPage(0);
        axios.get(config.drupal_url + `/api/unitsreport?field_eserviceunitid_value=${unitid}&field_mpdid_value=${cpid}&field_unit_city_value=${city}&field_country_nid_target_id=${countryid}&sort_by=${sort}&sort_order=${order}&items_per_page=${perPage}&page=0`, { withCredentials: true })
          .then((reponse) => {
            setDevicedata(reponse.data.rows);
            setTotalPages(reponse.data.pager.total_pages);
            settotalItems(reponse.data.pager.total_items);
            setLoading(false);
          })
          .catch(function (error) {
            console.error('There was an error!', error);
          })

      }

    }

    devicefilter();

  }
  const refreshPage = async () => {
    window.location.reload(false)
  }




  const handleCheckedunitid = (unitid, event) => {
    const unitChecked = [...unitIdsChecked];
    if (unitChecked.includes(event.target.id)) {
      var index = unitChecked.indexOf(event.target.id);
      unitChecked.splice(index, 1);
    } else {
      unitChecked.push(event.target.id);
    }
    setUnitIdsChecked(unitChecked);

  };

  const handleAllCheck = e => {
    if (allChecked) {
      setAllChecked(false);
      return setUnitIdsChecked([]);
    }
    setAllChecked(true);
    return setUnitIdsChecked(devicedata.map(row => row.nid));
  };



  useEffect(() => {
    const fetchDevicesemail = async () => {
      axios.get(config.drupal_url + '/Userslist/' + countryid, { withCredentials: true })
        .then(response => {
          setdevicesemail(response.data)
        })
    }
    fetchDevicesemail();
  }, []);

  const onChangeHandleremail = (useremail) => {
    setUseremail(useremail)
    let emailmatches = []
    if (useremail) {
      const regex = new RegExp(`${regExpEscape(useremail)}`, 'gi');
      emailmatches = deviesemail.filter(deviesemail => regex.test(deviesemail.mail) || regex.test(deviesemail.uid));
    }
    setUserid([])
    setSuggestionsemail(emailmatches)
  }

  const onSuggestHandlerunitid = (unitid) => {
    setUnitid(unitid)
    setSuggestionsunitid([false])
  }
  const onSuggestHandlercpid = (cpid) => {
    setCpid(cpid)
    setSuggestionscpid([false])
  }

  const onSuggestHandlercity = (city) => {
    setCity(city)
    setSuggestionscity([false]);
  }
  const onSuggestHandleremail = (useremail, uid) => {
    setUseremail(useremail)
    setUserid(uid)
    setSuggestionsemail([false]);
  }

  const buttonclicked = (val) => {
    setLoading(val);
  }

  const clearUnitid = (event) => {
    setSuggestionscity([false])
    setSuggestionsemail([false])
    setSuggestionscpid([false])
  }
  const clearCpid = (event) => {
    setSuggestionsunitid([false])
    setSuggestionscity([false])
    setSuggestionsemail([false])
  }
  const clearCity = (event) => {
    setSuggestionsunitid([false])
    setSuggestionsemail([false])
    setSuggestionscpid([false])
  }
  const clearEmail = (event) => {
    setSuggestionsunitid([false])
    setSuggestionscity([false])
    setSuggestionscpid([false])
  }

  if (userrole === "building_manager") {
    return (
      <AccessDenied title="Access Denied" />
    )
  }
  
  const getplaylistvalue = (row)=>{
     
     if (row.view.rows.length == 0) {
	 if (currentcountrydefault.length > 0 && currentcountrydefault.findIndex(item => item.DeviceID === row.field_mpdid) >= 0) {
        return "Country Default";
        
	 }else if ((globaldefault.length > 0 && globaldefault.findIndex(glddefaults => glddefaults.DeviceID === row.field_mpdid && glddefaults.Hash === null) >= 0) || (globaldefault.length > 0 && globaldefault.findIndex(glddefaults => glddefaults.DeviceID === row.field_mpdid && glddefaults.Hash === config.global_default_hash) >= 0)) {
		return "Global Default";
	 }else if (globaldefault.length > 0 && globaldefault.findIndex(glddefaults => glddefaults.DeviceID === row.field_mpdid && glddefaults.Hash !== userdetails.country_hash) >= 0) {
		return "Global Default / Old Country Default";
	 }
  }
   else {
		return row.view.rows.map(subrow => subrow.title);
	 }
                        					
}

const getplaylistvalueexcel = (row)=>{
  if (row.scdata.length == 0) {
    if (currentcountrydefault.length > 0 && currentcountrydefault.findIndex(item => item.DeviceID === row.field_mpdid) >= 0) {
          return "Country Default";
          
    }else if ((globaldefault.length > 0 && globaldefault.findIndex(glddefaults => glddefaults.DeviceID === row.field_mpdid && glddefaults.Hash === null) >= 0) || (globaldefault.length > 0 && globaldefault.findIndex(glddefaults => glddefaults.DeviceID === row.field_mpdid && glddefaults.Hash === config.global_default_hash) >= 0)) {
      return "Global Default";
    }else if (globaldefault.length > 0 && globaldefault.findIndex(glddefaults => glddefaults.DeviceID === row.field_mpdid && glddefaults.Hash !== userdetails.country_hash) >= 0) {
      return "Global Default / Old Country Default";
    }
  }
  else {
    return row.scdata.map(subrow => subrow.title);
  }
                      
}
 const headers = [
  { label: "Unit Number", key: "field_eserviceunitid"},
  { label: "CP-ID", key: "field_mpdid"},
  { label: "Device Type", key: "field_cpibdevicetype"},
  { label: "Commission Status", key: "field_commissionstatusid"},
  { label: "Device Status", key: "field_device_status"},
  { label: "E-mail", key: "field_assign_user"},
  { label: "Building ID", key: "field_buildingid"},
  { label: "Building Name", key: "field_building_names"},
  { label: "Street Address", key: "field_unit_streetaddress"},
  { label: "City", key: "field_unit_city"},
  { label: "Country", key: "field_country_nid"},
  { label: "Contract ID", key: "field_contractid"},
  { label: "ContractDate", key: "field_contractdate"},
  { label: "ContractType", key: "field_contracttype"},
  { label: "CustomerID", key: "field_customerid"},
  { label: "CustomerName", key: "field_customername"},
  { label: "Playlist Name", key: "title"},
  { label: "Schedule Date", key: "field_schedule_date"},
  { label: "Content Download", key: "field_transmit"},
  { label: "Forever", key: "field_forever"},
  { label: "Recurrence", key: "field_recurrence"},
  { label: "Week Days", key: "field_weekdays"}

];


	const device_data = searchdata.map(row => ({
			  field_eserviceunitid: row.field_eserviceunitid,
			  field_mpdid: row.field_mpdid,
			  field_cpibdevicetype: row.field_cpibdevicetype,
			  field_commissionstatusid: row.field_commissionstatusid,
			  field_device_status: row.field_device_status,
			  field_assign_user: row.field_assign_user,
			  field_buildingid: row.field_buildingid,
			  field_building_names: row.field_building_names,
			  field_unit_streetaddress: row.field_unit_streetaddress,
			  field_unit_city: row.field_unit_city,
			  field_country_nid: row.field_country_nid,
			  field_contractid: row.field_contractid,
			  field_contractdate: row.field_contractdate,
			  field_contracttype: row.field_contracttype,
			  field_customerid: row.field_customerid,
			  field_customername: row.field_customername,
			  title: getplaylistvalue(row),
			  field_schedule_date: row.view.rows.map(subrow => subrow.field_schedule_date),
			  field_transmit: row.view.rows.map(subrow => subrow.field_transmit),
			  field_forever: row.view.rows.map(subrow => subrow.field_forever),
			  field_recurrence: row.view.rows.map(subrow => subrow.field_recurrence),
		    field_weekdays: row.view.rows.map(subrow => subrow.field_weekdays),
			}))

 	 const alldevice = device_schedule.map(row => ({
		  field_eserviceunitid: row.field_eserviceunitid,
		  field_mpdid: row.field_mpdid,
		  field_cpibdevicetype: row.field_cpibdevicetype,
		  field_commissionstatusid: row.field_commissionstatusid,
		  field_device_status: row.field_device_status,
		  field_assign_user: row.field_assign_user,
		  field_buildingid: row.field_buildingid,
		  field_building_names: row.field_building_names,
		  field_unit_streetaddress: row.field_unit_streetaddress,
		  field_unit_city: row.field_unit_city,
		  field_country_nid: row.field_country_nid,
		  field_contractid: row.field_contractid,
		  field_contractdate: row.field_contractdate,
		  field_contracttype: row.field_contracttype,
		  field_customerid: row.field_customerid,
		  field_customername: row.field_customername,
      title: getplaylistvalueexcel(row),
	    field_schedule_date:row.scdata.map(subrow => subrow.field_schedule_date) ,
		  field_transmit:row.scdata.map(subrow => subrow.field_transmit) ,
		  field_forever:row.scdata.map(subrow => subrow.field_forever),
		  field_recurrence:row.scdata.map(subrow => subrow.field_recurrence) ,
		  field_weekdays: row.scdata.map(subrow => subrow.field_weekdays),
		}))
   
  return (
    <>
     <div className="container">
    </div>

      <div className="groups-heading-container Devicelist-heading">
        <ToastContainer />
        <h2>eView<sup>&reg;</sup> - <T _str="Units Report" /></h2>
      </div>
      <LoadingOverlay active={loading} spinner text={t('Processing')}>
       <LoadingOverlay active={loading1} spinner text={t('Processing')}>
        <div className="units-report">
          <div className="views-exposed-widgets">
            <form onSubmit={search} autoComplete="new-password"> 
              <div className="views-exposed-widget unit-number">
                <label htmlFor="unit-number"><T _str="Unit Number" /></label>
                <div className="views-widget">
                  <div className="input-group">
                    <input className="form-control form-text" type="text" id="unit-number" onFocus={(event) => clearUnitid(event)} name="field_eserviceunitid" onChange={(e) => onChangeHandlerunitid(e.target.value)} value={unitid} size="30" maxlength="128"  autoComplete='new-password'/>
                  </div>
                </div>
              </div>
              <div className="views-exposed-widget cpid">
                <label htmlFor="cpid"><T _str="CP-ID" /></label>
                <div className="views-widget">
                  <div className="input-group">
                    <input className="form-control form-text" type="text" id="cpid" onFocus={(event) => clearCpid(event)} name="field_mpdid" onChange={(e) => onChangeHandlercpid(e.target.value)} value={cpid} size="30" maxlength="128" autoComplete='new-password' />
                  </div>
                </div>
              </div>
              <div className="views-exposed-widget city">
                <label htmlFor="city"><T _str="City" /></label>
                <div className="views-widget">
                  <div className="input-group">
                    <input className="form-control form-text" type="text" id="city" onFocus={(event) => clearCity(event)} name="field_unit_city" onChange={(e) => onChangeHandlercity(e.target.value)} value={city} size="30" maxlength="128" autoComplete="new-password" aria-autocomplete="list" />
                  </div>
                </div>
              </div>
              <div className="views-exposed-widget email">
                <label htmlFor="email"><T _str="E-mail" /></label>
                <div className="views-widget">
                  <div className="input-group">
                    <input className="form-control form-text" type="text" id="edit-mail" onFocus={(event) => clearEmail(event)} name="field_assign_user" onChange={(e) => onChangeHandleremail(e.target.value)} datauserid={userid} value={useremail} size="30" maxlength="128" autoComplete="new-password" aria-autocomplete="list" />
                    <div id="list-dropdown" style={{ display: suggestionsemail == "false" ? "none" : "block" }}>
                      {(suggestionsemail.length == 0 && useremail != "") ?
                        <span className="list-items">
                          {t("No results found. Please try again.")}
                        </span> : suggestionsemail.map((emailsuggestion, i) =>
                          <span className="list-items" key={i} onClick={() => onSuggestHandleremail(emailsuggestion.mail, emailsuggestion.uid)} >{emailsuggestion.mail}</span>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="views-exposed-widget sort-by">
                <label htmlFor="sort-by"><T _str="Sort by" /></label>
                <div className="views-widget">
                  <div className="input-group">
                    <select value={sort} onChange={(val) => handleSortChange(val.target.value)} className="form-control form-select" id="edit-sort-by" name="sort_by"><option value="changed" selected="selected">{t("Updated date")}</option><option value="field_eserviceunitid_value">{t("Unit Number")}</option></select>
                  </div>
                </div>
              </div>
              <div className="views-exposed-widget sort-order">
                <label htmlFor="sort-by"><T _str="Sort Order" /></label>
                <div className="views-widget">
                  <div className="input-group">
                    <select onChange={(val) => handleOrderChange(val.target.value)} className="form-control form-select" id="edit-sort-order" name="sort_order"><option value="ASC">{t("Asc")}</option><option value="DESC" selected="selected">{t("Desc")}</option></select>
                  </div>
                </div>
              </div>

              <div className="views-exposed-widget views-submit-button">
                <button type="submit" id="edit-submit-devices-list" name="" value="Search" className="btn btn-primary form-submit"><T _str="Search" /></button>
                <button type="submit" id="edit-reset" name="op" value="Reset" onClick={refreshPage} className="btn btn-default form-submit"><T _str="Reset" /></button>
              </div>
		     {(devicedataall.length == 0) ?
			    <div className="export-exceldisable"> 
               	<a href="javascript:void(0)">			
			    <img src={excel} />
				</a>
				
			     </div>:
			  <div className="export-excel">  
			  <CSVLink
			  data={exportfull?device_data:alldevice}
			  filename={"eView2units_" + Date.now() +" .csv"}
			  headers={headers}
			  target="_blank"
			   >
			 <img src={excel} />
			  </CSVLink></div>}
            </form>
          </div>
          {(loading === false && loading1 === false && devicedata.length === 0) && (
            <div class="device-not-found">  <T _str="No Units Found" /></div>
          )}
          {(devicedata.length > 0) && (
            <div className="tbody-div">
              <table className="views-table cols-7 table table-hover table-striped">
                <thead><tr>
                  <th className="views-field field-eserviceunitid" scope="col"><T _str="Unit Number" /></th>
                  <th className="views-field field-mpdids" scope="col"><T _str="CP-ID" /> </th>
                  <th className="views-field field-devicetype" scope="col"><T _str="Device Type" /> </th>
                  <th className="views-field field-comm" scope="col"><T _str="Commission Status" /> </th>
                  <th className="views-field field-device" scope="col"><T _str="Device Status" /> </th>
                  <th className="views-field field-assign" scope="col"><T _str="Assign user" /> </th>
                  <th className="views-field field-bid" scope="col"><T _str="Building ID" /> </th>
                  <th className="views-field field-bname" scope="col"><T _str="Building Name" /> </th>
                  <th className="views-field field-addr" scope="col"><T _str="Street Address" /> </th>
                  <th className="views-field field-city" scope="col"><T _str="City" /> </th>
                  <th className="views-field field-count" scope="col"><T _str="Country" /> </th>
                  <th className="views-field field-cid" scope="col"><T _str="Contract ID" /> </th>
                  <th className="views-field field-cdate" scope="col"><T _str="Contract Date" /> </th>
                  <th className="views-field field-ctype" scope="col"><T _str="Contract Type" /> </th>
                  <th className="views-field field-cuid" scope="col"><T _str="Customer ID" /> </th>
                  <th className="views-field field-cuname" scope="col"><T _str="Customer Name" /> </th>
                  <th className="views-field field-pname" scope="col"><T _str="Playlist Name" /> </th>
                  <th className="views-field field-sdate" scope="col"><T _str="Schedule Date" /> </th>
                  <th className="views-field field-cdown" scope="col"><T _str="Content Download" /> </th>
                  <th className="views-field field-forev" scope="col"><T _str="Forever" /> </th>
                  <th className="views-field field-rec" scope="col"><T _str="Recurrence" /> </th>
                  <th className="views-field field-wdays" scope="col"><T _str="Week Days" /> </th>
                </tr></thead>
                <tbody>
                  {devicedata.map((row, index) => {
                     let items = currentcountrydefault;
                     let glddefault = globaldefault;
                     if (items.findIndex(item => item.DeviceID === row.field_mpdid) >= 0) {
                          var currentdefault = 1;
                     }else if (glddefault.findIndex(glddefaults => glddefaults.DeviceID === row.field_mpdid && glddefaults.Hash === null) >= 0 || glddefault.findIndex(glddefaults => glddefaults.DeviceID === row.field_mpdid && glddefaults.Hash === config.global_default_hash) >= 0) {
                          var defaultglobal = 1;
                     }else if (glddefault.findIndex(glddefaults => glddefaults.DeviceID === row.field_mpdid && glddefaults.Hash !== null) >= 0) {
                          var oldcountrydefault=1;
                     }
               
                        
                    return (
                      <tr className="views-row box" key={index}>
                        <td className="views-field field-eserviceunitid">{row.field_eserviceunitid.toString().length > 0 ? row.field_eserviceunitid:"-"}</td>
                        <td className="views-field field-mpdids"> {row.field_mpdid.toString().length > 0 ? row.field_mpdid:"-"}</td>
                        <td className="views-field field-devicetype"> {row.field_cpibdevicetype.toString().length > 0 ? row.field_cpibdevicetype:"-"}</td>
                        <td className="views-field field-comm"> {row.field_commissionstatusid.toString().length > 0 ? row.field_commissionstatusid:"-"}</td>
                        <td className="views-field field-device"> {row.field_device_status.toString().length > 0 ? row.field_device_status:"-"}</td>
                        <td className="views-field field-assign"> {row.field_assign_user.toString().length <= 0 ? "-" : ""} {row.field_assign_user.split(',').slice(0, 2).join(",")}
                          {row.field_assign_user.split(',').length <= 2 ? '' : <div><div class="email-moretooltip" data-tip={row.field_assign_user.split(',').slice(2, row.field_assign_user.split(',').length).join(",<br />")} data-html={true} data-offset="{'top': 10, 'left': 50}">+ {row.field_assign_user.split(',').length - 2} {t('Others')}</div><ReactTooltip effect='solid' className='extraClass' place={'bottom'} delayHide={1000} getContent={(dataTip) => `${dataTip}`} /></div>}
                        </td>
                        <td className="views-field field-bid"> {row.field_buildingid.toString().length > 0 ? row.field_buildingid:"-"}</td>
                        <td className="views-field field-bname"> {row.field_building_names.toString().length > 0 ? row.field_building_names:"-"}</td>
                        <td className="views-field field-addr"> {row.field_unit_streetaddress.toString().length > 0 ? row.field_unit_streetaddress:"-"}</td>
                        <td className="views-field field-city"> {row.field_unit_city.toString().length > 0 ? row.field_unit_city:"-"}</td>
                        <td className="views-field field-count"> {row.field_country_nid.toString().length > 0 ? row.field_country_nid:"-"}</td>
                        <td className="views-field field-cid"> {row.field_contractid.toString().length > 0 ? row.field_contractid:"-"}</td>
                        <td className="views-field field-cdate"> {row.field_contractdate.toString().length > 0 ? row.field_contractdate:"-"}</td>
                        <td className="views-field field-ctype"> {row.field_contracttype.toString().length > 0 ? row.field_contracttype:"-"}</td>
                        <td className="views-field field-cuid"> {row.field_customerid.toString().length > 0 ? row.field_customerid:"-"}</td>
                        <td className="views-field field-cuname">{row.field_customername.toString().length > 0 ? row.field_customername:"-"}</td>
                        <td colSpan="6">
						 {(row.view.rows.length == 0) &&
                        <>
                          {(currentdefault === 1) &&
                            
                                <table className="inner-table table table-bordered table-responsive">
                                  <tr>
                                    <td className="views-field field-pname">Country Default</td>
                                    <td className="views-field field-sdate">-</td>
                                    <td className="views-field field-cdown">-</td>
                                    <td className="views-field field-forev">-</td>
                                    <td className="views-field field-rec">-</td>
                                    <td className="views-field field-wdays">-</td>
                                  </tr>
                                </table>
                              
                            }
                              {(defaultglobal === 1) &&
                     
                                <table className="inner-table table table-bordered table-responsive">
                                  <tr>
                                    <td className="views-field field-pname">Global Default</td>
                                    <td className="views-field field-sdate">-</td>
                                    <td className="views-field field-cdown">-</td>
                                    <td className="views-field field-forev">-</td>
                                    <td className="views-field field-rec">-</td>
                                    <td className="views-field field-wdays">-</td>
                                  </tr>
                                </table>
                              
                            }
                              {(oldcountrydefault === 1) &&
                     
                                <table className="inner-table table table-bordered table-responsive">
                                  <tr>
                                    <td className="views-field field-pname">Global Default / Old Country Default</td>
                                    <td className="views-field field-sdate">-</td>
                                    <td className="views-field field-cdown">-</td>
                                    <td className="views-field field-forev">-</td>
                                    <td className="views-field field-rec">-</td>
                                    <td className="views-field field-wdays">-</td>
                                  </tr>
                                </table>
                              
                            }
							</>
						 }
                          {row.view.rows.map((row1, index1) => {
                            return (
                              <table className="inner-table table table-bordered table-responsive">
                                <tr>
                                  <td className="views-field field-pname">{row1.title.toString().length > 0 ? row1.title:"-"}</td>
                                  <td className="views-field field-sdate">{row1.field_schedule_date.toString().length > 0 ? row1.field_schedule_date:"-"}</td>
                                  <td className="views-field field-cdown">{row1.field_transmit.toString().length > 0 ? row1.field_transmit:"-"}</td>
                                  <td className="views-field field-forev">{row1.field_forever.toString().length > 0 ? row1.field_forever:"-"}</td>
                                  <td className="views-field field-rec">{row1.field_recurrence.toString().length > 0 ? row1.field_recurrence:"-"}</td>
                                  <td className="views-field field-wdays">{row1.field_weekdays.toString().length > 0 ? row1.field_weekdays:"-"}</td>
                                </tr>
                              </table>
                            )
                          }
                          )
                          }
                        
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          )}
          <div className="device-list-footer">
            {(perPage * (page + 1)) < totalItems && <button className="btn-load-more" onClick={() => { setPage(page + 1); setSearchPage(0) }}>{loading ? '' : <T _str='Load more' />}</button>}
            {totalItems !== 0 && <span className="list-count">{t('Displaying @start - @end of @total').replace('@start', 1).replace('@end', (perPage * (page + 1)) < totalItems ? perPage * (page + 1) : totalItems).replace('@total', totalItems)}</span>}
          </div>
        </div>
      </LoadingOverlay>
      </LoadingOverlay>
    </>
  )
}

export default UnitsReport;