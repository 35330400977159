/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: AppInsights.js
File Description: To integrate AppInsights

Copyright 2024 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
import config from "./utils/config";
import {
  ApplicationInsights,
  SeverityLevel,
} from "@microsoft/applicationinsights-web";
import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";

const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
  config: {
    instrumentationKey: config.appinsights_key,
    extensions: [reactPlugin],
  },
});
ai.loadAppInsights();

export default (Component) => withAITracking(reactPlugin, Component, severity);
export const appInsights = ai.appInsights;
export const severity = SeverityLevel;
